/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { classNames } from "../../../../utils"

import React, { useState, useEffect, Component } from "react"
import Select from "react-select"
import { selectStyles } from "../../../../styled/Select"
import ErrorMessage from "../ErrorMessage"
import FloatingSelect from "../../../Elements/Form/FloatingSelect"
import MetaDownload from "../../../Elements/MetaDownload"
import MetaDelivery from "../../../Elements/MetaDelivery"
const sData = [
  {
    value: "digital",
    label: (
      <div className="flex justify-between">
        Digital
        <MetaDownload />
      </div>
    ),
  },
  {
    value: "physical",
    label: (
      <div className="flex justify-between">
        Physical
        <MetaDelivery />
      </div>
    ),
  },
]
function ListString({
  data,
  errors,
  name,
  register,
  label,
  setValue,
  watch,
  control,
  isDisabled,
}) {
  // console.log(name)
  const [select1, setSelect1] = useState(null)
  const [selectValue, setSelectValue] = useState(null)
  const [defaultValue, setDefaultValue] = useState([])
  const { field_asset_type } = watch()
  const options =
    name === "field_asset_type"
      ? sData
      : data.allowed_values &&
        data.allowed_values.map(item => {
          return { value: item.key, label: item.title }
        })
  // console.log(options)
  useEffect(() => {
    if (data.current_value) {
      if (Array.isArray(data.current_value)) {
        let a = data.current_value.map(item => {
          if (typeof item === "string") return { value: item, label: item }
          else return { value: item.target_id, label: item.title }
        })
        setDefaultValue([...a])
        // console.log(a);
      } else {
        // setDefaultValue([
        //   { value: data.current_value, label: data.current_value },
        // ]);
        const defaultValue =
          data.current_value &&
          data.allowed_values &&
          data.allowed_values.filter(item => item.key === data.current_value)

        if (defaultValue)
          setSelectValue({
            value: defaultValue.key,
            label: defaultValue.title,
          })
      }
    } else if (data.default_value) {
      const defaultValue =
        data.allowed_values &&
        data.allowed_values.filter(item => item.key === data.default_value)[0]
      if (defaultValue)
        setSelectValue({
          value: defaultValue.key,
          label: defaultValue.title,
        })
    }
    if (name === "field_asset_type" && setValue) {
      if (data.current_value) {
        setValue(name, data.current_value)
      } else {
        setValue(name, field_asset_type || "digital")
      }
    }

    // console.log(defaultValue);
    // console.log(selectValue)
  }, [])
  // console.log(data);
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    const inputRef = action.name
    // console.log(action)
    const currentValue = watch(inputRef)
    setSelectValue(value)
    if (setValue) {
      if (value !== null) {
        const newValue = value.value
        if (childSelect && newValue !== currentValue) {
          setValue(childSelect, null)
        }
        setValue(inputRef, value.value)
        setStateFunction(value)
      } else {
        setValue(inputRef, data.current_value)
        setStateFunction(data.current_value)
      }
    }

    // if(childSelect && )
  }

  useEffect(() => {
    // if (data.required) {
    //   register(name, { required: "This field is required" })
    // } else {
    //   register(name)
    // }
    if (data.current_value && setValue) {
      // console.log(data.current_value)
      setValue(name, data.current_value)
    }
  }, [])
  console.log(defaultValue)

  const isSSR = typeof window === "undefined"
  // <span dangerouslySetInnerHTML={{ __html: data.description }}></span>
  return (
    <>
      {data.value_limit ? (
        <FloatingSelect
          // value={select1 !== null ? select1 : defaultValue[0]}
          name={name}
          options={options}
          isSearchable={true}
          // onChange={(value, action) => {
          //   handleChange(value, action, setSelect1, name)
          // }}
          isClearable
          defaultValue={defaultValue[0]}
          label={label}
          required={data.required}
          hookForm
          control={control}
          register={register}
          isDisabled={isDisabled}
          type={name === "field_asset_type" ? "field_asset_type" : false}
        />
      ) : (
        <FloatingSelect
          name={name}
          label={label}
          options={options}
          // value={select1 !== null ? select1 : defaultValue}
          isSearchable={true}
          required={data.required}
          hookForm
          control={control}
          type={name === "field_asset_type" ? "field_asset_type" : false}
          register={register}
          isClearable
          isMulti
          defaultValue={defaultValue}
        />
      )}
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </>
  )
}

export default ListString
