/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, {
  useState,
  useEffect,
  useRef,
  Component,
  useContext,
} from "react"

import Select, { components } from "react-select"
import Modal from "../../../Modal/Modal"
import FloatingSelect from "../../../Elements/Form/FloatingSelect"
import { FixedSizeList } from "react-window"
import { InView } from "react-intersection-observer"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import ErrorMessage from "../ErrorMessage"
import {
  entitySuggestions,
  myEntitySuggestions,
} from "../../../../helpers/DrupalHelper"
import {
  selectStyles,
  DropdownIndicator,
  ClearIndicator,
} from "../../../../styled/Select"
import DataContext from "../../../../context/Data/DataContext"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import ImageCardSmall from "../../../Elements/ImageCardSmall"
import CircleAvatar from "../../../Elements/CircleAvatar"
import AsyncFloatingSelect from "../../../Elements/Form/AsyncFloatingSelect"

function arrayMove(array, from, to) {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
  return array
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})
const SortableSelect = SortableContainer(Select)
const height = 35

const MenuList = props => {
  const { options, children, maxHeight, getValue } = props

  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  )
}
const myEntitiesItem = [
  "brand",
  "asset",
  "product",
  "store",
  "company",
  "retailer",
]
function EntityReferenceRevisions({
  data,
  errors,
  name,
  register,
  label,
  setValue,
  token,
  watch,
  control,
  description,
  restrictCheck,
  setRestrictCheck,
  setTagsData,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState([
    { value: "demo", label: "demo" },
  ])
  useEffect(() => {
    if (data.current_value?.length) {
      setRestrictCheck && setRestrictCheck(true)
    }
  }, [])
  const [select1, setSelect1] = useState(null)
  const [defaultValue, setDefaultValue] = useState([])
  const [inView, setInView] = useState(false)
  const { userType } = useContext(AuthenticationContext)

  // console.log(userType, "usertype")
  const { myUsersAsOptions, myBrandsAsOptions } = useContext(DataContext).data
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // console.log(select1)
    const newValue = arrayMove(select1, oldIndex, newIndex)
    setValue(
      `${name}`,
      newValue.map(i => i.value)
    )

    setSelect1(newValue)
    // console.log(
    //   "Values sorted:",
    //   newValue.map((i) => i.value)
    // );
  }
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    if (
      data.value_limit &&
      data.value_limit > 1 &&
      Array.isArray(value) &&
      value.length > data.value_limit
    ) {
      return
    } else {
      const inputRef = action.name
      const currentValue = watch(inputRef)
      // console.log(value)
      if (setValue) {
        if (value !== null) {
          // setValue(inputRef, value);
          setValue(
            `${name}`,
            Array.isArray(value) ? value.map(v => v.value) : [value.value]
          )
          setStateFunction(value)
        } else {
          // setValue(inputRef, null);
          setStateFunction(null)
        }
      }
    }

    // if(childSelect && )
  }

  const entity = data.entity_type.machine_name
  const entityTitle = data.entity_type.title
  useEffect(async () => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      })
    } else {
      register(name)
    }
    const entity = data.entity_type.machine_name
    // console.log(name, entity)
    const bundle = data.entities
      ? data.entities.map(v => v.machine_name)
      : data.entity_type.machine_name
    if (data.current_value && setValue) {
      setValue(
        `${name}`,
        data.current_value.map(item => item.target_id.toString())
      )

      let a = data.current_value.map(item => {
        return { value: item.target_id, label: item.title }
      })

      setDefaultValue([...a])
      setSelect1([...a])
    }

    // // console.log(entity, bundle);
    // if (name == "field_product_brand") {
    //   setEntitySuggestion([...myBrandsAsOptions])
    // } else {
    let datas = []
    if (name === "field_group_users" || name === "field_store_user") {
      setEntitySuggestion([...myUsersAsOptions])
      return
    }
    if (entity !== "asset") {
      if (myEntitiesItem.includes(entity)) {
        datas = await myEntitySuggestions(token, entity, bundle)
        // console.log(datas, "from my entities")
      } else {
        datas = await entitySuggestions(token, entity, bundle)
        if (setTagsData) {
          setTagsData(datas)
        }
      }
    }

    if (datas) {
      let ss = datas
      if (entity === "brand")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: (
              <div className="flex items-center">
                <CircleAvatar image={v.thumb} border xs />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      else if (entity === "asset") return
      // setEntitySuggestion(
      //   ss.map(v => ({
      //     ...v,
      //     value: v.target_id ? v.target_id : v.id,
      //     label: (
      //       <div className="flex items-center">
      //         <ImageCardSmall image={v.thumb} size={40} />
      //         <span className="ml-2">{v.title}</span>
      //         {/*<LazyImage src={v.cover} className="ml-6" />*/}
      //       </div>
      //     ),
      //   }))
      // )
      else if (entity === "product")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: (
              <div className="flex items-center">
                <ImageCardSmall image={v.thumb} size={40} />
                <span className="ml-2"> {v.title}</span>
                {/*<LazyImage
                  src={
                    v?.img && Array.isArray(v?.img) && v.img[0] ? v.img[0] : ""
                  }
                  className="ml-6"
                />*/}
              </div>
            ),
          }))
        )
      else if (entity === "company")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else if (entity === "retailer")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else if (entity === "store")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else
        setEntitySuggestion([
          ...datas.map(item => {
            return { value: item.target_id, label: item.title }
          }),
        ])
    } else {
      // }
    }
  }, [])
  useEffect(() => {
    if (name === "field_group_users") {
      setEntitySuggestion([...myUsersAsOptions])
    }
    if (name == "field_product_brand" && setValue) {
      console.log("field_product_brand")
      setEntitySuggestion([...myBrandsAsOptions])
      setValue(name, myBrandsAsOptions[0].value)
      setSelect1(myBrandsAsOptions[0])
    }
  }, [myUsersAsOptions, myBrandsAsOptions])
  // useEffect(() => {
  //   if (inView && !entitySuggestion.length) {
  //     const entity = data.entity_type.machine_name;
  //     const bundle = data.entities[0].machine_name;
  //     const token = localStorage.getItem("token");
  //     const EntitySuggestion = async () => {
  //       await EntitySuggestions(token, entity, bundle).then(async (data) => {
  //         let ss = data.map((item) => {
  //           return { value: item.target_id, label: item.title };
  //         });
  //         setEntitySuggestion(ss);
  //       });
  //     };
  //     EntitySuggestion();
  //   }
  // }, [inView]);

  const bundle = data.entities
    ? data.entities.map(v => v.machine_name)
    : data.entity_type.machine_name
  const [open, setOpen] = useState(false)
  const [selectedBundle, setSelectedBundle] = useState({
    label: bundle[0],
    value: bundle[0],
  })

  const handleClose = () => {
    setOpen(false)
    // props.close(false);
    setSelectedBundle(null)
  }
  const isSaved = async data => {
    // let value ={}
    // setEntitySuggestion([...entitySuggestion,value);
    // setSelect1(value);
    // props.close(false);
    // await EntitySuggestions(token, entity, bundle).then(async (data) => {
    //   let ss = data.map((item) => {
    //     return { value: item.target_id, label: item.title };
    //   });
    //   console.log(data);
    //   let newItem = ss.filter((item) => {
    //     return entitySuggestion.every((item2) => {
    //       if (JSON.stringify(item) !== JSON.stringify(item2)) {
    //         return item;
    //       }
    //     });
    //   });
    //   setEntitySuggestion([...ss]);
    //   setSelect1(newItem);
    //   setValue(`${name}`, newItem);

    setOpen(false)
    // });
    if (data && setValue) {
      let { id, title } = data
      let value = { id: id[0].value, label: title[0].value }
      let newItem = [...select1, value]
      setEntitySuggestion([...entitySuggestion, value])
      if (data.value_limit === 1) {
        setSelect1(value)
        setValue(`${name}`, value)
      } else {
        setSelect1(newItem)
        setValue(`${name}`, newItem)
      }
    }

    // console.log("saved in entity", data)
  }
  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
  const [hideSelField, setHide] = useState(
    !data?.current_value?.length && name === "field_user_groups" ? true : false
  )

  useEffect(() => {
    setHide(restrictCheck)
  }, [])

  if (
    entity === "asset" ||
    (entity === "store" &&
      name === "field_store" &&
      (userType.brandEditor || userType.brandManager))
  )
    return (
      <div className={`relative mb-6 w-full group `}>
        <AsyncFloatingSelect
          label={data.label}
          name={name}
          hookForm
          register={register}
          error={errors && errors[name]}
          control={control}
          myEntitySuggest={entity === "asset" ? true : false}
          required={data.required}
          description={description}
          body={{
            bundle: bundle,
            entity: entity,
          }}
          isMulti={data.value_limit !== 1}
          defaultValue={
            data.current_value
              ? data.current_value.map(item => {
                  return { value: item.target_id, label: item.title }
                })
              : null
          }
        />
      </div>
    )
  else
    return (
      <>
        {name === "field_user_groups" && (
          <div
            className={
              "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
            }
          >
            <div className="flex">
              <div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                    onChange={e => {
                      setRestrictCheck && setRestrictCheck(e.target.checked)
                      if (e.target.checked) {
                        setHide(false)
                      } else {
                        setValue && setValue(`${name}`, "")
                        setSelect1(null)
                        setHide(true)
                      }
                    }}
                    defaultChecked={
                      data.current_value?.length ? true : restrictCheck
                    }
                  />
                  <label className="ml-3 text-[#222] flex flex-col">
                    Restrict availability
                  </label>
                </div>

                <div
                  css={css`
                    font-family: "Jost";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    display: inline-block;
                    padding: 0 10px;
                    margin: 0 0 0 18px;
                    letter-spacing: 0.02em;
                    color: #717171;
                  `}
                >
                  This asset is only available to certain User Groups or Regions
                </div>
              </div>
            </div>
          </div>
        )}
        {(restrictCheck === true || name !== "field_user_groups") && (
          <div className={`relative mb-6 w-full group `}>
            <FloatingSelect
              name={name}
              options={entitySuggestion}
              isSearchable={true}
              onChange={(value, action) => {
                handleChange(value, action, setSelect1, name)
              }}
              isClearable
              value={select1 !== null ? select1 : defaultValue[0]}
              components={{
                MenuList,
                DropdownIndicator: DropdownIndicator,
                ClearIndicator,
              }}
              hookForm
              error={errors}
              control={control}
              defaultValue={defaultValue[0]}
              isMulti={data["value_limit"] === 1 ? false : true}
              description={description}
              // styles={selectStyles}
            />

            {/*
          {entitySuggestion !== null && entitySuggestion.length > 80 ? (
            <InView onChange={setInView}>
              {({ ref, inView }) => (
                <div key={name} ref={ref}>

                  {data.value_limit ? (
                    data.value_limit > 1 ? (
                      <SortableSelect
                        // react-sortable-hoc props:
                        axis="xy"
                        onSortEnd={onSortEnd}
                        distance={4}
                        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                        getHelperDimensions={({ node }) =>
                          node.getBoundingClientRect()
                        }
                        // react-select props:

                        closeMenuOnSelect={false}
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        components={{
                          MenuList,
                          MultiValue: SortableMultiValue,
                          DropdownIndicator: DropdownIndicator,
                          ClearIndicator,
                        }}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name)
                        }}
                        styles={selectStyles}
                        isClearable
                        value={select1}
                        isMulti
                      />
                    ) : (
                      <Select
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name)
                        }}
                        isClearable
                        value={select1 !== null ? select1 : defaultValue[0]}
                        components={{
                          MenuList,
                          DropdownIndicator: DropdownIndicator,
                          ClearIndicator,
                        }}
                        defaultValue={defaultValue[0]}
                        styles={selectStyles}
                      />
                    )
                  ) : (
                    <SortableSelect
                      // react-sortable-hoc props:
                      axis="xy"
                      onSortEnd={onSortEnd}
                      distance={4}
                      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      // react-select props:
                      components={{
                        MenuList,
                        MultiValue: SortableMultiValue,
                        DropdownIndicator: DropdownIndicator,
                        ClearIndicator,
                      }}
                      closeMenuOnSelect={false}
                      name={name}
                      options={entitySuggestion}
                      isSearchable={true}
                      onChange={(value, action) => {
                        handleChange(value, action, setSelect1, name)
                      }}
                      styles={selectStyles}
                      isClearable
                      value={select1 !== null ? select1 : defaultValue}
                      isMulti
                    />
                  )}
                </div>
              )}
            </InView>
          ) : (
            <div key={name}>

              {data.value_limit ? (
                data.value_limit > 1 ? (
                  <SortableSelect
                    // react-sortable-hoc props:
                    axis="xy"
                    onSortEnd={onSortEnd}
                    distance={4}
                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                    getHelperDimensions={({ node }) =>
                      node.getBoundingClientRect()
                    }
                    // react-select props:
                    components={{
                      MultiValue: SortableMultiValue,
                      DropdownIndicator: DropdownIndicator,
                      ClearIndicator,
                    }}
                    closeMenuOnSelect={false}
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    // components={{ MenuList }}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name)
                    }}
                    styles={selectStyles}
                    isClearable
                    value={select1}
                    isMulti
                  />
                ) : (
                  <Select
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name)
                    }}
                    isClearable
                    value={select1}
                    // components={{ MenuList }}
                    defaultValue={defaultValue[0]}
                    styles={selectStyles}
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      ClearIndicator,
                    }}
                  />
                )
              ) : (
                <SortableSelect
                  // react-sortable-hoc props:
                  axis="xy"
                  onSortEnd={onSortEnd}
                  distance={4}
                  // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                  getHelperDimensions={({ node }) =>
                    node.getBoundingClientRect()
                  }
                  // react-select props:

                  components={{
                    MultiValue: SortableMultiValue,
                    DropdownIndicator: DropdownIndicator,
                    ClearIndicator,
                  }}
                  closeMenuOnSelect={false}
                  name={name}
                  options={entitySuggestion}
                  isSearchable={true}
                  // components={{ MenuList }}
                  onChange={(value, action) => {
                    handleChange(value, action, setSelect1, name)
                  }}
                  styles={selectStyles}
                  isClearable
                  value={select1 !== null ? select1 : defaultValue}
                  isMulti
                />
              )}

              {errors && errors[name] && (
                <ErrorMessage message={errors[name]["message"]} />
              )}
            </div>
          )}
          */}
            <label
              for={name}
              className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
              css={css`
                ${fontStyles}
              `}
            >
              {label}
            </label>
          </div>
        )}
      </>
    )
}

export default EntityReferenceRevisions
