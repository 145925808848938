/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import Button from "../../Elements/Button"
import Tag from "../../Elements/Tag"
import Tags from "../../Elements/Tags"
import { Table, Tr, Td } from "../TableElements"
import ButtonAdd from "../../Elements/ButtonAdd"
import ButtonEdit from "../../Elements/ButtonEdit"
import ButtonDelete from "../../Elements/ButtonDelete"
import MetaDownload from "../../Elements/MetaDownload"
import MetaDelivery from "../../Elements/MetaDelivery"
import MetaMini from "../../Elements/MetaMini"
import ImageCardSmall from "../../Elements/ImageCardSmall"
import CircleAvatar from "../../Elements/CircleAvatar"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import { deleteMySingleForm } from "../../../helpers/DrupalHelper"
import FloatingCheckbox from "../../Elements/Form/FloatingCheckbox"
import UnhideIcon from "../../../assets/icons/unhide-eye.svg"
import InventoryContext from "../../../context/Inventory/InventoryContext"

const InventoryItem = props => {
  const { amount, title } = props
  return (
    <div className="leading-[1]">
      <span className="">{amount}</span>
      <span
        className="text-med-10 uppercase pl-1"
        css={css`
          color: #a1a1a1;
        `}
      >
        {title}
      </span>
    </div>
  )
}
const Inventory = props => {
  const { getInventoryByMajorAllocationGroup } = useContext(InventoryContext)
  const { segments, stock } = props
  if (stock === 0 && !props.digital)
    return (
      <>
        No stock
        <br />
        remaining
      </>
    )

  const data = getInventoryByMajorAllocationGroup(segments, stock)
  console.log(data)
  return (
    <div className="flex flex-col space-y-1 items-start">
      {data.internal !== 0 && (
        <InventoryItem amount={data.internal} title="Internal" />
      )}
      {data.retailer !== 0 && (
        <InventoryItem amount={data.retailer} title="Retail" />
      )}
      {data.unallocated !== 0 && !props.digital && (
        <InventoryItem amount={data.unallocated} title="Unallocated" />
      )}
    </div>
  )
}

const toCapitalCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export default function ProductsTable({
  data,
  onEditClick,
  onDeleteClick,
  fromAssetGroup,
  addInventory,
  // setModifiedData,
  // modifiedData,
  isOptionShow,
  setIsOptionShow,
  // selectedItems,
  fileData,
  setFileData,
}) {
  const { authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { brands } = currentUserData
  const { allUserGroupsAsOptions } = useContext(InventoryContext)

  console.log(allUserGroupsAsOptions)
  if (fromAssetGroup) {
    return (
      <Table columns={["ID", "Title", "Status", "Actions"]}>
        {data?.map((file, k) => {
          let assetImg = file?.cover && file?.cover[0]
          return (
            <Tr key={k}>
              <Td>{file.id ? file.id : file.target_id}</Td>
              <Td>
                <div className="flex items-center space-x-4">
                  <ImageCardSmall image={assetImg} alt={""} />
                  <div className="flex flex-col max-w-[70%]">
                    <span className="md:whitespace-pre-line md:break-all">
                      {file.title}
                    </span>
                  </div>
                </div>
              </Td>

              {/*<Td>
            <Tags>
              {file.field_asset_tags &&
                file.field_asset_tags.map(v => <Tag>{v.title}</Tag>)}
            </Tags>
          </Td>*/}
              <Td>
                {file.field_active === "1" || file.field_active === true ? (
                  <span style={{ color: "#009D4F" }}>Published</span>
                ) : (
                  <span className="red">Unpublished</span>
                )}
              </Td>
              <Td className="">
                <div className="flex space-x-6">
                  <ButtonEdit
                    text="Edit"
                    onClick={() =>
                      onEditClick(
                        file.id ? file.id : file.target_id,
                        file.bundle
                      )
                    }
                  />
                  {file.field_asset_type !== "digital" && file.bundle !== "asset_group" && (
                    <ButtonAdd
                      text="Add Inventory"
                      onClick={() => addInventory(file)}
                    />
                  )}
                  <ButtonDelete
                    text="Delete"
                    onClick={() => onDeleteClick(file.id, file.bundle)}
                  />
                </div>

                {/*  <button
              className="text-indigo-600 hover:text-indigo-900"
              onClick={() => onEditClick(file.id)}
            >
              Edit
            </button>
            <button
              onClick={() => {}}
              className="text-indigo-600 hover:text-red-600"
            >
              Delete
            </button> */}
              </Td>
            </Tr>
          )
        })}
      </Table>
    )
  } else
    return (
      <Table
        columns={[
          // "",
          // <FloatingCheckbox
          //   hookForm={false}
          //   gray
          //   register
          //   onChange={e => {
          //     setModifiedData(
          //       modifiedData?.map(item => {
          //         return {
          //           ...item,
          //           checked: e.target.checked,
          //         }
          //       })
          //     )
          //   }}
          //   className={"!w-7 !h-7 !ml-0 cursor-pointer !mr-0"}
          //   flush
          //   value={modifiedData?.every(item => item.checked === true)}
          // />,
          "SKU",
          "Title",
          // "Group",
          "Brand",
          "Category",
          "Type",
          // "Status",
          "Segments",
          "Inventory",
          "Allocations",
          // "Created",
          "Last Updated",
          "Actions",
        ]}
      >
        {data?.map((file, k) => {
          let assetImg = null

          const createDate = new Date(file.created * 1000)

          const updateDate = new Date(file.changed * 1000)

          // Format the date (e.g., YYYY-MM-DD format)
          const formattedDateCreate = createDate.toLocaleDateString()
          const formattedDateUpdate = updateDate.toLocaleDateString()
          let stock = 0

          if (file.fulfillment_type === "o2o") stock = file.stock_o2o
          if (file.fulfillment_type === "in-house") stock = file.stock_in_house
          if (file.fulfillment_type === "third-party")
            stock = file.stock_third_party

          console.log(file, "file")
          if (
            Array.isArray(file?.field_asset_cover_image) &&
            file.field_asset_cover_image[0]?.thumb
          )
            assetImg = file.field_asset_cover_image[0].thumb
          if (file.bundle === "asset_group" && file.field_images[0]?.thumb)
            assetImg = file.field_images[0].thumb
          // console.log(assetImg, file)
          return (
            <Tr key={k} className="cursor-pointer">
              {
                //  <Td className={"!px-4 !py-4"}>
                //   <FloatingCheckbox
                //     hookForm={false}
                //     gray
                //     register
                //     flush
                //     onChange={e => {
                //       setIsOptionShow(e.target.checked)
                //       setFileData(file)
                //     }}
                //     // onChange={e =>
                //     //   setModifiedData(
                //     //     modifiedData?.map((v, i) => {
                //     //       if (i === k) {
                //     //         return {
                //     //           ...v,
                //     //           checked: e.target.checked,
                //     //         }
                //     //       }
                //     //       return v
                //     //     })
                //     //   )
                //     // }
                //     className={"!w-7 !h-7 !ml-0 cursor-pointer !mr-0"}
                //     value={isOptionShow && fileData?.id === file?.id}
                //   />
                // </Td>
              }
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <div className="!max-w-[100px] !break-all whitespace-nowrap">
                  {file.field_asset_sku}
                </div>
              </Td>
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <div className="flex items-center space-x-4">
                  <ImageCardSmall image={assetImg} alt={""} size={60} />
                  <div className="flex flex-col max-w-[70%]">
                    <span className="whitespace-pre-line ">{file.title}</span>
                    <div className="flex flex-col gap-2">
                      {file.field_active === "1" ? (
                        <span className="whitespace-pre-line">
                          <MetaMini grey title="Published" noHeight />
                        </span>
                      ) : (
                        <span className="whitespace-pre-line">
                          <MetaMini grey title="Unpublished" noHeight />
                        </span>
                      )}
                      {file.bundle === "asset_group" &&
                        file.field_assets.length > 0 && (
                          <span className="whitespace-pre-line">
                            <MetaMini
                              noHeight
                              title={
                                file.field_assets.length === 1
                                  ? `${file.field_assets.length} variation`
                                  : `${file.field_assets.length} variations`
                              }
                            />
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </Td>
              {/* <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <span className="whitespace-normal text-med-14">
                  Brand X : Music Videos
                </span>
              </Td> */}
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <div className="flex items-center space-x-2 relative z-[0]">
                  <CircleAvatar
                    image={
                      brands.filter(
                        f =>
                          parseInt(f.id) ===
                          parseInt(file.field_asset_brand[0]?.target_id)
                      )[0]?.logo
                    }
                    xs
                  />
                  <span className="whitespace-normal">
                    {file.field_asset_brand[0]?.title}
                  </span>
                </div>
              </Td>
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                {file.field_asset_category?.length > 0 &&
                  file.field_asset_category?.map((v, k) => (
                    <span className="whitespace-normal text-med-14" key={k}>
                      {v.title}
                    </span>
                  ))}
              </Td>
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                {file.field_asset_type === "digital" ? (
                  <MetaDownload />
                ) : (
                  <MetaDelivery />
                )}
              </Td>
              {/*<Td>
            <Tags>
              {file.field_asset_tags &&
                file.field_asset_tags.map(v => <Tag>{v.title}</Tag>)}
            </Tags>
          </Td>*/}
              {
                //  <Td
                //   className={"!px-4 !py-4"}
                //   onClick={() => onEditClick(file.id, file.bundle)}
                // >
                //   {file.field_active === "1" ? (
                //     <span style={{ color: "#009D4F" }}>Published</span>
                //   ) : (
                //     <span className="red">Unpublished</span>
                //   )}
                // </Td>
              }
              <Td onClick={() => onEditClick(file.id, file.bundle)}>
                <div
                  className={`${file?.segments?.length > 0 && `max-w-[200px] w-[200px]`
                    } whitespace-pre-line`}
                >
                  {file.segments && file.segments.length > 0 &&
                    file.segments.map(i => (
                      <>
                        {
                          allUserGroupsAsOptions?.find(
                            f => parseInt(f.value) === parseInt(i.roles[0])
                          )?.label
                        }
                        <br />
                      </>
                    ))}
                </div>
              </Td>
              <Td>{stock}</Td>
              <Td onClick={() => onEditClick(file.id, file.bundle)}>
                <Inventory
                  stock={stock}
                  segments={file.segments}
                  digital={file.field_asset_type === "digital"}
                />
              </Td>
              {/* <Td className="">
                <div className="flex space-x-6">
                  <ButtonEdit
                    text="Edit"
                    onClick={() => onEditClick(file.id, file.bundle)}
                  />
                  <ButtonDelete
                    text="Delete"
                    onClick={() => onDeleteClick(file.id, file.bundle)}
                  />
                </div>

              </Td> */}
              {/* <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <span className="whitespace-normal text-med-14">
                  All Canada, West Reps
                </span>
              </Td>
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <span className="whitespace-normal text-med-14">
                  0 Internal 90 External 100 Unallocated
                </span>
              </Td> */}
              {
                //  <Td
                //   className={"!px-4 !py-4"}
                //   onClick={() => onEditClick(file.id, file.bundle)}
                // >
                //   <span className="whitespace-normal text-med-14">
                //     {formattedDateCreate}
                //   </span>
                // </Td>
              }
              <Td
                className={"!px-4 !py-4"}
                onClick={() => onEditClick(file.id, file.bundle)}
              >
                <span className="whitespace-normal text-med-14">
                  {formattedDateUpdate}
                </span>
              </Td>
              <Td className={"!px-4 !py-4"}>
                <div className="flex flex-col gap-3">
                  <ButtonEdit
                    text="Edit"
                    onClick={() => onEditClick(file.id, file.bundle)}
                  />
                  {file.field_asset_type !== "digital" && file.bundle !== "asset_group" && (
                    <ButtonAdd
                      text="Add Inventory"
                      onClick={() => {

                        console.log(file, 'file')
                        addInventory(file)

                      }}
                    />)}
                  <ButtonDelete
                    text="Delete"
                    onClick={() => onDeleteClick(file.id, file.bundle)}
                  />
                </div>
                {/* <div className="bg-[#d9ead3] p-1 rounded-[12px] cursor-pointer">
                  <UnhideIcon />
                </div> */}
              </Td>
            </Tr>
          )
        })}
      </Table>
    )
}
