/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useRef, useState } from "react"
import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import Tag from "./Elements/Tag"
import { classNames } from "../utils"
import { Link } from "gatsby"
import LazyImage from "./LazyImage"
import Skeleton from "./Skeleton"
import { useMediaQuery } from "react-responsive"
import Carousel, { Modal, ModalGateway } from "react-images"
import Zoom from "./ImagePan"
import MaxIcon from "../assets/icons/max.svg"
import IconClose from "./Elements/IconClose"
import MetaMini from "./Elements/MetaMini"
import "swiper/swiper-bundle.css"
import "./swiperArrow.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper"

SwiperCore.use([Navigation, Pagination])

const CustomHeader = props => {
  const {
    components,
    getStyles,
    getCloseLabel,
    getFullscreenLabel,
    innerProps,
    isModal,
    modalProps,
    currentView,
    currentIndex,
    carouselProps,
  } = props
  const { allowFullscreen, isFullscreen, onClose, toggleFullscreen } =
    modalProps
  // const FsIcon = isFullscreen ? FullscreenExit : FullscreenEnter
  const { CloseButton, FullscreenButton } = components
  const state = { isFullscreen, isModal }
  const { caption } = currentView
  // console.log(props)
  // console.log("innerProps")
  if (isModal) {
    return (
      <div
        {...innerProps}
        className="border-b border-[#EBEBEB] py-[15px] px-[15px] w-full flex items-center"
      >
        <h2 className="text-strong-20 flex flex-col items-start !leading-[37px]">
          <span>{caption}</span>{" "}
          <div className="mb-[5px] !leading-[1]">
            <MetaMini
              title={`Preview ${currentIndex + 1} of ${
                carouselProps.views.length
              }`}
            />
          </div>
        </h2>
        {/*allowFullscreen ? (
  <FullscreenButton
    getStyles={getStyles}
    innerProps={{
      onClick: toggleFullscreen,
      title: getFullscreenLabel(state),
    }}
  >
    <FsIcon size={32} />
  </FullscreenButton>
) : null*/}
        <div className="ml-auto">
          <CloseButton
            getStyles={getStyles}
            innerProps={{
              onClick: onClose,
              title: getCloseLabel(state),
            }}
          >
            <IconClose />
          </CloseButton>
        </div>
      </div>
    )
  }
  return null
}

const SingleImage = ({ image, sm }) => {
  const [width, setWidth] = useState(600)
  const [height, setHeight] = useState(600)
  const [imgLink, setLink] = useState("")
  useEffect(() => {
    setLink(image)

    var elImg = new Image()
    elImg.loading = "eager"
    elImg.onload = function () {
      let imageWidth = elImg.naturalWidth,
        imageHeight = elImg.naturalHeight
      setWidth(imageWidth)
      setHeight(imageHeight)
      // console.log(imageWidth, imageHeight)
    }
    elImg.src = image
  }, [])
  useEffect(() => {
    var elImg = new Image()
    elImg.loading = "eager"
    elImg.onload = function () {
      let imageWidth = elImg.naturalWidth,
        imageHeight = elImg.naturalHeight
      setWidth(imageWidth)
      setHeight(imageHeight)
      // console.log(imageWidth, imageHeight)
    }
    elImg.src = image
    setLink(image)
  }, [image])
  return (
    <div className="w-full h-full relative group">
      <div
        className="h-[50px] w-[50px] bg-[#222] rounded-full absolute top-0 bottom-0 left-0 right-0 opacity-0 md:opacity-[1]  z-[1] m-auto flex items-center justify-center transition-all"
        css={css`
          &&& {
            rect {
              fill: #fff;
            }
          }
        `}
      >
        <MaxIcon />
      </div>
      {/*<Zoom img={imgLink} zoomScale={2} width={width} height={height} />*/}
      <Zoom
        img={imgLink}
        // zoomScale={1.4}
        width={width}
        widthS={`${width}px`}
        height={height}
        heightS={`${height}px`}
      />
    </div>
  )
}
function ProductImageBox(props) {
  // console.log(props)
  const { sm, linkTo } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  let altView = false
  if (props.isAsset) altView = true
  if (isMobile) altView = true
  const [currentImage, setCurrentImage] = useState(0)
  const [lightBoxImages, setLightBoxImages] = useState([])
  let lightBoxImagess = props.images
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  // useEffect(() => {
  //   console.log(lightBoxImagess)
  //   // setLightBoxImages([...lightBoxImagess])
  // }, [lightBoxImagess])

  const openLightbox = value => {
    let index = lightBoxImagess.findIndex(v => v === value)

    let imageObjects = []
    if (lightBoxImagess !== null && lightBoxImagess.length > 0) {
      lightBoxImagess.map((image, i) => {
        let imageObject = {
          src: image,
          caption: props.name,
        }
        imageObjects.push(imageObject)
        return image
      })
    }
    setLightBoxImages(imageObjects)
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  function calculateTransform() {
    if (currentImageIndex < 2) {
      return 0
    } else if (currentImageIndex >= props.images.length - 1) {
      return (props.images.length - 2.5) * -(maxImageHeight + 16)
    } else {
      return (currentImageIndex - 1) * -(maxImageHeight + 8)
    }
  }

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const maxImageHeight = 96

  let maxHeightDesktop = `calc((96px * 5) + (1rem * 5))`

  const navigationPrevRef = useRef < HTMLDivElement > null
  const navigationNextRef = useRef < HTMLDivElement > null

  return (
    <>
      <Tab.Group
        as="div"
        className={`flex ${
          altView
            ? `flex-col-reverse`
            : `${
                sm
                  ? `lg:min-h-[280px] lg:max-h-[280px]`
                  : `lg:min-h-[38rem] lg:max-h-[38rem]`
              }`
        }`}
      >
        {
          // {/* Image selector */}
          // {props.images.length > 0 && (
          //   <div
          //     className="overflow-hidden p-[2px] relative"
          //     css={css`
          //       &&& {
          //         max-height: ${maxHeightDesktop};
          //       }
          //     `}
          //   >
          //     <div
          //       className={` ${
          //         sm ? `mr-3 overflow-hidden` : `mr-[2rem]`
          //       } min-w-max max-w-2xl mx-auto sm:block lg:max-w-none  ${
          //         altView && "w-full mt-[15px] md:mt-0"
          //       }`}
          //       css={css`
          //         &&& {
          //           transition: 0.5s ease all;
          //           transform: ${altView
          //             ? `translateX(${calculateTransform()}px)`
          //             : `translateY(${calculateTransform()}px)`};
          //         }
          //       `}
          //     >
          //       <Tab.List
          //         className={
          //           isMobile
          //             ? `flex gap-6`
          //             : `grid grid-${altView ? "cols-5" : "rows-4"} gap-4`
          //         }
          //       >
          //         {props.images.map((image, k) => (
          //           <Tab
          //             key={k}
          //             className={` ${
          //               sm ? `w-12 h-12` : `w-24 h-24`
          //             } relative bg-white rounded-[8px] flex items-center justify-center  cursor-pointer hover:bg-gray-50 focus:outline-none   focus:shadow-none shadow-none`}
          //             onClick={() => setCurrentImageIndex(k)}
          //           >
          //             {({ selected }) => (
          //               <>
          //                 <span className="absolute inset-0 rounded-[8px] overflow-hidden">
          //                   <LazyImage
          //                     src={image}
          //                     alt={props.name}
          //                     className="w-full h-full object-center object-cover"
          //                   />
          //                 </span>
          //                 <span
          //                   className={classNames(
          //                     selected ? "ring-gray-600" : "ring-gray-200",
          //                     "absolute inset-0 rounded-[8px] ring-1 ring-offset-0 pointer-events-none focus:outline-none shadow-none focus:shadow-none"
          //                   )}
          //                   aria-hidden="true"
          //                 />
          //               </>
          //             )}
          //           </Tab>
          //         ))}
          //       </Tab.List>
          //     </div>
          //   </div>
          // )}
        }
        <Tab.Panels
          className={`w-full aspect-w-1 rounded-[8px] border border-[#EBEBEB] overflow-hidden ${
            altView && `h-[calc(100vw-30px)] md:h-[550px] md:mb-6`
          }`}
        >
          <Swiper
            id="main"
            tag="section"
            wrapperTag="ul"
            navigation={{
              prevEl: navigationPrevRef.current
                ? navigationPrevRef.current
                : undefined,
              nextEl: navigationNextRef.current
                ? navigationNextRef.current
                : undefined,
            }}
            pagination
            spaceBetween={0}
            slidesPerView={1}
          >
            {props.images &&
              props.images.map((image, k) => (
                <SwiperSlide
                  tag="section"
                  wrapperTag="ul"
                  onClick={() => openLightbox(image)}
                >
                  {props.isAsset && (
                    <div className="absolute z-[1] top-4 right-4">
                      <Tag bold upper darkGreen>
                        {props.isAsset && Array.isArray(props.category)
                          ? props.category.map((v, k) =>
                              k === 0 ? v : ", " + v
                            )
                          : props.category}
                        {!props.isAsset &&
                          props.categories &&
                          props.categories.map((v, k) =>
                            k === 0 ? v : ", " + v
                          )}
                      </Tag>
                    </div>
                  )}
                  <SingleImage image={image} />
                </SwiperSlide>
              ))}
          </Swiper>
        </Tab.Panels>
        {
          // <Tab.Panels
          //   className={`w-full aspect-w-1 rounded-[8px] border border-[#EBEBEB] overflow-hidden ${
          //     altView && `h-[calc(100vw-30px)] md:h-[550px] md:mb-6`
          //   }`}
          // >
          //   {props.images && props.images.length > 0 ? (
          //     props.images.map((image, k) => (
          //       <Tab.Panel
          //         key={k}
          //         className=""
          //         onClick={() => openLightbox(image)}
          //       >
          //         {props.isAsset && (
          //           <div className="absolute z-[1] top-4 right-4">
          //             <Tag bold upper darkGreen>
          //               {props.isAsset && Array.isArray(props.category)
          //                 ? props.category.map((v, k) => (k === 0 ? v : ", " + v))
          //                 : props.category}
          //               {!props.isAsset &&
          //                 props.categories &&
          //                 props.categories.map((v, k) =>
          //                   k === 0 ? v : ", " + v
          //                 )}
          //             </Tag>
          //           </div>
          //         )}
          //         <SingleImage image={image} sm={sm} />
          //         {/* <div className="imgContainer w-full h-full">
          //           <div
          //             style={{ backgroundImage: `url(${image})` }}
          //             className=" detail-view w-full h-full object-center object-cover sm:rounded-[8px]"
          //             css={css`
          //               background-position: center;
          //               background-repeat: no-repeat;
          //               background-size: cover;
          //               max-width: 100%;
          //               width: 100em;
          //             `}
          //             onMouseEnter={e => {
          //               console.log(e)
          //               console.log(e.target)
          //             }}
          //           />
          //         </div>
          //          {linkTo ? (
          //           <Link to={linkTo}>
          //             <LazyImage
          //               src={image}
          //               alt={props.name}
          //               className="detail-view w-full h-full object-center object-cover sm:rounded-[8px]"
          //             />
          //           </Link>
          //         ) : (
          //           <LazyImage
          //             src={image}
          //             alt={props.name}
          //             className="detail-view w-full h-full object-center object-cover sm:rounded-[8px]"
          //           />
          //         )} */}
          //       </Tab.Panel>
          //     ))
          //   ) : (
          //     <Skeleton />
          //   )}
          // </Tab.Panels>
        }
      </Tab.Group>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={lightBoxImages}
              components={{ Header: CustomHeader, Footer: null }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default ProductImageBox
