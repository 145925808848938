/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

export default function FloatingTextarea({
  onChange,
  type,
  label,
  name,
  hookForm,
  register,
  rows,
  className,
  defaultValue,
  disabled,
  data,
  value,
  description,
  error,
}) {
  const inputName = name
    ? name
    : label.toLowerCase().replaceAll(" ", "-") + "-" + Math.random(5000, 9000)

  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
  return (
    <div className={`relative z-0 mb-6 w-full group ${className && className}`}>
      {hookForm ? (
        <textarea
          rows={rows || 3}
          type={type ? type : "text"}
          className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
          css={css`
            /* border: 1px solid #ebebeb; */
            border-radius: 8px;
            ${fontStyles}
          `}
          // placeholder={placeholder ? placeholder : "Type here"}
          {...register(inputName)}
          defaultValue={defaultValue}
          placeholder=" "
          disabled={disabled ? true : false}
        />
      ) : (
        <textarea
          rows={rows || 3}
          type={type ? type : "text"}
          name={inputName}
          className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
          css={css`
            /* border: 1px solid #ebebeb; */
            border-radius: 8px;
            ${fontStyles}
          `}
          placeholder=" "
          required=""
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          disabled={disabled ? true : false}
        />
      )}
      {error && (
        <span className="border-[#e43d30] text-[#e43d30]">{error.message}</span>
      )}
      <label
        for={inputName}
        className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
        css={css`
          ${fontStyles}
        `}
      >
        {label}
      </label>

      {data?.description ||
        (description && (
          <span
            // className="text-sm"
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding: 0 10px;
              margin: 7px 0 10px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
          >
            {data?.description || description}
          </span>
        ))}
      {/* <div
        className="text-sm text-gray-200"
        dangerouslySetInnerHTML={{ __html: data?.description || description || "" }}
      ></div> */}
    </div>
  )
}
