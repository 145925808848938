/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Icon from "../../../assets/icons/close-alt.svg"

import { useMediaQuery } from "react-responsive"

export default function MobileMenuHead(props) {
  const { title

    // , onClose
   } = props
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  if(!isMobile) return null
  return (
      <div className="border-b border-[#EBEBEB] m-[-10px_-20px_20px_-20px] pb-[10px]">
        <div className="flex items-start justify-between items-center px-[20px]">
          <h3 className="text-strong-20">{title}</h3>
          <button
            // type="button"
            className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
            // onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <Icon className="w-3 h-3" />
          </button>
        </div>
      </div>
  )
}
