/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import DataContext from "../../../context/Data/DataContext"
import StateContext from "../../../context/state/StateContext"
import {
  entitySuggest,
  entitySuggestPublic,
  getAllMessageAttachements,
  getAllMessageUsers,
  myEntitySuggestionsByBody,
} from "../../../helpers/DrupalHelper"
import {
  selectStyles,
  selectStylesVisualLabel,
  selectStylesSmall,
  // SelectContainer,
  DropdownIndicator,
  ClearIndicator,
} from "../../../styled/Select"
import LazyImage from "../../LazyImage"
import CircleAvatar from "../CircleAvatar"
import ImageCardSmall from "../ImageCardSmall"
import MetaDelivery from "../MetaDelivery"
import { components } from "react-select"
import MetaDownload from "../MetaDownload"
import Button from "../Button"
import ButtonLite from "../ButtonLite"

const AsyncFloatingSelect = ({
  onChange,
  type,
  label,
  name,
  hookForm,
  register,
  className,
  options,
  rqMsg,
  required,
  control,
  provisionTempState,
  isMulti,
  isSearchable,
  defaultValue,
  description,
  sm,
  error,
  notFoundButtonTitle,
  body,
  flush,
  customOnChange,
  notFoundButton,
  wrapMultiItems,
  notFoundButtonF,
  isClearable,
  placeholder,
  myEntitySuggest,
  minChars,
  isDisabled,
  value,
  exposeAllData,
  maxHeight,
  tempValue,
  userOptions,
  showOnlyEmail,
  attachmentOptions,
  setFieldValue,
  setCompanyOptions,
  overrideStyles,
}) => {
  console.log({ defaultValue })
  const [selectInput, setSelectInput] = useState(false)
  const [tempState, setTempState] = useState(tempValue || null)
  const [selectIsLoading, setSelectIsLoading] = useState(false)
  const IndicatorsContainer = ({ children, ...props }) => {
    useEffect(() => {
      if (props.selectProps.inputValue !== "") {
        setSelectInput(true)
      }
    }, [props.selectProps.inputValue])
    useEffect(() => {
      if (
        props.selectProps.value === null &&
        props.selectProps.isLoading === false
      ) {
        if (props.selectProps.inputValue) setSelectInput(true)
      } else {
        if (
          props.selectProps.inputValue &&
          props.selectProps.inputValue.length >= minChars &&
          selectIsLoading === false
        )
          setTimeout(() => {
            setSelectInput(true)
          }, 4000)
        else setSelectInput(false)
      }
    }, [props.selectProps.value, props.selectProps.isLoading, selectIsLoading])
    return (
      <components.IndicatorsContainer {...props}>
        <div className="flex justify-between">
          {notFoundButton && (
            <div className={!selectInput && "hidden"}>
              <ButtonLite
                className="z-[22]"
                inline
                pink
                sm
                bold
                callback={notFoundButtonF}
              >
                {notFoundButtonTitle}
              </ButtonLite>
            </div>
          )}
          {children}
        </div>
      </components.IndicatorsContainer>
    )
  }

  const LoadingIndicator = ({ children, ...props }) => {
    console.log(minChars)
    if (
      typeof minChars === "undefined" ||
      props.selectProps.inputValue.length >= minChars
    )
      return (
        <components.LoadingIndicator {...props}>
          {children}
        </components.LoadingIndicator>
      )

    return null
  }
  const inputName = name
    ? name
    : label.toLowerCase().replaceAll(" ", "-") + "-" + Math.random(5000, 9000)
  const { token } = useContext(StateContext).state
  const [temporaryAsyncOpts, setTemporaryAsyncOpts] = useState([])
  // console.log(options)
  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
  const loadOptions = (inputValue, callback) => {
    if (typeof minChars === "undefined" || inputValue.length >= minChars) {
      setSelectIsLoading(true)
      console.log(inputValue)

      console.log(myEntitySuggest, "myEntitySuggest")
      setTimeout(async () => {
        let bodyE = {
          ...body,
          title: inputValue,
        }

        if (myEntitySuggest === true) {
          let data = await myEntitySuggestionsByBody(token, bodyE)
          let ss = data.map(item => {
            let obj = { value: item.target_id, label: item.title }
            if (exposeAllData) obj = { ...obj, ...item }
            return obj
          })
          if (setCompanyOptions) {
            let data = await myEntitySuggestionsByBody(token, { ...body })
            let ss = data.map(item => {
              let obj = { value: item.target_id, label: item.title }
              return obj
            })
            setCompanyOptions && setCompanyOptions(ss)
          }
          console.log(data)
          console.log(ss, "ss")
          callback(ss)
          setTemporaryAsyncOpts(ss)
        } else {
          let data = await entitySuggestPublic(token, bodyE)
          let ss = data.map(item => {
            let obj = { value: item.target_id, label: item.title }
            if (exposeAllData) obj = { ...obj, ...item }
            return obj
          })
          console.log(data)
          callback(ss)
          setTemporaryAsyncOpts(ss)
        }
        setSelectIsLoading(false)
      }, 1000)
    }
  }
  const loadUserOptions = (inputValue, callback) => {
    if (typeof minChars === "undefined" || inputValue.length >= minChars) {
      setSelectIsLoading(true)
      setTimeout(async () => {
        let bodyE = {
          ...body,
          string: inputValue,
        }
        if (userOptions === true) {
          let data = await getAllMessageUsers(token, bodyE)
          setFieldValue && setFieldValue(data)
          let ss = data.map(item => {
            let obj = {
              value: item.uid,
              label: !showOnlyEmail ? (
                <div className="flex flex-col">
                  <span className="ml-2">
                    {item.first_name + " " + item.last_name}
                  </span>
                  <span className="ml-2">{item.email}</span>
                </div>
              ) : (
                <span className="ml-2">{item.email}</span>
              ),
            }
            if (exposeAllData) obj = { ...obj, ...item }
            return obj
          })
          console.log(data)
          console.log(ss, "ss")
          callback(ss)
          setTemporaryAsyncOpts(ss)
        }
        setSelectIsLoading(false)
      }, 1000)
    }
  }
  const loadAttachmentOptions = (inputValue, callback) => {
    if (typeof minChars === "undefined" || inputValue.length >= minChars) {
      setSelectIsLoading(true)
      setTimeout(async () => {
        let bodyE = {
          ...body,
          string: inputValue,
        }
        if (attachmentOptions === true) {
          let data = await getAllMessageAttachements(token, bodyE)
          let ss = data.map(item => {
            let obj = {
              value: `${item.type}$${item.id}`,
              label: `${
                item.type.charAt(0).toUpperCase() + item.type.slice(1)
              } - ${item.title}`,
            }
            if (exposeAllData) obj = { ...obj, ...item }
            return obj
          })
          console.log(data)
          console.log(ss, "ss")
          callback(ss)
          setTemporaryAsyncOpts(ss)
        }
        setSelectIsLoading(false)
      }, 1000)
    }
  }
  // const handleInputChange = newValue => {
  //   const inputValue = newValue.replace(/\W/g, "")
  //   console.log(inputValue)
  //   // setTimeout(() => {
  //   //   callback(filterColors(inputValue))
  //   // }, 1000)
  // }
  // console.log(name, defaultValue)

  let selectStylesObj = {...selectStyles}
  if(overrideStyles) selectStylesObj = {...selectStylesObj, ...overrideStyles}

  return (
    <div
      className={` relative ${!flush ? `mb-6` : `mb-2`} w-full group  ${
        className && className
      }`}
    >
      {hookForm ? (
        <Controller
          control={control}
          render={({ field: { onChange, value, name, ref } }) => (
            <>
              <AsyncSelect
                inputRef={ref}
                // value={options.find(c => c.value === value)}
                name={name}
                loadOptions={
                  userOptions
                    ? loadUserOptions
                    : attachmentOptions
                    ? loadAttachmentOptions
                    : loadOptions
                }
                // defaultOptions
                // noOptionsMessage="No options available"
                // loadingMessage={() => "Start typing to search..."}

                loadingMessage={field => {
                  if (minChars && field.inputValue.length < minChars)
                    return "Keep typing..."
                  else {
                    return "Loading..."
                  }
                }}
                placeholder={
                  placeholder ? placeholder : "Start typing to search..."
                }
                noOptionsMessage={() =>
                  selectInput === false
                    ? "Start typing to search..."
                    : "No options"
                }
                // onInputChange={handleInputChange}
                onChange={selectedOption => {
                  if (isMulti) {
                    onChange(selectedOption.map(v => v.value))
                    if (customOnChange)
                      customOnChange(selectedOption.map(v => v.value))
                  } else {
                    onChange(selectedOption?.value)
                  }
                  if (customOnChange) customOnChange(selectedOption)
                }}
                styles={selectStylesObj}
                components={{
                  DropdownIndicator: DropdownIndicator,
                  ClearIndicator,
                  IndicatorsContainer,
                  noOptionsMessage: e =>
                    e.inputValue === "" ? "Start typing..." : "No options",
                }}
                isClearable={isClearable ? true : required ? false : true}
                isMulti={isMulti ? true : false}
                defaultValue={defaultValue}
                isSearchable={isSearchable}
                isDisabled={isDisabled ? isDisabled : false}
                // menuIsOpen={true}
              />
              {error && <span className="text-red-600">{error.message}</span>}
              {description && inputName !== "title" && (
                <span
                  // className="text-sm"
                  css={css`
                    font-family: "Jost";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    display: inline-block;
                    padding: 0 10px;
                    margin: 7px 0 10px;
                    letter-spacing: 0.02em;
                    color: #717171;
                  `}
                >
                  {description}
                </span>
              )}
            </>
          )}
          rules={
            required ? { required: rqMsg ? rqMsg : "Please Select" } : false
          }
          name={name}
        />
      ) : (
        <AsyncSelect
          // inputRef={ref}
          // value={options.find(c => c.value === value)}
          // name={name}
          loadOptions={loadOptions}
          value={value ? value : provisionTempState === true ? tempState : null}
          // defaultOptions
          // noOptionsMessage="No options available"
          // loadingMessage={() => "Start typing to search..."}
          loadingMessage={field => {
            if (minChars && field.inputValue.length < minChars)
              return "Keep typing..."
            else {
              return "Loading..."
            }
          }}
          placeholder={placeholder ? placeholder : "Start typing to search..."}
          noOptionsMessage={() =>
            selectInput === false ? "Start typing to search..." : "No options"
          }
          // onInputChange={handleInputChange}
          onChange={(selectedOption, a, b) => {
            // console.log("TEST")
            // console.log(a, 'a')
            // console.log(b, 'b')
            // console.log(loadOptions, 'load')
            // console.log(temporaryAsyncOpts, 'temporaryAsyncOpts')

            // let sendOption = selectedOption

            // console.log(temporaryAsyncOpts.filter(f => f.id === selectedOption.value)[0], 'z')
            // let sendOption = { ...selectedOption, ...temporaryAsyncOpts.filter(f => f.id === selectedOption.value)[0] }
            console.log({ tempValue, selectedOption })
            if (provisionTempState === true) {
              // if (tempValue) {
              //   setTempState(selectedOption.unshift(tempValue))
              // }
              setTempState(selectedOption)
            }
            // console.log(sendOption, 'selected')
            if (isMulti) {
              if (onChange) onChange(selectedOption.map(v => v.value))
              if (customOnChange)
                customOnChange(selectedOption.map(v => v.value))
            } else {
              if (onChange) onChange(selectedOption?.value)
            }
            if (customOnChange) customOnChange(selectedOption)
          }}
          maxHeight={maxHeight || null}
          styles={selectStylesObj}
          components={{
            DropdownIndicator: DropdownIndicator,
            LoadingIndicator,
            ClearIndicator,
            IndicatorsContainer,
            noOptionsMessage: e =>
              e.inputValue === "" ? "Start typing..." : "No options",
          }}
          isClearable={isClearable ? true : required ? false : true}
          isMulti={isMulti ? true : false}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          required={required ? true : false}
          isDisabled={isDisabled ? isDisabled : false}
          // menuIsOpen={true}
        />
      )}

      {!sm && (
        <label
          for={name}
          className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
          css={css`
            ${fontStyles}
          `}
        >
          {label} {required === true && "*"}
        </label>
      )}
    </div>
  )
}
export default AsyncFloatingSelect
