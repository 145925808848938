/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState, useContext } from "react"
import CartContext from "../../context/Cart/CartContext"
import CartIcon from "../../assets/icons/cart.svg"
import NotificationContext from "../../context/Notification/NotificationContext"
import FloatingInput from "../Elements/Form/FloatingInput"
import FloatingCheckbox from "../Elements/Form/FloatingCheckbox"
import { set } from "lodash"
const Counter = ({
  k,
  list,
  setList,
  checked,
  setChecked,
  id,
  max_qty,
  max_qty_ordered,
  available_total,
  currentCount,
  itemInCartQty,
  itemIsInCart,
  totalQtyAdded,
  disabled,
  qtyAll,
  allVisible,
}) => {

  const [count, setCount] = useState(
    itemInCartQty ? itemInCartQty : k === 0 && !itemIsInCart ? currentCount : 0
  )

  const { toggleNotification } = useContext(NotificationContext)

  const increment = () => {
    const val = count + 1

    if (
      val <= max_qty &&
      val <= available_total &&
      totalQtyAdded < available_total
    )
      return setCount(val)
    else if (
      max_qty == null &&
      val <= available_total &&
      totalQtyAdded < available_total
    )
      return setCount(val)
    return toggleNotification({
      error: true,
      content: `Sorry, we don't have enough in stock.`,
    })

    // if (max_qty == null && count + 1 < available_total) setCount(count + 1)
    // else setCount(count + 1)
  }
  const decrement = () => (count > 0 ? setCount(count - 1) : null)
  useEffect(() => {
    if (count > 0) {
      let f = list.findIndex(v => v.address_id === id)
      if (f >= 0) {
        let nLis = [...list]
        nLis[f] = { ...nLis[f], quantity: count }

        if (

          // doesn't equal version in list

          nLis[f].quantity !== list[f].quantity

        ) {
          setList(nLis)
        }
      } else {

        if (
          // doesnt exist in list

          list.findIndex(v => v.address_id === id) === -1
        ) {
          setList(list => [...list, { address_id: id, quantity: count }])
        }
      }
      if ((max_qty - max_qty_ordered > 0) && checked === false) setChecked(true)
    } else {
      let f = list.filter(v => v.address_id !== id)

      if (f !== list) setList(f)
      if (checked === true) setChecked(false)
    }
  }, [count])

  useEffect(() => {
    if (checked && list.length === 1) {
      setCount(currentCount)
    } else if (checked && count === 0) {
      setCount(1)
    } else if (checked !== true) {
      setCount(0)
    }
  }, [checked])

  useEffect(() => {

    if (qtyAll !== count && allVisible.includes(id) && checked) {
      if (totalQtyAdded >= available_total && qtyAll > count) {
        return toggleNotification({
          error: true,
          content: `Sorry, we don't have enough in stock.`,
        })
      }

      if (parseInt(max_qty) - parseInt(max_qty_ordered) <= 0) return null
      let val = parseInt(qtyAll)
      if (val <= parseInt(max_qty)) setCount(parseInt(val))
      else if (max_qty == null && val < parseInt(available_total))
        setCount(parseInt(val))
    }

  }, [qtyAll])

  return (
    <div
      className={`custom-number-input ${true ? `h-[32px] w-[62px] px-[4px]` : `h-[50px] w-[30%]`
        }  flex bg-white flex-row items-center overflow-hidden lg:mr-4`}
      css={css`
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 8px;
        &:hover {
          border-color: #222;
        }
        min-width: ${true ? `62px` : `none`};
      `}
    >
      <button
        onClick={decrement}
        className={`h-full ${true ? `w-[20px] relative -top-[1px]` : `w-18`
          } cursor-pointer outline-none bg-white overflow-hidden ${true && `text-reg-12`
          }`}
        css={css`
          border-radius: 8px 0 0 8px;
        `}
        type="button"
        disabled={
          parseInt(max_qty) - parseInt(max_qty_ordered) <= 0 || disabled
        }
      >
        <span className="">−</span>
      </button>

      <input
        type="text"
        className={` ${true ? `text-reg-12` : `text-med-14`
          } outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden`}
        value={count}
        disabled={disabled}
        onChange={e => {

          // if (
          //   val <= max_qty &&
          //   val <= available_total &&
          //   totalQtyAdded < available_total
          // )
          //   return setCount(val)
          // else if (
          //   max_qty == null &&
          //   val <= available_total &&
          //   totalQtyAdded < available_total
          // )
          //   return setCount(val)
          // return toggleNotification({
          //   error: true,
          //   content: `Sorry, we don't have enough in stock.`,
          // })

          const val = parseInt(e.target.value)

    if (
      val <= max_qty &&
      val <= available_total &&
      totalQtyAdded < available_total
    ) {

      console.log( val, max_qty, available_total, totalQtyAdded, 'val')
      return setCount(val) 
    } else if (
      max_qty == null &&
      val <= available_total &&
      totalQtyAdded < available_total
    )
      return setCount(val)
    return toggleNotification({
      error: true,
      content: `Sorry, we don't have enough in stock.`,
    })
          // console.log( e.target.value, totalQtyAdded, available_total, 'e.target.value')
          // if((parseInt(e.target.value) + totalQtyAdded) >= available_total) return toggleNotification({
          //   error: true,
          //   content: `Sorry, we don't have enough in stock.`,
          // })

          // if (totalQtyAdded >= available_total && e.target.value > count) {
          //   return toggleNotification({
          //     error: true,
          //     content: `Sorry, we don't have enough in stock.`,
          //   })
          // }

          // if (parseInt(max_qty) - parseInt(max_qty_ordered) <= 0) return null
          // let val = parseInt(e.target.value)
          // if (val <= parseInt(max_qty)) setCount(parseInt(val))
          // else if (max_qty == null && val < parseInt(available_total))
          //   setCount(parseInt(val))
        }}
        onKeyPress={event => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />

      <button
        type="button"
        onClick={increment}
        className={`h-full ${true ? `w-[20px] relative -top-[1px]` : `w-18`
          } cursor-pointer bg-white overflow-hidden ${true && `text-reg-12`}`}
        css={css`
          border-radius: 0 8px 8px 0;
        `}
        disabled={
          count === parseInt(max_qty) ||
          parseInt(max_qty) - parseInt(max_qty_ordered) <= 0 ||
          disabled
        }
      >
        <span className="">+</span>
      </button>
    </div>
  )
}
const SingleItem = ({
  store,
  assetId,
  k,
  list,
  selectAllVisible,
  qtyAll,
  allVisible,
  setList,
  preSetcount,
  max_qty,
  max_qty_ordered = 0,
  max_qty_frequency,
  available_total,
  ordered_last,
  totalQtyAdded,
  assetData,
  // setBubbleChecked,
}) => {

  const storesValue = assetData.status?.stores
  const { cart } = useContext(CartContext)
  // const item = list[k]
  const [itemInCartQty, setItemInCartQty] = useState(null)
  const [itemIsInCart, setItemIsInCart] = useState(false)
  const [checked, setChecked] = useState(false)

  // useEffect(() => {
  //   setBubbleChecked(checked, k)
  // }, [checked])

  useEffect(() => {

    if (checked) {
      setList(list => [
        ...list.filter(v => v.address_id !== store.id),
        { address_id: store.id, quantity: qtyAll },
      ])
    }

  }, [qtyAll])
  const formatDate = d =>
    typeof d === "string"
      ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
      : String(d.getDate()).padStart(2, "0") +
      "/" +
      String(d.getMonth() + 1).padStart(2, "0") +
      "/" +
      d.getFullYear()
  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
  const addMonths = (numOfMonths, date = new Date()) =>
    date.setMonth(date.getMonth() + numOfMonths)

  const addDays = (numOfDays, date = new Date()) =>
    date.setDate(date.getDate() + numOfDays)

  const [date, setDate] = useState(false)

  useEffect(async () => {
    if (ordered_last && ordered_last !== "") {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, "0")
      var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
      var yyyy = today.getFullYear()
      today = mm + "/" + dd + "/" + yyyy

      let orderLastDate = new Date(formatDate(ordered_last))

      let doMath = false
      switch (max_qty_frequency) {
        case "daily":
          doMath = addDays(1, orderLastDate)
          break
        case "weekly":
          doMath = addDays(7, orderLastDate)
          break
        case "monthly":
          doMath = addMonths(1, orderLastDate)
          break
        case "yearly":
          doMath = addMonths(12, orderLastDate)
          break
        default:
          break
      }
      if (doMath !== false) setDate(diffDays(doMath, new Date(today)))
    }
  }, [])

  useEffect(() => {
    if (assetData.type === "digital" && k === 0) {
      setItemInCartQty(false)
      setChecked(true)
      return
    }

    if (itemInCartQty === null) {
      const existingAsset /* in cart */ = cart.filter(
        f => parseInt(f.asset_id) === parseInt(assetId)
      )
      if (existingAsset.length) {
        setItemIsInCart(true)
        const singleStore = existingAsset[0].addresses.filter(
          f => parseInt(f.address_id) === parseInt(store.id)
        )
        if (singleStore.length > 0 && singleStore[0].quantity > 0) {
          setItemInCartQty(parseInt(singleStore[0].quantity))
          if (max_qty - max_qty_ordered > 0) setChecked(true)
        } else {
          setItemInCartQty(false)
        }
      } else {
        setItemInCartQty(false)
        if (k === 0 && max_qty - max_qty_ordered > 0
        ) setChecked(true)
      }
    }
  }, [])

  const findLowestIndexWithOrderableValue = array => {
    let lowestIndex = -1
    if (array?.length) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].ordable_value === true) {
          // console.log({ i, k })
          lowestIndex = i
          break // Stop loop once the first true orderable_value is found
        }
      }
    }
    return lowestIndex
  }
  const [initialChecked, setInitialChecked] = useState(true)

  useEffect(() => {
    if (
      storesValue?.length &&
      storesValue.find(f => parseInt(f.id) === parseInt(store.id))
        .ordable_value === true &&
      findLowestIndexWithOrderableValue(storesValue) === k &&
      initialChecked &&
      max_qty - max_qty_ordered > 0
    ) {
      setChecked(true)
    }
    if (
      storesValue?.length &&
      storesValue.find(f => parseInt(f.id) === parseInt(store.id))
        .ordable_value === false
    ) {
      setChecked(false)
    }
  }, [checked, k, storesValue])

  const doSelectAddress = (isChecked, selectAllVisibleDisabled = true) => {
    // If digital order
    if (findLowestIndexWithOrderableValue(storesValue) === k) {
      setInitialChecked(isChecked)
    }
    if (assetData.type === "digital") {
      setChecked(isChecked)
      if (isChecked) {
        setList(list => [
          ...list,
          { address_id: store.id, quantity: 1 },
        ])
      } else {
        let nList = list.filter(v => v.address_id !== store.id)
        setList(nList)
      }
      return
    }

    // If physical order and no stock
    if (checked === false && totalQtyAdded >= available_total) {
      return toggleNotification({
        error: true,
        content: `Sorry, we don't have enough in stock. ${available_total} ${totalQtyAdded}`,
      })
    }

    // If physical order and is stock
    if (parseInt(max_qty) - parseInt(max_qty_ordered) > 0) {
      setChecked(isChecked)
      if (isChecked) {
        setList(list => [
          ...list,
          { address_id: store.id, quantity: 1 },
        ])
      } else if (selectAllVisibleDisabled !== true) {
        let nList = list.filter(v => v.address_id !== store.id)

        // console.log(list, "nList")
        // console.log(store.id, "store.id")
        // console.log(nList, 'realNList')
        setList(list.filter(v => v.address_id !== store.id))
      }
    }
  }

  useEffect(() => {

    // console.log(allVisible, store.id, 'allVisible')
    if (selectAllVisible === true && checked === false && allVisible.includes(store.id)) {
      // console.log('select ', k)
      doSelectAddress(true)
    } else if (selectAllVisible === false && checked === true && allVisible.includes(store.id)) {

      // console.log('deselect ', k)
      doSelectAddress(false, false)
    }
  }, [selectAllVisible])

  const { toggleNotification } = useContext(NotificationContext)
  if (itemInCartQty === null) return null
  if (findLowestIndexWithOrderableValue(storesValue) === k) {
    // console.log({ k, checked })
  }

  return (
    <>
      <div
        key={store.name}
        className={`bg-white relative rounded-[8px]  border border-[#EBEBEB] px-5 py-4 flex`}
        style={{
          pointerEvents:
            storesValue?.length &&
              storesValue.find(f => parseInt(f.id) === parseInt(store.id))
                .ordable_value === false
              ? "none"
              : "auto",
          opacity:
            storesValue?.length &&
              storesValue.find(f => parseInt(f.id) === parseInt(store.id))
                .ordable_value === false
              ? 0.5
              : 1,
        }}
      >
        <div className="mr-4 w-full">
          <div key={store.id} className="flex w-full">
            <input
              disabled={
                storesValue?.length &&
                  storesValue.find(f => parseInt(f.id) === parseInt(store.id))
                    .ordable_value === false
                  ? true
                  : false
              }
              id={`filter-stores-${k}`}
              defaultValue={store.id}
              type="checkbox"
              checked={
                checked
                // ||
                // (findLowestIndexWithOrderableValue(storesValue) === k &&
                //   checked)
              }
              onChange={e => {

                doSelectAddress(e.target.checked)
                // // If digital order
                // if (findLowestIndexWithOrderableValue(storesValue) === k) {
                //   setInitialChecked(e.target.checked)
                // }
                // if (assetData.type === "digital") {
                //   setChecked(e.target.checked)
                //   if (e.target.checked) {
                //     setList(list => [
                //       ...list,
                //       { address_id: store.id, quantity: 1 },
                //     ])
                //   } else {
                //     let nList = list.filter(v => v.address_id !== store.id)
                //     setList(nList)
                //   }
                //   return
                // }

                // // If physical order and no stock
                // if (checked === false && totalQtyAdded >= available_total) {
                //   return toggleNotification({
                //     error: true,
                //     content: `Sorry, we don't have enough in stock.`,
                //   })
                // }

                // // If physical order and is stock
                // if (parseInt(max_qty) - parseInt(max_qty_ordered) > 0) {
                //   setChecked(e.target.checked)
                //   if (e.target.checked) {
                //     setList(list => [
                //       ...list,
                //       { address_id: store.id, quantity: 1 },
                //     ])
                //   } else {
                //     let nList = list.filter(v => v.address_id !== store.id)
                //     setList(nList)
                //   }
                // }
              }}
              className="mt-[7px] h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
            />
            <label
              htmlFor={`filter-stores-${k}`}
              className="ml-3 flex flex-col cursor-pointer w-full"
            >
              <span className="text-reg-16 text-dark">{store.name}</span>

              <span
                htmlFor={`filter-stores-${k}`}
                className={`text-reg-14 max-w-[70%] !leading-6`}
              >
                {store.location}
              </span>
              {storesValue?.length &&
                storesValue.find(f => parseInt(f.id) === parseInt(store.id))
                  .ordable_value === false ? (
                <div className="bg-[#f7f7f7] border border-[#EBEBEB] rounded-[8px] px-2 py-2 text-reg-12 !leading-[1.2] d-flex mr-auto mt-2">
                  <span>This asset can't be ordered to this address.</span>
                </div>
              ) : (
                max_qty !== null && (
                  <>
                    <div className="bg-[#f7f7f7] border border-[#EBEBEB] rounded-[8px] px-2 py-2 text-reg-12 !leading-[1.2] d-flex mr-auto mt-2">
                      {max_qty_frequency === "forever" && (
                        <span>
                          {max_qty - max_qty_ordered <= 0
                            ? `You've reached your order limit for this asset. You can't order more units.`
                            : `You can still order ${max_qty - max_qty_ordered
                            } units of this asset.`}
                        </span>
                      )}
                      {max_qty_frequency === "yearly" && (
                        <span>
                          {max_qty - max_qty_ordered === 0
                            ? `You've reached your order limit for this year.
                  ${date !== false && `/ ${date} days until you can order again`
                            }`
                            : `You can still order ${max_qty - max_qty_ordered
                            } this year
                            ${date !== false
                              ? `/ ${date} days until you can order again`
                              : ``
                            }`}
                        </span>
                      )}
                      {max_qty_frequency === "monthly" && (
                        <span>
                          {max_qty - max_qty_ordered <= 0
                            ? `You've reached your order limit for this month.
                          ${date !== false &&
                            `/ ${date} days until you can order again`
                            }`
                            : `You can still order ${max_qty - max_qty_ordered
                            } this month`}
                        </span>
                      )}
                      {max_qty_frequency === "biweekly" && (
                        <span>
                          {max_qty - max_qty_ordered <= 0
                            ? `You've reached your order limit for this fortnight.`
                            : `You can still order ${max_qty - max_qty_ordered
                            } during this two-week period.`}
                        </span>
                      )}
                      {max_qty_frequency === "weekly" && (
                        <span>
                          {max_qty - max_qty_ordered <= 0
                            ? `You've reached your order limit for this week.`
                            : `You can still order ${max_qty - max_qty_ordered
                            } this week.`}
                        </span>
                      )}
                      {max_qty_frequency === "daily" && (
                        <>
                          <span>
                            {max_qty - max_qty_ordered <= 0
                              ? `You've reached your order limit for today.`
                              : `You can still order ${max_qty - max_qty_ordered
                              } today.`}
                          </span>
                        </>
                      )}
                    </div>
                    {itemInCartQty !== null && itemInCartQty !== false && (
                      <span className="flex space-x-2 items-center mt-2">
                        <CartIcon className="w-4 h-4" />
                        <span className="text-reg-11 text-dark">
                          {itemInCartQty} already in your cart. You can add{" "}
                          {max_qty - itemInCartQty} more.
                        </span>
                      </span>
                    )}
                  </>
                )
              )}
            </label>
          </div>
        </div>
        {assetData.type === "physical" && (
          <div className="ml-auto">
            <Counter
              k={k}
              list={list}
              qtyAll={qtyAll}
              allVisible={allVisible}
              setList={setList}
              checked={checked}
              setChecked={setChecked}
              max_qty={
                max_qty
                  ? parseInt(max_qty) - parseInt(max_qty_ordered)
                  : max_qty
              }
              totalQtyAdded={totalQtyAdded}
              max_qty_ordered={max_qty_ordered}
              available_total={available_total}
              currentCount={
                itemInCartQty !== false && parseInt(itemInCartQty) !== 0
                  ? itemInCartQty
                  : preSetcount
              }
              disabled={
                storesValue?.length &&
                  storesValue.find(f => parseInt(f.id) === parseInt(store.id))
                    .ordable_value === false
                  ? true
                  : false
              }
              id={store.id}
              itemInCartQty={itemInCartQty}
              itemIsInCart={itemIsInCart}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default function RadioGroupCart({
  addresses,
  newAddresses,
  value,
  onChange,
  count,
  max_qty,
  available_total,
  max_qty_frequency,
  withOrderStores,
  assetId,
  ordered_last,
  assetData,
  // isOpen
}) {

  const [allAddresses, setAllAddresses] = useState(addresses)

  useEffect(() => {
    if (newAddresses && newAddresses.length > 0) {
      setAllAddresses(
        newAddresses.filter(f => f.hidden_from_cart !== true)
      )
    }
  }, [newAddresses])

  const [totalQtyAdded, setTotalQtyAdded] = useState(0)

  const [qtyAll, setQtyAll] = useState(0)

  useEffect(() => {
    // console.log(totalQtyAdded, "totalQtyAdded")
  }, [totalQtyAdded])

  useEffect(() => {

    console.log(addresses, 'addresses...')
    setTotalQtyAdded(
      value.reduce((acc, cur) => {

        // console.log(acc, cur, "acc, cur")
        return parseInt(acc) + parseInt(cur.quantity)
      }, 0)
    )
    // console.log(value, "val")
  }, [value, allAddresses])

  // if(!value) return null

  const [search, setSearch] = useState("")

  const [selectAllVisible, setSelectAllVisible] = useState(null)
  const [allVisible, setAllVisible] = useState([])

  useEffect(() => {

    if (allVisible.length === 0) setAllVisible(allAddresses.map(f => f.id))
  }, [allAddresses])

  useEffect(() => {

    if (search !== "") {
      const x = allAddresses.filter(f => {
        if (search === "") return true;
        if (f.name.toLowerCase().includes(search.toLowerCase())) return true
      }).map(i => i.id)

      if (allVisible !== x) setAllVisible(x)
    } else if (allVisible !== allAddresses.map(f => f.id)) {
      setAllVisible(allAddresses.map(f => f.id))
    }

  }, [search])

  useEffect(() => {
    if (selectAllVisible === false && value.length > 0) {
      onChange([])
    }
  }, [selectAllVisible])

  useEffect(() => {
    setQtyAll(count)
  }, [count])

  // const updateList = async (list) => {

  // }

  const { toggleNotification } = useContext(NotificationContext)

  // useEffect(() => {

  //   // update the onChange:

  //   onChange(
  //     allVisible.map(f => {

  //       // const address = addresses.find(a => a.id === f)

  //       return {
  //         address_id: f,
  //         quantity: qtyAll,
  //       }
  //     })
  //   )

  // }, [qtyAll])

  const [changeAllQty, setChangeAllQty] = useState(false)
  const incrementAll = () => {
    const val = qtyAll + 1
    const valMultiplied = val /* val * allVisible.length */

    if (
      valMultiplied <= max_qty &&
      valMultiplied <= available_total &&
      totalQtyAdded < available_total &&
      qtyAll !== val
    ) {
      setQtyAll(val)
      return;
    } else if (
      max_qty == null &&
      valMultiplied <= available_total &&
      totalQtyAdded < available_total &&
      qtyAll !== val
    ) {
      setQtyAll(val);
      return;
    } else {
      return toggleNotification({
        error: true,
        content: `Sorry, we don't have enough in stock or you can't order that many. Please select fewer addresse or lower the quantity.`,
      })
    }
  }

  const decrementAll = () => {
    if (qtyAll > 0 && ((qtyAll - 1) !== qtyAll)) setQtyAll(qtyAll - 1)

  }

  // useEffect(() => {
  //   if (changeAllQty) {
  //     // onChange(
  //     //   allVisible.map(f => {
  //     //     return {
  //     //       address_id: f,
  //     //       quantity: qtyAll,
  //     //     }
  //     //   })
  //     // )
  //   }
  // }, [qtyAll])

  useEffect(() => {
    // console.log(value, 'val')
  }, [value])

  return (
    <div className="w-full pt-5 pb-8">
      <div className="w-full mx-auto">
        <div>
          <FloatingInput
            label="Search your addresses..."
            type="text"
            name="search_addresses"
            // value={}
            onChange={e => {
              if (search !== e.target.value) setSearch(e.target.value)
            }}
          />
          <div className="flex items-center gap-5 mb-5">
            <FloatingCheckbox
              label="Select all visible"
              checked={false}
              onChange={e => {
                if (e.target.checked && (selectAllVisible === false || selectAllVisible === null)) {
                  setSelectAllVisible(true)
                } else if (selectAllVisible === true) {
                  setSelectAllVisible(false)
                }

              }}
              hookForm={false}
              gray
              className={"!h-5 !w-5"}
            />
            <div className={`flex gap-5 items-center`}>
              <FloatingCheckbox
                label="Change qty for selected"
                checked={false}
                onChange={e => {
                  setChangeAllQty(e.target.checked)
                }}
                hookForm={false}
                gray
                className={"!h-5 !w-5"}
              />
              <div className="relative h-[32px] top-[-3px]">
                {!changeAllQty && <div className="absolute z-1 bg-[rgba(255,255,255,.4)] top-0 bottom-0 left-0 right-0 cursor-not-allowed"></div>}
                <div
                  className={`custom-number-input ${true ? `h-[32px] w-[62px] px-[4px]` : `h-[50px] w-[30%]`
                    }  flex bg-white flex-row items-center overflow-hidden lg:mr-4`}
                  css={css`
              background: #ffffff;
              border: 1px solid #ebebeb;
              box-sizing: border-box;
              border-radius: 8px;
              &:hover {
                border-color: #222;
              }
              min-width: ${true ? `62px` : `none`};
      `}
                >
                  <button
                    onClick={decrementAll}
                    className={`h-full ${true ? `w-[20px] relative -top-[1px]` : `w-18`
                      } cursor-pointer outline-none bg-white overflow-hidden ${true && `text-reg-12`
                      }`}
                    css={css`
                border-radius: 8px 0 0 8px;
              `}
                    type="button"
                    disabled={
                      !changeAllQty
                    }
                  >
                    <span className="">−</span>
                  </button>

                  <input
                    type="text"
                    className={` ${true ? `text-reg-12` : `text-med-14`
                      } outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden`}
                    value={qtyAll}
                    disabled={!changeAllQty}
                  // onChange={e => {
                  //   if (totalQtyAdded >= available_total && e.target.value > count) {
                  //     return toggleNotification({
                  //       error: true,
                  //       content: `Sorry, we don't have enough in stock.`,
                  //     })
                  //   }

                  //   if (parseInt(max_qty) - parseInt(max_qty_ordered) <= 0) return null
                  //   let val = parseInt(e.target.value)
                  //   if (val <= parseInt(max_qty)) setCount(parseInt(val))
                  //   else if (max_qty == null && val < parseInt(available_total))
                  //     setCount(parseInt(val))
                  // }}
                  // onKeyPress={event => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault()
                  //   }
                  // }}
                  />

                  <button
                    type="button"
                    onClick={incrementAll}
                    className={`h-full ${true ? `w-[20px] relative -top-[1px]` : `w-18`
                      } cursor-pointer bg-white overflow-hidden ${true && `text-reg-12`}`}
                    css={css`
          border-radius: 0 8px 8px 0;
        `}
                    disabled={!changeAllQty}
                  >
                    <span className="">+</span>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-semibold-16 mb-4">
          {assetData.type === "digital"
            ? "Which locations is this file for?"
            : "Which location do you want to deliver to?"}
        </div>
        <div className="space-y-2">
          {/* {allAddresses.map(i => i.name)} */}
          {allAddresses
            // .filter(f => {

            //   // console.log(f, 'fiey')
            //   if (search === "") return true;
            //   if (f.name.toLowerCase().includes(search.toLowerCase())) return true
            // })
            .map((store, k) => {

              // if () return null;

              return (
                <div className={!allVisible.includes(store.id) ? `hidden` : ``}>
                  <SingleItem
                    key={k}
                    selectAllVisible={selectAllVisible}
                    allVisible={allVisible}
                    assetId={assetId}
                    k={
                      k
                      // find the key of the address from addresses without the hidden ones

                      // addresses.findIndex(f => f.id === store.id)

                    }
                    store={store}
                    totalQtyAdded={totalQtyAdded}
                    list={value}
                    qtyAll={qtyAll}
                    setList={onChange}
                    preSetcount={count}
                    max_qty={max_qty ? parseInt(max_qty) : max_qty}
                    max_qty_frequency={max_qty_frequency}
                    available_total={available_total}
                    ordered_last={ordered_last}
                    max_qty_ordered={
                      withOrderStores?.filter(v => v.id == store.id)[0]
                        ?.max_qty_ordered
                    }
                    assetData={assetData}
                  />
                </div>
              )
            }
            )}

          {search !== "" && allAddresses.filter(f => {
            if (search === "") return true;
            if (f.name.toLowerCase().includes(search.toLowerCase())) return true
          }).length === 0 && <p className="text-reg-14 red">No addresses match your search</p>}
        </div>
      </div>
    </div>
  )
}
