/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Logo from "../../../assets/logos/o2o-logo-r.png"
import Footer from "../../Commons/Footer/Footer"
import Header from "../../Commons/Header/Header"
import Helmet from "react-helmet"
const pageTitleTemplate = "%s"

function LayoutMinimal({
  footer,
  title,
  metaTitle,
  metaDesc,
  meta,
  children,
}) {
  let pageTitle = "O2O"
  let pageDescription = ""

  // Check for title and description next...
  if (typeof metaTitle !== "undefined" && metaTitle !== null) {
    pageTitle = metaTitle + " | O2O"
  }
  if (typeof metaDesc !== "undefined" && metaDesc !== null) {
    pageDescription = metaDesc
  }

  // Finally, if a whole object was passed, use that instead...
  if (typeof meta !== "undefined") {
    if (typeof meta.title !== "undefined" && meta.title !== null) {
      pageTitle = meta.title
    }
    if (typeof meta.description !== "undefined" && meta.description !== null) {
      pageDescription = meta.description
    }
  }
  // console.log(pageTitle)
  return (
    <div className="">
      <Helmet title={pageTitle} titleTemplate={pageTitleTemplate}>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <div className="flex items-center bg-[#f9f9f9] min-h-screen">
        <div className="w-full my-[30px] max-w-[600px] m-auto bg-white rounded-[8px] border border-primaryBorder shadow-default py-10 px-16">
          <a className="flex justify-center" href="https://useo2o.com">
            <img src={Logo} className="w-[60px]" />
          </a>
          {title && (
            <h1 className="text-strong-20 red mt-2 mb-6 text-center">
              {title}
            </h1>
          )}

          {children}

            <p
              css={css`
                margin: 20px auto 0;
                text-align: center;
                max-width: 400px;
                color: #222;
                font-size: 12px;
              `}
            >
              {footer ? footer : "©2023 O2O. All rights reserved unless otherwise stated. Trademarks belong to their respective owners."}
            </p>

        </div>
      </div>
    </div>
  )
}

export default LayoutMinimal
