/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import Button from "./Button"
import AddToWishlist from "./AddToWishlist"
import Share from "./Share"
import CartContext from "../../context/Cart/CartContext"

import { navigate } from "gatsby"
// import ModalCart from "../Cart/ModalCart"
import Tag from "./Tag"
import {
  getSingleAssetQty,
  // orderDigitalAsset as orderDigitalAssetCallback,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
// import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import NotificationContext from "../../context/Notification/NotificationContext"
import Modal from "../Modal/Modal"
import RequestAssetForm from "../Forms/RequestAssetForm"
// import { string } from "prop-types"
import Skeleton from "../Skeleton"
import { verifiedHost } from "../../helpers/HostHelper"
// import { log } from "console"
import { deliveryDate } from "../../helpers/DeliveryData"
import { getRemainingTimeToMidnightEST } from "../../helpers/ETAEstTime"
const formatDate = d =>
  typeof d === "string"
    ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
    : String(d.getDate()).padStart(2, "0") +
    "/" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "/" +
    d.getFullYear()
const diffDays = (date, otherDate) =>
  Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
const addMonths = (numOfMonths, date = new Date()) =>
  date.setMonth(date.getMonth() + numOfMonths)

function AddToCartBox({
  id,
  title,
  // cover,
  // qtyData,
  sm,
  type,
  assetData,
  fromAssetGroupPage,
}) {
  const [count, setCount] = useState(1)
  // const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState(null)
  const increment = () => {
    const val = count + 1

    increaseQty(val)

    // setCount(count + 1)
    // if (data.max_qty == null && count + 1 < data.available_total)
    //   setCount(count + 1)
    // else setCount(count + 1)
  }
  const decrement = () => (count > 0 ? setCount(count - 1) : null)
  const {
    // updateCart,
    showCartModal,
    userAddresses,
    // userStores,
    setOpenCartDropdown,
    // cart,
    orderDigitalAsset,
  } = useContext(CartContext)
  // const {
  //   requestAsset
  // } = useContext
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const [modalContact, setModalContact] = useState(false)
  const [date, setDate] = useState(false)
  const [emptyAddressBookError, setEmptyAddressBookError] = useState(false)

  useEffect(async () => {
    // let data =
    if (assetData?.status) {
      setData(assetData.status)
    } else {
      const data = await getSingleAssetQty(token, id)
      setData(data)
    }

    if (data?.ordered_last && data?.ordered_last !== "") {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, "0")
      var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
      var yyyy = today.getFullYear()

      today = mm + "/" + dd + "/" + yyyy

      let orderLastDate = new Date(formatDate(data.ordered_last))
      let plusOneMonth = addMonths(1, orderLastDate)

      setDate(diffDays(plusOneMonth, new Date(today)))
    }
  }, [])
  const addToCart = () => {
    if (userAddresses.length === 0) {
      setEmptyAddressBookError(true)
      // You must first create an address in your Address Book
      return
    }
    const nonHiddenAddresses = userAddresses.filter(
      item => item.hidden_from_cart !== true
    )
    const { status, user_group } = data
    if (!nonHiddenAddresses.length) {
      toggleNotification({
        error: true,
        content: `No valid delivery address was found. Please check your addresses to ensure your address is not hidden or deleted.`,
      })
    } else if (status === "can_order") {
      if (userAddresses.length === 1) {
        setOpenCartDropdown(id, count, title, user_group)
      } else {
        showCartModal(id, {
          ...data,
          count,
          status,
          user_group,
          assetData: assetData,
        })
      }
    } else {
      showCartModal(id, {
        ...data,
        count,
        status,
        user_group,
        assetData: assetData,
      })
    }
  }

  const onDownload = async id => {
    const rand = () => Math.floor(100000000000 + Math.random() * 900000000000)
    let query = `${rand()}${id}${rand()}`
    query = btoa(query)
    const url = `${verifiedHost}/api/download?q=${query}`
    window.open(url, "_blank").focus()
  }

  const increaseQty = e => {
    const val = parseInt(e)

    if (val <= data.max_qty && val <= data.available_total) return setCount(val)
    else if (data.max_qty == null && val <= data.available_total)
      return setCount(val)

    return toggleNotification({
      error: true,
      content: `Sorry, we don't have enough in stock.`,
    })
  }

  const orderDigitalAssetFunction = async assetId => {
    // console.log(assetId, "assetId")
    const nonHiddenAddresses = userAddresses.filter(
      item => item.hidden_from_cart !== true
    )
    if (userAddresses.length === 0) {
      setEmptyAddressBookError(true)
      // You must first create an address in your Address Book
      return
    }

    if (!nonHiddenAddresses.length) {
      toggleNotification({
        error: true,
        content: `No valid delivery address was found. Please check your addresses to ensure your address is not hidden or deleted.`,
      })
    } else if (userAddresses.length === 1) {
      // console.log([userAddresses[0].id], "test")

      await orderDigitalAsset({
        assetId: assetId,
        addresses: [{ address_id: userAddresses[0].id }],
        title: assetData.title,
      })
      // do download
      // await onDownload(id)
    } else {
      showCartModal(id, {
        ...data,
        assetData: assetData,
      })
    }

    // let res = await orderDigitalAssetCallback(token, assetId)
    // if (res.ok) {
    //   // navigate("/dashboard/my-orders")
    //   const result = await res.text()

    //   if (result.includes("OK")) {
    //     toggleNotification({
    //       content: `Thanks for ordering ${title}! Your download is starting now.`,
    //     })
    //     onDownload(assetId)
    //   } else {
    //     toggleNotification({
    //       content: `Downloading the latest version of ${title}`,
    //     })
    //     onDownload(assetId)
    //   }
    // } else {
    //   toggleNotification({
    //     content: `There was an issue processing your digital order.`,
    //     error: true,
    //   })
    // }
  }

  const navigateCreateAddressBookItem = () => {
    navigate("/dashboard/address-book", {
      state: { create: true },
    })
  }
  // console.log(data)
  if (sm) {
    return (
      <div className="relative flex items-center ">
        {data?.status === "must_request" ? (
          <Button
            red
            className="w-1/2 mb-3"
            // onClick={() => setModalContact(true)}
            onClick={addToCart}
            sm
          >
            Request Asset
          </Button>
        ) : (
          <div className="flex flex-row">
            {type === "physical" && (
              <>
                {data === null && <Skeleton />}
                {data !== null && data?.in_stock ? (
                  <>
                    <div>
                      <div
                        className="custom-number-input h-[50px] w-24 mb-2 flex bg-white flex-row overflow-hidden lg:mr-4"
                        css={css`
                          background: #ffffff;
                          border: 1px solid #ebebeb;
                          box-sizing: border-box;
                          border-radius: 8px;
                          &:hover {
                            border-color: #222;
                          }
                        `}
                      >
                        <button
                          data-action="decrement"
                          onClick={decrement}
                          className="h-full w-16 cursor-pointer outline-none bg-white overflow-hidden"
                          css={css`
                            border-radius: 8px 0 0 8px;
                          `}
                        >
                          <span className="">−</span>
                        </button>
                        <input
                          type="text"
                          className="text-med-14 outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden"
                          name="custom-input-number"
                          value={count}
                          onChange={e => {
                            let val = parseInt(e.target.value)
                            if (val <= data.max_qty) setCount(val)
                            else if (
                              data.max_qty == null &&
                              val < data.available_total
                            )
                              setCount(val)
                          }}
                        />
                        <button
                          data-action="increment"
                          onClick={increment}
                          disabled={parseInt(count) === parseInt(data?.max_qty)}
                          className="h-full w-16 cursor-pointer bg-white overflow-hidden"
                          css={css`
                            border-radius: 0 8px 8px 0;
                          `}
                        >
                          <span className="">+</span>
                        </button>
                      </div>
                      {data.available_total ? (
                        <p className="text-center mr-2">{`of ${data.available_total} in stock`}</p>
                      ) : (
                        <p className="text-center mr-2">{`${data.available_total} in stock`}</p>
                      )}
                    </div>
                    <div className="text-center">
                      <Button red outline onClick={addToCart} sm>
                        Add to Cart
                      </Button>
                      <p className="mt-1 font-semibold">{`${assetData.price === "0.00"
                        ? "FREE"
                        : "$ " + assetData.price
                        }`}</p>
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <Tag red>Out of Stock</Tag>
                    <p className="mt-1 font-semibold">{`${assetData.price === "0.00"
                      ? "FREE"
                      : "$ " + assetData.price
                      }`}</p>
                  </div>
                )}
              </>
            )}
            {type === "digital" && (
              <div className="text-center">
                <Button
                  red
                  outline
                  onClick={() => orderDigitalAssetFunction(id)}
                >
                  Download Now
                </Button>
                <p className="mt-1 font-semibold">{`${assetData.price === "0.00" ? "FREE" : "$ " + assetData.price
                  }`}</p>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div
        className="mt-3 p-5 flex flex-col"
        css={css`
          background: #f7f7f7;
          border: 1px solid #ebebeb;
          border-radius: 8px;
        `}
      >

        <div
          className="relative flex flex-row xl:flex-row lg:flex-col max-sm:flex-col max-sm:items-baseline gap-4 items-center xl:items-center lg:items-baseline"
        >
          {data?.status === "must_request" ? (
            <Button
              red
              className="w-1/2 mb-3"
              // onClick={() => setModalContact(true)}
              onClick={addToCart}
            >
              Request Asset
            </Button>
          ) : (
            <>
              {type === "physical" ? (
                <div className="flex gap-2 items-start">
                  {userAddresses.length === 1 &&
                    assetData.status.max_qty_ordered >=
                    assetData.status.max_qty ? (
                    <>Sorry, you've reached your maximum order quantity.</>
                  ) : (
                    <>
                      <div className="text-center">
                        <div
                          className=" custom-number-input h-[50px] w-32 flex bg-white flex-row overflow-hidden lg:mr-4"
                          css={css`
                            background: #ffffff;
                            border: 1px solid #ebebeb;
                            box-sizing: border-box;
                            border-radius: 8px;
                            &:hover {
                              border-color: #222;
                            }
                          `}
                        >
                          <button
                            data-action="decrement"
                            // disabled={count===data.max_qty}
                            onClick={decrement}
                            className="h-full w-20 cursor-pointer outline-none bg-white overflow-hidden"
                            css={css`
                              border-radius: 8px 0 0 8px;
                            `}
                          >
                            <span className="">−</span>
                          </button>
                          <input
                            type="text"
                            className="text-med-14 outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden"
                            name="custom-input-number"
                            value={count}
                            onChange={e => increaseQty(e.target.value)}
                          />
                          <button
                            data-action="increment"
                            onClick={increment}
                            className="h-full w-20 cursor-pointer bg-white overflow-hidden"
                            css={css`
                              border-radius: 0 8px 8px 0;
                            `}
                            disabled={
                              parseInt(count) === parseInt(data?.max_qty)
                            }
                          >
                            <span className="">+</span>
                          </button>
                        </div>
                        <p className="lg:mr-4">{`of ${assetData.status.available_total} in stock`}</p>
                      </div>
                      <Button red onClick={addToCart}>
                        Add to Cart
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <Button red onClick={() => orderDigitalAssetFunction(id)}>
                  Download
                </Button>
              )}
            </>
          )}
          <div
            className={`flex flex-wrap gap-[20px] ${type === "physical" && "mb-6"
              }`}
          >
            <AddToWishlist
              title={title}
              id={id}
              name={"asset"}
              isListed={assetData.bookmarked}
            />
            <Share title={title} id={id} name={"asset"} />
          </div>
        </div>

        {
          <ul className="text-reg-14 line-height-30 text-dark mt-4">
            {data?.status === "can_order" && (
              <>
                {" "}
                {data !== null && data.max_qty_frequency !== null && (
                  <li>
                    Maximum Order Quantity:{" "}
                    {(() => {
                      if (data.max_qty_frequency === "daily")
                        return `${data.max_qty} per location, per day`
                      else if (data.max_qty_frequency === "weekly")
                        return `${data.max_qty} per location, per week`
                      else if (data.max_qty_frequency === "biweekly")
                        return `${data.max_qty} per location, every two weeks`
                      else if (data.max_qty_frequency === "monthly")
                        return `${data.max_qty} per location, per month`
                      else if (data.max_qty_frequency === "yearly")
                        return `${data.max_qty} per location, per year`
                      else if (data.max_qty_frequency === "forever")
                        return `${data.max_qty} per location. After ordering this asset, you won't be able to order it again.`
                    })()}
                  </li>
                )}
                {userAddresses.length == 1 &&
                  data !== null &&
                  data.max_qty !== null &&
                  data.max_qty_frequency === "yearly" && (
                    <li>
                      You can still order {data.max_qty - data.max_qty_ordered}{" "}
                      this year{" "}
                      {date !== false &&
                        `/ ${date} days until you can order again`}
                    </li>
                  )}
                {userAddresses.length == 1 &&
                  data !== null &&
                  data.max_qty !== null &&
                  data.max_qty_frequency === "monthly" && (
                    <li>
                      You can still order {data.max_qty - data.max_qty_ordered}{" "}
                      this month{" "}
                      {date !== false &&
                        `/ ${date} days until you can order again`}
                    </li>
                  )}
                {userAddresses.length == 1 &&
                  data !== null &&
                  data.max_qty !== null &&
                  data.max_qty_frequency === "biweekly" && (
                    <li>
                      You can still order {data.max_qty} this fortnight{" "}
                      {date !== false &&
                        `/ ${date} days until you can order again`}
                    </li>
                  )}
                {userAddresses.length == 1 &&
                  data !== null &&
                  data.max_qty !== null &&
                  data.max_qty_frequency === "weekly" && (
                    <li>
                      You can still order {data.max_qty} this week{" "}
                      {date !== false &&
                        `/ ${date} days until you can order again`}
                    </li>
                  )}
                {userAddresses.length == 1 &&
                  data !== null &&
                  data.max_qty !== null &&
                  data.max_qty_frequency === "daily" && (
                    <li>
                      You can still order {data.max_qty} today{" "}
                      {date !== false && `/ 1 day until you can order again`}
                    </li>
                  )}
              </>
            )}
            {type === "physical" && (
              <li>{`Estimated Delivery : ${deliveryDate().toDateString()}. Order within ${getRemainingTimeToMidnightEST().hours
                } hrs ${getRemainingTimeToMidnightEST().minutes} mins.`}</li>
            )}
          </ul>
        }
      </div>

      <Modal
        isOpen={modalContact}
        title="Request Asset"
        closeModal={() => setModalContact(false)}
      >
        <RequestAssetForm id={id} title={title} onSave={setModalContact} />
      </Modal>
      <Modal
        isOpen={emptyAddressBookError}
        title="Your Address Book is Empty"
        closeModal={() => setEmptyAddressBookError(false)}
      >
        <p className="text-reg-17">
          To place an order, you must first create an address in your Address
          Book.
        </p>
        <p className="text-reg-17">
          This ensures that we ship to the correct location.
        </p>
        <br />
        <Button red onClick={() => navigateCreateAddressBookItem()} widthAuto>
          Create New Address
        </Button>
      </Modal>
    </>
  )
}

export default AddToCartBox
