/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import DataContext from "../../../context/Data/DataContext"
import StateContext from "../../../context/state/StateContext"
import { myEntitySuggestions } from "../../../helpers/DrupalHelper"
import {
  selectStyles,
  selectStylesVisualLabel,
  selectStylesSmall,
  // SelectContainer,
  DropdownIndicator,
  ClearIndicator,
} from "../../../styled/Select"
import LazyImage from "../../LazyImage"
import CircleAvatar from "../CircleAvatar"
import ImageCardSmall from "../ImageCardSmall"
import MetaDelivery from "../MetaDelivery"
import MetaDownload from "../MetaDownload"
import { components } from "react-select"
import ButtonLite from "../ButtonLite"
import { classNames } from "../../../utils"

const dummyOptions = [
]
const SelectN = props => {
  const {
    wrapMultiItems,
    onChange,
    type,
    label,
    name,
    hookForm,
    register,
    value,
    className,
    options,
    required,
    control,
    isMulti,
    isSearchable,
    defaultValue,
    sm,
    error,
    customChange,
    rqMsg,
    description,
    noSelection,
    notFoundButton,
    notFoundButtonF,
    notFoundButtonTitle,
    placeholder,
    widthAuto,
    locationSelect,
    subtitleSelector,
    subdued,
    isDisabled,
    overrideComponents,
    edit,
  } = props
  let inputName = name
    ? name
    : label.toLowerCase().replaceAll(" ", "-") + "-" + Math.random(5000, 9000)

  let setLabel = required && label && !label.includes("*") ? label + "*" : label

  const IndicatorsContainer = ({ children, ...props }) => {
    return (
      <components.IndicatorsContainer {...props}>
        <div className="flex justify-between">
          {notFoundButton && (
            <ButtonLite inline pink sm bold callback={notFoundButtonF}>
              {notFoundButtonTitle}
            </ButtonLite>
          )}
          {children}
        </div>
      </components.IndicatorsContainer>
    )
  }

  const Option = props => {
    const { label, renderLabel } = props.data

    if (renderLabel)
      return <components.Option {...props}>{renderLabel}</components.Option>
    let subTitle = null
    if (subtitleSelector && subtitleSelector in props.data)
      subTitle = props.data[subtitleSelector]

    // console.log(subTitle)
    // console.log(props.data[subtitleSelector])
    return (
      <components.Option {...props}>
        <span
          style={subTitle !== null ? { display: "block", color: "#222" } : {}}
        >
          {label}
        </span>
        {subTitle !== null && (
          <span
            style={{
              display: "block",
              // textTransform: "uppercase",
              fontSize: 12,
              lineHeight: 1.4,
              paddingTop: 4,
              color: "rgba(0,0,0,.3)",
            }}
          >
            {subTitle}
          </span>
        )}
      </components.Option>
    )
  }

  const SingleValue = props => {
    const { label } = props.getValue()[0]
    let subTitle = null
    if (subtitleSelector && subtitleSelector in props.getValue()[0])
      subTitle = props.getValue()[0][subtitleSelector]

    return (
      <components.SingleValue {...props}>
        <span
          style={
            subTitle
              ? {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "83%",
                }
              : {}
          }
        >
          {label}
        </span>
        {false && subTitle !== null && (
          <span style={{ color: "darkgray" }}>{subTitle}</span>
        )}
      </components.SingleValue>
    )
  }

  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`

  let applySelectStyles = selectStyles
  if (widthAuto)
    applySelectStyles = {
      ...selectStyles,
      menu: (provided, state) => ({
        ...provided,
        ...selectStyles.menu,
        minWidth: `100px`,
        width: `auto`,
        left: 0,
        textAlign: `center`,
        // zIndex: 99999999
      }),
    }
  const [select, setSelected] = useState(null)

  let customComponents = {
    DropdownIndicator: DropdownIndicator,
    ClearIndicator,
    IndicatorsContainer,
    Option,
    SingleValue,
  }

  if (overrideComponents) {
    customComponents = {
      ...customComponents,
      ...overrideComponents,
    }
  }

  return (
    <div className={` relative mb-6 w-full group ${className && className}`}>
      {hookForm ? (
        <>
          {options && (
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => {
                //make a const which has all options and also elements of value which
                // are not in options, not by convating but those should remain as objects in the array
                const firstArrayValues = options.map(item => item.value)

                // Filter the second array to include only those elements not in firstArrayValues
                const filteredSecondArray =
                  value
                    ?.filter(item => !firstArrayValues.includes(item))
                    .map(item => ({ value: item, label: item })) || []

                // Create the new array by combining firstArray and filteredSecondArray
                const newArray =
                  options[0].value === "demo"
                    ? []
                    : [...options, ...filteredSecondArray]
                console.log(newArray)
                console.log(options)
                return (
                  <CreatableSelect
                    wrapMultiItems
                    inputRef={ref}
                    // 20221115211759 Not sure what this is doing here?
                    // value={
                    //   isMulti && Array.isArray(value) && value.length
                    //     ? options.filter(c => value.includes(c.value))
                    //     : options.find(c => c.value === value)
                    //     ? options.find(c => c.value === value)
                    //     : null
                    // }

                    //@TODO: Zis added value prop back below. To clarify

                    value={
                      isMulti && Array.isArray(value) && value.length
                        ? newArray.filter(c => value.includes(c.value))
                        : newArray.find(c => c.value === value)
                        ? newArray.find(c => c.value === value)
                        : Array.isArray(value) && value.length == 1
                        ? newArray.find(c => c.value === value[0])
                          ? newArray.find(c => c.value === value[0])
                          : null
                        : required && !value
                        ? !noSelection && newArray[0]
                        : null
                    }
                    name={name}
                    // menuIsOpen={true}
                    options={newArray}
                    onChange={selectedOption => {
                      if (customChange) customChange(selectedOption)

                      if (isMulti) onChange(selectedOption.map(v => v.value))
                      else onChange(selectedOption?.value)
                    }}
                    styles={applySelectStyles}
                    components={customComponents}
                    isClearable={required ? false : true}
                    isMulti={isMulti ? true : false}
                    isDisabled={isDisabled ? isDisabled : false}
                    defaultValue={
                      defaultValue
                        ? defaultValue
                        : required && !noSelection && !value
                        ? options[0]
                        : defaultValue
                    }
                    isSearchable={isSearchable}
                    placeholder={placeholder ? placeholder : "Select..."}
                    // menuIsOpen={true}
                  />
                )
              }}
              rules={
                required ? { required: rqMsg ? rqMsg : "Please Select" } : false
              }
              name={name}
            />
          )}
          {error && (
            <span className="border-[#e43d30] text-[#e43d30]">
              {error.message}
            </span>
          )}
        </>
      ) : (
        <>
          {value || locationSelect ? (
            <Select
              wrapMultiItems
              options={options}
              // menuIsOpen={true}
              styles={
                selectStylesVisualLabel
                  ? selectStylesVisualLabel
                  : sm
                  ? selectStylesSmall
                  : applySelectStyles
              }
              components={customComponents}
              // {...register(inputName)}
              onChange={onChange}
              isClearable={required ? false : true}
              isMulti={isMulti ? true : false}
              isSearchable={isSearchable}
              // menuIsOpen={true}
              value={value}
              // menuIsOpen={true}
              defaultValue={defaultValue}
              isDisabled={isDisabled ? isDisabled : false}
              placeholder={placeholder ? placeholder : "Select..."}
            />
          ) : (
            <Select
              wrapMultiItems
              options={options}
              value={props.deleteSegment ? "" : value}
              // menuIsOpen={true}
              styles={
                selectStylesVisualLabel
                  ? selectStylesVisualLabel
                  : sm
                  ? selectStylesSmall
                  : applySelectStyles
              }
              components={customComponents}
              // {...register(inputName)}
              onChange={onChange}
              isClearable={required ? false : true}
              isMulti={isMulti ? true : false}
              isSearchable={isSearchable}
              // menuIsOpen={options.length ? true : false}
              defaultValue={defaultValue}
              placeholder={placeholder ? placeholder : "Select..."}
              subdued={subdued || null}
              isDisabled={isDisabled ? isDisabled : false}
            />
          )}
          {/*<input
          type={type ? type : "text"}
          name={inputName}
          className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
          css={css`
            border-radius: 8px;
            ${fontStyles}
          `}
          placeholder=" "
          required=""
          onChange={onChange}
        />*/}
          {error && (
            <span className="border-[#e43d30] text-[#e43d30]">
              {error?.message ? error.message : error}
            </span>
          )}
        </>
      )}
      {!sm && (
        <label
          for={name}
          className={classNames(
            `absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10`,
            isDisabled === true && "bg-[transparent]"
          )}
          css={css`
            ${fontStyles}
          `}
        >
          {setLabel}
        </label>
      )}
      {description && (
        <span
          // className="text-sm"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            padding: 0 10px;
            margin: 7px 0 10px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          {description}
        </span>
      )}
    </div>
  )
}
export default function FloatingSelectCreatable(props) {
  const [myOptions, setOptions] = useState(null)
  const { data } = useContext(DataContext)
  const { state } = useContext(StateContext)
  // console.log(myOptions)

  const setAvailableOptions = async () => {
    if (props.type) {
      if (props.type === "myBrandsAsOptions") {
        let data = await myEntitySuggestions(state.token, "brand", ["default"])
        // console.log(data)

        setOptions(
          data.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,
            label: v.title,
            // company: v.field_brand_company,
            renderLabel: (
              <div className="flex items-center">
                <CircleAvatar border xs image={v.thumb} />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      } else if (props.type === "myAssetsAsOptions") {
        let data = await myEntitySuggestions(state.token, "asset", ["default"])
        // console.log(data)

        setOptions(
          data.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,
            label: (
              <div className="flex items-center">
                <ImageCardSmall size={"40"} alt="" image={v.thumb} />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      } else if (props.type === "myAssetGroupAsOptions") {
        let data = await myEntitySuggestions(state.token, "asset", [
          "asset_group",
        ])
        // console.log(data)

        setOptions(
          data.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,
            label: (
              <div className="flex items-center">
                <ImageCardSmall size={"40"} alt="" image={v.thumb} />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      } else if (props.type === "myProductsAsOptions") {
        let data = await myEntitySuggestions(state.token, "product", [
          "default",
        ])
        // console.log(data)
        setOptions(
          data.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,
            label: (
              <div className="flex items-center">
                <ImageCardSmall size={"40"} alt="" image={v.thumb} />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      } else if (props.type === "myStoresAsOptions") {
        let data = await myEntitySuggestions(state.token, "store", [
          "retail_store",
        ])
        // console.log(data)
        // console.log("data")
        setOptions(
          data.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,
            label: (
              <div className="flex items-center">
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      } else if (props.type === "field_asset_type") {
        setOptions([
          {
            value: "digital",
            label: (
              <div className="flex justify-between space-x-4">
                <span>Digital</span>
                <MetaDownload />
              </div>
            ),
          },
          {
            value: "physical",
            label: (
              <div className="flex justify-between space-x-4">
                <span>Physical</span>
                <MetaDelivery />
              </div>
            ),
          },
        ])
        // console.log(props)
      } else setOptions(data[props.type])
    } else if (props.body) {
      let data = await myEntitySuggestions(state.token, props.body.entity, [
        props.body.bundle,
      ])
      // console.log(data)
      setOptions(
        data.map(v => ({
          ...v,
          value: v.target_id ? v.target_id : v.id,
          label: v.title,
        }))
      )
    } else {
      if (props.options) setOptions(props.options)
      else setOptions(dummyOptions)
    }
  }
  useEffect(() => {
    setAvailableOptions()
  }, [])
  // useEffect(() => {
  //   // if (props.type) {
  //   //   console.log(data[props.type])
  //   //   console.log("data[props.type]")
  //   //   if (props.type === "myBrandsAsOptions")
  //   //     setOptions([
  //   //       ...data.myBrandsAsOptions.map(v => ({
  //   //         ...v,
  //   //         label: (
  //   //           <div className="flex items-center">
  //   //             <CircleAvatar border xs image={v.img} />
  //   //             <span className="ml-2">{v.label}</span>
  //   //           </div>
  //   //         ),
  //   //       })),
  //   //     ])
  //   //   else if (props.type === "myAssetsAsOptions")
  //   //     setOptions(
  //   //       data.myAssetsAsOptions.map(v => ({
  //   //         ...v,
  //   //         label: (
  //   //           <div className="flex ">
  //   //             {v.label}
  //   //             <LazyImage src={v.cover} className="ml-6" />
  //   //           </div>
  //   //         ),
  //   //       }))
  //   //     )
  //   //   else setOptions([...data[props.type]])
  //   // }
  //   setAvailableOptions()
  // }, [data])
  useEffect(() => {
    setAvailableOptions()
  }, [props.options])

  if (props.hideIfEmpty) {
    return (
      <>
        {myOptions?.length ? <SelectN {...props} options={myOptions} /> : null}
      </>
    )
  } else {
    return <SelectN {...props} options={myOptions} />
  }
}
