/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState, useRef } from "react"

import {
  deleteMySingleForm,
  getBrandSingle,
  getFieldAssets,
  getMyListing,
  getMyListingPagination,
  getMySingleForm,
  entitySuggestions,
  myEntitySuggestions,
  getSingleAsset,
} from "../../../../helpers/DrupalHelper"
import AssetsTable from "../../../Table/MyListing/AssetsTable"
import DynamicForm from "../../DynamicForm/DynamicForm"
import EditFormTemplate from "../../EditFormTemplate"
// import Modal from "../../components/Modal/Modal"
// import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import Button from "../../../Elements/Button"
import NotificationContext from "../../../../context/Notification/NotificationContext"
// import TableFilter from "../../components/Table/TableFilter"
import Skeleton from "../../../Skeleton"
import Icon from "../../../../assets/icons/close-alt.svg"
import CreateAsset from "../../CreateAsset"
// import Pagination from "../../components/Pagination"

function FieldAssets({
  data,
  currentFormData,
  token,
  errors,
  addInventory,
  name,
  register,
  // label={label}
  setValue,
  watch,
  fieldAssets,
  setFieldAssets,
}) {
  // console.log(data, currentsFormData)
  const [list, setList] = useState(null)
  const [allList, setAllList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [editCurrentBundle, setEditCurrentBundle] = useState(null)
  const [formData, setFormData] = useState(null)
  const [openCreate, setOpenCreate] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [showPagination, setShowPagination] = useState(true)
  const [perPage, setPerPage] = useState(20)
  //   useEffect(async () => {
  //     let total = await getMyListingPagination(token, "asset", "all")
  //     setTotal(total)

  //     let pages = Math.ceil(total / perPage)
  //     setTotalPage(pages)
  //   }, [])

  useEffect(() => {
    // let pages = Math.ceil(total / perPage)
    setFieldAssets(list)
  }, [list])
  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  //   // }, [total])
  //   const onPageChange = async v => {
  //     setPageNumber(v)
  //     const newPageData = await getMyListing(token, "asset", "all", v)
  //     // console.log(newPageData, "pageBody")
  //     setList([...newPageData])
  //     // setTotal(newPageData.total)
  //   }

  const onEditClick = (v, bundle) => {
    setEditCurrent(v)
    setEditCurrentBundle("default")
    setOpen(true)
    console.log(v)
  }
  const onSave = async (data, v, resData) => {
    if (v === "added") {
      console.log(resData)
      const datas = await getSingleAsset(token, resData.id[0].value)

      const datasJson = await datas.json()
      setList([...list, { ...datasJson, id: resData.id[0].value }])

      setValue(name, [
        ...list.map(v => (v.id ? v.id : v.target_id)),
        resData.id[0].value,
      ])

      setOpenCreate(false)
      toggleNotification({ content: "Asset Added Sucessfully" })
    } else if (v === "edited") {
      // const datas = await myEntitySuggestions(token, "asset", ["default"])
      // console.log(datas)
      setList(data)

      // setValue(
      //   name,
      //   datas.map(v => v.target_id)
      // )
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: "Asset Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed", error: true })
    }
  }
  useEffect(async () => {
    // const data = await myEntitySuggestions(token, "asset", ["default"])
    // console.log(data)
    if (data?.current_value && data.current_value.length) {
      setList(data.current_value.map(v => ({ id: v.target_id, ...v })))
      setValue(
        name,
        data.current_value.map(v => v.target_id)
      )
    } else {
      if (fieldAssets) setList(fieldAssets)
      else setList([])
    }

    let formData = await getMySingleForm(token, "asset")
    // console.log(formData)
    setFormData(formData)
  }, [])
  console.log({ list })
  return (
    <div>
      {/*list !== null && list.length > 0 && (
        <h3 className="mb-2">
          <>
            {list.length} variations
          </>
        </h3>
      )*/}
      <div className="mb-[30px]">
        <Button
          red
          outline
          widthAuto
          onClick={() => setOpenCreate(true)}
          type={"button"}
        >
          Create New Variation
        </Button>
      </div>

      {formData !== null && openCreate && (
        <div className="rounded-[8px] border border-[#EBEBEB] mb-[30px]">
          <div className="border-b border-[#EBEBEB] flex items-center py-[10px] px-[15px]">
            <h1 className="text-strong-20">Create Variation</h1>
            <div className="ml-auto">
              <button
                type="button"
                className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
                onClick={() => setOpenCreate(false)}
              >
                <Icon className="w-3 h-3" />
              </button>
            </div>
          </div>
          <div className="p-[30px]">
            {
              // <DynamicForm
              //   onSave={onSave}
              //   addNew={true}
              //   data={formData}
              //   token={token}
              //   name="asset"
              //   bundle="default"
              //   nested
              // />
            }
            <CreateAsset onSave={onSave} disableVariantField={true} />
          </div>
        </div>
      )}
      {open === true && editCurrent !== null && (
        <div className="p-[30px]">
          <div className="ml-auto float-right">
            <button
              type="button"
              className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
              onClick={() => {
                setOpen(false)
                setEditCurrent(null)
              }}
            >
              <Icon className="w-3 h-3" />
            </button>
          </div>
          <CreateAsset
            edit
            id={editCurrent}
            editCurrentBundle={editCurrentBundle}
            list={list}
            onSave={onSave}
            variantList
            disableVariantField
            addInventory={addInventory}
          />
        </div>
      )}
      {list === null ? (
        <Skeleton table />
      ) : list.length > 0 ? (
        <div className="mb-[30px]">
          <AssetsTable
            data={list}
            addInventory={addInventory}
            fromAssetGroup
            onEditClick={(id, bundle) => {
              setOpen(false)
              setEditCurrent(null)
              setTimeout(() => {
                onEditClick(id, bundle)
              }, 200)
            }}
            onDeleteClick={async (id, bundle) => {
              let res = await deleteMySingleForm(token, "asset", "default", id)
              if (res.ok) {
                toggleNotification({ content: id + " Deleted Sucessfully" })
                //  const datas = await getSingleAsset(token, resData.id[0].value)
                let del = list.filter(v =>
                  v.id ? v.id !== id : v.target_id !== id
                )
                setList([...del])

                setValue(
                  name,
                  del.map(v => (v.id ? v.id : v.target_id))
                )
                // let data = await getMyListing(token, "asset", "all")
                // setList(data.map(v => ({ id: v.target_id, ...v })))
                // setValue(
                //   name,
                //   data.map(v => v.target_id)
                // )
              } else {
                toggleNotification({
                  content: id + " Failed to delete ",
                  error: true,
                })
              }
            }}
          />
        </div>
      ) : (
        <div className="mb-[30px]">You haven't added any variations yet.</div>
      )}
      {/* <div ref={loadRef} />
        {showPagination ? (
            <Pagination
            page={pageNumber}
            setPage={v => onPageChange(v)}
            totalPages={totalPage}
            />
        ) : hasMore ? (
            <div className="w-full overflow-hidden flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
            </div>
        ) : (
            <p className="text-reg-14">No more results to display</p>
        )} */}
    </div>
  )
}

export default FieldAssets
