/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"
import Skeleton from "../../../Skeleton"
import ButtonFilter from "../../ButtonFilter"
// import Icon from "../../assets/icons/delivery.svg"
// import { red } from "../Styles"
function FilterBarMeta(props) {
  const {
    dataLength,
    filterCallback,
    metaHideReset,
    showSideBar,
    total,
    loading,
    callReset,
    forceTray,
  } = props

  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 992px)").matches
  )
  console.log(forceTray, "forceTray")
  console.log(metaHideReset)

  useEffect(() => {
    const handleResize = () =>
      setIsDesktop(window.matchMedia("(min-width: 992px)").matches)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      {
        // <p className="text-reg-15 text-dark flex">
        //   Showing {loading ? <Skeleton fromText /> : dataLength} of {total}{" "}
        //   results
        // </p>
      }
      <div className="flex items-center justify-between px-[15px] md:px-0 w-full md:justify-start md:w-auto">
        {(!isDesktop && !showSideBar || forceTray === true) && (
          <ButtonFilter callback={filterCallback} title="Filters" />
        )}
        {!metaHideReset && (
          <ButtonFilter
            color={"blue"}
            reset
            callback={callReset}
            title="Reset Filters"
          />
        )}
      </div>


    </>
  )
  }

export default FilterBarMeta
