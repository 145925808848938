/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import DeleteIcon from "../../assets/icons/remove-alt.svg"

function ButtonDelete(props) {
  const { text, onClick, disable } = props
  if (props.disable) {
    return (
      <div
        // type={type}
        className={`${
          props.className ? props.className : ""
        } flex items-center justify-center`}
        css={css`
          /* border: 1px solid #ebebeb; */
          border-radius: 8px;

          letter-spacing: 0.03em;
          color: #9c9c9c;
          /* background-color: #ebebeb; */
          transition: 0.2s ease all;
          cursor: not-allowed;
          pointer-events: all !important;
          &:hover {
            /* background-color: #fff; */
            color: #9c9c9c;
          }
          svg {
            path {
              fill: #9c9c9c;
            }
          }
        `}
      >
        <div className="bg-[#ebebeb] rounded-[8px] h-[30px] w-[30px] flex items-center justify-center mr-2">
          <DeleteIcon className="text-white" />
        </div>
        <span className="">{text || "Delete"}</span>
      </div>
    )
  }
  return (
    <button
      type={"button"}
      onClick={onClick}
      className="text-reg-15 flex items-center hover-group"
      css={css`
        &&& {
          color: #e43d30;
        }
      `}
    >
      <div className="bg-[rgba(228,61,48,.1)] rounded-[8px] h-[30px] w-[30px] flex items-center justify-center mr-2">
        <DeleteIcon className="" />
      </div>
      <span className="hover">{text || "Delete"}</span>
    </button>
  )
}

export default ButtonDelete
