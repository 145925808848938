/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { Td, Tr, ThWrapper, Th } from './TableElements'

export const AllocationsTable = props => {
  const { columns, children, sm, overflowVisible, flush, flushHead, fit, className } =
    props

  let styles = css``
  if (flush)
    styles = css`
      &&& {
        tr {
          td {
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
          }
          td:first-child {
            padding-left: 0 !important;
          }
          td:last-child {
            padding-right: 0 !important;
          }
        }
      }
    `

  let tablestyles = css`thead {
    border: 1px solid transparent;
  }`

  if (fit)
    tablestyles = css`
    table-layout: fixed;
    width: 100%;
    &&& {
      td {
        // width: 1px;
        // white-space: nowrap;
      }
    }
    `

  return (
    <div className="flex flex-col">
      <div
        className={`-my-2 ${!overflowVisible && `overflow-x-auto`
          } sm:-mx-6 lg:-mx-8 `}
      >
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={`${!overflowVisible && `overflow-hidden`} ${!flush && `border border-[#EBEBEB] rounded-[8px]`
              }`}
          >
            <table
              className={`min-w-full divide-y divide-gray-200 ${className}`}
              css={tablestyles}
            >
              {columns && (
                <ThWrapper>
                  {columns.map((item, k) => {
                    if (item === "Per Segment Availability") return (
                      <th
                        scope="col"
                        className={` ${sm ? `py-[15px]` : `py-[30px]`} pb-[0] text-center text-med-16 ${flushHead ? `px-0` : `px-6`}`}
                      > <span css={css`
                      &&& {
                        color: #0033A1;
                        border-bottom: 2px solid #0033A1;
                        display: flex;
                        justify-content: center;
                      }
                    `}
                        className="pb-[13px] mb-[20px]">{item}</span></th>
                    )
                    if (item === "Per Location Order Limits") return (
                      <th
                        scope="col"
                        className={` ${sm ? `py-[15px]` : `py-[30px]`} pb-[0] text-center text-med-16 ${flushHead ? `px-0` : `px-6`}`}
                      >
                        <span css={css`
                          &&& {
                            color: #DDAA1F;
                            border-bottom: 2px solid #DDAA1F;
                            display: flex;
                            justify-content: center;
                          }
                        `}
                          className="pb-[13px] mb-[20px]">{item}</span></th>
                    )
                    return (
                      <th
                        scope="col"
                        className={` ${sm ? `py-[15px]` : `py-[30px]`} pb-[0] text-left text-med-16 ${flushHead ? `px-0` : `px-6`}`}
                      >
                        <span css={css`
                          &&& {
                            border-bottom: 2px solid #fff;
                            display: flex;
                            justify-content: center;
                          }
                        `}
                        className="pb-[13px] mb-[20px]">{item}</span>
                      </th>
                    )
                  })}
                </ThWrapper>
              )}
              <tbody
                className="_rm_bg-white divide-y divide-gray-200"
                css={styles}
              >
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}