/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

export const Table = props => {
  const {
    columns,
    children,
    sm,
    overflowVisible,
    flush,
    flushHead,
    fit,
    className,
    bold,
    padding,
  } = props

  let styles = css``
  if (flush)
    styles = css`
      &&& {
        tr {
          td {
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
          }
          td:first-child {
            padding-left: 0 !important;
          }
          td:last-child {
            padding-right: 0 !important;
          }
        }
      }
    `

  let tablestyles = css``

  if (fit)
    tablestyles = css`
      table-layout: fixed;
      width: 100%;
      &&& {
        td {
          // width: 1px;
          // white-space: nowrap;
        }
      }
    `

  return (
    <div className="flex flex-col">
      <div
        className={`-my-2 ${
          !overflowVisible && `overflow-x-auto`
        } sm:-mx-6 lg:-mx-8 `}
      >
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            className={`${!overflowVisible && ``} ${
              !flush && `border border-[#EBEBEB] rounded-[8px]`
            }`}
            css={css`
              overflow-x: auto;
              white-space: nowrap;
            `}
          >
            <table
              className={`min-w-full divide-y divide-gray-200 ${className}`}
              css={tablestyles}
            >
              {columns && (
                <ThWrapper>
                  {columns.map((item, k) => {
                    return (
                      <Th
                        key={k}
                        sm={sm}
                        flushHead={flushHead}
                        padding={padding}
                        bold={bold}
                      >
                        {item}
                      </Th>
                    )
                  })}
                </ThWrapper>
              )}
              <tbody
                className="divide-y divide-gray-200 _rm_bg-white"
                css={styles}
              >
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ThWrapper = props => {
  const { children } = props
  return (
    <thead className="">
      <tr>{children}</tr>
    </thead>
  )
}
export const Th = props => {
  const { children, right, sm, flushHead, bold, padding } = props
  return (
    <th
      scope="col"
      className={` ${sm ? `py-[15px]` : `py-[30px]`} ${
        right ? `text-right` : `text-left`
      } text-med-16 ${flushHead ? `px-0` : `px-6`} ${
        bold && "!font-bold"
      } ${padding}`}
    >
      {children}
    </th>
  )
}
export const Tr = props => {
  const { children, onClick } = props
  return (
    <tr className={props.className && props.className} onClick={onClick}>
      {children}
    </tr>
  )
}
export const Td = props => {
  const { children, onClick } = props
  return (
    <td
      className={`px-6 py-4 whitespace-nowrap text-reg-15 ${
        props.className && props.className
      }`}
      onClick={onClick}
    >
      {children}
    </td>
  )
}
export const Td1 = props => {
  const { children, onClick } = props
  return (
    <td
      className={`px-1 py-4 whitespace-nowrap text-reg-15 ${
        props.className && props.className
      }`}
      onClick={onClick}
    >
      {children}
    </td>
  )
}

export const Td2 = props => {
  const { children, onClick } = props
  return (
    <td
      className={`pl-2 py-4 whitespace-nowrap text-reg-15 ${
        props.className && props.className
      }`}
      onClick={onClick}
    >
      {children}
    </td>
  )
}
