import React from "react"
import LazyImage from "../../LazyImage"

function RecentlyUploadedCard(props) {
  const { callback, widthAuto } = props
  return (
    <div
      className={`my-1 px-1 w-full ${
        !widthAuto && `md:w-1/2 lg:my-4 lg:w-1/3 xl:w-1/4`
      } lg:px-4`}
      onClick={callback}
    >
      <article className="overflow-hidden rounded-[8px] border border-[#EBEBEB]">
        {props.filetype.includes("image") && (
          <>
            <LazyImage
              alt="Placeholder"
              className="block h-auto w-full"
              src={props.thumb}
            />
          </>
        )}

        <div className="p-2 md:p-4">
          <header className="">
            <h2 className="no-underline hover:underline text-med-16">
              {props.name}
            </h2>
          </header>
          <footer className="">
            <span className="text-reg-14">{props.filetype}</span>
          </footer>
        </div>
      </article>
    </div>
  )
}

export default RecentlyUploadedCard
