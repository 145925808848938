/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useContext, useEffect } from "react"
import Icon from "../../assets/icons/heart.svg"
import { red, green, blue, redLight, greenLight, blueLight } from "../Styles"
import NotificationContext from "../../context/Notification/NotificationContext"
import StateContext from "../../context/state/StateContext"
import { addmyBookmark, getMyBookmarks } from "../../helpers/DrupalHelper"
import DataContext from "../../context/Data/DataContext"
function AddToWishlist(props) {
  const { noText, isListed } = props

  const [following, setFollowing] = useState(isListed)
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const { setData } = useContext(DataContext)
  useEffect(() => {
    setFollowing(isListed)
    // console.log(isListed, 'isListed')
  }, [isListed])

  // useEffect(() =)

  const onFollowClick = async () => {
    let body = {
      [props.name]: props.id,
    }
    // if (props.assets) body = { asset: props.nid }

    let res = await addmyBookmark(token, body)
    if (res.status === 200) {
      getMyBookmarks(token).then(res => {
        // console.log(res)
        let product = res.map(item => item["product"]?.id).filter(Boolean)
        // console.log(product)
        let asset = res.map(item => item["asset"]?.id).filter(Boolean)
        // console.log(asset)
        let retailer = res
          .map(item =>
            item["retailer"] && Array.isArray(item["retailer"])
              ? item["retailer"][0]?.id
              : item["retailer"]?.id
          )
          .filter(Boolean)

        // console.log(retailer)
        let store = res.map(item => item["store"]?.id).filter(Boolean)

        // console.log(store)
        let brand = res.map(item => item["brand"]?.id).filter(Boolean)
        // console.log(brand)
        setData(prevState => ({
          ...prevState,
          myBookmarks: res,
          myBookmarksID: { product, store, asset, brand, retailer },
        }))
      })
      if (following) {
        setFollowing(false)
        toggleNotification({ content: `You just unbookmarked ${props.title}` })
      } else {
        setFollowing(true)
        toggleNotification({ content: `You just bookmarked ${props.title}` })
      }
    } else {
      toggleNotification({ content: "failed to bookmarked" })
    }
  }
  return (
    <button
      className="flex items-center AddToWishlist"
      css={css`
        z-index: 222222;
      `}
      onClick={onFollowClick}
    >
      <div
        className="flex items-center justify-center"
        css={css`
          width: 40px;
          height: 40px;
          background: ${following ? redLight : "#ffffff"};
          box-shadow: 0px 6px 20px rgba(34, 34, 34, 0.05);
          border-radius: 50%;
          border: 1px solid #ebebeb;
          border-color: ${following ? "#f7d1ce" : "#ebebeb"};
          margin-right: ${props.noText ? `0` : `13px`};
          svg {
            path {
              fill: ${following ? red : "rgb(72, 72, 72)"};
            }
          }
          &:hover {
            border-color: ${following ? red : "#222"};
            background: ${following ? red : "#ffffff"};
            svg {
              path {
                fill: ${following ? "#fff" : "rgb(72, 72, 72)"};
              }
            }
          }
        `}
      >
        <Icon className={`${following ? "red" : "#222"}`} />
      </div>
      {!noText && (
        <span
          className="text-reg-14"
          css={css`
            &&& {
              color: #222222;
              &:hover {
                color: ${red};
              }
            }
          `}
        >
          {following ? `Remove from Favorites` : `Add to Favorites`}
        </span>
      )}
    </button>
  )
}

export default AddToWishlist
