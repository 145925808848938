/** @jsx jsx */
import { css, jsx } from "@emotion/react"

import React from "react"
import Icon from "../../assets/icons/download.svg"
import { green } from "../Styles"
function MetaDownload(props) {
  let styles = `font-size: 10px;
  font-weight: 600;
  color: ${green};`

  if (props.black)
    styles =
      styles +
      `
      svg {
        path {
          fill: #222;
        }
      }
    `

  return (
    <div className="flex items-center uppercase green" css={css(styles)}>
      <Icon className="mr-[.4rem] mt-[-2px] ml-[-2px]" />
      {!props.noText && "Download"}
    </div>
  )
}

export default MetaDownload
