/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useContext, useEffect, useRef } from "react"
import ShareIcon from "../../assets/icons/share.svg"
import Copy from "../../assets/icons/copy.svg"
import { red, green, blue, redLight, greenLight, blueLight } from "../Styles"
import NotificationContext from "../../context/Notification/NotificationContext"
import StateContext from "../../context/state/StateContext"
import { addmyBookmark, getMyBookmarks } from "../../helpers/DrupalHelper"
import DataContext from "../../context/Data/DataContext"
import QRCode from "react-qr-code"
import Message from "../../assets/icons/message.svg"
import Modal from "../Modal/Modal"
import ContactBrandForm from "../Forms/ContactBrandForm"
import CreateMessage from "../Forms/DynamicForm/CreateMessage"
function Share(props) {
  const { noText, title } = props

  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const { setData } = useContext(DataContext)
  const [modalContact, setModalContact] = useState(false)

  // useEffect(() =)
  const [showPopup, setShowPopup] = useState(false)

  const onShareClick = async () => {
    // Perform any necessary share action here
    // For example, you can toggle a boolean to show the popup
    setShowPopup(!showPopup)
  }

  //get the current url
  const currentUrl = window.location.href

  const popupRef = useRef(null)

  useEffect(() => {
    // Function to close the popup when clicking outside of it
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false)
      }
    }

    // Adding event listener when the popup is shown
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      // Removing event listener when the popup is hidden
      document.removeEventListener("mousedown", handleClickOutside)
    }

    // Clean up function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showPopup])

  const onCopyClick = () => {
    // Copy the current url to the clipboard
    navigator.clipboard.writeText(currentUrl)
    toggleNotification({ content: `Link copied to clipboard`, type: "success" })
  }
  return (
    <div>
      <button
        className="flex items-center AddToWishlist"
        css={css`
          z-index: 222222;
        `}
        onClick={onShareClick}
      >
        <div
          className="flex items-center justify-center"
          css={css`
            width: 40px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 6px 20px rgba(34, 34, 34, 0.05);
            border-radius: 50%;
            border: 1px solid #ebebeb;
            border-color: #ebebeb;
            margin-right: 13px;
            svg {
              path {
                fill: rgb(72, 72, 72);
              }
            }
            &:hover {
              border-color: ${red};
              background: #ffffff;
              svg {
                path {
                  fill: ${red};
                }
              }
            }
          `}
        >
          <ShareIcon />
        </div>
        {
          <span
            className="text-reg-14"
            css={css`
              &&& {
                color: #222222;
                &:hover {
                  color: ${red};
                }
              }
            `}
          >
            Share
          </span>
        }
      </button>
      {showPopup && (
        <div
          ref={popupRef}
          css={css`
            position: absolute;
            // top: calc(100% + 10px);
            transform: translateX(-50%);
            background: #fff;
            border: 1px solid #ebebeb;
            border-radius: 8px;
            padding: 30px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            z-index: 999999; /* Make sure this value is higher than the button's z-index */
            @media (max-width: 420px) {
              right: -130px;
            }
            @media (min-width: 650px) and (max-width: 767px) {
              right: -140px;
            }
          `}
        >
          {/* Content of your popup goes here */}
          <QRCode value={currentUrl} />
          <div
            className="flex items-center justify-center mt-6 cursor-pointer"
            onClick={() => {
              onCopyClick()
            }}
          >
            <div
              className="flex items-center justify-center"
              css={css`
                width: 40px;
                height: 40px;
                background: #ffffff;
                box-shadow: 0px 6px 20px rgba(34, 34, 34, 0.05);
                border-radius: 50%;
                border: 1px solid #ebebeb;
                border-color: #ebebeb;
                margin-right: 13px;
                &:hover {
                  border-color: #222;
                  background: #ffffff;
                }
              `}
            >
              <Copy />
            </div>
            Copy Link URL
          </div>
          <div
            className="flex items-center justify-center mt-6 cursor-pointer"
            onClick={() => {
              onShareClick()
              setModalContact(!modalContact)
            }}
          >
            <div
              className="flex items-center justify-center"
              css={css`
                width: 40px;
                height: 40px;
                background: #ffffff;
                box-shadow: 0px 6px 20px rgba(34, 34, 34, 0.05);
                border-radius: 50%;
                border: 1px solid #ebebeb;
                border-color: #ebebeb;
                margin-right: 13px;
                &:hover {
                  border-color: #222;
                  background: #ffffff;
                }
              `}
            >
              <Message />
            </div>
            Send Message
          </div>
        </div>
      )}
      <Modal
        isOpen={modalContact}
        title="Create New Message"
        closeModal={() => setModalContact(false)}
      >
        <CreateMessage setModalOpen={setModalContact} share title={title} />
      </Modal>
    </div>
  )
}

export default Share