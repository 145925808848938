/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect } from "react"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import Next from "../assets/icons/next.svg"
import Prev from "../assets/icons/prev.svg"
import { red } from "./Styles"
import { Link as GLink } from "gatsby"
import { useMediaQuery } from "react-responsive"
import { handleScrolltoRef } from "../utils"
const Div = ({ children, ...props }) => <div {...props}>{children}</div>

export function Paginator({ totalPages, setPage, page, notLink, scrollRef }) {
  const Link = !notLink ? GLink : Div

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const pagination = []
  // useEffect(() => {
  //   // if (!scrollRef && !isMobile && !notLink) {
  //   //   window.scrollTo({
  //   //     top: 0,
  //   //     behavior: "smooth",
  //   //   })
  //   // }
  //   console.log(scrollRef)
  //   if (
  //     scrollRef &&
  //     scrollRef?.current !== null &&
  //     scrollRef.current !== undefined
  //   ) {
  //     handleScrolltoRef(scrollRef.current)
  //   }
  // }, [page])
  function createLink(i) {
    // const page = `?page=${i}`
    return (
      <Link
        to={`?p=${i}`}
        onClick={() => {
          setPage(i)
          if (scrollRef?.current) handleScrolltoRef(scrollRef.current)
        }}
        aria-current="page"
        className={
          page === i
            ? `h-9 w-9 rounded-full relative inline-flex items-center justify-center text-sm font-medium text-white`
            : "z-10  relative inline-flex items-center px-4 py-2 text-black text-sm font-medium"
        }
        css={css`
          &&& {
            background-color: ${page === i ? red : `transparent`};
            &:hover {
              color: ${page === i ? `#fff` : red};
            }
            cursor: pointer;
          }
        `}
      >
        {i}
      </Link>
    )
  }

  function createDots() {
    return (
      <span className="relative inline-flex items-center px-4 py-2  z-10 relative inline-flex items-center px-4 py-2 text-black text-sm font-medium">
        ...
      </span>
    )
  }

  // If there are no pages return a message
  if (!totalPages) return <div>No pages</div>

  // If totalPages is less than seven, iterate
  // over that number and return the page links
  if (totalPages < 7) {
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(createLink(i))
    }
    return pagination
  }

  // Otherwise create the first three page links
  let n = isMobile ? page : page !== 1 && page - 1
  let B = isMobile ? page + 1 : page + 3
  if (!isMobile && totalPages - 3 <= page) {
    n = page - 3
    B = page + 1
  } else if (isMobile && totalPages - 1 <= page) {
    n = page - 1
    B = page + 1
  }

  for (let i = n; i <= B; i++) {
    if (!isMobile && totalPages - 3 >= i) pagination.push(createLink(i))
    if (isMobile && totalPages - 1 >= i) pagination.push(createLink(i))
  }

  // Create the dots
  pagination.push(createDots())

  // Last three page links
  if (!isMobile) {
    for (let i = totalPages - 2; i <= totalPages; i++) {
      pagination.push(createLink(i))
    }
  } else {
    for (let i = totalPages; i <= totalPages; i++) {
      pagination.push(createLink(i))
    }
  }

  return pagination
}
export default function Pagination({
  page,
  setPage,
  totalPages,
  notLink,
  scrollRef,
}) {
  const Link = !notLink ? GLink : Div
  return (
    <div className="px-4 py-3 flex items-center justify-center  sm:px-6">
      <div className="flex-1 flex items-center justify-center">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md space-x-3"
            aria-label="Pagination"
          >
            {totalPages ? (
              <>
                {page > 1 && (
                  <Link
                    to={`?p=${page - 1}`}
                    onClick={() => {
                      setPage(page - 1)
                      if (scrollRef?.current)
                        handleScrolltoRef(scrollRef.current)
                    }}
                    className="inline-flex items-center p-2 cursor-pointer"
                  >
                    <span className="sr-only">Previous</span>
                    <Prev
                      className="h-3 w-3"
                      css={css`
                        &&& {
                          &:hover {
                            path {
                              fill: ${red};
                            }
                          }
                        }
                      `}
                    />
                  </Link>
                )}
                <Paginator
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  scrollRef={scrollRef}
                  notLink={notLink ? true : false}
                />
                {totalPages !== page && (
                  <Link
                    to={`?p=${page + 1}`}
                    onClick={() => {
                      setPage(page + 1)
                      if (scrollRef?.current)
                        handleScrolltoRef(scrollRef.current)
                    }}
                    className="inline-flex items-center p-2 cursor-pointer"
                    css={css`
                      &&& {
                        &:hover {
                          path {
                            fill: ${red};
                          }
                        }
                      }
                    `}
                  >
                    <span className="sr-only">Next</span>
                    <Next className="h-3 w-3" />
                  </Link>
                )}
              </>
            ) : (
              <>No More Pages</>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}
