/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState, useContext } from "react"
import { useForm } from "react-hook-form"
import { useLocation, useHistory, Link } from "@reach/router"
import FloatingTelephone from "../../Elements/Form/FloatingTelephone"
import FloatingInput from "../../Elements/Form/FloatingInput"
import Button from "../../Elements/Button"
import FloatingTextarea from "../../Elements/Form/FloatingTextarea"
import BooleanType from "./FormTypes/BooleanType"
import EntityReferenceRevisions from "./FormTypes/EntityReferenceRevisions"
import EntityReferenceFieldConfig from "./FormTypes/EntityReferenceFieldConfig"
import Media from "./FormTypes/Media"
import {
  addMySingleNewForm,
  fileUploadDrupal,
  fileUploadToMediaEntityDrupal,
  getMySingleForm,
  updateMySingleEditForm,
} from "../../../helpers/DrupalHelper"
import Float from "./FormTypes/Float"
import ListString from "./FormTypes/ListString"
import ListStringAddressType from "./FormTypes/ListStringAddressType"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import GeoLocations from "./FormTypes/GeoLocations"
import NotificationContext from "../../../context/Notification/NotificationContext"
import mapSingle from "../../../utils/mapSingle"
import { RadioGroupAsset } from "../../Tabs/ManageFileAssetTab"
import ManageUserCompany from "./FormTypes/ManageUserCompany"
import CompanyField from "./FormTypes/CompanyField"
import { Menu, Tab } from "@headlessui/react"
import { classNames } from "../../../utils"
import AssetBrand from "./FormTypes/AssetBrand"
import FieldAssets from "./FormTypes/FieldAssets"
import RetailerField from "./FormTypes/RetailerField"
import PriceBooleanType from "./FormTypes/PriceBooleanType"
import StateContext from "../../../context/state/StateContext"

const SingleField = props => {
  const { children, small, med } = props

  let maxWidth = "none"
  if (small) maxWidth = 500
  if (med) maxWidth = 700
  return <>{children}</>
}

// Reusable Form Component
let companyArr = [
  "field_brand_company",
  // "field_asset_company",
  // "field_product_company",
  // "field_store_company",
  "field_retailer_company",
]
let skipArray = [
  /* NOTE: Use this array for fully, 100%, exclcuding these fields from being POSTED. Use this for fields where Drupal is manipulating them in hook_insert or hook_update. If excluding here, there is no possiblity with any clever hacks in your code to POST the field to Drupal. This array will NOT register the field properly in react-hook-form, it will completely unset it, eg field_size_type. So, exclude it manually from the return in the render func below, but do not list here if we are just moving it into another container.*/
  "id",
  "field_brand_active",
  "field_internal_featured",
  "field_aloc_rep",
  "field_aloc_ret",
  "field_aloc_unallocated",
  "field_aloc_rep_restrictions",
  "field_aloc_ret_restrictions",
  "field_shopifyid",
  "field_shopifyinventoryid",
  "field_aloc_rep_segments",
  "field_shopifyvariantid",
  // "field_asset_price",
  "field_asset_sku",
  "field_product_featured",
  "field_aloc_unallocated",
  "field_company_created",
  "field_company_type",
  "field_retailer_created",
  "field_provided_retailer_id",
  "field_google_maps_link",
  "field_provided_location_id",
  "field_store_website",
  "field_store_opening_hours",
  "field_deleted",
  "field_provided_retailer_id",
  "field_provided_store_id",
  "field_store_permanently_closed",
  "field_store_user",
  "field_type",
  "field_asset_company",
  "field_store_company",
  "field_product_company",
  // "field_size_type",
]
let delDisable = ["brand", "retailer", "company"]
export const renderFields = (
  setRestrictCheck,
  restrictCheck,
  tabsTouched,
  data,
  errors,
  register,
  setValue,
  watch,
  index,
  unregister,
  prename,
  token,
  control,
  location,
  fromManageFileTab,
  entityType,
  bundle,
  fromTabs,
  fieldsIncludes,
  edit,
  dynamFields,
  getValues,
  suppData,
  formName,
  onExtraClick,
  savedStoreId
) => {
  watch()
  if (data) {
    // console.log(watch().field_asset_price, "from data")
    return Object.entries(data).map(field => {
      // let { type, label, description } = field[1]
      let { type, description } = field[1]
      // console.log(type, field[1], "from type")
      const requi = field[1].required
      //  let lebe = field[1].label;
      const label = !requi ? field[1].label : `${field[1].label} *`

      let name = field[0]
      if (index) {
        name = `${prename}_${field[0]}_${index.toString()}`
      }
      if (type === "text_long") {
        type = "string_long"
      }
      if (
        fromManageFileTab &&
        [
          "field_asset_files",
          "field_product_images",
          "field_asset_cover_image",
        ].includes(name)
      )
        return
      // if (name === "field_type" && bundle === "asset_group") return

      if (skipArray.includes(name)) return
      if (fromTabs && fieldsIncludes && !fieldsIncludes.includes(name)) return

      let checkifhidden = dynamFields?.filter(f => f.subject === name) || []

      if (name === "title" && entityType === "brand") return
      const { userType } = useContext(AuthenticationContext)

      if (formName === "address") {
        useEffect(() => {
          if (watch("field_address_type") === "store") {
            unregister("field_location")
            // unregister("field_phone")
            setValue("field_location", null)
          }
          if (name === "field_store" && suppData) {
            const store_id_selected = watch().field_store
            const storeExists = suppData.some(obj =>
              !edit
                ? store_id_selected &&
                  obj.store_id === Number(store_id_selected)
                : store_id_selected &&
                  obj.store_id === Number(store_id_selected) &&
                  data?.field_store?.current_value?.[0]?.target_id ===
                    store_id_selected
            )

            console.log({
              storeExists,
              errors,
              suppData,
              store_id_selected,
            })
            register("field_store", {
              validate: value => {
                if (storeExists === true) {
                  return "Address Card not saved. Sorry, but you already have an Address Card in your Address Book for this Location."
                }
              },
              required: store_id_selected && "This field is required.",
            })
          }
        }, [watch().field_store])

        useEffect(() => {
          if (savedStoreId) {
            if (["store"].includes(watch("field_address_type"))) {
              console.log(savedStoreId)
              setValue("field_store", savedStoreId.toString())
            }
          }
        }, [savedStoreId])

        useEffect(() => {
          if (
            ["office", "home", "locker", "other"].includes(
              watch("field_address_type")
            )
          ) {
            setValue("field_store", null)
            unregister("field_store")
          }
          console.log(watch().field_store, errors.field_store)
          if (
            (name === "field_location" &&
              suppData &&
              watch().field_store === undefined) ||
            watch().field_store === null
          ) {
            const location_id_selected = parseInt(
              watch().field_location?.[0]?.target_id || watch().field_location
            )

            const locationExists = suppData.some(obj =>
              !edit
                ? obj.location_id === Number(location_id_selected)
                : obj.location_id === Number(location_id_selected) &&
                  data.field_location?.current_value[0]?.target_id !==
                    location_id_selected
            )

            console.log({
              locationExists,
              errors,
              suppData,
              location_id_selected,
            })

            register("field_location", {
              validate: value => {
                if (locationExists === true) {
                  return "Address Card not saved. Sorry, but you already have an Address Card in your Address Book for this Location."
                }
              },
              required: location_id_selected && "This field is required.",
            })
          }
        }, [watch().field_location])
      }

      if (
        checkifhidden.length === 1 &&
        Array.isArray(checkifhidden[0].if_equals) &&
        !checkifhidden[0].if_equals.includes(
          getValues()[checkifhidden[0].controller]
        )
      ) {
        return
      }
      switch (type) {
        case "string_long":
          return (
            <FloatingTextarea
              label={label}
              description={description}
              error={errors[name]}
              name={name}
              hookForm
              data={field[1]}
              register={register}
            />
          )
        case "string":
          return (
            <FloatingInput
              label={label}
              description={description}
              className="mb-[20px]"
              name={name}
              error={errors[name]}
              hookForm
              data={field[1]}
              register={register}
              setValue={setValue}
              watch={watch}
              required={field[1].required}
            />
          )
        case "address":
          return (
            <FloatingInput
              label={label}
              description={description}
              className="mb-[20px]"
              name={name}
              error={errors[name]}
              hookForm
              required={field[1].required}
              data={field[1]}
              type={"address"}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          )
        case "email":
          return (
            <FloatingInput
              label={label}
              description={description}
              className="mb-[20px]"
              name={name}
              error={errors[name]}
              hookForm
              data={field[1]}
              register={register}
              required={field[1].required}
              type="email"
            />
          )
        case "integar":
          return (
            <FloatingInput
              label={label}
              description={description}
              className="mb-[20px]"
              name={name}
              required={field[1].required}
              error={errors[name]}
              hookForm
              data={field[1]}
              register={register}
              setValue={setValue}
              type="number"
            />
          )
        case "telephone":
          return (
            <FloatingTelephone
              label={label}
              required={field[1].required}
              description={description}
              className="mb-[20px]"
              name={name}
              error={errors[name]}
              hookForm
              data={field[1]}
              register={register}
              setValue={setValue}
              control={control}
              type="tel"
            />
          )
        case "boolean":
          return (
            <BooleanType
              label={label}
              description={description}
              className="mb-[20px]"
              name={name}
              error={errors[name]}
              hookForm
              data={field[1]}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          )
        case "entity_reference":
          // if(typeof dynamicFields !== "undefined" && Array.isArray(dynamicFields) && ){
          //
          // } else
          if (field[1].hasOwnProperty("entity_type")) {
            if (field[1].entity_type.machine_name === "paragraph") {
              return <p>paragraph type</p> //<EntityParagraph data={field[1]} token={token} />;
            } else if (field[1].entity_type.machine_name === "media") {
              return (
                <SingleField med>
                  <Media
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    file_size={26214400}
                  />
                </SingleField>
              )
            } else if (
              name.includes("location") &&
              field[1].entity_type.machine_name === "location"
            ) {
              return (
                <SingleField med>
                  <GeoLocations
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    unregister={unregister}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                    fromAddressPage={true}
                    required
                    control={control}
                  />
                </SingleField>
              )
            } else if (field[1].entity_type.machine_name === "field_config") {
              return (
                <SingleField med>
                  <EntityReferenceFieldConfig
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                  />
                </SingleField>
              )
            } else if (companyArr.includes(name)) {
              return (
                <CompanyField
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                  label={label}
                  setValue={setValue}
                  watch={watch}
                  token={token}
                  control={control}
                />
              )
            } else {
              if (
                entityType === "user_group" &&
                name == "field_group_company"
              ) {
                return (
                  <ManageUserCompany
                    data={field[1]}
                    errors={errors}
                    unregister={unregister}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                    control={control}
                    dataUser={data["field_group_users"]}
                    dataRegion={data["field_group_regions"]}
                    dataStore={data["field_group_stores"]}
                    dataCompany={data["field_group_companies"]}
                    editing={edit ? true : false}
                  />
                )
              } else if (
                entityType === "user_group" &&
                name == "field_group_users"
              )
                return null
              else if (
                entityType === "user_group" &&
                name == "field_group_regions"
              )
                return null
              else if (
                entityType === "user_group" &&
                name == "field_group_stores"
              )
                return null
              else if (
                entityType === "user_group" &&
                name == "field_group_companies"
              )
                return null
              else if (
                // entityType === "user_group" &&
                name == "field_asset_brand" ||
                name == "field_product_brand"
              ) {
                return (
                  <AssetBrand
                    data={field[1]}
                    errors={errors}
                    // unregister={unregister}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    // token={token}
                    control={control}
                  />
                )
              } else if (
                // entityType === "user_group" &&
                name == "field_store_retailer"
              ) {
                return (
                  <RetailerField
                    data={field[1]}
                    errors={errors}
                    // unregister={unregister}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    // watch={watch}
                    // token={token}
                    control={control}
                    dataCompany={data["field_store_company"]}
                  />
                )
              } else if (name === "field_store") {
                console.log(errors[name])
                return (
                  <SingleField small>
                    <EntityReferenceRevisions
                      data={field[1]}
                      errors={
                        errors && errors.field_store
                        // (errors.field_store?.message  || errors.field_store errors.field_store?.message)
                      }
                      key={name}
                      name={name}
                      register={register}
                      label={label}
                      setValue={setValue}
                      watch={watch}
                      token={token}
                      control={control}
                      description={
                        <>
                          {userType.retailerManager === true ? (
                            <p>
                              To select a store missing from this list,{" "}
                              <span
                                className="text-red underline cursor-pointer"
                                onClick={onExtraClick}
                              >
                                Click Here
                              </span>
                              {
                                //  a retail company “Manager” or O2O Admin must first add it to
                                // the company's
                                // {userType.retailerManager === true ? (
                                //   <Link
                                //     to="/dashboard/my-organization"
                                //     className="ml-1"
                                //   >
                                //     <span className="text-red underline">
                                //       Stores
                                //     </span>
                                //   </Link>
                                // ) : (
                                //   <span className="ml-1 text-red">Stores</span>
                                // )}
                              }
                            </p>
                          ) : (
                            <p>
                              To select a store missing from this list, a retail
                              company “Manager” or O2O Admin must first add it
                              to the company's
                              <span className="ml-1 text-red">Stores</span>
                            </p>
                          )}
                        </>
                      }
                    />
                  </SingleField>
                )
              } else
                return (
                  <SingleField small>
                    <EntityReferenceRevisions
                      data={field[1]}
                      errors={errors}
                      key={name}
                      name={name}
                      register={register}
                      label={label}
                      setValue={setValue}
                      watch={watch}
                      token={token}
                      control={control}
                      restrictCheck={restrictCheck}
                      setRestrictCheck={setRestrictCheck}
                    />
                  </SingleField>
                )
            }
          } else {
            return (
              <SingleField small>
                <FloatingInput
                  label={label}
                  required={field[1].required}
                  description={description}
                  className="mb-[20px]"
                  name={name}
                  error={errors[name]}
                  hookForm
                  data={field[1]}
                  setValue={setValue}
                  register={register}
                />
              </SingleField>
            )
          }

        case "entity_reference_revisions":
          return (
            <SingleField small>
              <EntityReferenceRevisions
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
                token={token}
              />
            </SingleField>
          )
        case "list_string":
          if (name === "field_size_type") return
          // if (
          //   entityType === "address" &&
          //   bundle === "default" &&
          //   name === "field_address_type"
          // ) {
          //   return (
          //     <SingleField small>
          //       <ListStringAddressType
          //         data={field[1]}
          //         errors={errors}
          //         key={name}
          //         name={name}
          //         register={register}
          //         label={label}
          //         setValue={setValue}
          //         watch={watch}
          //         control={control}
          //       />
          //     </SingleField>
          //   )
          // }

          return (
            <SingleField small>
              <ListString
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
                control={control}
                isDisabled={name === "field_address_type" && entityType === "address" && !userType.brandManager && !userType.brandEditor}
              />
            </SingleField>
          )
        case "float":
          if (name === "field_size") {
            // console.log(field[1], "field")
            // console.log(name, "name")
            // console.log(register, "register")
            // console.log(setValue, "setVal")
            // console.log(control, "control")
            // console.log(data["field_size_type"], "field")
            return (
              <div className="grid md:grid-cols-2 md:gap-[15px]">
                <Float
                  data={field[1]}
                  error={errors[name]}
                  key={name}
                  name={name}
                  register={register}
                  label={label}
                  setValue={setValue}
                  watch={watch}
                />
                <ListString
                  data={data["field_size_type"]}
                  errors={errors}
                  key={"field_size_type"}
                  name={"field_size_type"}
                  register={register}
                  label={data["field_size_type"].label}
                  setValue={setValue}
                  watch={watch}
                  control={control}

                  // data={data["field_size_type"]}
                  // errors={errors}
                  // name={"field_size_type"}
                  // register={register}
                  // label={data["field_size_type"].label}
                  // setValue={setValue}
                  // watch={watch}
                  // control={control}
                />
              </div>
            )
          } else if (name === "field_asset_price") {
            return (
              <SingleField small>
                <PriceBooleanType
                  label={label}
                  description={description}
                  className="mb-[20px]"
                  name={name}
                  error={errors[name]}
                  hookForm
                  control={control}
                  data={field[1]}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  tabsTouched={tabsTouched}
                />
              </SingleField>
            )
          } else
            return (
              <SingleField small>
                <Float
                  data={field[1]}
                  error={errors[name]}
                  key={name}
                  name={name}
                  register={register}
                  label={label}
                  setValue={setValue}
                  watch={watch}
                />
              </SingleField>
            )
        default:
          return <SingleField>no field</SingleField>
      }
    })
  }
}

function DynamicForm({
  data: propsData,
  token,
  id,
  name,
  bundle: propsBundle,
  addNew,
  isSaved,
  edit,
  nested,
  editSubmit,
  onDelete,
  fromManageFileTab,
  onSave,
  fileId,
  dynamicFields,
  suppData,
  setUserAddressFormData,
  list,
  onExtraClick,
  savedStoreId,
}) {
  let defaultValues = {}
  const [restrictCheck, setRestrictCheck] = useState(false)
  // console.log(data)
  Object.keys(propsData).map(v => {
    if (!skipArray.includes(v) && addNew)
      Object.assign(defaultValues, { [v]: propsData[v].default_value })
    if (!skipArray.includes(v) && !addNew)
      Object.assign(defaultValues, { [v]: propsData[v].current_value })
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm({ defaultValues: defaultValues })
  const location = useLocation()
  const [radioValue, setRadioValue] = useState(1)
  const [bundle, setBundle] = useState(propsBundle)
  const [data, setData] = useState(propsData)

  const [dynamFields, setDynamFields] = useState([])
  useEffect(() => {
    if (dynamicFields) {
      setDynamFields(dynamicFields)
    }
  }, [])
  const { currentUserId, authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { companies } = currentUserData
  const [saving, setSaving] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)

  const formData = getValues()
  useEffect(() => {
    let body = formData
    if (name === "address") {
      if (
        ["office", "home", "locker", "other"].includes(body.field_address_type)
      ) {
        delete body.field_store
      } else if (["store"].includes(body.field_address_type)) {
        delete body.field_location
        // delete body.field_first_name
        // delete body.field_last_name
        delete body.field_company_name
        delete body.customBodyLocation
        // delete body.field_phone
      }
    }
    console.log({ body })
    if (setUserAddressFormData) {
      setUserAddressFormData(body)
    }
  }, [
    formData.title,
    formData.field_address_type,
    formData.field_hide_from_cart,
    formData.field_phone,
    formData.field_first_name,
    formData.field_last_name,
    formData.field_company_name,
    formData.field_store,
    formData.customBodyLocation,
    formData.field_location,
  ])

  const onSubmit = async v => {
    setSaving(true)
    let data = getValues()
    for (let key in data) {
      if (data[key] === undefined) data[key] = null
    }

    let locationBody = data?.customBodyLocation?.body
    let locationFieldName = data?.customBodyLocation?.name
    let locId = ""

    await mapSingle(Object.keys(data), async v => {
      if (v.includes("fromUpload_")) {
        let name = v.replace("fromUpload_", "")
        let files = data[v]
        if (files.length) {
          let ids = []
          await mapSingle(
            files,
            async (file, k) => {
              let res = await fileUploadDrupal(token, file)
              if (res.response.ok) {
                let res2 = await fileUploadToMediaEntityDrupal(
                  token,
                  "asset_file",
                  companies[0].id,
                  res.data
                )
                toggleNotification({ content: `${file.name} Uploaded` })
                let rees2Data = await res2.json()
                ids.push(rees2Data.mid[0].value)
                delete data[v]
              } else
                toggleNotification({
                  content: `Fail to Upload ${file.name} `,
                })
            },
            1
          )
          data[name] = ids
        }
      }
    })

    if (locationBody) {
      let res = await addMySingleNewForm(
        token,
        "location",
        "default",
        locationBody
      )
      if (res.status === 200) {
        let dataL = await res.json()
        let id = dataL.id
        locId = id[0].value

        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">
              Added new location
            </p>
          ),
        })
      } else
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">
              fail to add new location
            </p>
          ),
        })
    }

    if (!addNew && edit) {
      let nData = { ...data }

      if (locationBody && locId)
        nData = {
          ...data,
          [locationFieldName]: locId,
          customBodyLocation: undefined,
        }
      editSubmit(nData, defaultValues)
    } else {
      let title = data.title
      let withOutTitle = { ...data }
      delete withOutTitle.title
      let body = {
        entity_title: title,
        json: { ...withOutTitle, customBodyLocation: undefined },
      }

      if (locationBody && locId && locationFieldName) {
        body.json = { ...body.json, [locationFieldName]: locId }
      }

      if (fromManageFileTab) {
        if (name === "product")
          body = {
            ...body,
            json: { ...body.json, field_product_images: [fileId] },
          }
        if (name === "asset" && bundle === "default") {
          if (radioValue == 2) {
            body = {
              ...body,
              json: {
                ...body.json,
                field_asset_files: [fileId],
              },
            }
          } else {
            body = {
              ...body,
              json: {
                ...body.json,
                field_asset_cover_image: [fileId],
              },
            }
          }
        }
      }
      if (name == "retailer") {
        body = {
          ...body,
          json: { ...body.json, field_retailer_created: currentUserId },
        }
      }

      if (name === "store") {
        if (locId)
          body = {
            ...body,

            json: { ...body.json, field_store_location: locId },
          }

        onSave(body, addNew ? "added" : "edited")
        return
      }

      if (name === "address") {
        if (
          ["office", "home", "locker", "other"].includes(
            body.json.field_address_type
          )
        ) {
          delete body.json.field_store
        } else if (["store"].includes(body.json.field_address_type)) {
          delete body.json.field_location
          // delete body.json.field_first_name
          // delete body.json.field_last_name
          delete body.json.field_company_name
          delete body.json.customBodyLocation
          // delete body.json.field_phone
        }
      }

      let res = await addMySingleNewForm(token, name, bundle, body)

      if (res.status === 200) {
        let resData = await res.json()
        onSave(data, "added", resData)
      } else {
        onSave("fail")
      }
    }
    setSaving(false)
  }

  const handleDelete = () => {
    onDelete()
    // await deleteEntity(token, type, bundle, id).then(res => {
    //   console.log(res)
    //   if (res.status === 200) {
    //     // history.push(`/${type}`)
    //     // dispatch({
    //     //   type: "SUCCESSFUL_TOAST",
    //     //   payload: `Successfully deleted ${id} from ${type} ${bundle}`,
    //     // })
    //   } else {
    //     // dispatch({
    //     //   type: "SUCCESSFUL_TOAST",
    //     //   payload: `${id} from ${type} ${bundle} failed to delete`,
    //     // })
    //   }
    // })
  }
  // console.log(watch())
  // console.log(data)
  let tabss = [
    // { name: "Activity feed", href: "#", component: false },
    // { name: "Albums", href: "#", component: false },
  ]
  if (name === "asset") {
    tabss = [
      {
        name: "General",
        fields: [
          "title",
          "field_description",
          // "field_asset_company",
          "field_asset_brand",
        ],
      },
      {
        name: "Categorization",
        fields: [
          "field_asset_category",
          "field_asset_tags",
          "field_asset_type",
        ],
      },
      {
        name: "Media",
        fields: ["field_asset_files", "field_asset_cover_image"],
      },
      {
        name: "Price",
        fields: ["field_asset_price"],
      },
      {
        name: "Restrictions",
        fields: ["field_asset_by_request", "field_user_groups", "field_active"],
      },
    ]
  }
  if (bundle === "asset_group") {
    tabss = [
      {
        name: "General",
        fields: [
          "title",
          "field_description",
          // "field_asset_company",
          "field_asset_brand",
        ],
      },
      {
        name: "Variations",
        fields: ["field_assets"],
      },
    ]
  }
  const [tabs, setTabs] = useState(tabss)
  // console.log(tabs, "from tabs")
  const [fieldAssets, setFieldAssets] = useState([])

  console.log(errors, watch())

  const { state, dispatch } = useContext(StateContext)
  const [tabsTouched, setTabsTouched] = useState([0])

  // watch()

  return (
    <>
      <div //style={{ marginTop: 20 }}
      >
        <form
          // state.locformsubmit((v)=>{console.log(v)})
          onSubmit={handleSubmit(
            state.locformsubmit !== null
              ? state.locformsubmit(onSubmit)
              : onSubmit
          )}
        >
          {tabs.length ? (
            <>
              <Tab.Group
                selectedIndex={selectedIndex}
                defaultIndex={0}
                onChange={v => {
                  setSelectedIndex(v)
                  if (!tabsTouched.includes(v))
                    setTabsTouched([...tabsTouched, v])
                }}
              >
                <Tab.List className="hidden md:flex -mb-px space-x-8 border-b border-[#EBEBEB]">
                  {tabs.map((item, k) => (
                    <Tab
                      key={k}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-[#222] text-[#222]"
                            : "border-transparent text-[#222] hover:text-gray-500",
                          "whitespace-nowrap  pb-[28px] px-1 border-b-2 text-med-16"
                        )
                      }
                    >
                      {Object.keys(errors) &&
                      item.fields.some(item =>
                        Object.keys(errors).includes(item)
                      ) ? (
                        <span className="text-red-600">{item.name}*</span>
                      ) : (
                        item.name
                      )}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="pt-[30px]">
                  {tabs.map((item, k) => {
                    if (
                      item.name === "Media" &&
                      fromManageFileTab &&
                      name == "asset"
                    ) {
                      return (
                        <Tab.Panel key={k}>
                          {/* <RadioGroupAsset onChange={setRadioValue} /> */}
                        </Tab.Panel>
                      )
                    } else if (
                      item.name === "Variations" &&
                      // fromManageFileTab &&
                      name == "asset"
                    ) {
                      return (
                        <Tab.Panel key={k}>
                          <FieldAssets
                            data={data["field_assets"]}
                            currentFormData={data}
                            token={token}
                            errors={errors}
                            key={"field_assets"}
                            name={"field_assets"}
                            register={register}
                            // label={label}
                            setValue={setValue}
                            watch={watch}
                            fieldAssets={fieldAssets}
                            setFieldAssets={setFieldAssets}
                          />
                        </Tab.Panel>
                      )
                    } else if (item.name == "General" && name == "asset") {
                      return (
                        <Tab.Panel key={k}>
                          {renderFields(
                            null,
                            null,
                            tabsTouched,
                            data,
                            errors,
                            register,
                            setValue,
                            watch,
                            null,
                            unregister,
                            null,
                            token,
                            control,
                            location,
                            fromManageFileTab,
                            name,
                            bundle,
                            true,
                            item.fields,
                            null,
                            null
                          )}

                          {!nested && (
                            <div
                              className={
                                "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer} border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                              }
                            >
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                                  onChange={async e => {
                                    // console.log(e.target.checked)
                                    if (e.target.checked) {
                                      setBundle("asset_group")
                                      setTabs([
                                        {
                                          name: "General",
                                          fields: [
                                            "title",
                                            "field_description",
                                            // "field_asset_company",
                                            "field_asset_brand",
                                          ],
                                        },
                                        {
                                          name: "Variations",
                                          fields: ["field_assets"],
                                        },
                                      ])
                                      let formData = await getMySingleForm(
                                        token,
                                        "asset",
                                        "asset_group"
                                      )
                                      setData(formData)
                                      unregister("field_asset_category")
                                      unregister("field_asset_tags")
                                    } else {
                                      setBundle("default")
                                      setData(propsData)
                                      setTabs([
                                        {
                                          name: "General",
                                          fields: [
                                            "title",
                                            "field_description",
                                            // "field_asset_company",
                                            "field_asset_brand",
                                          ],
                                        },
                                        {
                                          name: "Categorization",
                                          fields: [
                                            "field_asset_category",
                                            "field_asset_tags",
                                            "field_asset_type",
                                          ],
                                        },
                                        {
                                          name: "Media",
                                          fields: [
                                            "field_asset_files",
                                            "field_asset_cover_image",
                                          ],
                                        },
                                        {
                                          name: "Restrictions",
                                          fields: [
                                            "field_asset_by_request",
                                            "field_user_groups",
                                            "field_active",
                                          ],
                                        },
                                      ])
                                    }
                                    // setData
                                  }}
                                  defaultChecked={bundle === "asset_group"}
                                  disabled={edit}
                                  name="toggle_variations"
                                />
                                <label
                                  htmlFor="toggle_variations"
                                  className="ml-3 text-[#222] flex flex-col"
                                >
                                  This asset has multiple variations
                                </label>
                              </div>
                              <div
                                css={css`
                                  font-family: "Jost";
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 12px;
                                  line-height: 16px;
                                  display: inline-block;
                                  padding: 0 10px;
                                  margin: 0 0 0 18px;
                                  letter-spacing: 0.02em;
                                  color: #717171;
                                `}
                              >
                                Is this asset comprised of multiple variants,
                                such as size or color?
                              </div>
                            </div>
                          )}
                        </Tab.Panel>
                      )
                    } else {
                      return (
                        <Tab.Panel key={k}>
                          {renderFields(
                            setRestrictCheck,
                            restrictCheck,
                            tabsTouched,
                            data,
                            errors,
                            register,
                            setValue,
                            watch,
                            null,
                            unregister,
                            null,
                            token,
                            control,
                            location,
                            fromManageFileTab,
                            name,
                            bundle,
                            true,
                            item.fields,
                            null,
                            null,
                            null
                          )}
                        </Tab.Panel>
                      )
                    }
                  })}
                  {Object.keys(errors).length > 0 && (
                    <span
                      css={css`
                        color: red;
                      `}
                    >
                      Please fill all the required fields
                    </span>
                  )}
                  <div style={{ display: "none" }}>
                    {tabs.map((item, k) => {
                      console.log({ item, k, selectedIndex, tabsTouched })
                      if (selectedIndex == k) return null
                      if (tabsTouched.includes(k)) return null
                      if (
                        item.name === "Media" &&
                        fromManageFileTab &&
                        name == "asset"
                      ) {
                        return (
                          <Tab.Panel key={k}>
                            {/* <RadioGroupAsset onChange={setRadioValue} /> */}
                          </Tab.Panel>
                        )
                      } else if (
                        item.name === "Variations" &&
                        // fromManageFileTab &&
                        name == "asset"
                      ) {
                        return (
                          <>
                            <FieldAssets
                              data={data["field_assets"]}
                              currentFormData={data}
                              token={token}
                              errors={errors}
                              key={"field_assets"}
                              name={"field_assets"}
                              register={register}
                              // label={label}
                              setValue={setValue}
                              watch={watch}
                              fieldAssets={fieldAssets}
                              setFieldAssets={setFieldAssets}
                            />
                          </>
                        )
                      } else if (item.name == "General" && name == "asset") {
                        return (
                          <>
                            {renderFields(
                              null,
                              null,
                              tabsTouched,
                              data,
                              errors,
                              register,
                              setValue,
                              watch,
                              null,
                              unregister,
                              null,
                              token,
                              control,
                              location,
                              fromManageFileTab,
                              name,
                              bundle,
                              true,
                              item.fields,
                              null,
                              null
                            )}

                            {!nested && (
                              <div
                                className={
                                  "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer} border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                                }
                              >
                                <div className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                                    onChange={async e => {
                                      console.log(e.target.checked)
                                      if (e.target.checked) {
                                        setBundle("asset_group")
                                        setTabs([
                                          {
                                            name: "General",
                                            fields: [
                                              "title",
                                              "field_description",
                                              // "field_asset_company",
                                              "field_asset_brand",
                                            ],
                                          },
                                          {
                                            name: "Variations",
                                            fields: ["field_assets"],
                                          },
                                        ])
                                        let formData = await getMySingleForm(
                                          token,
                                          "asset",
                                          "asset_group"
                                        )
                                        setData(formData)
                                      } else {
                                        setBundle("default")
                                        setData(propsData)
                                        setTabs([
                                          {
                                            name: "General",
                                            fields: [
                                              "title",
                                              "field_description",
                                              // "field_asset_company",
                                              "field_asset_brand",
                                            ],
                                          },
                                          {
                                            name: "Categorization",
                                            fields: [
                                              "field_asset_category",
                                              "field_asset_tags",
                                              "field_asset_type",
                                            ],
                                          },
                                          {
                                            name: "Media",
                                            fields: [
                                              "field_asset_files",
                                              "field_asset_cover_image",
                                            ],
                                          },
                                          {
                                            name: "Restrictions",
                                            fields: [
                                              "field_asset_by_request",
                                              "field_user_groups",
                                              "field_active",
                                            ],
                                          },
                                        ])
                                      }
                                      // setData
                                    }}
                                    defaultChecked={bundle === "asset_group"}
                                    disabled={edit}
                                    name="toggle_variations"
                                  />
                                  <label
                                    htmlFor="toggle_variations"
                                    className="ml-3 text-[#222] flex flex-col"
                                  >
                                    This asset has multiple variations
                                  </label>
                                </div>
                                <div
                                  css={css`
                                    font-family: "Jost";
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    display: inline-block;
                                    padding: 0 10px;
                                    margin: 0 0 0 18px;
                                    letter-spacing: 0.02em;
                                    color: #717171;
                                  `}
                                >
                                  Is this asset comprised of multiple variants,
                                  such as size or color?
                                </div>
                              </div>
                            )}
                          </>
                        )
                      } else {
                        return (
                          <>
                            {renderFields(
                              null,
                              null,
                              tabsTouched,
                              data,
                              errors,
                              register,
                              setValue,
                              watch,
                              null,
                              unregister,
                              null,
                              token,
                              control,
                              location,
                              fromManageFileTab,
                              name,
                              bundle,
                              true,
                              item.fields,
                              null,
                              null
                            )}
                          </>
                        )
                      }
                    })}
                  </div>
                  {/*  <Tab.Panel key={3}>
            {posts}
            {props.assets?.map((event, eventIdx) => (
              <AlbumCard event={event} k={eventIdx} key={eventIdx} />
            ))}
          </Tab.Panel>*/}
                </Tab.Panels>
              </Tab.Group>
            </>
          ) : (
            <>
              {Object.keys(data).length ? (
                renderFields(
                  null,
                  null,
                  tabsTouched,
                  data,
                  errors,
                  register,
                  setValue,
                  watch,
                  null,
                  unregister,
                  null,
                  token,
                  control,
                  location,
                  fromManageFileTab,
                  name,
                  bundle,
                  false,
                  [],
                  edit,
                  dynamFields,
                  getValues,
                  suppData,
                  name,
                  onExtraClick,
                  savedStoreId
                )
              ) : (
                <h4>No fields available</h4>
              )}
            </>
          )}

          {!setUserAddressFormData && (
            <div class="flex justify-between">
              <Button
                type="submit"
                onClick={
                  // state.locformsubmit((v)=>{console.log(v)})
                  handleSubmit(
                    state.locformsubmit !== null
                      ? state.locformsubmit(onSubmit)
                      : onSubmit
                  )
                }
              >
                Save
              </Button>

              {typeof onDelete !== "undefined" && (
                <>
                  {!addNew && delDisable.includes(name) ? (
                    <Button disabled>Delete</Button>
                  ) : (
                    !addNew && (
                      <Button red outline type="button" onClick={handleDelete}>
                        Delete
                      </Button>
                    )
                  )}
                </>
              )}
            </div>
          )}
        </form>
      </div>
      {saving === true && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden flex flex-col items-center justify-center bg-[rgba(0,0,0,.6)]">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
        </div>
      )}
    </>
  )
}

export default DynamicForm
