/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { green } from "../Styles"
function MetaStandard(props) {
  return (
    <span
      className={`flex items-center justify-center brand-single-card-meta ${
        props.className ? props.className : ``
      }`}
    >
      <span className="h-5 w-5 mr-2">{props.icon}</span> {props.text}
    </span>
  )
}

export default MetaStandard
