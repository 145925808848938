/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Max from "../../assets/icons/max.svg"

function IconAddCircle(props) {
  return (
    <div
      className={`rounded-full bg-[#F7F7F7] flex items-center justify-center ${
        props.className ? props.className : ``
      }`}
      // css={{css``}}
    >
      <Max className="h-3 w-3" />
    </div>
  )
}

export default IconAddCircle
