/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState } from "react"
import { red, redLight } from "../Styles"
function Button(props) {
  const { type, onClick, widthAuto, disabled, outline, loading } = props

  let width = `140px`
  if (props.full) width = `100%`
  if (props.widthAuto) width = `auto`

  const [load, setLoad] = useState(loading)

  // width: ${width}; padding: ${widthAuto ? `0 25px` : `0`};

  if (loading) {
    return (
      <>
        <button
          type={type}
          className={`${props.className ? props.className : ""} group`}
          css={css`
            &&& {
              border: 1px solid #e43d30;
              border-radius: 8px;
              font-family: "KansasNew";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.03em;
              color: ${outline ? `#e43d30` : `#fff`};
              width: ${width};
              padding: ${widthAuto ? `0 25px` : `0`};
              height: ${props.height ? props.height : "50px"};
              transition: 0.2s ease all;
              background-color: ${outline ? `transparent` : `#e43d30`};
              &:hover {
                background-color: ${outline ? `#e43d30` : `transparent`};
                color: ${outline ? `#fff` : `#e43d30`};
              }
            }
          `}
        >
          <svg
            className={`animate-spin mx-auto h-5 w-5 text-white group-hover:!text-[#e43d30]`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
      </>
    )
  }
  if (type && typeof onClick === "undefined") {
    return (
      <>
        <button
          type={type}
          className={props.className ? props.className : ""}
          css={css`
            &&& {
              border: 1px solid #e43d30;
              border-radius: 8px;
              font-family: "KansasNew";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.03em;
              color: ${outline ? `#e43d30` : `#fff`};
              width: ${width};
              padding: ${widthAuto ? `0 25px` : `0`};
              height: ${props.height ? props.height : "50px"};
              transition: 0.2s ease all;
              background-color: ${outline ? `transparent` : `#e43d30`};
              &:hover {
                background-color: ${outline ? `#e43d30` : `transparent`};
                color: ${outline ? `#fff` : `#e43d30`};
              }
            }
          `}
        >
          {props.children}
        </button>
      </>
    )
  }
  if (type) {
    // let color = '#fff';
    // if(outline) color = '#e43d30';
    // let colorHover = '';
    // if() colorHover = '';
    // let background = '#e43d30';
    // if(outline) background = 'transparent';
    // do this later, it'll be needed at some point...

    return (
      <>
        <button
          type={type}
          className={props.className ? props.className : ""}
          css={css`
            &&& {
              border: 1px solid #e43d30;
              border-radius: 8px;
              font-family: "KansasNew";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.03em;
              color: ${outline ? `#e43d30` : `#fff`};
              width: ${width};
              padding: ${widthAuto ? `0 25px` : `0`};
              height: ${props.height ? props.height : "50px"};
              transition: 0.2s ease all;
              background-color: ${outline ? `transparent` : `#e43d30`};
              &:hover {
                background-color: ${outline ? `#e43d30` : `transparent`};
                color: ${outline ? `#fff` : `#e43d30`};
              }
            }
          `}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      </>
    )
  }

  if (props.disabled) {
    return (
      <div
        // type={type}
        className={`${
          props.className ? props.className : ""
        } flex items-center justify-center`}
        css={css`
          border: 1px solid #ebebeb;
          border-radius: 8px;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: #fff;
          background-color: #ebebeb;
          width: ${width};
          padding: ${widthAuto ? `0 25px` : `0`};
          height: 50px;
          transition: 0.2s ease all;
          cursor: not-allowed;
          pointer-events: all !important;
          &:hover {
            background-color: #fff;
            color: #ebebeb;
          }
        `}
      >
        {props.children}
      </div>
    )
  }

  if (props.pink) {
    return (
      <button
        onClick={props.onClick}
        role="none"
        type="button"
        className={props.className ? props.className : ""}
        css={css`
          &&& {
            border: 1px solid ${redLight};
            border-radius: 8px;
            font-family: "KansasNew";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
            color: ${outline ? `#e43d30` : red};
            width: ${width};
            padding: ${widthAuto ? `0 25px` : `0`};

            height: 50px;
            transition: 0.2s ease all;
            background-color: ${outline ? `transparent` : redLight};
            &:hover {
              background-color: ${outline ? `#e43d30` : red};
              color: ${outline ? `#fff` : `#fff`};
              border-color: ${red};
            }
          }
        `}
      >
        {props.children}
      </button>
    )
  }
  if (props.blue) {
    return (
      <button
        onClick={props.onClick}
        role="none"
        type="button"
        className={props.className ? props.className : ""}
        css={css`
          &&& {
            border: 1px solid ${"#cad9f0"};
            border-radius: 8px;
            font-family: "KansasNew";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
            color: ${outline ? `#e43d30` : "#527dbf"};
            width: ${width};
            padding: ${widthAuto ? `0 25px` : `0`};

            height: 50px;
            transition: 0.2s ease all;
            background-color: ${outline ? `transparent` : "#cad9f0"};
            &:hover {
              background-color: ${outline ? `#e43d30` : "#527dbf"};
              color: ${outline ? `#fff` : `#fff`};
              border-color: ${"#cad9f0"};
            }
          }
        `}
      >
        {props.children}
      </button>
    )
  }
  if (props.black) {
    return (
      <button
        type={type}
        className={props.className ? props.className : ""}
        css={css`
          border: 1px solid #222;
          border-radius: 8px;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: ${outline ? `#222` : `#fff`};
          height: 50px;
          transition: 0.2s ease all;
          background-color: ${outline ? `transparent` : `#222`};
          width: ${width};
          padding: ${widthAuto ? `0 25px` : `0`};
          &:hover {
            background-color: ${outline ? `#222` : `transparent`};
            color: ${outline ? `#fff` : `#222`};
          }
        `}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }
  if (props.red) {
    return (
      <button
        type={type}
        className={props.className ? props.className : ""}
        css={css`
          border: 1px solid #e43d30;
          border-radius: 8px;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: ${outline ? `#e43d30` : `#fff`};
          width: ${width};
          padding: ${widthAuto ? `0 25px` : `0`};
          height: 50px;
          transition: 0.2s ease all;
          background-color: ${outline ? `transparent` : `#e43d30`};
          &:hover {
            background-color: ${outline
              ? `#e43d30`
              : props.hoverWhite
              ? `#fff`
              : `transparent`};
            color: ${outline ? `#fff` : `#e43d30`};
            border-color: ${props.hoverWhite ? `#fff` : `#e43d30`};
          }
        `}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }
  if (props.bordered) {
    return (
      <button
        type={type}
        className={props.className ? props.className : ""}
        css={css`
          border: 1px solid #e43d30;
          border-radius: 8px;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: ${!outline ? `#e43d30` : `#fff`};
          width: ${width};
          padding: ${widthAuto ? `0 25px` : `0`};
          height: 50px;
          transition: 0.2s ease all;
          background-color: ${!outline ? `transparent` : `#e43d30`};
          &:hover {
            background-color: ${!outline
              ? `#e43d30`
              : props.hoverWhite
              ? `#fff`
              : `transparent`};
            color: ${!outline ? `#fff` : `#e43d30`};
            border-color: ${props.hoverWhite ? `#fff` : `#e43d30`};
          }
        `}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }
  if (props.transparent) {
    return (
      <button
        type={type}
        className={props.className ? props.className : ""}
        css={css`
          border-radius: 8px;
          font-family: "KansasNew";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: #222;
          width: ${width};
          padding: ${widthAuto ? `0 25px` : `0`};
          height: 50px;
          transition: 0.2s ease all;
          border: 1px solid transparent;
          &:hover {
            color: #e43d30;
            border-color: #e43d30;
          }
        `}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }
  return (
    <button
      type={type}
      className={props.className ? props.className : ""}
      css={css`
        border: 1px solid #e43d30;
        border-radius: 8px;
        font-family: "KansasNew";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: #e43d30;
        width: ${width};
        padding: ${widthAuto ? `0 25px` : `0`};
        height: 50px;
        transition: 0.2s ease all;
        &:hover {
          background-color: #e43d30;
          color: #fff;
        }
      `}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export default Button
