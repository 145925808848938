import React, { useContext } from "react"
import Footer from "../../Commons/Footer/Footer"
import Header from "../../Commons/Header/Header"
import Helmet from "react-helmet"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
const pageTitleTemplate = "%s"

function LayoutPage({
  noFooter,
  floating,
  metaTitle,
  metaDesc,
  meta,
  children,
  thin,
}) {
  let pageTitle = "O2O"
  let pageDescription = ""

  // Check for title and description next...
  if (typeof metaTitle !== "undefined" && metaTitle !== null) {
    pageTitle = metaTitle + " | O2O"
  }
  if (typeof metaDesc !== "undefined" && metaDesc !== null) {
    pageDescription = metaDesc
  }

  // Finally, if a whole object was passed, use that instead...
  if (typeof meta !== "undefined") {
    if (typeof meta.title !== "undefined" && meta.title !== null) {
      pageTitle = meta.title
    }
    if (typeof meta.description !== "undefined" && meta.description !== null) {
      pageDescription = meta.description
    }
  }

  const { userType } = useContext(AuthenticationContext)
  // console.log(pageTitle)
  return (
    <div className={`${userType.brandManager === true ? `md:mt-[130px]` : `md:mt-[90px]`}`}>
      <Helmet title={pageTitle} titleTemplate={pageTitleTemplate}>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Header floating={floating} />
      <div className={`${thin && `lg:max-w-[848px] mx-auto`}`}>{children}</div>
      {!noFooter && <Footer />}
    </div>
  )
}

export default LayoutPage
