/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Icon from "../../../assets/icons/close-alt.svg"
function SlideoutHeader(props) {
  const { title, onClose, fromPage, toggleSidebar } = props
  return (
    <div className="">
      <div className="border-b border-[#EBEBEB] mb-10 pb-[10px]">
        <div className="flex items-start justify-between items-center px-[30px]">
          <Dialog.Title className="text-strong-20">{title}</Dialog.Title>
          {fromPage && (
            <button
              type="button"
              className="hidden md:flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
              onClick={toggleSidebar}
            >
              <span className="sr-only">Sidebar On</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          <button
            type="button"
            className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <Icon className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SlideoutHeader
