/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState, useContext } from "react"
import SocialButton from "../../Elements/SocialButton"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { isSSR } from "../../../helpers/RendererHelper"
import OnSiteMarketingContext from "../../../context/OnSiteMarketing/OnSiteMarketingContext"
import AdRegion from "./AdRegion"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
const navigation = [

  {
    title: "Explore O2O",
    items: [
      { name: "About", href: "https://useo2o.com/" },
      { name: "Contact", href: "https://useo2o.com/ca/contact" },
      { name: "Blog", href: "https://useo2o.com/ca/blog" },
    ],
  },
]
const legal = [
  { name: "Cookies", href: "https://useo2o.com/ca/cookies" },
  { name: "Privacy", href: "https://useo2o.com/ca/privacy" },
  { name: "Terms of Use", href: "https://useo2o.com/ca/terms" },
]
const social = [
  {
    name: "Instagram",
    href: "https://instagram.com/useo2o",
    icon: faInstagram,
  },
  {
    name: "LinkedIn",
    href: "https://ca.linkedin.com/company/useo2o/",
    icon: faLinkedin,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/useo2o",
    icon: faTwitter,
  },
]

const Footer = (props) => {
  const { container } = props

  const { pageFooterAd } = useContext(OnSiteMarketingContext)
  const [ad, setAd] = useState(null)
  const { userType } = useContext(AuthenticationContext)
  useEffect(async () => {
    if (!userType.brandManager && !userType.brandEditor) {
      const getAd = await pageFooterAd()
      setAd(getAd)
    }
  }, [])

  return (
    <footer className="bg-[#222]" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className={`container mx-auto px-4 pt-[40px] pb-[40px] md:py-12 lg:py-16 ${container && `max-w-7xl !md:px-8`}`}>
        <div className="lg:grid lg:grid-cols-4">
          {navigation.map((item, k) => {
            return (
              <div className="mb-[40px] md:mb-0">
                <h3
                  className="text-med-18"
                  css={css`
                    &&& {
                      font-weight: 500;
                      color: #fff;
                    }
                  `}
                >
                  {item.title}
                </h3>
                <ul role="list" className="mt-2 md:mt-4 space-y-1">
                  {item.items.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        className="text-reg-14"
                        css={css`
                          &&& {
                            color: #fff;
                          }
                        `}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
          <div className="mb-[40px] md:mb-0">
            <h3
              className="text-med-18"
              css={css`
                &&& {
                  font-weight: 500;
                  color: #fff;
                }
              `}
            >
              Connect with us
            </h3>

            <p
              className="my-4 text-reg-14"
              css={css`
                &&& {
                  color: #fff;
                }
              `}
            >
              Keep in touch with us across the web.
            </p>
            <div className="mt-[30px] md:mt-0 flex space-x-4 flex-wrap">
              {social.map(item => (
                <a target="_blank" href={item.href}>
                  <SocialButton icon={item.icon} lg transparent />
                </a>
              ))}
            </div>
          </div>
          <div></div>
          <div className="lg:ml-auto">
            {!userType.brandManager && !userType.brandEditor && !isSSR && ad !== null && <AdRegion ad={ad} />}
          </div>
        </div>
      </div>

      <div className="pb-24 md:pb-6 md:mt-6 py-6 border-t border-[rgba(255,255,255,.1)] lg:mt-0">
        <div className={`container mx-auto px-4 ${container && `max-w-7xl !md:px-8`}`}>
          <div className="flex flex-col md:flex-row">
            <p className="text-reg-14 text-[rgba(255,255,255,.7)]">
              &copy; 2023 O2O. All rights reserved.
            </p>
            <div className="md:ml-auto">
              <ul role="list" className=" space-x-8 flex">
                {legal.map(item => (
                  <li key={item.name} className="">
                    <a
                      href={item.href}
                      target="_blank"
                      className="text-reg-14 text-[rgba(255,255,255,.7)] hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
