/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect } from "react"
import { classNames } from "../../../../utils"

function BooleanType({
  name,
  data,
  label,
  setValue,
  error,
  register,
  description,
  watch,
  fromAsset,
}) {
  const { field_active } = watch()
  let defaultChecked = data?.current_value
    ? data?.current_value === "0"
      ? false
      : true
    : data?.defaultValue === "0" || data?.default_value === 0
    ? false
    : true
  let assetDefaultChecked = field_active === undefined ? true : field_active
  useEffect(() => {
    if (setValue) {
      setValue(name, defaultChecked)
    }
  }, [])
  return (
    <div
      className={classNames(
        "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}",
        error
          ? "border-red-600 focus:border-red-600 focus:text-red-600 "
          : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
      )}
    >
      <div className="flex">
        <div>
          <div className="flex items-center">
            <input
              id={`${name}`}
              // defaultValue={
              //   data?.current_value ? data?.current_value : data?.defaultValue
              // }
              type="checkbox"
              // onChange={e => {
              //   console.log(e.target.checked)
              //   // if (e.target.checked) {
              //   //   setData({
              //   //     ...data,
              //   //     marketing: 1,
              //   //   })
              //   // } else {
              //   //   setData({
              //   //     ...data,
              //   //     marketing: 0,
              //   //   })
              //   // }
              //   // setData
              // }}
              // defaultValue={defaultChecked}
              defaultChecked={fromAsset ? assetDefaultChecked : defaultChecked}
              className="h-4 w-4 border-[#EBEBEB] rounded rounded-[4px] text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
              {...register(`${name}`)}
            />
            <label
              htmlFor={`${name}`}
              className="ml-3 text-[#222] flex flex-col"
            >
              {label}
            </label>
          </div>
          {(data?.description || description) && (
            <div
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: inline-block;
                padding: 0 10px;
                margin: 0 0 0 18px;
                letter-spacing: 0.02em;
                color: #717171;
              `}
              dangerouslySetInnerHTML={{
                __html: data?.description || description,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BooleanType
