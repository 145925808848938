import React from "react"
import { isSSR } from "./RendererHelper"

export const scriptGoogleTagManager = isSSR === false && window.location.href.includes("useo2o.com") ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5WCL9PR');`
  : null;

// export const scriptStonlyFirst = isSSR === false && window.location.href.includes("useo2o.com") ? `window.STONLY_WID = "404846b4-fae0-11ec-9fb8-0ae9fa2a18a2";` : null;

// export const scriptStonlySecond = isSSR === false && window.location.href.includes("useo2o.com") ? `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
//   w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
//   (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
//   4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
//   (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
// }(window,document,"script","https://stonly.com/js/widget/v2/");`
//     : null;

// export const scriptStonlyIdentify = userId => {
//   return `stonlyTrack('identify', '${userId}');`
// }
// export const scriptStonlyIdentify = (userId, onboardingCompleted) => {
//   return `stonlyTrack('identify', '${userId}', {
//     'user-id': ${userId},
//     'created-at': ${onboardingCompleted}
//   });`
// }

// export const scriptStonlyEventRegistrationSuccessful = () => {
//   return `stonlyTrack('track', 'registration-successful');`
// }
