/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import { Helmet } from "react-helmet"
import DataContext from "../../../context/Data/DataContext"
import FilterBar from "./FilterBar"
import HTMLReactParser from "html-react-parser"
import postscribe from "postscribe"
import { isSSR } from "../../../helpers/RendererHelper"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"

function AdRegion(props) {
  const { userType } = useContext(AuthenticationContext)
  const { adArray } = props

  const [rand, setRand] = useState(Math.random(100000, 999999))
  useEffect(() => {
    if (
      !userType.brandManager &&
      !userType.brandEditor &&
      props.adArray.length &&
      !isSSR
    ) {
      props.adArray.map((item, k) => {
        if (item.code_snippet !== null && item.code_snippet !== "") {
          // const script = document.createElement("script")
          postscribe(
            `#-o2o__adspco-assetListing-P-${k}-${rand}`,
            `${item.code_snippet}`
          )
        }
      })
    }
  }, [adArray])

  if (
    !userType.brandManager &&
    !userType.brandEditor &&
    Array.isArray(adArray) &&
    adArray.length
  ) {
    return (
      <>
        {adArray.map((item, k) => {
          return (
            <div className="border border-[#3c4fe0] overflow-hidden rounded-[8px] mb-4 min-h-[350px] max-h-[446px] w-full">
              <div id={`-o2o__adspco-assetListing-P-${k}-${rand}`}></div>
            </div>
          )
        })}
      </>
    )
  }
  return null
}

export default AdRegion
