/** @jsx jsx */
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FloatingInput from "../Elements/Form/FloatingInput"
import Button from "../Elements/Button"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import SectionCard from "../Cards/Dashboard/SectionCard"
import {
  addUserStore,
  getStoreSingle,
  submitWebform,
  updateStoreSingle,
  requestAsset,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import Textarea from "../Elements/Textarea"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import NotificationContext from "../../context/Notification/NotificationContext"
import DataContext from "../../context/Data/DataContext"
import {
  validateEmail,
  validateUrl,
  validatePhone,
} from "../../utils/messageValidation"
import AuthenticationContext from "../../context/authentication/AuthenticationContext";
import { css, jsx } from "@emotion/react";

const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
function RequestAssetForm({ data, onSave, id }) {
  const { state } = useContext(StateContext)
  const { authentication: { currentUserData } } = useContext(AuthenticationContext)
  const { token } = state
  // const { retailStoresAsOptions } = useContext(DataContext).data

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()
  const { toggleNotification } = useContext(NotificationContext)
  console.log("watch", watch())
  const onSubmit = async data => {
    const body = {
      ...data,
      asset: id,
    }
    requestAsset(token, body).then(() => { })
    /*const runF = async () => {
      let body = {
        ...data,
        brand: `${id}`,
        webform_id: "request_asset",
      }
      console.log(body)
      let res = await submitWebform(token, body)
      if (res.status === 200) {
        toggleNotification({ content: `Successfully submitted visit request` })
        onSave(false)
      } else {
        toggleNotification({
          content: `Failed to submit visit request`,
          error: true,
        })
        onSave(false)
      }
    }
    if (data.comments) {
      let myString = data.comments
      let strArray = myString.split(" ")
      let found = strArray.some(
        str => validatePhone(str) || validateEmail(str) || validateUrl(str)
      )
      console.log(found)
      if (found) {
        toggleNotification({
          content: `This Message can't be sent! There's violation`,
          error: true,
        })
      } else {
        await runF()
      }
    } else {
      await runF()
    }*/
  }
  useEffect(() => {
    register("company", {
      required: `This field is required`,
    })
  }, [])

  // if(!data) return "Loading"
  return (
    <>
      {/* <SectionCard
      title={edit ? "Edit Store" : "Create New Store"}
      id="create"> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative z-0 mb-6 w-full group">
          <a
            href={`/asset/${id}`}
            target="_blank"
            className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none
             focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer mb-6"
          >
            {/* {title} */}
          </a>
          <div
            className="absolute scale-75 top-[15px] left-[12px] z-10 bg-white
          px-[8px] scale-100 bg-white scale-75
          -translate-y-7 bg-white bg-white z-10"
            css={css`
             ${fontStyles}
          `}
          >
            Asset name
          </div>
        </div>
        <FloatingSelect
          label={"Select company"}
          name="company"
          hookForm
          error={errors["company"]}
          type={"currentUserCompaniesAsOp"}
          control={control}
        />
        <FloatingInput
          label="Job title"
          error={errors["job_name"]}
          name="job_title"
          hookForm
          register={register}
          required
        />
        <div className="grid xl:grid-cols-2 xl:gap-6">
          <FloatingInput
            label="First name"
            error={errors["first_name"]}
            name="first_name"
            defaultValue={currentUserData.firstname}
            hookForm
            register={register}
            required
          />
          <FloatingInput
            label="Last name"
            error={errors["last_name"]}
            name="last_name"
            defaultValue={currentUserData.lastname}
            hookForm
            register={register}
            required
          />
        </div>
        <div
          className="relative z-0 mb-6 w-full group"
        >
          <input
            type="number"
            name="quantity"
            max="999"
            min="0"
            className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none
           focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
            placeholder=" "
            defaultValue={1}
            {...register("quantity", {
              required: true,
              max: {
                value: 999,
                message: "Quantity must be less than 1000"
              },
              min: {
                value: 1,
                message: "Quantity must be at least 1"
              },
            })}
          />
          <label
            htmlFor="quantity"
            className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0]
          px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10
          peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75
          peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
            css={css`
             ${fontStyles}
          `}
          >
            Quantity
          </label>
        </div>
        {/*  <FloatingSelect
        label={"Time Of Day"}
        name="time"
        hookForm
        error={errors["time"]}
        options={[
          { value: "morning", label: "Morning" },
          { value: "afternoon", label: "Afternoon" },
          { value: "evening", label: "Evening" },
          { value: "night", label: "Night" },
        ]}
        control={control}
      />
      <p className="text-sm text left">What time of day is best to visit?</p>
      <FloatingCheckbox
        options={[
          { value: 1, label: "Monday" },
          { value: 2, label: "Tuesday" },
          { value: 3, label: "Wednesday" },
          { value: 4, label: "Thursday" },
          { value: 5, label: "Friday" },
          { value: 6, label: "Saturday" },
          { value: 7, label: "Sunday" },
        ]}
        title={"Days"}
        name="days"
        control={control}
        register={register}
      />
      
      <p className="text-sm text left">The main reason for visit.</p>
      <div className="grid xl:grid-cols-2 xl:gap-6">
        <FloatingInput
          label="First name"
          error={errors["first_name"]}
          name="first_name"
          hookForm
          register={register}
        />
        <FloatingInput
          label="Last name"
          error={errors["last_name"]}
          name="last_name"
          hookForm
          register={register}
        />
      </div> 
      <div className="grid xl:grid-cols-2 xl:gap-6">
        <FloatingInput
          label="Email"
          error={errors["email"]}
          name="email"
          hookForm
          register={register}
        />
        <FloatingInput
          label="Phone"
          error={errors["phone"]}
          name="phone"
          type={"number"}
          hookForm
          register={register}
        />
      </div>
       <div className="grid xl:grid-cols-2 xl:gap-6"> 
      <FloatingInput
        label="Title"
        name="title"
        hookForm
        register={register}
        error={errors["title"]}
        required
      />
       <FloatingInput
        label="Company Name"
        hookForm
        name="company_name"
        error={errors["company_name"]}
        register={register}
        required
      /> 
      <FloatingInput
        label="Store Address"
        name="store_address"
        hookForm
        register={register}
        error={errors["store_address"]}
        required
      />
      <FloatingInput
        label="Store Address Line 2"
        name="store_address2"
        hookForm
        register={register}
        error={errors["store_address2"]}
        required
      />
      <FloatingSelect
        label={"City"}
        name="city"
        hookForm
        error={errors["city"]}
        control={control}
        options={regionsAsOptions}
      />

      <FloatingSelect
        label={"Province"}
        name="province"
        hookForm
        error={errors["province"]}
        control={control}
        options={regionsAsOptions}
      />
      <FloatingInput
        label="Postal Code"
        name="postal_code"
        hookForm
        register={register}
        error={errors["postal_code"]}
        required
      />
       </div> */}
        <FloatingTextarea
          label="Comment"
          error={errors["comment"]}
          name="comment"
          hookForm
          register={register}
        />
        <Button red type="submit" full>
          Submit Request
        </Button>
      </form>
      {/* </SectionCard> */}
    </>
  )
}

export default RequestAssetForm
