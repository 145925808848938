/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import NavigateIcon from "../../../../assets/icons/navigate.svg"
import Cart from "../../../../assets/icons/cart.svg"
import Bell from "../../../../assets/icons/bell.svg"
import CircleAvatar from "../../../Elements/CircleAvatar"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

import { red } from "../../../Styles"
import { styles, menuItemsClasses } from "./utils"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import ShopIcon from "../../../../assets/icons/shop.svg"

import IconButton from "./IconButton"
import LazyImage from "../../../LazyImage"
import Skeleton from "../../../Skeleton"
import MenuTransition from "./MenuTransition"
import MobileMenuHead from "../MobileMenuHead"
import MenuBackdrop from "./MenuBackdrop"
import { Scrollbar } from "react-scrollbars-custom"
import {
  ChatAlt2Icon,
  // MenuAlt2Icon,
  // PhotographIcon,
  // PuzzleIcon,
  // UsersIcon,
  // XIcon,
  // UserGroupIcon,
  // ShoppingBagIcon,
  ChevronRightIcon,
  ClockIcon,
  // OfficeBuildingIcon,
  // LogoutIcon,
} from "@heroicons/react/outline"
import { Table, Tr, Td } from "../../../Table/TableElements"
import DataContext from "../../../../context/Data/DataContext"
import MenuSection from "../MenuSection"
// const MenuSection = props => {
//   return (
//     <div
//       // to={props.link}
//       className={`border-b border-[#EBEBEB] pb-4 mb-6 ${
//         props.className && props.className
//       }`}
//     >
//       <MenuHead title={props.title} link={props.link} />
//       {props.children}
//     </div>
//   )
// }
const MenuHead = props => {
  const { title, link } = props
  return (
    <div className="px-0 lg:px-4 pb-2 w-full">
      <span
        className=""
        css={css`
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 0.02rem;
        `}
      >
        <Link to={link}>{title}</Link>
      </span>
    </div>
  )
}
export default function Messages({ floating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const { userType } = useContext(AuthenticationContext)
  const { authentication } = useContext(AuthenticationContext)
  const user_id = authentication.currentUserData.i
  const { companies, brands, retailers } = authentication.currentUserData
  const { messages } = useContext(DataContext).data
  const [jointMessages, setJointMessages] = useState(null)
  // console.log({ messages })
  // const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (messages && Array.isArray(messages.sentMessages)) {
      let combinedMessages = []

      messages.sentMessages.map(v => {
        combinedMessages.push({ ...v })
      })
      setJointMessages(combinedMessages)
    }
  }, [messages])
  // console.log({ jointMessages })
  return (
    <Menu as="div" className="relative inline-block text-left" ref={ref}>
      {({ open }) => {
        return (
          <>
            <MenuBackdrop open={open} />
            <Menu.Button
              className={styles}
              css={css`
                &&& {
                  svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    path {
                      // stroke: red;
                      fill: none;
                      stroke-width: 1px;
                    }
                  }
                }
              `}
            >
              <IconButton icon={ChatAlt2Icon} floating={floating} isMessage />
            </Menu.Button>
            <MenuTransition>
              <Menu.Items
                className={`${menuItemsClasses} lg:!w-[420px]`}
                // css={css`
                //   min-height: 200px;
                //   height: auto;
                //   max-height: calc(90vh - 90px);
                // `}
              >
                {/*<Scrollbar
              // style={{ width: '100%', height: 'calc(90vh - 90px)' }}
              style={{width: '100%', height: 'auto'}}
              // autoHeight
              // autoHeightMax={''}
              // autoHeightMin={'200px'}
              >*/}
                <div
                  className="p-[20px_15px_20px_15px] lg:p-[20px] overflow-y-scroll overflow-x-hidden"
                  css={css`
                    max-height: calc(80vh - 90px);
                  `}
                >
                  <Menu.Button className="md:hidden w-full">
                    <MobileMenuHead title="Messages" />
                  </Menu.Button>
                  <div className="">
                    {jointMessages !== null ? (
                      <MenuSection
                        title="My Messages"
                        link={"/dashboard/messages"}
                        reducedPaddingX
                        reducedPaddingY
                        noBorder
                      >
                        <div className="">
                          <Table noBorder flush overflowVisible>
                            {jointMessages.length > 0
                              ? jointMessages.map((item, k) => {
                                  return (
                                    <Tr key={k}>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            to={"/dashboard/messages"}
                                            state={{
                                              id: item.id,
                                            }}
                                            // to={`/dashboard/messages/t/${item.webform_submission_id}`}
                                            className={`block px-2 rounded-[8px] my-2 cursor-pointer ${
                                              active && `bg-[#fce3e1]`
                                            }`}
                                            // state={{ id: item.webform_submission_id }}
                                          >
                                            <div className="flex items-center py-3">
                                              <div className="min-w-0 flex-1 flex items-center">
                                                <div className="flex-shrink-0">
                                                  <div
                                                    className={`ring-1 ring-[#ebebeb] rounded-full ${
                                                      active &&
                                                      `!ring-[#e43d30] ring-offset-2`
                                                    } transition-all`}
                                                  >
                                                    <CircleAvatar
                                                      xs
                                                      shadowNone
                                                      image={
                                                        userType?.retailerManager ===
                                                          true ||
                                                        userType?.retailerEditor ===
                                                          true
                                                          ? item.type !==
                                                            "general_message"
                                                            ? item?.brand
                                                                ?.logo || null
                                                            : item?.from?.id !==
                                                              user_id
                                                            ? item?.to?.avatar
                                                            : item?.from?.avatar
                                                          : item.type !==
                                                            "general_message"
                                                          ? item?.brand?.logo ||
                                                            null
                                                          : item?.from?.id ===
                                                            user_id
                                                          ? item?.to?.avatar
                                                          : item?.from?.avatar
                                                      }
                                                      alt={
                                                        item?.brand?.title ||
                                                        item?.to?.name ||
                                                        item?.from?.name ||
                                                        null
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2 flex items-center">
                                                  <div className="flex flex-col justify-center">
                                                    {userType?.retailerManager ===
                                                      true ||
                                                    userType?.retailerEditor ===
                                                      true ? (
                                                      item.type ===
                                                      "general_message" ? (
                                                        item?.from?.id !==
                                                        user_id ? (
                                                          <p className="text-med-13">
                                                            {item?.to?.name}
                                                          </p>
                                                        ) : (
                                                          <p className="text-med-13">
                                                            {item?.from?.name}
                                                          </p>
                                                        )
                                                      ) : (
                                                        <p className="text-med-13">
                                                          {item?.brand?.title ||
                                                            item.title}
                                                        </p>
                                                      )
                                                    ) : item.type ===
                                                      "general_message" ? (
                                                      item?.from?.id ===
                                                      user_id ? (
                                                        <p className="text-med-13">
                                                          {item?.to?.name}
                                                        </p>
                                                      ) : (
                                                        <p className="text-med-13">
                                                          {item?.from?.name}
                                                        </p>
                                                      )
                                                    ) : (
                                                      <p className="text-med-13">
                                                        {item?.brand?.title ||
                                                          item.title}
                                                      </p>
                                                    )}
                                                    <span className="capitalize text-reg-15">
                                                      {item?.title?.replaceAll(
                                                        "_",
                                                        " "
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div className="hidden md:block flex flex-col">
                                                    <p
                                                      className={`flex items-center text-reg-12 !font-medium ${
                                                        active && "red"
                                                      }`}
                                                    >
                                                      <div className="w-[20px]">
                                                        <ClockIcon
                                                          className="w-4 w-4 "
                                                          css={css`
                                                            stroke-width: 1px;
                                                            stroke: ${active
                                                              ? "#e43d30"
                                                              : "#222"};
                                                          `}
                                                        />
                                                      </div>
                                                      <time
                                                        dateTime={
                                                          item.last_comment_date
                                                            ? item.last_comment_date
                                                            : item.created
                                                        }
                                                      >
                                                        {item.last_comment_date
                                                          ? item.last_comment_date
                                                          : item.created}
                                                      </time>
                                                    </p>
                                                    <p
                                                      className={`flex items-center text-reg-12 !font-medium ${
                                                        active && "red"
                                                      }`}
                                                    >
                                                      <div className="w-[20px]">
                                                        {item.webform_title ===
                                                        "Request Visit" ? (
                                                          <ShopIcon
                                                            className="w-3 w-3"
                                                            css={css`
                                                              path {
                                                                fill: ${active
                                                                  ? `#e43d30`
                                                                  : `#222`};
                                                              }
                                                            `}
                                                          />
                                                        ) : (
                                                          <ChatAlt2Icon
                                                            className="w-4 w-4 "
                                                            css={css`
                                                              stroke-width: 1px;
                                                              stroke: ${active
                                                                ? "#e43d30"
                                                                : "#222"};
                                                            `}
                                                          />
                                                        )}
                                                      </div>
                                                      <span className="capitalize">
                                                        {item?.type?.replaceAll(
                                                          "_",
                                                          " "
                                                        )}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <ChevronRightIcon
                                                  className={`h-4 w-4 transition-all ${
                                                    active
                                                      ? `red`
                                                      : "text-gray-400"
                                                  }`}
                                                  aria-hidden="true"
                                                />
                                              </div>
                                            </div>
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </Tr>
                                  )
                                })
                              : "Nothing to messages yet"}
                          </Table>
                        </div>
                      </MenuSection>
                    ) : (
                      <div className="w-full overflow-hidden flex flex-col items-center justify-center">
                        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
                      </div>
                    )}
                  </div>
                  {/*    </Scrollbar>*/}
                </div>
              </Menu.Items>
            </MenuTransition>
          </>
        )
      }}
    </Menu>
  )
}
