/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import Navigate from "./Icons/Navigate"
import Cart from "./Icons/Cart"
import Liked from "./Icons/Liked"
import Messages from "./Icons/Messages"
import Reporting from "./Icons/Reporting"
import Notifications from "./Icons/Notifications"
export default function Icons(props) {
  const { userType } = useContext(AuthenticationContext)
  if (userType.brandManager) {
    return (
      <>
        <Reporting {...props} />
        <Messages {...props} />
        <Notifications {...props} />
        <Cart {...props} />
      </>
    )
  }
  return (
    <>
      <Liked {...props} />
      <Messages {...props} />
      <Notifications {...props} />
      <Cart {...props} />
    </>
  )
}
