import React, { useState, useEffect, useContext } from "react"
import DataContext, { defaultState } from "./DataContext"
import {
  getUserGroups,
  getRegionsListing,
  getTagsProduct,
  getTagCategoryProduct,
  getRetailStores,
  getMyAssets,
  getMyProducts,
  getTagsListing,
  getTagCategory,
  // getSubUserListing,
  getPageNumbers,
  getMyBookmarks,
  getMyListing,
  getOnSiteMarketing,
  getMyNotifications,
  // getMyMessageSent,
  // getMyMessageReceived,
  clearMyNotification,
  clearMyNotifications,
  // getStoreCompanies,
  // getStoreRegions,
  // getStoreCities,
  getMyActiveMessages,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import AuthenticationContext from "../authentication/AuthenticationContext"
import { toCapitalCase } from "../../utils"

export const DataProvider = props => {
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)

  const { token } = state
  const { currentUserData } = authentication

  const [data, setData] = useState(defaultState)

  const updateState = (key, value) => {
    let selectData = false
    switch (key) {
      case "userGroups":
        selectData = value.map(v => {
          return {
            value: v.id,
            label: v.title,
          }
        })
        break
      default:
        selectData = value.map(v => {
          return {
            value: v.id,
            label: v.title,
          }
        })
        break
    }

    const obj = {
      [key]: value,
    }

    if (selectData) {
      obj[`${key}AsOptions`] = selectData
    }

    setData(prevState => ({
      ...prevState,
      ...obj,
    }))
  }

  useEffect(() => {
    if (currentUserData.isAuthenticated) {
      let myBrandsAsOptions = []
      currentUserData.brands.map((i, k) => {
        myBrandsAsOptions.push({
          value: i.id,
          label: i.title,
          img: i.logo,
          company_id: i.company_id,
        })
      })
      setData(prevState => ({
        ...prevState,
        myBrands: currentUserData.brands,
        myBrandsAsOptions: myBrandsAsOptions,
      }))
    }
  }, [])
  const run = () => {
    if (currentUserData.isAuthenticated) {
      if (currentUserData._r.includes("administrator")) {
        // fetchAll...
      }
      if (currentUserData.companies.length) {
        getUserGroups(token, currentUserData.companies[0].id).then(res => {
          // console.log(res, 'resolute')
          // alert("res")

          if (res?.unique[0]?.users) {
            let users = res.unique[0].users
            let selectData = users.map(v => {
              return {
                value: v,
                label: toCapitalCase(v),
              }
            })
          }
          setData(prevState => ({
            ...prevState,
            universalUserGroups: res.default,
          }))
        })
        let currentUserCompanies = currentUserData.companies.map(v => ({
          value: v.id,
          label: v.title,
        }))
        setData(prevState => ({
          ...prevState,
          currentUserCompaniesAsOp: currentUserCompanies,
        }))
      }
      getMyListing(token, "user_group", "default", "all").then(res => {
        // console.log(res)
        // console.log(res)
        // let users = res.unique[0].users
        let selectData = res.map(v => {
          return {
            value: v.id,
            label: v.title,
          }
        })
        setData(prevState => ({
          ...prevState,
          myUserGroups: res,
          userGroups: res,
          /* @TODO this object key is wrong, ensure no depedencies before removing */ myUsersAsOptions:
            selectData,
          myUserGroupsAsOptions: selectData,
        }))
      })
      getPageNumbers(token).then(res => {
        setData(prevState => ({
          ...prevState,
          entityItemsNumber: res,
          myBookmarksItems: res.bookmarked,
        }))
      })
      getMyNotifications(token).then(res => {
        setData(prevState => ({
          ...prevState,
          myNotifications: res,
          //  myBookmarksItems: res.bookmarked,
        }))
      })
      getMyBookmarks(token).then(res => {
        // console.log(res)
        let product = res.map(item => item["product"]?.id).filter(Boolean)
        // console.log(product)
        let asset = res.map(item => item["asset"]?.id).filter(Boolean)
        // console.log(asset)
        let retailer = res
          .map(item =>
            item["retailer"] && Array.isArray(item["retailer"])
              ? item["retailer"][0]?.id
              : item["retailer"]?.id
          )
          .filter(Boolean)

        // console.log(retailer)
        let store = res.map(item => item["store"]?.id).filter(Boolean)

        // console.log(store)
        let brand = res.map(item => item["brand"]?.id).filter(Boolean)
        // console.log(brand)
        setData(prevState => ({
          ...prevState,
          myBookmarks: res,
          myBookmarksID: { product, store, asset, brand, retailer },
        }))
      })

      getRegionsListing(token).then(res => {
        let regionsAsOptions = []
        let regionsAsOptionsCA = []
        let regionsAsOptionsUS = []
        res["CA"].map((i, k) => {
          let pushCa = {
            value: i.id,
            label: i.name + ` (${i.code}, CA)`,
            region_code: i.code,
          }
          regionsAsOptions.push(pushCa)
          regionsAsOptionsCA.push(pushCa)
        })
        res["US"].map((i, k) => {
          let pushUs = {
            value: i.id,
            label: i.name + ` (${i.code}, US)`,
            region_code: i.code,
          }
          regionsAsOptions.push(pushUs)
          regionsAsOptionsUS.push(pushUs)
        })
        setData(prevState => ({
          ...prevState,
          regions: res,
          regionsAsOptions: regionsAsOptions,
          regionsAsOptionsUS: regionsAsOptionsUS,
          regionsAsOptionsCA: regionsAsOptionsCA,
        }))
      })
      getRetailStores(token).then(res => {
        let retailStoresAsOptions = []
        res.map((i, k) => {
          retailStoresAsOptions.push({ value: i.id, label: i.name })
        })
        setData(prevState => ({
          ...prevState,
          retailStores: res,
          retailStoresAsOptions: retailStoresAsOptions,
        }))
      })

      getMyAssets(token).then(res => {
        let myAssetsAsOptions = []
        res.map((i, k) => {
          myAssetsAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          myAssets: res,
          myAssetsAsOptions: myAssetsAsOptions,
        }))
      })

      getMyProducts(token).then(res => {
        let myProductsAsOptions = []
        res.map((i, k) => {
          myProductsAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          myProducts: res,
          myProductsAsOptions: myProductsAsOptions,
        }))
      })
      getTagsListing(token, "default").then(res => {
        let tagsAsOptions = []
        res.map((i, k) => {
          tagsAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          tagsAsOptions: tagsAsOptions,
        }))
      })
      getTagCategory(token).then(res => {
        let categoriesAsOptions = []
        res.map((i, k) => {
          categoriesAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          categoriesAsOptions: categoriesAsOptions,
        }))
      })

      getTagsProduct(token).then(res => {
        let productTagsAsOptions = []
        res.map((i, k) => {
          productTagsAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          productTagsAsOptions: productTagsAsOptions,
        }))
      })

      getTagCategoryProduct(token).then(res => {
        let productCategoriesAsOptions = []
        res.map((i, k) => {
          productCategoriesAsOptions.push({ value: i.id, label: i.title })
        })
        setData(prevState => ({
          ...prevState,
          productCategoriesAsOptions: productCategoriesAsOptions,
        }))
      })

      // getStoreCompanies(token).then(res => {
      //   let storeCompaniesAsOptions = []
      //   res.map((i, k) => {
      //     storeCompaniesAsOptions.push({ value: i.value, label: i.label })
      //   })
      //   setData(prevState => ({
      //     ...prevState,
      //     storeCompaniesAsOptions: storeCompaniesAsOptions,
      //   }))
      // })
      // getStoreRegions(token).then(res => {
      //   let storeRegionsAsOptions = []
      //   res.map((i, k) => {
      //     storeRegionsAsOptions.push({ value: i.region, label: i.region })
      //   })
      //   setData(prevState => ({
      //     ...prevState,
      //     storeRegionsAsOptions: storeRegionsAsOptions,
      //   }))
      // })
      // getStoreCities(token).then(res => {
      //   let storeCitiesAsOptions = []
      //   res.map((i, k) => {
      //     storeCitiesAsOptions.push({ value: i.city, label: i.city })
      //   })
      //   setData(prevState => ({
      //     ...prevState,
      //     storeCitiesAsOptions: storeCitiesAsOptions,
      //   }))
      // })

      // getSubUserListing(token).then(res => {
      //   let myUsersAsOptions = []
      //   res.map((i, k) => {
      //     myUsersAsOptions.push({ value: i.id, label: i.name })
      //   })
      //   setData(prevState => ({
      //     ...prevState,
      //     myUsers: res,
      //     myUsersAsOptions: myUsersAsOptions,
      //   }))
      // })
      getOnSiteMarketing(token).then(res => {
        setData(prevState => ({
          ...prevState,
          onSiteMarketing: res,
        }))
      })

      getMyActiveMessages(token, {}).then(res => {
        setData(prevState => ({
          ...prevState,
          messages: { ...prevState.messages, sentMessages: res },
        }))
      })
      // getMyMessageReceived(token).then(res => {
      //   setData(prevState => ({
      //     ...prevState,
      //     messages: { ...prevState.messages, receivedMessage: res },
      //   }))
      // })
    }
  }

  useEffect(() => {
    run()
  }, [])

  const myNotificationsClearAll = async () => {
    await clearMyNotifications(token).then(res =>
      getMyNotifications(token).then(res => {
        setData(prevState => ({
          ...prevState,
          myNotifications: res,
        }))
      })
    )
  }
  const myNotificationsClearSingle = async id => {
    await clearMyNotification(token, id).then(res =>
      getMyNotifications(token).then(res => {
        setData(prevState => ({
          ...prevState,
          myNotifications: res,
        }))
      })
    )
  }

  return (
    <DataContext.Provider
      value={{
        updateState: updateState,
        data: data,
        setData: setData,
        myNotificationsClearAll: myNotificationsClearAll,
        myNotificationsClearSingle,
        myNotificationsClearSingle,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export default DataProvider
