/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Icon from "../../assets/icons/close-alt.svg"

function IconClose(props) {
  const { callback, height, width, small } = props

  return (
    <button
      type="button"
      className={`flex justify-center items-center bg-[#F7F7F7] rounded-[8px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all`}
      onClick={callback}
      css={css`
        &&& {
          min-height: ${small ? `24px` : `40px`};
          min-width: ${small ? `24px` : `40px`};
          height: ${small ? `24px` : `40px`};
          width: ${small ? `24px` : `40px`};
        }
      `}
    >
      <Icon className={`h-${height ? height : 3} w-${width ? width : 3}`} />
    </button>
  )
}

export default IconClose
