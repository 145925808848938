/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import { Link } from "gatsby"
import { HeartIcon } from "@heroicons/react/solid"
import Heart from "../../assets/icons/heart.svg"
import Location from "../../assets/icons/pin.svg"
import Store from "../../assets/icons/shop-alt.svg"
import AddToWishlist from "../Elements/AddToWishlist"
import MetaStandard from "../Elements/MetaStandard"
import MetaGroup from "../Elements/MetaGroup"
import Button from "../Elements/Button"
import ButtonLite from "../Elements/ButtonLite"
import Pin from "../../assets/icons/pin.svg"
import Navigate from "../../assets/icons/navigate.svg"
import DataContext from "../../context/Data/DataContext"
import LazyImage from "../LazyImage"
import Separator from "../Elements/Separator"
import { useMediaQuery } from "react-responsive"
import Skeleton from "../Skeleton"

function RetailerListCard(props) {
  const { myBookmarksID } = useContext(DataContext).data

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  if (props.loading) {
    return (
      <>
        <div
          // to="#"
          className={
            props.search
              ? " relative group text-sm rounded-[8px] border border-gray-100 mb-4 container grow p-8"
              : "flex items-stretch relative md:my-8 grow"
          }
        >
          {(props.isStore && props.owner_logo !== "") ||
            (!props.isStore && props.logo && (
              <div
                className="hidden md:block rounded-[8px] bg-[#EBEBEB] overflow-hidden object-cover md:mr-6"
                css={css`
                  height: 160px;
                  min-width: 180px;
                  width: 180px;
                  "@media(min-width: 1400px)": {
                    height: 200px;
                    min-width: 220px;
                    width: 220px;
                  }
                `}
              >
                <Skeleton />
              </div>
            ))}
          <div className="justify-between leading-normal grow">
            <h5 className={`mb-2 ${isMobile ? `text-med-18` : `text-med-15`}`}>
              <Skeleton fromText width={"100%"} />
            </h5>
            <div className="flex justify-between mb-2">
              <MetaGroup>
                <MetaStandard
                  icon={
                    <Navigate
                      css={css`
                        path {
                          fill: rgba(0, 0, 0, 0.1);
                        }
                      `}
                    />
                  }
                  text={<Skeleton fromText width={100} />}
                />
                <MetaStandard
                  icon={
                    <Store
                      css={css`
                        path {
                          fill: rgba(0, 0, 0, 0.1);
                        }
                      `}
                    />
                  }
                  text={<Skeleton fromText width={100} />}
                />
              </MetaGroup>
            </div>
            {!props.isStore ? (
              <ul className="mb-4 text-reg-15 grid gap-5 grid-cols-1 w-full">
                <li
                  className="mb-2"
                  css={css`
                    &&& {
                      line-height: 1.2;
                    }
                  `}
                >
                  <Skeleton fromText width={"80%"} />
                </li>
              </ul>
            ) : (
              <p className="mb-3 font-normal text-reg-15">
                <Skeleton fromText />
              </p>
            )}
            {!props.search && (
              <Skeleton fromText width={"150px"} height={"50px"} />
            )}
            {props.search && (
              <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-all">
                <Skeleton />
              </div>
            )}
          </div>
        </div>
        <Separator className="mt-[40px] md:hidden" />
      </>
    )
  }
  return (
    <>
      <div
        // to="#"
        className={
          props.search
            ? " relative group text-sm rounded-[8px] border border-gray-100 mb-4 container grow p-8"
            : "flex items-stretch relative md:my-8 grow"
        }
      >
        {/*(props.isStore && props.owner_logo !== "") ||
          (!props.isStore && props.logo && (
            <div
              className="hidden md:block rounded-[8px] bg-[#EBEBEB] overflow-hidden object-cover md:mr-6"
              css={css`
                height: 160px;
                min-width: 180px;
                width: 180px;
                "@media(min-width: 1400px)": {
                  height: 200px;
                  min-width: 220px;
                  width: 220px;
                }
              `}
            >
              <LazyImage
                className="object-cover w-full h-full"
                src={props.isStore ? props.owner_logo : props.logo}
                alt={props.name}
              />
            </div>
          ))*/}
        <div className="justify-between leading-normal grow">
          <h5 className={`mb-2 ${isMobile ? `text-med-18` : `text-med-15`}`}>
            {props.name}
          </h5>
          <div className="flex justify-between mb-2">
            <MetaGroup>
              <MetaStandard icon={<Navigate />} text={"Canada"} />
              <MetaStandard
                icon={<Store />}
                text={
                  !props.isStore
                    ? props.stores?.length && props.stores?.length > 1
                      ? `${props.stores?.length} Locations `
                      : "1 Location"
                    : props.stores + " Locations"
                }
              />
            </MetaGroup>
          </div>
          {!props.isStore &&
            Array.isArray(props.stores) &&
            props.stores.length > 0 && (
              <ul
                className={`mb-4 mt-4 text-reg-15 grid gap-x-5 gap-y-2 ${
                  props.stores.length === 1 ? `grid-cols-1` : `grid-cols-2`
                } w-full`}
              >
                {props.stores &&
                  props.stores.map((v, k) => (
                    <li
                      key={k}
                      className="mb-2 flex space-x-[12px]"
                      css={css`
                        &&& {
                          line-height: 1.2;
                        }
                      `}
                    >
                      <Pin
                        className="h-4 w-4"
                        css={css`
                          &&& {
                            path {
                              fill: #717171;
                            }
                          }
                        `}
                      />
                      <span className="text-[13px]">
                        {v.name && v.name.includes(props.name)
                          ? v.name.replaceAll(`${props.name} -`, "")
                          : v.name}
                      </span>
                    </li>
                  ))}
              </ul>
            )}
          {props.isStore &&
            Array.isArray(props.moreStores) &&
            props.moreStores.length > 0 && (
              <>
                {props.moreStores.length > 5 && (
                  <h5 className="text-med-14 underline">Closest to you:</h5>
                )}
                <ul
                  className={`mb-4 mt-4 text-reg-15 grid gap-x-5 gap-y-2 ${
                    props.moreStores.length === 1
                      ? `grid-cols-1`
                      : `grid-cols-2`
                  } w-full`}
                >
                  {props.moreStores &&
                    props.moreStores.slice(0, 5).map((v, k) => (
                      <li
                        key={k}
                        className="mb-2 flex space-x-[12px]"
                        css={css`
                          &&& {
                            line-height: 1.2;
                          }
                        `}
                      >
                        <Pin
                          className="h-4 w-4"
                          css={css`
                            &&& {
                              path {
                                fill: #717171;
                              }
                            }
                          `}
                        />
                        <span className="text-[13px]">
                          {v.name && v.name.includes(props.name)
                            ? v.name.replaceAll(`${props.name} -`, "")
                            : v.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </>
            )}

          {!props.search && !props.isStore && !props.moreStores?.length > 0 && (
            <Button onClick={props.onViewClick} full={isMobile ? true : false}>
              View
            </Button>
          )}
          {props.isStore && props.moreStores.length > 0 && (
            <div className="flex space-x-2">
              {props.moreStores.length > 1 && (
                <ButtonLite
                  color={"green"}
                  inline
                  callback={props.onViewClickAll}
                  full={isMobile ? true : false}
                >
                  View all locations
                </ButtonLite>
              )}
              <ButtonLite
                inline
                callback={props.onViewClick}
                full={isMobile ? true : false}
                hoverMeta={props.name.substring(props.name.indexOf("-") + 1)}
              >
                {props.moreStores.length > 1
                  ? "Just view this store"
                  : "View this store"}
              </ButtonLite>
            </div>
          )}
          {!props.search && (
            <div className="absolute top-0 right-0">
              <AddToWishlist
                noText
                title={props.name}
                id={props.id}
                name={props.isStore ? "store" : "retailer"}
                isListed={
                  myBookmarksID[props.isStore ? "store" : "retailer"]?.includes(
                    props.id
                  )
                    ? true
                    : false
                }
              />
            </div>
          )}
          {props.search && (
            <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-all">
              <AddToWishlist
                noText
                title={props.name}
                id={props.id}
                name={props.isStore ? "store" : "retailer"}
                isListed={
                  myBookmarksID[props.isStore ? "store" : "retailer"]?.includes(
                    props.id
                  )
                    ? true
                    : false
                }
              />
            </div>
          )}
        </div>
      </div>
      <Separator className="mt-[40px] md:hidden" />
    </>
  )
}

export default RetailerListCard
