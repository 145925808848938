/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useEffect, useContext } from "react"

import FloatingSelect from "../../../Elements/Form/FloatingSelect"
import { FixedSizeList } from "react-window"
import {
  entitySuggestions,
  myEntitySuggestions,
} from "../../../../helpers/DrupalHelper"
import { DropdownIndicator, ClearIndicator } from "../../../../styled/Select"
import DataContext from "../../../../context/Data/DataContext"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import ImageCardSmall from "../../../Elements/ImageCardSmall"
import CircleAvatar from "../../../Elements/CircleAvatar"
import AsyncFloatingSelect from "../../../Elements/Form/AsyncFloatingSelect"
import FloatingSelectCreatable from "../../../Elements/Form/FloatingSelectCreatable"

const height = 35

const MenuList = props => {
  const { options, children, maxHeight, getValue } = props

  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  )
}
const myEntitiesItem = [
  "brand",
  "asset",
  "product",
  "store",
  "company",
  "retailer",
]
function EntityReferenceRevisionsAsset({
  data,
  errors,
  name,
  register,
  label,
  setValue,
  token,
  watch,
  control,
  description,
  restrictCheck,
  setRestrictCheck,
  placeholder,
  nomargin,
  edit,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState([
    { value: "demo", label: "demo" },
  ])
  useEffect(() => {
    if (data.current_value?.length) {
      setRestrictCheck && setRestrictCheck(true)
    }
  }, [])
  const [select1, setSelect1] = useState(null)
  const [defaultValue, setDefaultValue] = useState([])
  const { userType } = useContext(AuthenticationContext)

  console.log(entitySuggestion, "usertype")
  const { myUsersAsOptions, myBrandsAsOptions } = useContext(DataContext).data

  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    if (
      data.value_limit &&
      data.value_limit > 1 &&
      Array.isArray(value) &&
      value.length > data.value_limit
    ) {
      return
    } else {
      const inputRef = action.name
      const currentValue = watch(inputRef)
      // console.log(value)
      if (setValue) {
        if (value !== null) {
          // setValue(inputRef, value);
          setValue(
            `${name}`,
            Array.isArray(value) ? value.map(v => v.value) : [value.value]
          )
          setStateFunction(value)
        } else {
          // setValue(inputRef, null);
          setStateFunction(null)
        }
      }
    }

    // if(childSelect && )
  }

  const entity = data.entity_type.machine_name
  useEffect(async () => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      })
    } else {
      register(name)
    }

    const entity = data.entity_type.machine_name
    // console.log(name, entity)
    const bundle = data.entities
      ? data.entities.map(v => v.machine_name)
      : data.entity_type.machine_name
    if (data.current_value && setValue) {
      setValue(
        `${name}`,
        data.current_value.map(item => item.target_id.toString())
      )

      let a = data.current_value.map(item => {
        return { value: item.target_id, label: item.title }
      })

      setDefaultValue([...a])
      setSelect1([...a])
    } else {
      setSelect1([
        {
          value: watch(name),
          label,
        },
      ])
    }
    console.log(select1)
    // // console.log(entity, bundle);
    // if (name == "field_product_brand") {
    //   setEntitySuggestion([...myBrandsAsOptions])
    // } else {
    let datas = []
    if (name === "field_group_users" || name === "field_store_user") {
      setEntitySuggestion([...myUsersAsOptions])
      return
    }
    if (entity !== "asset") {
      if (myEntitiesItem.includes(entity)) {
        datas = await myEntitySuggestions(token, entity, bundle)
        // console.log(datas, "from my entities")
      } else {
        datas = await entitySuggestions(token, entity, bundle)
      }
    }

    if (datas) {
      let ss = datas
      if (entity === "brand")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: (
              <div className="flex items-center">
                <CircleAvatar image={v.thumb} border xs />
                <span className="ml-2">{v.title}</span>
              </div>
            ),
          }))
        )
      else if (entity === "asset") return
      // setEntitySuggestion(
      //   ss.map(v => ({
      //     ...v,
      //     value: v.target_id ? v.target_id : v.id,
      //     label: (
      //       <div className="flex items-center">
      //         <ImageCardSmall image={v.thumb} size={40} />
      //         <span className="ml-2">{v.title}</span>
      //         {/*<LazyImage src={v.cover} className="ml-6" />*/}
      //       </div>
      //     ),
      //   }))
      // )
      else if (entity === "product")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: (
              <div className="flex items-center">
                <ImageCardSmall image={v.thumb} size={40} />
                <span className="ml-2"> {v.title}</span>
                {/*<LazyImage
                  src={
                    v?.img && Array.isArray(v?.img) && v.img[0] ? v.img[0] : ""
                  }
                  className="ml-6"
                />*/}
              </div>
            ),
          }))
        )
      else if (entity === "company")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else if (entity === "retailer")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else if (entity === "store")
        setEntitySuggestion(
          ss.map(v => ({
            ...v,
            value: v.target_id ? v.target_id : v.id,

            label: v.title,
          }))
        )
      else
        setEntitySuggestion([
          ...datas.map(item => {
            return { value: item.target_id, label: item.title }
          }),
        ])
    } else {
      // }
    }
  }, [])
  useEffect(() => {
    if (name === "field_group_users") {
      setEntitySuggestion([...myUsersAsOptions])
    }
    if (name == "field_product_brand" && setValue) {
      console.log("field_product_brand")
      setEntitySuggestion([...myBrandsAsOptions])
      setValue(name, myBrandsAsOptions[0].value)
      setSelect1(myBrandsAsOptions[0])
    }
  }, [myUsersAsOptions, myBrandsAsOptions])

  const bundle = data.entities
    ? data.entities.map(v => v.machine_name)
    : data.entity_type.machine_name

  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`

  if (
    entity === "asset" ||
    (entity === "store" &&
      name === "field_store" &&
      (userType.brandEditor || userType.brandManager))
  )
    return (
      <div className={`relative mb-6 w-full group `}>
        <AsyncFloatingSelect
          label={data.label}
          name={name}
          hookForm
          register={register}
          error={errors && errors[name]}
          control={control}
          myEntitySuggest={entity === "asset" ? true : false}
          required={data.required}
          description={description}
          body={{
            bundle: bundle,
            entity: entity,
          }}
          isMulti={data.value_limit !== 1}
          defaultValue={
            data.current_value
              ? data.current_value.map(item => {
                  return { value: item.target_id, label: item.title }
                })
              : null
          }
        />
      </div>
    )
  else
    return (
      <>
        {(restrictCheck === true || name !== "field_user_groups") && (
          <div className={`relative mb-6 w-full group `}>
            <FloatingSelectCreatable
              name={name}
              options={entitySuggestion}
              isSearchable={true}
              onChange={(value, action) => {
                handleChange(value, action, setSelect1, name)
              }}
              isClearable
              value={select1 !== null ? select1 : defaultValue[0]}
              components={{
                MenuList,
                DropdownIndicator: DropdownIndicator,
                ClearIndicator,
              }}
              hookForm
              error={errors}
              control={control}
              defaultValue={defaultValue[0]}
              isMulti={data["value_limit"] === 1 ? false : true}
              description={description}
              placeholder={placeholder}
              edit={edit}
              // notFoundButton={notFoundButton && notFoundButton}
              // notFoundButtonTitle={notFoundButton && notFoundButtonTitle}
              // notFoundButtonF={notFoundButton && notFoundButtonF}
              // styles={selectStyles}
            />

            <label
              for={name}
              className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
              css={css`
                ${fontStyles}
              `}
            >
              {label}
            </label>
          </div>
        )}
      </>
    )
}

export default EntityReferenceRevisionsAsset
