/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import CircleAvatar from "../Elements/CircleAvatar"
import Button from "../Elements/Button"
import { useMediaQuery } from "react-responsive"
import Skeleton from "../Skeleton"
import AddToWishlist from "../Elements/AddToWishlist"
import DataContext from "../../context/Data/DataContext"
import Tag from "../Elements/Tag"
import { isLatestUpdated } from "../../helpers/GeneralHelper"
function BrandGrid(brand) {
  const { borderNone, large, loading, searchResultGrid } = brand

  const { myBookmarksID } = useContext(DataContext).data
  // const leftIncrement = brand.asset_covers.length * 15 + `px`
  // let leftIncrement = '0'

  // if(brand.asset_covers.length === 2) leftIncrement = '20px'
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  if (loading) {
    return (
      <div
        className={`group relative ${
          large || isMobile
            ? `bg-white shadow-none hover:shadow-[0px_6px_50px_rgba(34,34,34,0.05)] rounded-[8px] p-[40px] transition-all`
            : `px-0 pt-8`
        } ${isMobile === true && !large && `border border-[#EBEBEB]`}`}
      >
        {!large && !isMobile && (
          <div
            className={`scale-100 group-hover:scale-125 group-hover:z-[2] absolute top-0 left-0 bottom-0 right-0 bg-white  ${
              !borderNone && `border border-[#EBEBEB]`
            } rounded-[8px] transition-all group-hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] `}
          ></div>
        )}
        <div
          className={`z-[3] w-full relative flex flex-col justify-center items-center ${
            isMobile === true ? `pb-8` : `pb-10`
          }  transition-all ${
            !large && !isMobile && `group-hover:-translate-y-[40px]`
          }`}
        >
          <CircleAvatar image={loading} />
          <Skeleton />
          <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
            <h3 className="text-med-18">
              <Skeleton />
            </h3>
          </div>

          <div className="space-x-3 flex items-center justify-center text-reg-14 py-2">
            <span>
              <Skeleton />
            </span>
            <span>
              {" "}
              <Skeleton />
            </span>
          </div>

          {/*<div
            className={`relative h-[50px] flex mt-3 transition-all`}
            // css={css`
            //   &&& {
            //     left: ${true
            //       ? `-` + (brand.asset_covers.length - 1) * 20 + `px`
            //       : 0};
            //   }
            // `}
          >
            {[2, 2, 3].map((i, k) => {
              return (
                <div
                 style={{ margint: "15px" }}
                 className={`hover:scale-[1.1] transition-all relative`}
               >
                  <CircleAvatar sm border image={false} />
                </div>
              )
            })}
          </div>*/}
        </div>
        <div
          className={`${!isMobile && `mt-8`} ${
            !large &&
            !isMobile &&
            `absolute opacity-0 group-hover:translate-y-[30px] group-hover:opacity-100 bottom-[30px] left-0 right-0 translate-y-[20px]  z-[3]`
          } transition-all`}
        >
          <Button full red outline={large ? true : false}>
            <Skeleton />
          </Button>
        </div>
      </div>
    )
  }

  // console.log(brand)
  // console.log("brand")
  // else {
  return (
    <div
      key={brand.id}
      className={`group relative ${searchResultGrid ? "w-full" : ""} ${
        large || isMobile || searchResultGrid
          ? `bg-white shadow-none hover:shadow-[0px_6px_50px_rgba(34,34,34,0.05)] rounded-[8px] p-[40px] transition-all`
          : `px-0 pt-8`
      } ${
        (isMobile === true && !large) ||
        (searchResultGrid === true && `border border-[#EBEBEB]`)
      }`}
    >
      {!large && !isMobile && !searchResultGrid && (
        <div
          className={`scale-100 group-hover:scale-125 group-hover:z-[2] absolute top-0 left-0 bottom-0 right-0 bg-white  ${
            !borderNone && `border border-[#EBEBEB]`
          } rounded-[8px] transition-all group-hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] `}
        ></div>
      )}
      <div
        className={`z-[3] w-full relative flex flex-col justify-center items-center ${
          isMobile === true ? `pb-8` : `pb-10`
        }  transition-all ${
          !large &&
          !isMobile &&
          !searchResultGrid &&
          `group-hover:-translate-y-[40px]`
        }`}
      >
        {brand.created_at && isLatestUpdated(brand.created_at, 7) && (
          <div
            className={`absolute right-4 top-4 opacity-100 group-hover:opacity-0 transition-all z-[1]`}
          >
            <Tag bold upper red>
              New
            </Tag>
          </div>
        )}
        <div
          className={` ${
            searchResultGrid
              ? ``
              : `group-hover:top-[-10px] group-hover:right-[-10px]  opacity-0 group-hover:opacity-[1]`
          } absolute top-[3px] right-[3px]  transition-all `}
        >
          <AddToWishlist
            title={brand.name}
            id={brand.id}
            name={"brand"}
            isListed={myBookmarksID["brand"]?.includes(brand.id) ? true : false}
            noText
          />
        </div>
        <Link to={`/brand/${brand.id}`}>
          <CircleAvatar image={brand.logo} alt={brand.name} maxImg />
        </Link>

        <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">
          <h3 className="text-med-18">
            {" "}
            <Link to={`/brand/${brand.id}`}>{brand.name || ""}</Link>
          </h3>
        </div>

        <div className="space-x-3 flex items-center justify-center text-reg-14 py-2">
          <span>{brand.number_of_assets} Assets</span>
          <span>{brand.number_of_products} Products</span>
        </div>

        <Link
          to={`/brand/${brand.id}`}
          className={`relative h-[50px]  mt-3 transition-all`}
          css={css`
            &&& {
              left: ${brand?.asset_covers?.length > 1
                ? `-` + (brand.asset_covers.length - 1) * 20 + `px`
                : 0};
            }
          `}
        >
          {/*brand.asset_covers?.map((i, k) => {
            const increment = 40 * k
            if (i.type === "image") {
              if (k === 0) {
                return (
                  <div className={`hover:scale-[1.1] transition-all relative`}>
                    <CircleAvatar sm border image={i.img} alt={i.title} />
                  </div>
                )
              }
              return (
                <div
                  className={`absolute top-0  z-1 transition-all hover:scale-[1.1]`}
                  css={css`
                    right: -${increment}px;
                  `}
                >
                  <CircleAvatar sm border image={i.img} alt={i.title} />
                </div>
              )
            } else return null
          })*/}
        </Link>
      </div>
      <div
        className={`${!isMobile && `mt-8`} ${
          !large &&
          !isMobile &&
          !searchResultGrid &&
          `absolute opacity-0 group-hover:translate-y-[30px] group-hover:opacity-100 bottom-[30px] left-0 right-0 translate-y-[20px]  z-[3]`
        } transition-all`}
      >
        <Button
          full
          red
          outline={large ? true : false}
          onClick={() => navigate(`/brand/${brand.id}`)}
        >
          View More
        </Button>
      </div>
    </div>
  )
  // }
}

export default BrandGrid
