/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import NavigateIcon from "../../../../assets/icons/bell.svg"
import CartIcon from "../../../../assets/icons/cart.svg"
import Bell from "../../../../assets/icons/bell.svg"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { XIcon } from "@heroicons/react/outline"
import { red } from "../../../Styles"
import { navigation } from "../../../Layout/Dashboard"
import { styles, menuItemsClasses } from "./utils"
import ImageCardSmall from "../../../Elements/ImageCardSmall"
import CartContext from "../../../../context/Cart/CartContext"
import Icon from "../../../../assets/icons/close-alt.svg"
import CartCard from "../../../Cards/CartCard"
import Button from "../../../Elements/Button"
import IconButton from "./IconButton"
import DataContext from "../../../../context/Data/DataContext"
import StateContext from "../../../../context/state/StateContext"
import MenuTransition from "./MenuTransition"
import { processCheckOut } from "../../../../helpers/DrupalHelper"
import MobileMenuHead from "../MobileMenuHead"
import MenuBackdrop from "./MenuBackdrop"
export default function Cart({ floating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  const { cart, openCartDropdown, setOpenCartDropdown, handleProcessCart } =
    useContext(CartContext)
  let typeArray = cart.map(v => v.type)

  const { state } = useContext(StateContext)
  const { token } = state
  const { entityItemsNumber } = useContext(DataContext).data
  const menuRef = useRef()
  const [price, setprice] = useState(false)

  const CartWithPrice = cart.filter((currentProduct, index) => {
    return currentProduct.price > 0
  })

  const [isCheckingOut, setIsCheckingOut] = useState(false)

  return (
    <>
      <Menu as="div" className="relative inline-block text-left" ref={menuRef}>
        {props => {
          let { open } = props
          return (
            <>
              <MenuBackdrop open={open} />
              <IconButton
                icon={CartIcon}
                floating={floating}
                isCart
                badge={cart.length > 0 ? cart.length : null}
              />

              <MenuTransition>
                <Menu.Items
                  className={`${menuItemsClasses} p-[20px] lg:!w-[380px]`}
                >
                  <Menu.Button className="w-full md:hidden ">
                    <MobileMenuHead title="Cart" />
                  </Menu.Button>
                  <ul role="list" className="overflow-auto sm:max-h-[60vh]">
                    {cart.length > 0 && (
                      <>
                        <div className="border border-b-1 border-l-0 border-r-0 border-t-0 border-[#EBEBEB] pb-[20px] mb-[20px] flex space-x-[20px] items-center">
                          <Button red full onClick={() => navigate("/cart")}>
                            View Cart
                          </Button>
                          {CartWithPrice && CartWithPrice.length ? (
                            <Button
                              red
                              full
                              onClick={() => navigate("/CheckOut")}
                            >
                              Checkout
                            </Button>
                          ) : (
                            <Button
                              red
                              full
                              onClick={() => {
                                setIsCheckingOut(true)
                                if (isCheckingOut) return
                                handleProcessCart()
                              }}
                            >
                              Process Order
                            </Button>
                          )}
                        </div>
                        {cart.map((v, k) =>
                          v?.addresses?.map((c, kk) => (
                            <CartCard
                              small
                              key={`${k}_${kk}`}
                              {...v}
                              {...c}
                              mainIndex={k}
                              storeIndex={kk}
                              assetId={v.asset_id}
                              storeId={c.address_id}
                            />
                          ))
                        )}
                      </>
                    )}
                    {cart.length === 0 && (
                      <>
                        <h3 className="text-reg-16 mb-4">
                          There's nothing in your cart right now.
                        </h3>
                        <div className="flex space-x-[20px] items-center">
                          <Button pink full onClick={() => navigate("/assets")}>
                            Browse {entityItemsNumber.assets}+ Premium Assets
                          </Button>
                        </div>
                      </>
                    )}
                  </ul>
                </Menu.Items>
              </MenuTransition>
            </>
          )
        }}
      </Menu>
    </>
  )
}
