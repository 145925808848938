/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function BoxCardGroup(props) {
  const {  children } = props
  return (
    <div className="grid md:grid-cols-3 gap-[15px] md:gap-4">
      {children}
    </div>
  )
}

export default BoxCardGroup
