/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function Separator(props) {
  if (props.vertical) {
    return (
      <div
        className={props.className ? props.className : ""}
        css={css`
          width: 1px;
          background: #ebebeb;
          margin: 0 15px;
          /* height: 100%; */
        `}
      />
    )
  }
  return (
    <div
      className={props.className ? props.className : ""}
      css={css`
        width: 100%;
        background: #ebebeb;
        height: 1px;
      `}
    />
  )
}

export default Separator
