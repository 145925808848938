import { FolderAddIcon } from "@heroicons/react/solid"
import React from "react"

const ErrorMessage = ({ message }) => {
  return (
    <p style={{ color: "red" }}>
      <FolderAddIcon className="w-5 h-5" />
      {message}
    </p>
  )
}

export default ErrorMessage
