import React, { useEffect, useState } from "react"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const LazyImage = props => (
  <LazyLoadImage
    // {alt={image.alt}
    // effect="blur"
    // src={image.src}
    // className={classNames}}
    {...props}
  />
)

export default LazyImage
