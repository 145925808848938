/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { useMediaQuery } from "react-responsive"

function SectionCard(props) {
  const { title, children, id, description } = props
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <div className="border border-[#EBEBEB] rounded-[8px]" id={id}>
      {title && (
        <header className="flex items-center border-b border-[#EBEBEB] p-[15px] md:p-[30px]">
          <h1
            className={`${
              isMobile ? `text-semibold-16` : `text-semibold-18`
            } w-full`}
          >
            {title}
          </h1>
        </header>
      )}

      <footer className="flex flex-col p-[30px_15px_0_15px] md:p-[30px]">
        {description && (
          <span
            // className="text-sm"
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding: 0 ;
              margin: 7px 0 20px 0;
              letter-spacing: 0.02em;
              color: #717171;
            `}
          >
            {description}
          </span>
        )}
        {children}
      </footer>
    </div>
  )
}

export default SectionCard
