/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { BoxCard, BoxCardGroup } from "../Cards/Dashboard/BoxCard"
import { Table, Tr, Td } from "../Table/TableElements"

function Skeletonm(props) {
  const { dark, height, fromText, width, rounded, flush } = props

  let styles = css`
    min-height: ${height
      ? typeof height === "string"
        ? height
        : height + "px"
      : "none"};
    border-radius: ${rounded ? rounded : 0};
    min-width: ${width
      ? typeof width === "string"
        ? width
        : width + "px"
      : "none"};
  `
  if (fromText) {
    styles = css`
      min-height: ${height
        ? typeof height === "string"
          ? height
          : height + "px"
        : "20px"};
      border-radius: ${rounded ? rounded : "4px"};
      min-width: ${width
        ? typeof width === "string"
          ? width
          : width + "px"
        : "26px"};
      max-height: ${height
        ? typeof height === "string"
          ? height
          : height + "px"
        : "30px"};
      max-width: ${width
        ? typeof width === "string"
          ? width
          : width + "px"
        : "30px"};
      padding-top: ${flush ? "inherit" : "5px"};
    `
    return (
      <div className={`animate-pulse `} css={styles}>
        <div
          className={`${dark ? `bg-[#EBEBEB]` : `bg-[#f7f7f7]`}`}
          css={styles}
        ></div>
      </div>
    )
  }
  return (
    <div className={`animate-pulse w-full h-full`} css={styles}>
      <div
        className={`${dark ? `bg-[#EBEBEB]` : `bg-[#f7f7f7]`} h-full w-full`}
        css={styles}
      ></div>
    </div>
  )
}
const TableSkeleton = (props) => {

  const { count = 5 } = props
  return (
    <Table columns={[<Skeletonm height={23} rounded={"4px"} />]}>
      {Array.from({ length: count })
      
      .map((file, k) => (
        <Tr key={k}>
          <Td>
            <Skeletonm height={30} rounded={"4px"} />
          </Td>
        </Tr>
      ))}
    </Table>
  )
}

const BoxCardSkeleton = () => {
  return (
    <BoxCard title={<Skeletonm height={26} width={100} rounded={"4px"} />}>
      <Skeletonm height={26} rounded={"4px"} />
      <br />
      <Skeletonm height={26} rounded={"4px"} />
    </BoxCard>
  )
}
export default function Skeleton(props) {
  const { table, boxCard, count } = props
  return (
    <>
      {table && <TableSkeleton {...props} />}
      {boxCard && [1, 3].map(v => <BoxCardSkeleton key={v} />)}
      {!table && !boxCard && <Skeletonm {...props} />}
    </>
  )
}
