/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect } from "react"
import { Fragment, useState } from "react"
import Select, { components } from "react-select"

import Tags from "./Tags"
import Tag from "./Tag"
import { classNames } from "../../utils"
import DataContext from "../../context/Data/DataContext"
import {
  selectStyles,
  // SelectContainer,
  DropdownIndicator,
  ClearIndicator,
  selectFilterStyles,
  selectFilterStylesFullWidth,
} from "../../styled/Select"
import CircleAvatar from "./CircleAvatar"
import Remove from "../../assets/icons/close.svg"
import Skeleton from "../Skeleton"
export default function FilterTag({
  menuClassNames,
  name,
  title,
  onChange,
  options,
  placeholder,
  label,
  type,
  value,
  fullWidth,
  loading,
  noImage,
}) {
  const [selected, setSelected] = useState(value ? value : [])

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  // useEffect(() => {
  //   onChange(selected)
  // },[selected])
  // const { tagsAsOptions: options } = useContext(DataContext).data

  // console.log(options)
  // console.log(value, selected)
  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selected))
      setSelected(value)
  }, [value])
  return (
    <div
      className={`relative w-full group`}
      onMouseLeave={() => setMenuIsOpen(false)}
    >
      <div className="-rm-min-h-[400px]">
        {title && (
          <h3 className="text-semibold-18 mb-[30px]">Filter By {title}</h3>
        )}

        {options.length && type === "brand" && (
          <Select
            options={options}
            filterOption={(option, searchText) =>
              option.data.label.toLowerCase().includes(searchText.toLowerCase())
            }
            getOptionLabel={option => {
              // if (type === "brand") {
              return (
                <div
                  className={
                    fullWidth
                      ? "flex items-center bg-white border border-[#EBEBEB] rounded-md px-2 py-2"
                      : "flex items-center"
                  }
                >
                  {!noImage && <CircleAvatar border xs image={option.thumb} />}
                  <span className="ml-2">{option.label}</span>
                </div>
              )
              // }
              // return option.label
            }}
            components={{
              DropdownIndicator: DropdownIndicator,
              // ClearIndicator: () => null,
              // MultiValueRemove,
              // MultiValue,
              ValueContainer: ({ children, ...props }) => {
                const { getValue, hasValue } = props
                const nbValues = getValue().length
                if (!hasValue) {
                  return (
                    <components.ValueContainer {...props}>
                      {children}
                      {/* <p className="text-[#717171]">Select tags...</p> */}
                    </components.ValueContainer>
                  )
                }
                return (
                  <components.ValueContainer {...props}>
                    {`${nbValues} items selected`}
                  </components.ValueContainer>
                )
              },
            }}
            // {...register(inputName)}
            onChange={v => {
              // console.log(v)

              let data = v.map(b => b.value)
              // console.log(data)
              // if (data.length) onChange(data)
              // else onChange([])
              onChange([...data])

              setSelected([...data])
            }}
            isClearable={false}
            closeMenuOnSelect={false}
            value={selected.map(v => {
              let b = options.filter(c => v === c.value)[0]
              return b
            })}
            isMulti={true}
            placeholder={placeholder || `Search brands...`}
            styles={
              fullWidth
                ? selectFilterStylesFullWidth(label)
                : selectFilterStyles
            }
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            // isSearchable={isSearchable}
            // defaultValue={defaultValue}
          />
        )}

        {options.length && !type && fullWidth && (
          <Select
            options={options}
            getOptionLabel={option => {
              return (
                <div
                  className={
                    "flex items-center bg-white border border-[#EBEBEB] rounded-md px-2 py-2"
                  }
                >
                  <span className="">{option.label}</span>
                </div>
              )
              // }
              // return option.label
            }}
            components={{
              DropdownIndicator: DropdownIndicator,
              // ClearIndicator: () => null,
              // MultiValueRemove,
              // MultiValue,
              ValueContainer: ({ children, ...props }) => {
                const { getValue, hasValue } = props
                const nbValues = getValue().length
                if (!hasValue) {
                  return (
                    <components.ValueContainer {...props}>
                      {children}
                      {/* <p className="text-[#717171]">Select tags...</p> */}
                    </components.ValueContainer>
                  )
                }
                return (
                  <components.ValueContainer {...props}>
                    {`${nbValues} items selected`}
                  </components.ValueContainer>
                )
              },
            }}
            // {...register(inputName)}
            onChange={v => {
              // console.log(v)

              let data = v.map(b => b.value)
              // console.log(data)
              // if (data.length) onChange(data)
              // else onChange([])
              onChange([...data])

              setSelected([...data])
            }}
            isClearable={false}
            value={selected.map(v => {
              let b = options.filter(c => v === c.value)[0]
              return b
            })}
            isMulti={true}
            placeholder={placeholder || "Select..."}
            styles={selectFilterStylesFullWidth(label)}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            // isSearchable={isSearchable}
            // defaultValue={defaultValue}
          />
        )}
        {options.length && !type && !fullWidth && (
          <Select
            options={options}
            // getOptionLabel={option => {
            //   if (type === "brand") {
            //     return (
            //       <div className="flex items-center">
            //         <CircleAvatar border xs image={option.thumb} />
            //         <span className="ml-2">{option.title}</span>
            //       </div>
            //     )
            //   }
            //   return option.label
            // }}
            components={{
              DropdownIndicator: DropdownIndicator,
              // ClearIndicator: () => null,
              // MultiValueRemove,
              // MultiValue,
              ValueContainer: ({ children, ...props }) => {
                const { getValue, hasValue } = props
                const nbValues = getValue().length
                if (!hasValue) {
                  return (
                    <components.ValueContainer {...props}>
                      {children}
                      {/* <p className="text-[#717171]">Select tags...</p> */}
                    </components.ValueContainer>
                  )
                }
                return (
                  <components.ValueContainer {...props}>
                    {`${nbValues} items selected`}
                  </components.ValueContainer>
                )
              },
            }}
            // {...register(inputName)}
            onChange={v => {
              // console.log(v)

              let data = v.map(b => b.value)
              // console.log(data)
              // if (data.length) onChange(data)
              // else onChange([])
              onChange([...data])

              setSelected([...data])
            }}
            isClearable={false}
            value={selected.map(v => {
              let b = options.filter(c => v === c.value)[0]
              return b
            })}
            isMulti={true}
            placeholder={placeholder || "Select..."}
            styles={selectStyles}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            // isSearchable={isSearchable}
            // defaultValue={defaultValue}
          />
        )}

        {type === "brand" && (
          <div className="mb-[30px] bg-[#EDEDED] mt-[12px]">
            {!loading ? (
              <div className="flex flex-col">
                {selected
                  .map(v => {
                    let b = options.filter(c => v === c.value)[0]
                    return b
                  })
                  .map((option, optionIdx) => (
                    <div
                      className="flex items-center cursor-pointer hover:bg-[#f7f7f7] p-2 rounded-[8px] transition-all pr-4"
                      // onClick={() => {
                      //   if (selected.includes(option.value)) return
                      //   else setSelected([...selected, option.value])
                      //   // setSelected(option)
                      //   // onChange(option.value    )
                      // }}
                      onClick={() => {
                        if (selected.includes(option.value)) {
                          let withoutClose = selected.filter(
                            v => v !== option.value
                          )
                          setSelected([...withoutClose])
                          onChange([...withoutClose])
                        } else return
                        // setSelected(option)
                        // console.log("sss")
                        // onChange(option.value    )
                      }}
                      // active={selected.includes(option.value)}
                    >
                      <div className="flex-grow">
                        <CircleAvatar
                          xs
                          border
                          image={option.thumb}
                          text={option.label}
                          noImage={noImage}
                        />
                      </div>
                      <div
                        className="pl-2"
                        role="none"
                        css={css`
                          svg * {
                            fill: black;
                          }
                        `}
                      >
                        <Remove className="pt-[1px]" />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <Skeleton />
            )}
          </div>
        )}
        {!type && (
          <div className="mt-[30px]">
            <Tags wrap>
              {selected
                .map(v => {
                  let b = options.filter(c => v === c.value)[0]
                  return b
                })
                .map((option, optionIdx) => (
                  <Tag
                    // onClick={() => {
                    //   if (selected.includes(option.value)) return
                    //   else setSelected([...selected, option.value])
                    //   // setSelected(option)
                    //   // onChange(option.value    )
                    // }}
                    onCloseClick={() => {
                      if (selected.includes(option.value)) {
                        let withoutClose = selected.filter(
                          v => v !== option.value
                        )
                        setSelected([...withoutClose])
                        onChange([...withoutClose])
                      } else return
                      // setSelected(option)
                      // console.log("sss")
                      // onChange(option.value    )
                    }}
                    showClose
                    // className={classNames(
                    //   selected.includes(option.value)
                    //     ? "font-medium text-gray-900  bg-[#009D4F];"
                    //     : "text-gray-500",
                    //   "block px-4 py-2 text-sm bg-green-500"
                    // )}
                    active={selected.includes(option.value)}
                  >
                    {option.label}
                  </Tag>
                ))}
            </Tags>
          </div>
        )}

        <label
          for={name}
          className={`absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10`}
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          {label}
        </label>
      </div>
    </div>
  )
}
