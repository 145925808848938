const { promisify } = require("util");

const { setImmediate } = require("timers");

const setImmediateP = promisify(setImmediate);

async function mapItem(mapFn, currentValue, index, array) {
  try {
    await setImmediateP();
    return {
      status: "fulfilled",
      value: await mapFn(currentValue, index, array),
    };
  } catch (reason) {
    return {
      status: "rejected",
      reason,
    };
  }
}

async function worker(id, gen, mapFn, result) {
  for (let [currentValue, index, array] of gen) {
    result[index] = await mapItem(mapFn, currentValue, index, array);
  }
}

function* arrayGenerator(array) {
  for (let index = 0; index < array.length; index++) {
    const currentValue = array[index];
    yield [currentValue, index, array];
  }
}

export default async function mapSingle(arr, mapFn, limit = arr.length) {
  const result = [];

  if (arr.length === 0) {
    return result;
  }

  const gen = arrayGenerator(arr);

  limit = Math.min(limit, arr.length);

  const workers = new Array(limit);
  for (let i = 0; i < limit; i++) {
    workers.push(worker(i, gen, mapFn, result));
  }

  await Promise.all(workers);

  return result;
}
