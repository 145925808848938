/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { HeartIcon } from "@heroicons/react/solid"
import Heart from "../../assets/icons/heart.svg"
import AddToWishlist from "../Elements/AddToWishlist"
import Location from "../../assets/icons/pin.svg"
import Store from "../../assets/icons/shop-alt.svg"
import CircleAvatar from "../Elements/CircleAvatar"
import MetaDownload from "../Elements/MetaDownload"
import MetaDelivery from "../Elements/MetaDelivery"
import MetaMini from "../Elements/MetaMini"
import CartContext from "../../context/Cart/CartContext"
import Button from "../Elements/Button"
import LazyImage from "../LazyImage"
import Skeleton from "../Skeleton"
import NotificationContext from "../../context/Notification/NotificationContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import StateContext from "../../context/state/StateContext"
import {
  addmyBookmark,
  getSingleAssetQty,
  getAssetSubcriptionInventory,
  subcribeAssetInventory,
} from "../../helpers/DrupalHelper"
import { parseHTML } from "../../helpers/GeneralHelper"
import DataContext from "../../context/Data/DataContext"

function ProductListCard(props) {
  const { showCartModal } = useContext(CartContext)
  const { categoriesAsOptions, myBookmarksID } = useContext(DataContext).data
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const [inventorySubscribed, setInventorySubscribed] = useState(null)

  let coverImg = ""
  if (props.assets) {
    coverImg =
      props?.cover && Array.isArray(props?.cover) && props.cover[0]
        ? props.cover[0]
        : props.cover
  } else {
    coverImg =
      props?.img && Array.isArray(props?.img) && props.img[0]
        ? props.img[0]
        : ""
  }

  const callInventorySuscribe = async () => {
    let iData = await getAssetSubcriptionInventory(token, props.id)
    if (iData === "Not subscribed") {
      // setInventorySubscribed(false)

      let body = {
        user: currentUserData.i,
        email: currentUserData.email,
        asset: props.id,
      }
      let res = await subcribeAssetInventory(token, body)

      if (res.status === 200) {
        toggleNotification({
          content: `${props.title} Inventory Successfully Subscribed`,
        })
        setInventorySubscribed(true)
      } else {
        toggleNotification({
          content: `Fail to subscribe ${props.title} inventory `,
          error: true,
        })
      }
    } else setInventorySubscribed(true)
  }

  if (props.loading) {
    return (
      <div className="flex relative bg-white group min-w-full mb-[30px] md:flex-row items-center">
        <div
          className="rounded-[8px] h-[300px] w-[300px] overflow-hidden border border-[#EBEBEB]"
          css={css`
            &&& {
              min-height: 300px;
              min-width: 300px;
            }
          `}
        >
          <Skeleton dark />

          {props.assets && (
            <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[85px] group-hover:-translate-y-[80px] px-[30px]">
              <Skeleton dark />
            </div>
          )}
          {/*<button
          className={`absolute m-2 w-[97%]  top-[200px]!important hidden  h-12 justify-center items-center  text-white text-center text-xs px-3 bg-red-500 font-bold rounded group-hover:block`}
        >
          Add to Cart
        </button>*/}
        </div>
        <div className="grow pl-[30px]">
          <div className=" flex justify-between mb-[20px]">
            <CircleAvatar border sm />
            <div className="ml-auto">
              <Skeleton dark />
            </div>
            {/*<div className=" w-7 h-7 p-1 text-center items-center justify-center  rounded-full border-gray-600 hover:border">
            <Heart className="text-black" />
          </div>*/}
          </div>
          <h5 className="mb-2 text-med-20 group-hover:underline">
            <Skeleton dark />
          </h5>
          {/*<p className="text-reg-14 mb-1">
          <span className="line-through mr-2">$444</span>{" "}
          <span className="text-[#222]">$333</span>
        </p>*/}

          <p className="text-reg-15 max-w-[70%]">
            <Skeleton dark />
          </p>

          <div className="mt-3">
            <Skeleton dark />
          </div>
        </div>
      </div>
    )
  }
  let linkUrl = ""
  if (props.assets) {
    if (props.assetsGroup) {
      if (props.bundle === "default") linkUrl = `/asset/${props.id}`
      else linkUrl = `/assets/${props.id}`
    } else {
      linkUrl = `/asset/${props.id}`
    }
  } else {
    linkUrl = `/product/${props.id}`
  }
  return (
    <div className="flex relative bg-white group min-w-full mb-[30px] md:flex-row items-center">
      <div
        className="rounded-[8px] h-[300px] w-[300px] overflow-hidden border border-[#EBEBEB]"
        css={css`
          &&& {
            min-height: 300px;
            min-width: 300px;
          }
        `}
      >
        <Link to={linkUrl}>
          {coverImg !== null &&
          (coverImg.includes(`.png`) ||
            coverImg.includes(`.jpg`) ||
            coverImg.includes(`.webp`) ||
            coverImg.includes(`.jpeg`)) ? (
            <LazyImage
              src={coverImg}
              alt={props.title}
              className="w-full h-full object-center object-cover bg-[#EBEBEB]"
            />
          ) : (
            <Skeleton dark />
          )}
        </Link>
        {props.assets &&
          !props.assetsGroup &&
          props.type === "physical" &&
          props.status !== "out-of-stock" && (
            <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[85px] group-hover:-translate-y-[80px] px-[30px]">
              <Button
                full
                red
                hoverWhite
                onClick={() => showCartModal(props.nid)}
              >
                Add to Cart
              </Button>
            </div>
          )}
        {props.assets &&
          !props.assetsGroup &&
          props.type === "physical" &&
          props.status === "out-of-stock" && (
            <div className="opacity-0 group-hover:opacity-100 transition-all	-translate-y-[85px] group-hover:-translate-y-[80px] px-[30px]">
              <Button
                full
                red
                hoverWhite
                onClick={() => callInventorySuscribe()}
              >
                Notify Me
              </Button>
            </div>
          )}

        {/*<button
          className={`absolute m-2 w-[97%]  top-[200px]!important hidden  h-12 justify-center items-center  text-white text-center text-xs px-3 bg-red-500 font-bold rounded group-hover:block`}
        >
          Add to Cart
        </button>*/}
      </div>
      <div className="grow pl-[30px]">
        <div className=" flex justify-between mb-[20px]">
          <CircleAvatar
            alt={props.brand_name}
            image={props.brand_logo}
            border
            sm
          />
          <div className="ml-auto">
            <AddToWishlist
              noText
              title={props.title}
              id={props.id}
              name={props.assets ? "asset" : "product"}
              isListed={
                myBookmarksID[props.assets ? "asset" : "product"]?.includes(
                  props.id
                )
                  ? true
                  : false
              }
            />
          </div>
          {/*<div className=" w-7 h-7 p-1 text-center items-center justify-center  rounded-full border-gray-600 hover:border">
            <Heart className="text-black" />
          </div>*/}
        </div>
        <Link to={linkUrl}>
          <h5 className="mb-2 text-med-20 group-hover:underline">
            {props.title}
          </h5>
          {/*<p className="text-reg-14 mb-1">
          <span className="line-through mr-2">$444</span>{" "}
          <span className="text-[#222]">$333</span>
        </p>*/}
          {"description" in props === true && props.description !== "" && (
            <p className="text-reg-15 max-w-[70%]">
              {parseHTML(
                props.description.length > 5
                  ? `${props.description.substring(0, 30)}...`
                  : props.description
              )}
            </p>
          )}
        </Link>
        <div className="mt-3">
          {props.type && props.type === "digital" && <MetaDownload />}
          {props.type && props.type === "physical" && <MetaDelivery />}
        </div>
        <div className="py-[30px]">
          <>
            {props.assetsItems && (
              <MetaMini title={`Includes ${props.assetsItems.length} assets`} />
            )}
            {/*<p className="text-reg-14">
            <span className="line-through mr-2">$444</span>{" "}
            <span className="text-[#222]">$333</span>
          </p>*/}
          </>
        </div>
      </div>
    </div>
  )
}

export default ProductListCard
