/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { red, green, blue, redLight, greenLight, blueLight } from "../Styles"

function MetaUpdated(props) {
  return (
    <span
      className={`text-reg-14 ${
        typeof props.className !== "undefined" && props.className
      }`}
      css={css`
        &&& {
          color: ${props.black ? `#717171`: red};
          line-height: ${props.lineHeight ? props.lineHeight : `inherit`};
        }
      `}
    >
      {props.text}
    </span>
  )
}

export default MetaUpdated
