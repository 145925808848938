export const green = `#009D4F`
export const greenLight = `#e0f0e5`
export const red = `#E43D30`
export const redLight = `#fce3e1`
export const blue = `#0033A1`
export const blueLight = `#e6eaf6`
export const yellow = `#DDAA1F`
export const yellowLight = `#FFF7E2`

export const palette = [
  green,
  greenLight,
  red,
  redLight,
  blue,
  blueLight,
  yellow,
  yellowLight,
]
export const paletteStrong = [
  green,red,blue,yellow
]
