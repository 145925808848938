/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import LazyImage from "../LazyImage"
import Skeleton from "../Skeleton"
function ImageCardSmall(props) {
  const { size, image, alt } = props
  return (
    <div
      className="flex items-center justify-center"
      css={css`
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        width: ${props.size ? props.size + `px` : `80px`};
        min-width: ${props.size ? props.size + `px` : `80px`};
        height: ${props.size ? props.size + `px` : `80px`};
        min-height: ${props.size ? props.size + `px` : `80px`};
      `}
    >
      {props.image &&
      (props.image.toLowerCase().includes(".png") ||
        props.image.toLowerCase().includes(".jpg") ||
        props.image.toLowerCase().includes("blob") ||
        props.image.toLowerCase().includes(".webp") ||
        props.image.toLowerCase().includes(".jpeg")) ? (
        <LazyImage src={props.image} alt={props.alt || ""} />
      ) : (
        <Skeleton dark />
      )}
    </div>
  )
}

export default ImageCardSmall
