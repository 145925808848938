import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FloatingInput from "../Elements/Form/FloatingInput"
import Button from "../Elements/Button"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import SectionCard from "../Cards/Dashboard/SectionCard"
import {
  addUserStore,
  getStoreSingle,
  submitWebform,
  updateStoreSingle,
  myEntitySuggestions,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import Textarea from "../Elements/Textarea"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import NotificationContext from "../../context/Notification/NotificationContext"
import DataContext from "../../context/Data/DataContext"
import {
  validateEmail,
  validateUrl,
  // validatePhone,
  stringIncludesCanadianTelephone,
} from "../../utils/messageValidation"
function ContactBrandForm({ data, onSave, id, share, title }) {
  const { state } = useContext(StateContext)
  const { regionsAsOptions } = useContext(DataContext).data
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = state
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()
  const onSubmit = async data => {
    const runF = async () => {
      let body = { ...data, brand: `${id}`, webform_id: "contact_us" }
      if (share) {
        body = { ...data, webform_id: "general_message" }
      }
      console.log(body)

      let res = await submitWebform(token, body)
      if (res.status === 200) {
        toggleNotification({
          content: `Successfully submitted!`,
        })
        onSave(false)
      } else {
        toggleNotification({
          content: `Failed to submit`,
          error: true,
        })
        onSave(false)
      }
    }
    if (data.body) {
      let myString = data.body
      let strArray = myString.split(" ")
      let found = strArray.some(
        str =>
          /*validatePhone(str) ||*/ stringIncludesCanadianTelephone(str) ||
          validateEmail(str) ||
          validateUrl(str)
        // str => validatePhone(str) || validateEmail(str) || validateUrl(str)
      )
      console.log(found)
      if (found) {
        toggleNotification({
          content: `Oops, please don't share email addresses, weblinks, or phone numbers.`,
          error: true,
        })
      } else {
        await runF()
      }
    } else {
      await runF()
    }
  }
  useEffect(() => {
    register("store", {
      required: `This field is required`,
    })
    if (share) {
      setValue("title", `Check out ${title}!`)
      setValue("body", `Check out ${title} - ${window.location.href} on O2O!`)
    }
  }, [])
  return (
    // <SectionCard
    // title={edit ? "Edit Store" : "Create New Store"}
    // id="create">
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <FloatingInput
        label="Company Name"
        hookForm
        name="company_name"
        error={errors["company_name"]}
        register={register}
        required
      />

       <div className="grid xl:grid-cols-2 xl:gap-6">
        <FloatingInput
          label="First name"
          error={errors["first_name"]}
          name="first_name"
          hookForm
          register={register}
        />
        <FloatingInput
          label="Last name"
          error={errors["last_name"]}
          name="last_name"
          hookForm
          register={register}
        />
      </div> */}
      {/* <div className="grid xl:grid-cols-2 xl:gap-6"> */}
      <FloatingInput
        label="Subject"
        name="title"
        hookForm
        register={register}
        error={errors["title"]}
        required
      />
      {/*<div className="grid xl:grid-cols-2 xl:gap-6">*/}
      {/*<FloatingInput
          label="Email"
          error={errors["email"]}
          name="email"
          type="email"
          hookForm
          register={register}
        />*/}
      {/*<FloatingSelect
          label={"Province"}
          name="province"
          hookForm
          error={errors["province"]}
          control={control}
          options={regionsAsOptions}
        />*/}
      <FloatingSelect
        label={"Store"}
        type={"myStoresAsOptions"}
        name="store"
        hookForm
        error={errors["store"]}
        control={control}
        // options={regionsAsOptions}
      />
      {/*</div>*/}
      <FloatingTextarea
        label="Message"
        error={errors["body"]}
        name="body"
        hookForm
        register={register}
      />
      {/* </div> */}
      <Button black type="submit" full>
        Contact Us
      </Button>
    </form>
    // </SectionCard>
  )
}

export default ContactBrandForm