/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { Fragment, useState, useEffect } from "react"
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Tab,
  Transition,
} from "@headlessui/react"
import {
  ChevronDownIcon,
  FilterIcon,
  MinusSmIcon,
  PlusSmIcon,
  ViewGridIcon,
  ViewListIcon,
} from "@heroicons/react/solid"

import Select from "react-select"

import {
  selectStyles,
  selectStylesSmall,
  // SelectContainer,
  DropdownIndicator,
  ClearIndicator,
} from "../../styled/Select"
import { classNames } from "../../utils"
const SelectN = ({
  onChange,
  type,
  label,
  name,
  hookForm,
  register,
  className,
  options,
  required,
  control,
  isMulti,
  isSearchable,
  defaultValue,
  sm,
  error,
  value,
}) => {
  const fontStyles = `font-family: "Jost";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #717171;`

  // console.log(value)
  return (
    <div className={` relative mb-6 w-full group ${className && className}`}>
      <Select
        options={options}
        // menuIsOpen={true}
        styles={sm ? selectStylesSmall : selectStyles}
        components={{
          DropdownIndicator: DropdownIndicator,
          ClearIndicator,
        }}
        // {...register(inputName)}
        onChange={onChange}
        isClearable={required ? false : true}
        isMulti={isMulti ? true : false}
        isSearchable={isSearchable}
        // menuIsOpen={true}
        // menuIsOpen={true}
        defaultValue={defaultValue}
      />

      {/* {!sm && (
          <label
            for={name}
            className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
            css={css`
              ${fontStyles}
            `}
          >
            {label}
          </label>
        )} */}
    </div>
  )
}

function FilterSelect2({
  menuClassNames,
  className,
  // fromRight,
  last,
  options,
  onChange,
  label,
  value,
  defaultValue,
}) {
  console.log(defaultValue)
  const [selected, setSelected] = useState(
    defaultValue ? defaultValue : options[0]
  )
  // useEffect(() => {
  //   onChange(selected.value)
  // }, [selected])
  useEffect(() => {
    let nValue = value
    // console.log(nValue, selected.value)
    if (nValue && nValue.hasOwnProperty("field_product_price"))
      delete nValue.field_product_price
    if (nValue && JSON.stringify(nValue) !== JSON.stringify(selected.value)) {
      let foundOpts = options.filter(v =>
        typeof nValue === "string"
          ? v.value === parseInt(nValue)
          : JSON.stringify(v.value) === JSON.stringify(nValue)
      )[0]
      // console.log(options, value, foundOpts)
      if (foundOpts) setSelected(foundOpts)
    }
  }, [value])
  return (
    <Menu
      as="div"
      className={
        menuClassNames
          ? `${menuClassNames} ${className && className}`
          : `relative inline-block text-left ${
              !last && `border-r border-[#ebebeb]`
            } px-6 ${className && className}`
      }
      onChange={v => console.log(v)}
    >
      <div>
        <Menu.Button className="group inline-flex justify-center items-center text-reg-15">
          <span className="text-[#222] group-hover:text-[#717171]">
            {label && <>{label} </>}
            <span className="text-[#999]">
              {selected ? selected.label : options[0].label}
            </span>
          </span>
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          defaultValue={defaultValue}
          className="origin-top-right z-[10] absolute right-0 mt-2 w-40 rounded-[8px] shadow-[0px_6px_50px_rgba(34,34,34,.05] bg-white ring-1 ring-[#222]"
          css={css`
            &&& {
              top: 100%;
              /* background-color: hsl(0, 0%, 100%); */
              /* border-radius: 8px; */
              /* box-shadow: 0px 6px 20px rgb(34 34 34 / 5%); */
              margin-bottom: 8px;
              /* margin-top: 8px; */
              /* position: absolute; */
              width: 100%;
              /* z-index: 1; */
              /* box-sizing: border-box; */
              min-width: 100px;
              max-width: 300px;
              /* text-align: left; */
              /* border: 1px solid #222; */
              /* left: fromRight ? "unset" : 0};
            right: fromRight ? 0 : 0}; */
              right: 0;
              left: unset;
              &:after,
              &:before {
                bottom: 100%;
                right: 15px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }
              &:after {
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: #222;
                border-width: 4px;
                margin-left: -4px;
              }
              &:before {
                border-color: rgba(235, 235, 235, 0);
                border-bottom-color: #222;
                border-width: 5px;
                margin-left: -5px;
              }
            }
          `}
        >
          <div
            className=""
            css={css`
              max-height: 600px;
              overflow-y: auto;
              /* padding-bottom: 4px;
              padding-top: 4px; */
              position: relative;
              -webkit-overflow-scrolling: touch;
              box-sizing: border-box;
              padding: 5px 20px;
              cursor: pointer;
            `}
          >
            {options.map((option, o) => (
              <Menu.Item key={option.label}>
                {({ active }) => (
                  <button
                    // href={option.href}
                    onClick={() => {
                      setSelected(option)
                      onChange(option.value)
                    }}
                    className={classNames(
                      option.label === selected.label ? "text-[#222]" : "",
                      "hover:text-[#222]"
                    )}
                    css={css`
                      background-color: transparent;
                      color: #717171;
                      cursor: pointer;
                      display: block;
                      font-size: 15px;
                      padding: 14px 0;
                      width: 100%;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                      box-sizing: border-box;
                      font-weight: 400;
                      letter-spacing: normal;
                      border-bottom: ${o === options.length - 1
                        ? `0`
                        : `1px solid #ebebeb`};
                      /* border-radius: 4px; */
                    `}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default function FilterSelect(props) {
  if (props.sidebar) {
    return (
      <div className="mb-6">
        <h3 className="text-semibold-18 mb-[30px]">
          {props.customTitle ? (
            <>{props.customTitle}</>
          ) : (
            <>Filter By {props.title}</>
          )}
        </h3>
        <SelectN {...props} />
      </div>
    )
  }
  if (props.RSelect) return <SelectN {...props} />
  else return <FilterSelect2 {...props} />
}
