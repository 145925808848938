/** @jsx jsx */
import { css, jsx } from "@emotion/react"

import { connectPagination } from "react-instantsearch-dom"
import React from "react"
import { Link } from "gatsby"
import Next from "../../assets/icons/next.svg"
import Prev from "../../assets/icons/prev.svg"
import { red } from "../Styles"
import { Paginator } from "../Pagination"

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <div className="px-4 py-3 flex items-center justify-center  sm:px-6">
    <div className="flex items-center justify-center">
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md space-x-3"
          aria-label="Pagination"
        >
          {currentRefinement > 1 && (
            <Link
              to="#"
              className="inline-flex items-center p-2"
              onClick={event => {
                event.preventDefault()
                refine(currentRefinement - 1)
              }}
            >
              <span className="sr-only">Previous</span>
              <Prev
                className="h-3 w-3"
                css={css`
                  &&& {
                    &:hover {
                      path {
                        fill: ${red};
                      }
                    }
                  }
                `}
              />
            </Link>
          )}

          <Paginator
            page={currentRefinement}
            setPage={e => {
              refine(e)
            }}
            totalPages={nbPages}
            notLink
          />
          {currentRefinement < nbPages && (
            <Link
              to="#"
              onClick={event => {
                event.preventDefault()
                refine(currentRefinement + 1)
              }}
              className="inline-flex items-center p-2"
              css={css`
                &&& {
                  &:hover {
                    path {
                      fill: ${red};
                    }
                  }
                }
              `}
            >
              <span className="sr-only">Next</span>
              <Next className="h-3 w-3" />
            </Link>
          )}
        </nav>
      </div>
    </div>
  </div>
)

const CustomPagination = connectPagination(Pagination)
export default CustomPagination
