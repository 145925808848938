/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import styled from "@emotion/styled"
import React, { useState, useContext, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Search from "./Search"
import Icons from "./Icons"
import User from "./User"
import Logo from "../../../assets/logos/o2o-logo-r.png"
import NavIcon from "../../../assets/icons/nav-icon.svg"
import { Fragment } from "react"
import { Dialog, Menu, Popover, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import LazyImage from "../../LazyImage"
import IconClose from "../../Elements/IconClose"
import Slideout from "../../Elements/Slideout"
import SocialButton from "../../Elements/SocialButton"

import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { SubHeaderManager, SubHeaderEditor } from "./SubHeader"

const social = [
  {
    name: "Instagram",
    href: "https://instagram.com/useo2o",
    icon: faInstagram,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/useo2o",
    icon: faTwitter,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/useo2o",
    icon: faLinkedin,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Nav = styled.div`
  visibility: ${props => (props.show ? "visible" : "hidden")};
  transition: ${props => (props.pos ? "all .25s ease" : "all .25s ease")};
  transform: ${props => (props.show ? "none" : "translate(0, -100%)")};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
`

function Header({ floating }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const [hideNavbarOnScroll, setHideNavbarOnScroll] = useState(true)
  const [isTop, setIsTop] = useState(true)
  const { navigation, authentication, userType } = useContext(
    AuthenticationContext
  )
  // console.log({ userType })
  const allMenuItems = {
    my_assets: { title: "My Assets", path: "/dashboard/my-assets" },
    my_products: { title: "My Products", path: "/dashboard/my-products" },
    my_brands: { title: "My Brands", path: "/dashboard/my-organization" },
    // manage_files: { title: "My Media", path: "/dashboard/manage-files" },
    my_orders: { title: "My Orders", path: "/dashboard/my-orders" },
    our_orders: { title: "My Orders", path: "/dashboard/my-orders" },
    all_assets: { title: "All Assets", path: "/assets" },
    all_products: { title: "All Products", path: "/products" },
    all_brands: { title: "All Brands", path: "/brands" },
    all_addresses: { title: "My Addresses", path: "/dashboard/address-book" },
    // all_inventory: { title: "My Inventory", path: "/dashboard/inventory" },
  }

  let setMenuItems = []

  if (userType.retailerManager)
    setMenuItems = [
      "all_assets",
      "all_products",
      "all_brands",
      "our_orders",
      "all_addresses",
    ]
  if (userType.retailerEditor)
    setMenuItems = ["all_assets", "all_products", "all_brands", "my_orders"]
  if (userType.brandManager)
    setMenuItems = [
      "my_assets",
      "my_products",
      "my_brands",
      // "all_inventory",
      "our_orders",
    ]
  if (userType.brandEditor)
    setMenuItems = [
      "all_assets",
      "all_products",
      "all_brands",
      "all_addresses",
      "my_orders",
    ]

  const { currentUserData } = authentication
  const [sidebarOpen, setSidebarOpen] = useState(false)
  let menuItemClasses = `!text-[#222] hover:!text-[#e43d30] hover`
  if (floating && !isMobile) menuItemClasses = `!text-white hover hover-white`
  if (isMobile) menuItemClasses = menuItemClasses + ` !block py-4 text-med-18`
  if (!isMobile) menuItemClasses = menuItemClasses + ` text-med-15`

  const location = useLocation()

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -50
      if (isShow !== hideNavbarOnScroll) setHideNavbarOnScroll(isShow)
      if (currPos.y < 50) {
        setIsTop(true)
      } else {
        setIsTop(false)
      }
    },
    [hideNavbarOnScroll],
    null,
    false,
    100
  )

  const NavSurround = props => {
    // alert(!isMobile)
    if (!isMobile) {
      return (
        <Nav show={hideNavbarOnScroll} pos={isTop}>
          {props.children}
        </Nav>
      )
    }
    return props.children
  }
  return (
    <>
      <NavSurround>
        <Popover
          className={`${
            floating
              ? ` bg-[transparent] border-[rgba(255,255,255,.1)] z-[1]`
              : ` bg-[rgba(255,255,255,.95)] backdrop-blur-[20px] border-[#EBEBEB]`
          } border-b h-[90px] w-full o2o__header`}
        >
          <div className="mx-auto px-[15px] md:px-[30px] h-full">
            <div className="flex items-center h-full w-full">
              <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10 w-full">
                <div className="flex justify-start  --rm-lg:w-0 --rm-lg:flex-1">
                  <Link
                    to={
                      userType.brandManager === true
                        ? // ||userType.brandEditor === true
                          "/dashboard/my-assets"
                        : "/assets"
                    }
                    className="h-[36px] w-[44px]"
                    css={css`
                      background-image: url(${Logo});
                      background-size: contain;
                      background-repeat: no-repeat;
                      filter: ${floating ? "brightness(0) invert(1)" : "none"};
                    `}
                  >
                    <span className="sr-only">O2O</span>
                  </Link>
                </div>
                <div className="flex items-center -mr-2 -my-2 md:hidden">
                  <button
                    type="button"
                    className="focus:outline-none md:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <NavIcon
                      className="w-18 h-18"
                      css={css`
                        &&& {
                          rect {
                            fill: #222;
                          }
                        }
                      `}
                    />
                  </button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  {setMenuItems.map((item, k) => {
                    const { path, title } = allMenuItems[item]
                    return (
                      <Link
                        to={path}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === path && "!text-[#e43d30] hover"
                        )}
                      >
                        {title}
                      </Link>
                    )
                  })}
                  {/* {userType.brandManager === true ||
                    userType.brandEditor === true ? (
                    <>
                      <Link
                        to={"/dashboard/my-assets"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/dashboard/my-assets" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        My Assets
                      </Link>
                      <Link
                        to={"/dashboard/my-products"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/dashboard/my-products" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        My Products
                      </Link>
                      <Link
                        to={"/dashboard/my-organization"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/dashboard/my-organization" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        My Brands
                      </Link>
                      <Link
                        to={"/dashboard/manage-files"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/dashboard/manage-files" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        My Media
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={"/assets"}
                        className={classNames(
                          menuItemClasses,
                          (location.pathname === "/" ||
                            location.pathname === "/assets") &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        All Assets
                      </Link>
                      <Link
                        to={"/products"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/products" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        All Products
                      </Link>
                      <Link
                        to={"/brands"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/brands" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        All Brands
                      </Link>
                      <Link
                        to={"/dashboard/my-orders"}
                        className={classNames(
                          menuItemClasses,
                          location.pathname === "/dashboard/my-orders" &&
                          "!text-[#e43d30] hover"
                        )}
                      >
                        My Orders
                      </Link>
                    </>
                  )} */}
                </Popover.Group>
              </div>
              {!isMobile && (
                <div
                  className={`flex ml-auto items-center ${
                    isMobile
                      ? `fixed bottom-0  z-[9] left-0 -ml-[1px] -mr-[1px] -mb-[1px] right-0 border border-[#EBEBEB] rounded-[15px_15px_0_0] overflow-hidden bg-white h-[70px] justify-around`
                      : `space-x-6`
                  }`}
                >
                  {/* {userType.brandManager !== true && ( */}
                  <Search floating={floating} isTop={isTop} />
                  {/* )} */}
                  <Icons floating={floating} />
                  <User floating={floating} />
                </div>
              )}
            </div>
          </div>
        </Popover>

        {userType.brandManager === true && <SubHeaderManager />}
        {userType.brandEditor === true && <SubHeaderEditor />}

        {isMobile && (
          <div
            className={`flex ml-auto items-center ${
              isMobile
                ? `fixed bottom-0  z-[9] left-0 -ml-[1px] -mr-[1px] -mb-[1px] right-0 border border-[#EBEBEB] rounded-[15px_15px_0_0] overflow-hidden bg-white h-[70px] justify-around`
                : `space-x-6`
            }`}
          >
            {/* {(userType.retailerEditor === true ||
              userType.retailerManager === true ||
              userType.brandManager === true) && ( */}
            <Search floating={floating} isTop={isTop} />
            {/* )} */}
            <Icons floating={floating} />
            <User floating={floating} />
          </div>
        )}
      </NavSurround>
      <Slideout
        notUnmount
        title="Menu"
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        onClose={setSidebarOpen}
        isMobile={isMobile}
        fromPage={false}
      >
        <div className="relative h-[calc(100vh-150px)]">
          <div className="">
            {setMenuItems.map((item, k) => {
              const { path, title } = allMenuItems[item]
              return (
                <Link
                  to={path}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === path && "!text-[#e43d30] hover"
                  )}
                >
                  {title}
                </Link>
              )
            })}
            {/* {userType.brandManager === true || userType.brandEditor === true ? (
              <>
                <Link
                  to={"/dashboard/my-assets"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/dashboard/my-assets" &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  My Assets
                </Link>
                <Link
                  to={"/dashboard/my-products"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/dashboard/my-products" &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  My Products
                </Link>
                <Link
                  to={"/dashboard/my-organization"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/dashboard/my-organization" &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  My Brands
                </Link>
                <Link
                  to={"/dashboard/manage-files"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/dashboard/manage-files" &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  Manage Files
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={"/assets"}
                  className={classNames(
                    menuItemClasses,
                    (location.pathname === "/" ||
                      location.pathname === "/assets") &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  All Assets
                </Link>
                <Link
                  to={"/products"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/products" && "!text-[#e43d30] hover"
                  )}
                >
                  All Products
                </Link>
                <Link
                  to={"/brands"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/brands" && "!text-[#e43d30] hover"
                  )}
                >
                  All Brands
                </Link>
                <Link
                  to={"/dashboard/my-orders"}
                  className={classNames(
                    menuItemClasses,
                    location.pathname === "/dashboard/my-orders" &&
                    "!text-[#e43d30] hover"
                  )}
                >
                  My Orders
                </Link>
              </>
            )} */}
            <div className="absolute bottom-[0]">
              <div className="flex space-x-4 flex-wrap">
                {social.map(item => (
                  <a href={item.href} target="_blank">
                    <SocialButton icon={item.icon} lg />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Slideout>
    </>
  )
}

export default Header
