import React from "react"

const defaultState = {
  options: {},
  items: [],
  handleRemoveClick: () => {},
  cart: [],
  openCartDropdown: false,
}

const CartContext = React.createContext(defaultState)
export default CartContext
