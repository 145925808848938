/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { Link, navigate } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
// import "react-phone-number-input/style.css"
// import PhoneInput from "react-phone-input-2"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Controller, useForm, useWatch } from "react-hook-form"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import FloatingCheckboxs from "../components/Elements/Form/FloatingCheckboxs"
import FloatingSelect from "../components/Elements/Form/FloatingSelect"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import {
  checkEmailExist,
  getRetailStoresLocation,
  getUser,
  makeLogin,
  registerRetailer,
  addMySingleNewForm,
  getAllStores,
  getAllLocation,
  getAllCompanies,
  registerUserDetails,
  getRetailStoresByCompany,
  registerCompanyDetails,
} from "../helpers/DrupalHelper"
import DatePicker from "react-datepicker"

import NotificationContext from "../context/Notification/NotificationContext"

import "react-datepicker/dist/react-datepicker.css"
import Button from "../components/Elements/Button"
import ButtonLite from "../components/Elements/ButtonLite"
import FloatingDatePicker from "../components/Elements/Form/FloatingDatePicker"

import FloatingRadios from "../components/Elements/Form/FloatingRadios"
import AsyncFloatingSelect from "../components/Elements/Form/AsyncFloatingSelect"
import Logo from "../assets/logos/o2o-logo-r.png"
import GeoLocations from "../components/Forms/DynamicForm/FormTypes/GeoLocations"
import {
  ArrowLeftIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid"
import { validateEmail, validatePhone } from "../utils/messageValidation"
import CustomLocationForm from "../components/Forms/CustomLocationForm"
import FloatingTelephone from "../components/Elements/Form/FloatingTelephone"
import DataContext from "../context/Data/DataContext"
import Skeleton from "../components/Skeleton"
import AccountStatus from "./account-status"
import AccountStatusInner from "../components/AccountStatusInner"
import FloatingRadiosInput from "../components/Elements/Form/FloatingRadiosInput"
const Registration = props => {
  const { partialRegistration } = props

  const {
    unregister,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
    control,
    getValues,
    setError,
  } = useForm({ mode: "onChange" })
  const { state, dispatch } = useContext(StateContext)
  const { authentication, dispatchAuthentication } = useContext(
    AuthenticationContext
  )

  const { isAuthenticated } = authentication
  const addressType = [
    { label: "Office", value: "office" },
    { label: "Locker", value: "locker" },
    { label: "Home", value: "home" },
    { label: "Other", value: "other" },
  ]
  const [customActive, setCustomActive] = useState(false)
  const [customCompanyActive, setCustomCompanyActive] = useState(false)
  const [companyOptions, setCompanyOptions] = useState(null)
  const [storeCustomLoc, setStoreCustomLoc] = useState(false)
  const [showPersonal, setShowPersonal] = useState(true)
  const [showCompany, setShowCompany] = useState(false)
  const [showLoc, setShowLoc] = useState(false)
  const [showAssociation, setShowAssociation] = useState(false)
  const [showCertification, setShowCertification] = useState(false)
  const [showLicense, setShowLicense] = useState(false)
  const [showAdmin, setShowAdmin] = useState(false)
  const [geoLoc, setGeoLoc] = useState(false)
  const [emailExist, setEmailExist] = useState(null)
  const [userId, setUserId] = useState()
  const [storeLocations, setStoreLocation] = useState(null)
  const [allUserLocations, setAllUserLocations] = useState(null)
  const [allCompanies, setAllCompanies] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isPreApproved, setIsPreApproved] = useState(false)
  const referralCode = new URLSearchParams(window.location.search).get(
    "referral-code"
  )
  const [referralBrand, setReferralBrand] = useState(null)
  const [companiesLoading, setCompaniesLoading] = useState(true)
  console.log(errors["referral_source"])
  console.log({ errors })
  const { toggleNotification, notificationIsVisible } =
    useContext(NotificationContext)
  const [notMatch, setNotMatch] = useState(false)
  const [showThanks, setShowThanks] = useState(false)

  useEffect(() => {
    if (partialRegistration === true) {
      setShowPersonal(false)
      setShowCompany(true)
      loadStores()
    }
  }, [])

  const onUserDetailsSubmit = async v => {
    console.log(referralBrand)
    setIsLoading(true)
    let data = getValues()
    setNotMatch(false)
    let body = data
    // const referral_source_info = {
    //   referral_source: data.referral_source,
    //   referral_source_extra_field: data.referral_source_extra_field,
    // }
    // console.log(referral_source_info)
    // body.referral_source_info = referral_source_info
    delete body.password_repeat
    const buffer = Buffer.from(body.password, "utf-8")
    const base64Password = buffer.toString("base64")
    body.password = base64Password
    if (!body.referral_code) {
      delete body.referral_code
    }
    if (referralBrand !== null) {
      body.referral_source_extra_field = referralBrand.label
    }
    const res = await registerUserDetails(state.token, body)
    const uid = res.uid[0].value
    if (uid) {
      setUserId(uid)
      if (isValid) {
        setShowPersonal(false)
        setShowCompany(true)
      }
      loadStores()
    }
    setIsLoading(false)
    reset()
    console.log({ body, res })
    // let locationBody = data?.customBodyLocation?.body
    // if (locationBody) {
    //   let res = await addMySingleNewForm(
    //     state.token,
    //     "location",
    //     "default",
    //     locationBody
    //   )
    //   if (res.status === 200) {
    //     let dataL = await res.json()
    //     let id = dataL.id[0].value
    //     // body.json[locationFieldName] = id
    //     body.customBodyLocation = id
    //   }
    // }

    // await registerRetailer(state.token, body).then(async res => {
    //   if (res.status === 200) {
    //     toggleNotification({ content: "Thanks for registering with O2O." })
    //     setEmailAddress(res.json().email)
    //   } else {
    //     toggleNotification({
    //       content: `Your registration was unsuccessful.`,
    //       error: true,
    //     })
    //   }
    // })
  }
  const onCompanyDetailsSubmit = async v => {
    setIsLoading(true)
    let data = getValues()
    let body = data
    let locationBody = data?.customBodyLocation?.body
    body.user_id = userId
    if (body.type) {
      body.account_type = body.type
    }
    if (!body.address_type) {
      body.address_type = "store"
    }
    delete body.type
    if (body.account_type === "brand") {
      delete body.store_id
    }
    if (body.account_type === "retailer") {
      delete body.location_id
    }
    if (locationBody && storeCustomLoc) {
      // let res = await addMySingleNewForm(
      //   state.token,
      //   "location",
      //   "default",
      //   locationBody
      // )
      // if (res.status === 200) {
      //   let dataL = await res.json()
      //   let id = dataL.id[0].value
      //   // body.json[locationFieldName] = id
      //   body.location_id = id
      // }
    }
    if (!storeCustomLoc) {
      delete body.customBodyLocation
    }
    const res = await registerCompanyDetails(state.token, body)
    if (res === "preapproved") {
      setIsPreApproved(true)
    }
    console.log(res)
    setIsLoading(false)
    setShowThanks(true)
    setShowCompany(false)
  }

  useEffect(() => {
    if (!showCompany) {
      register("dob", { required: "Date of birth is required" })
      register("referral_source", { required: "Referral source is required" })
    }
  }, [])
  useEffect(async () => {
    const comp = allCompanies?.filter(
      item => item.value === getValues("company_id")
    )
    if (comp && comp[0]?.type === "retailer") {
      setValue("type", "retailer")
    } else if (comp && comp[0]?.type === "brand") {
      setValue("type", "brand")
    }
    if (comp && comp.length) {
      console.log({ comp })
      const res = await getRetailStoresByCompany(state.token, comp[0]?.value)
      const select_res = res.map(item => {
        const { name, id, ...rest } = item
        return { ...rest, value: id, label: name }
      })
      console.log({ select_res })
      setStoreLocation(select_res)
    }
  }, [watch("company_id")])
  useEffect(() => {
    if (showCompany) {
      console.log(watch("store_id"), storeCustomLoc)
      register("access_level", { required: "Access Level is required" })
      // if (storeCustomLoc === true) {
      //   register("store_id", { required: "Access Level is required" })
      // }

      register("type", {
        validate: value => {
          if (watch("type") === undefined) {
            return "Company Type is required"
          } else return true
        },
      })
      register("company_manual", {
        validate: value => {
          const duplicate_company = allCompanies?.filter(
            obj => obj.label === value
          )

          console.log({ duplicate_company })
          if (duplicate_company?.length) {
            setTimeout(() => {
              setCustomCompanyActive(false)
              unregister("company_manual")
            }, 4000)
            return "Sorry, a company with that name already exists. Instead of creating a new company, please search for and select this company from the list."
          } else return true
        },
      })
    }
  }, [watch()])
  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`

  const fieldValue = watch("company_manual")
  const company_exist = companyOptions?.filter(obj =>
    obj.label.includes(fieldValue)
  ).length
  const company_error = errors.company_manual
  console.log(company_error)
  if (
    authentication.currentUserData.isAuthenticated === true &&
    authentication.currentUserData.account_completion !== "partial"
  ) {
    navigate("/")
    return null
  }

  const onCustomLocationSave = data => {
    console.log({ data })
    let values = Object.values(data).filter(Boolean)
    let strAdd = values.toLocaleString().replaceAll(",", ", ")
    setValue("customBodyLocation", {
      name: "customBodyLocation",
      body: {
        entity_title: strAdd,
        json: {
          field_location_address: { ...data },
          field_location_shortname: strAdd,
        },
      },
    })
  }

  const loadStores = async () => {
    // getAllStores(state.token).then(data => {
    //   const mappedLocations = Object.keys(data).map(key => ({
    //     value: data[key].location,
    //     label: data[key].location,
    //   }))
    //   setValue("store_id", mappedLocations[0].value)
    //   setStoreLocation(mappedLocations)
    // })
    const all_locations = await getAllLocation(state.token)
    let ss = all_locations.map(item => {
      let obj = { value: item.id, label: item.title }
      return obj
    })
    const all_companies = await getAllCompanies(state.token)
    setAllCompanies(all_companies)
    setCompaniesLoading(false)
    setAllUserLocations(ss)
  }

  const BackToLogin = () => {
    // if (isAuthenticated) {

    //   const onBackToLogin = () => {
    //     dispatchAuthentication({ type: "LOGOUT" })
    //   }

    //   return (<Button widthAuto onClick={() => onBackToHomepage()}>
    //     <div className="flex flex-row gap-2">
    //       <ArrowLeftIcon className="w-4 h-4" /> Go Back to Login
    //     </div>
    //   </Button>)
    // }

    return (
      <Link to={"/login"}>
        <Button widthAuto>
          <div className="flex flex-row gap-2">
            <ArrowLeftIcon className="w-4 h-4" /> Go Back to Login
          </div>
        </Button>
      </Link>
    )
  }
  return (
    <>
      <Helmet title={"Register | O2O"} titleTemplate={"%s"}>
        <meta name="description" content={"Login to o2o"} />
      </Helmet>

      <div className="flex bg-[#f9f9f9] min-h-[100vh]">
        <div className="w-full my-[30px] max-w-[600px] m-auto bg-white rounded-[8px] border border-primaryBorder shadow-default py-10 px-16">
          <a className="flex justify-center" href="https://useo2o.com">
            <img src={Logo} className="w-[60px]" />
          </a>
          <h1 className="text-strong-20 red mt-2 mb-3 text-center">
            Registration
          </h1>

          {showThanks === false && (
            <p
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: inline-block;
                padding: 0 10px;
                margin: 7px 0 10px;
                letter-spacing: 0.02em;
                color: #717171;
              `}
            >
              Already have an account?{" "}
              <Link to={"/login"} className="hover red hover-red hover-thin">
                Login
              </Link>
            </p>
          )}
          {showPersonal && (
            <p
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 16px;
                padding: 0 10px;
                margin: 7px 0px 22px;
                letter-spacing: 0.02em;
                color: #717171;
              `}
            >
              Please tell us about yourself ...
            </p>
          )}
          {showCompany && (
            <p
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 16px;
                padding: 0 10px;
                margin: 7px 0px 22px;
                letter-spacing: 0.02em;
                color: #717171;
              `}
            >
              ... and your company.
            </p>
          )}

          {isPreApproved ? (
            <div>
              <h1 className="text-xl mb-4">Thank You!</h1>
              <p className="text-sm mb-4">
                Your registration has been received successfully.
              </p>
              <h1 className="text-xl mb-4">
                Your account has been pre-approved, so you can login right away.
              </h1>
              <p className="text-sm mb-4">
                You should receive an email momentarily notifying you that your
                account has been approved. You can now{" "}
                <Link to="/login" className="text-red underline">
                  login
                </Link>
              </p>
              <h1 className="text-xl mb-4">
                What can I do if I have questions or concerns?{" "}
              </h1>
              <p className="text-sm mb-4">
                If you can't find the answers you need in our knowledge centre,
                you can submit a service ticket with our{" "}
                <a
                  href="https://support.useo2o.com/hc/en-us"
                  className="text-red underline"
                >
                  support team
                </a>
                .
              </p>
              <BackToLogin />
            </div>
          ) : showThanks === true ? (
            <>
              <AccountStatusInner />
            </>
          ) : (
            <>
              <form
                onSubmit={handleSubmit(
                  state.locformsubmit !== null
                    ? state.locformsubmit(onUserDetailsSubmit)
                    : onUserDetailsSubmit
                )}
                onChange={() => {
                  console.log(getValues())
                }}
              >
                {showPersonal && (
                  <>
                    <div>
                      <div className="flex relative">
                        <FloatingInput
                          label="Email Address"
                          hookForm
                          flush
                          name="email"
                          type="email"
                          register={register}
                          error={
                            emailExist
                              ? {
                                  message:
                                    "An account with this email address already exists.",
                                }
                              : errors["email"]
                          }
                          required
                          description={
                            "Your individually owned email address, preferably one assigned to you by the company you work for."
                          }
                          onBlur={async e => {
                            let email = e.target.value

                            if (validateEmail(email)) {
                              let exists = await checkEmailExist(
                                state.token,
                                email
                              )
                              setEmailExist(exists)
                            }
                          }}
                        />
                        {emailExist === false && (
                          <div className="w-[20px] h-[20px] absolute top-[18px] right-[15px]">
                            <CheckIcon color="green" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:gap-x-[30px]">
                      <FloatingInput
                        label="First Name"
                        hookForm
                        name="first_name"
                        flush
                        // type="email"
                        register={register}
                        error={errors["first_name"]}
                        required
                        description={"Your personal first name."}
                      />
                      <FloatingInput
                        label="Last Name"
                        hookForm
                        name="last_name"
                        flush
                        // type="email"
                        register={register}
                        error={errors["last_name"]}
                        required
                        description={"Your personal last name."}
                      />
                    </div>
                    <FloatingInput
                      label="Job Title"
                      hookForm
                      name="job_title"
                      flush
                      register={register}
                      error={errors["job_title"]}
                      required
                      description={"Your current job title at your company."}
                    />
                    <FloatingTelephone
                      label="Mobile number (optional)"
                      hookForm
                      name="tel"
                      register={register}
                      error={errors["tel"]}
                      description={
                        "Your personal mobile phone number, including area code."
                      }
                      setValue={setValue}
                      control={control}
                      type="tel"
                      flush
                    />
                    <FloatingDatePicker
                      setValue={setValue}
                      error={errors["dob"]}
                      label={"Date of Birth*"}
                      description={"We ask this for legal reasons."}
                      flush
                    />
                    <div className="md:flex md:gap-x-[30px]">
                      <FloatingInput
                        label="Password"
                        hookForm
                        name="password"
                        type="password"
                        passwordR={watch("password")}
                        register={register}
                        flush
                        required
                        error={errors["password"]}
                        description={"Create a new password."}
                      />
                      <FloatingInput
                        label="Confirm Password"
                        hookForm
                        required
                        name="password_repeat"
                        type="password"
                        flush
                        passwordR={watch("password")}
                        register={register}
                        error={errors["password_repeat"]}
                        description={"Please confirm your password."}
                      />
                    </div>
                    <p
                      css={css`
                        font-family: "Jost";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        padding: 0 10px;
                        margin: -7px 0px 25px;
                        letter-spacing: 0.02em;
                        color: #717171;
                        display: flex;
                        gap: 8px;
                      `}
                    >
                      <InformationCircleIcon style={{ width: "30px" }} />
                      Your password must be at least 12 characters in length
                      with at least 1 uppercase letter, 1 lowercase letter, 1
                      number, and 1 symbol.
                    </p>
                    <FloatingInput
                      label="Referral Code (optional)"
                      hookForm
                      name="referral_code"
                      flush
                      defaultValue={referralCode}
                      register={register}
                      error={errors["referral_code"]}
                      description={
                        "If you were referred by someone, enter their code here for potential benefits."
                      }
                    />
                    <FloatingRadiosInput
                      label={"How did you hear about us? (Choose One)"}
                      name="referral_source"
                      textFieldName="referral_source_extra_field"
                      flush
                      required
                      hookForm
                      registerForm={register}
                      unregisterForm={unregister}
                      error={errors["referral_source"]}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      controlForm={control}
                      setReferralBrand={setReferralBrand}
                      options={[
                        {
                          value: "tradeshow",
                          label: "Tradeshow/Event",
                          checked: false,
                          showTextField: true,
                          textFieldLabel: "Which Tradeshow/Event?",
                          textFieldDescription:
                            "Please specify the name of the tradeshow or event where you heard about us.",
                        },
                        {
                          value: "sampling_program",
                          label: "Sampling Program",
                          checked: false,
                          showTextField: false,
                        },
                        {
                          value: "another_retailer",
                          label: "Another Retailer",
                          checked: false,
                          showTextField: true,
                          textFieldLabel: "Which Retailer?",
                          textFieldDescription:
                            "Please specify the name of the retailer where you heard about us.",
                        },
                        {
                          value: "ocs",
                          label: "OCS",
                          checked: false,
                          showTextField: false,
                        },
                        {
                          value: "direct_mail",
                          label: "Direct Mail",
                          checked: false,
                          showTextField: false,
                        },
                        {
                          value: "social_media",
                          label: "Social Media",
                          checked: false,
                          showTextField: false,
                        },
                        {
                          value: "brand_rep",
                          label: "Brand/Brand Rep",
                          checked: false,
                          showTextField: true,
                          textFieldLabel: "Which Brand/Rep?",
                          textFieldDescription:
                            "Please specify the name of the brand or brand representative who referred you.",
                        },
                        {
                          value: "other",
                          label: "Other",
                          checked: false,
                          showTextField: true,
                          textFieldLabel: "Please Specify",
                          textFieldDescription:
                            "Please specify how you heard about us.",
                        },
                      ]}
                    />
                    <div className="flex flex-col justify-center items-center mt-6">
                      <Button red widthAuto type="submit" loading={isLoading}>
                        Continue
                      </Button>
                    </div>
                  </>
                )}
              </form>
              <form
                onSubmit={handleSubmit(
                  state.locformsubmit !== null
                    ? state.locformsubmit(onCompanyDetailsSubmit)
                    : onCompanyDetailsSubmit
                )}
              >
                {showCompany && (
                  <>
                    {/* <div className="relative z-0 mb-6 w-full group">
                <PhoneInput
                  inputClass={classNames(
                    "block py-[16px] px-[18px] !w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer",
                    errors["tel"]
                      ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                      : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                  )}
                  specialLabel={"Personal Phone Number"}
                  inputProps={{
                    name: "tel",
                    required: true,
                    // autoFocus: true,
                    css: css`
                      border-radius: 8px;
                      ${fontStyles}
                    `,
                  }}
                  onlyCountries={["us", "ca"]}
                />
              </div> */}
                    {/* <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const threeSpilter = str => {
                    if (!str) return ""
                    else if (str.length > 3 && str.length <= 6) {
                      let first3 = str.substring(0, 3)
                      return first3 + "-" + str.substring(3)
                    } else if (str.length > 6) {
                      let first3 = str.substring(0, 3)
                      let second3 = str.substring(3, 6)
                      return first3 + "-" + second3 + "-" + str.substring(6)
                    } else if (str.length <= 3) return str
                  }
                  return (
                    <>
                      <FloatingInput
                        name="tel"
                        type="tel"
                        inputRef={ref}
                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onKeyPress={event => {
                          if (!/[0-9]/.test(event.key) && event.key !== "+") {
                            event.preventDefault()
                          }
                        }}
                        value={value}
                        onChange={e => {
                          let val = e.target.value

                          onChange(threeSpilter(val.replaceAll("-", "")))
                        }}
                        error={errors["tel"]}
                        label={"Personal Phone Number"}
                        description={
                          "Your personal telephone or mobile number."
                        }
                      />
                    </>
                  )
                }}
                rules={{
                  required: "Please Enter Your Number",
                  validate: value =>
                    validatePhone(value) || "This is not a valid phone number",
                  maxLength: {
                    value: 20,
                    message: "Phone number is less than 10 digit",
                  },
                  minLength: {
                    value: 9,
                    message: "Phone number is larger than 9 digit",
                  },
                }}
                name={"tel"}
              /> */}
                    {!customCompanyActive ? (
                      companiesLoading ? (
                        <h3 className="text-med-16 pr-2 flex items-center">
                          <Skeleton width={"300px"} height={"30px"} />
                        </h3>
                      ) : (
                        <>
                          <FloatingSelect
                            label="Name of Company"
                            name="company_id"
                            options={allCompanies}
                            control={control}
                            error={errors["company_id"]}
                            rqMsg="Please choose your Company!"
                            required
                            loading={companiesLoading}
                            noSelection={true}
                            notFoundButton={true}
                            notFoundButtonTitle="Can't find your Company?"
                            notFoundButtonF={() => {
                              setCustomCompanyActive(true)
                              unregister("company_id")
                              unregister("store_id")
                              setStoreLocation(null)
                            }}
                            hookForm
                            description="Search for and select the name of the company you want your account associated with. Typically it is the company you work for or represent, such as a retail franchise (e.g. Global Pet Foods) or manufacturer  (e.g. Hasbro)."
                          />
                        </>
                      )
                    ) : (
                      <>
                        <FloatingInput
                          label="Company Name"
                          hookForm
                          flush
                          name="company_manual"
                          register={register}
                          error={errors["company_manual"]}
                          required
                          company_exist={company_exist}
                          company_error={company_error}
                          description="Enter the name of your new company"
                          setCustomCompanyActive={setCustomCompanyActive}
                        />
                      </>
                    )}

                    <FloatingRadios
                      label={"Type"}
                      name="type"
                      hookForm
                      flush
                      disabled={!customCompanyActive ? true : false}
                      required
                      registerForm={register}
                      error={errors["type"]}
                      getValues={getValues}
                      setValue={setValue}
                      handleSubmit={handleSubmit}
                      description={
                        "Select the type that best represents the company you selected above. Does the company primarily operate Retail Stores (e.g. Walmart) or manufacture Product Brands (e.g. Pepsi)?"
                      }
                      options={[
                        {
                          value: "retailer",
                          label: "Retailer",
                          checked: false,
                        },
                        {
                          value: "brand",
                          label: "Brand/Manufacturer",
                          checked: false,
                        },
                      ]}
                    />
                    <FloatingRadios
                      label={"Access Level"}
                      name="access_level"
                      flush
                      required
                      hookForm
                      registerForm={register}
                      error={errors["access_level"]}
                      getValues={getValues}
                      setValue={setValue}
                      description={
                        "The “Manager” access level gives you the ability to add, edit, remove and monitor aspects of your company on O2O,  such as users, stores, brands, reporting and more. Typically the “Manager” account level would not be held by associates, representatives or other agents of the company."
                      }
                      options={[
                        {
                          value: "lead",
                          label: "Manager",
                          checked: false,
                        },
                        {
                          value: "default",
                          label: "Associate/ Rep/ Other",
                          checked: false,
                        },
                      ]}
                    />
                    {getValues("type") === "brand" && !storeCustomLoc && (
                      <>
                        <FloatingSelect
                          label="Delivery Address"
                          name="location_id"
                          onChange={() => unregister("store_id")}
                          options={allUserLocations}
                          control={control}
                          error={errors["location_id"]}
                          rqMsg="Please choose your delivery location!"
                          required
                          noSelection={true}
                          notFoundButton={true}
                          notFoundButtonTitle="Can't find your Location?"
                          notFoundButtonF={() => {
                            setStoreCustomLoc(true)
                            unregister("location_id")
                          }}
                          hookForm
                          description="Search for and select a default address for your account. This is typically the delivery location for your primary store, head office, or storage locker. You can add additional addresses to your account after you log in."
                        />
                        <FloatingSelect
                          label="Address Type"
                          name="address_type"
                          options={addressType}
                          control={control}
                          error={errors["address_type"]}
                          rqMsg="Please choose your delivery type!"
                          noSelection={true}
                          required
                          hookForm
                          description="Select the address type of the delivery location"
                        />
                      </>
                    )}
                    {getValues("type") === "retailer" && !storeCustomLoc && (
                      <FloatingSelect
                        label="Delivery Address"
                        name="store_id"
                        options={storeLocations || allUserLocations}
                        control={control}
                        noSelection={true}
                        error={errors["store_id"]}
                        rqMsg="Please choose your delivery location!"
                        required
                        onChange={() => unregister("store_id")}
                        notFoundButton={true}
                        notFoundButtonTitle="Can't find your Location?"
                        notFoundButtonF={() => {
                          unregister("store_id")
                          setStoreCustomLoc(true)
                        }}
                        hookForm
                        description="Search for and select a default address for your account. This is typically the delivery location for your primary store, head office, or storage locker. You can add additional addresses to your account after you log in."
                      />
                    )}

                    {storeCustomLoc && (
                      <div className="flex flex-col gap-4">
                        <ButtonLite
                          callback={() => {
                            setStoreCustomLoc(false)
                            unregister("location_id")
                          }}
                          color="grey"
                          inline
                        >
                          Select from existing locations
                        </ButtonLite>
                        {getValues("type") === "brand" && (
                          <FloatingSelect
                            label="Address Type"
                            name="address_type"
                            options={addressType}
                            control={control}
                            error={errors["address_type "]}
                            rqMsg="Please choose your delivery type!"
                            required
                            hookForm
                            noSelection={true}
                            description="Select the address type of the delivery location"
                          />
                        )}
                        <CustomLocationForm onSave={onCustomLocationSave} />
                      </div>
                    )}

                    <div className="flex flex-col justify-center items-center mt-6">
                      <Button type="submit" red widthAuto loading={isLoading}>
                        Submit Registration
                      </Button>
                      <p
                        css={css`
                          margin: 20px 0 0;
                          text-align: center;
                          max-width: 400px;
                          color: #222;
                          font-size: 12px;
                        `}
                      >
                        By clicking the button above, you are accepting our{" "}
                        <a
                          target="_blank"
                          href="https://useo2o.com/ca/terms"
                          className="hover red"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          target="_blank"
                          href="https://useo2o.com/ca/privacy"
                          className="hover red"
                        >
                          Privacy Policy
                        </a>
                        , and agree to receive transactional and marketing
                        emails from O2O. You may opt out at any time.
                      </p>
                    </div>
                  </>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Registration
