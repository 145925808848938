import React, { useState, useEffect, useContext } from "react"
import InventoryContext from "./InventoryContext"
import StateContext from "../state/StateContext"
import DataContext from "../Data/DataContext"
import authenticationContext from "../authentication/AuthenticationContext"
export const InventoryProvider = props => {
  const { state } = useContext(StateContext)
  const { authentication } = useContext(authenticationContext)
  const { token } = state
  const { myUserGroups, universalUserGroups } = useContext(DataContext).data
  const [allUserGroupsAsOptions, setAllUserGroupsAsOptions] = useState([])

  useEffect(() => {
    setAllUserGroupsAsOptions([
      ...universalUserGroups.map(item => {
        let groupType = ""
        if (item.companies.length) groupType = "Retail companies"
        if (item.stores.length) groupType = "Retail stores"
        if (item.regions.length) groupType = "Regional retailers"
        if (item.users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
      ...myUserGroups.map(item => {
        // console.log(item.title, 'item.title')
        let groupType = ""
        if (item.field_group_companies.length) groupType = "Retail companies"
        if (item.field_group_stores.length) groupType = "Retail stores"
        if (item.field_group_regions.length) groupType = "Regional retailers"
        if (item.field_group_users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
    ])
  }, [myUserGroups, universalUserGroups])

  const returnGroupType = id => {
    let role = allUserGroupsAsOptions.filter(
      f => parseInt(f.value) === parseInt(id)
    )[0]
    return role?.type || null
  }

  const getInventoryByMajorAllocationGroup = (segments, totalStock) => {
    let internalaloc = 0
    let retailersaloc = 0
    segments.map(v => {
      if (v.roles.length) {
        let type = returnGroupType(v.roles[0])
        if (type === "Company users") internalaloc += parseInt(v.aloc)
        else retailersaloc += parseInt(v.aloc)
      }
    })

    let unaloc = parseInt(totalStock) - (internalaloc + retailersaloc)

    return {
      internal: internalaloc || 0,
      retailer: retailersaloc || 0,
      unallocated: unaloc || 0,
    }
  }

  if (
    authentication.isAuthenticated === true &&
    universalUserGroups.length === 0
  ) {
    console.warn("Rendering paused in InventoryProvider.")
    // return "Loading"
  }

  return (
    <InventoryContext.Provider
      value={{
        returnGroupType,
        getInventoryByMajorAllocationGroup,
        allUserGroupsAsOptions,
      }}
    >
      {props.children}
    </InventoryContext.Provider>
  )
}

export default InventoryProvider
