/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import GridIcon from "../../../../assets/icons/grid.svg"
import ListIcon from "../../../../assets/icons/list.svg"
import FilterSelect from "../../FilterSelect"
function FilterBarSort(props) {
  const {
    viewOptions,
    viewOptionsCallback,
    sortOptions,
    sortOptionsCallback,
    toggleGridView,
    toggleListView,
    values,
  } = props

  return (
    <div className="hidden md:flex items-center">
      {/* <FilterSelect
        options={viewOptions}
        onChange={viewOptionsCallback}
        value={values["item_per_page"]}
      /> */}

      <FilterSelect
        options={sortOptions}
        title="Sort by"
        menuClassNames={"relative inline-block text-left"}
        onChange={sortOptionsCallback}
        value={values && values['sort'] ? values["sort"][0] : null}
      />
      {/* <button
        type="button"
        className="hidden md:block p-2 -m-2 ml-2 sm:ml-5 text-gray-400 hover:text-gray-500"
        onClick={toggleGridView}
        css={css`
          rect {
            transition: 0.2s ease all;
          }
          &:hover {
            rect {
              stroke: #717171;
            }
          }
        `}
      >
        <span className="sr-only">View grid</span>

        <GridIcon className="w-4 h-4" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="hidden md:block p-2 -m-2 ml-2 sm:ml-5 text-gray-400 hover:text-gray-500"
        onClick={toggleListView}
        css={css`
          rect {
            transition: 0.2s ease all;
          }
          &:hover {
            rect {
              fill: #717171;
            }
          }
        `}
      >
        <span className="sr-only">View List</span>
        <ListIcon className="w-4 h-4" aria-hidden="true" />
      </button> */}
    </div>
  )
}

export default FilterBarSort
