/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useState, useRef, useContext, useEffect } from "react"
import {
  connectHits,
  connectStateResults,
  Index,
  Highlight,
  connectSearchBox,
  SearchBox,
  ClearRefinements,
  RefinementList,
  connectRefinementList,
} from "react-instantsearch-dom"
import SearchIcon from "../../assets/icons/search.svg"
import Icon from "../../assets/icons/close-alt.svg"
import GridIcon from "../../assets/icons/grid.svg"
import ListIcon from "../../assets/icons/list.svg"
import SearchContext from "../../context/Search/searchContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import StateContext from "../../context/state/StateContext"
import { useLocation } from "@reach/router"
import { Dialog, Transition } from "@headlessui/react"
import { Link, navigate } from "gatsby"
import LazyImage from "../LazyImage"
import BrandGrid from "../Cards/BrandGrid"
import ProductGrid from "../Cards/ProductGridCard"
import RetailerListCard from "../Cards/RetailerListCard"
import { classNames, toCapitalCase } from "../../utils"
import { Scrollbar } from "react-scrollbars-custom"
import { useMediaQuery } from "react-responsive"
import AdRegion from "../Elements/Listing/AdRegion"
import { getOnSiteMarketingCustomLocation } from "../../helpers/DrupalHelper"
import CustomPagination from "../../components/Search/pagination"
import "../../styles/search.css"

const RefinementListComponent = ({ items, currentRefinement, refine }) => {
  console.log(items, "itemse")
  return (
    <div>
      {/* <div>Current refinement: {currentRefinement.join(', ')}</div> */}
      <ul className="ais-RefinementList-list">
        {items
          .filter(f => f.count > 0)
          .map(item => {
            // console.log(item, "item")
            if (item.label === "retailers") return null
            return (
              <li
                key={item.label}
                className={`ais-RefinementList-item ${
                  item.isRefined && `ais-RefinementList-item--selected`
                }`}
              >
                <label class="ais-RefinementList-label">
                  <input
                    class="ais-RefinementList-checkbox"
                    type="checkbox"
                    name={item.label}
                    checked={item.isRefined ? true : false}
                    onChange={() => refine(item.value)}
                    value={item.value}
                  />
                  <span class="ais-RefinementList-labelText">{item.label}</span>
                  <span class="ais-RefinementList-count">{item.count}</span>
                </label>
                {/* <a
                href="#"
                style={{ fontWeight: item.isRefined ? 'bold' : '' }}
                onClick={event => {
                  event.preventDefault();
                  refine(item.value);
                }}
              >
                {item.label} ({item.count})
              </a> */}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

const CustomRefinementList = connectRefinementList(RefinementListComponent)

function HitGrid(props) {
  const { setSearchModal } = useContext(SearchContext)

  let { dataType } = props
  if (dataType === "brand") {
    return (
      <div
        // to={`}
        onClick={() => {
          navigate(`/brand/${props.id}`, { state: { fromSearch: true } })
          setSearchModal(false)
        }}
        className="z-[222] cursor-pointer flex"
      >
        <BrandGrid
          searchResultGrid={true}
          {...props}
          title={<Highlight attribute="title" hit={props} />}
        />
      </div>
    )
  } else if (dataType === "asset") {
    if (
      ("bundle" in props && props.bundle === "asset_group") ||
      props.sku === null
    )
      return (
        <div
          // to={`}
          onClick={() => {
            navigate(`/assets/${props.id}`, { state: { fromSearch: true } })
            setSearchModal(false)
          }}
          className="z-[222] cursor-pointer flex"
        >
          <ProductGrid
            assets
            hiddenCta
            assetsGroup
            {...props}
            title={<Highlight attribute="title" hit={props} />}
          />
        </div>
      )
    return (
      <div
        // to={`}
        onClick={() => {
          navigate(
            props.assets ? `/assets/${props.id}` : `/asset/${props.id}`,
            { state: { fromSearch: true } }
          )
          setSearchModal(false)
        }}
        className="z-[222] cursor-pointer flex"
      >
        <ProductGrid
          assets
          hiddenCta
          {...props}
          title={<Highlight attribute="title" hit={props} />}
        />
      </div>
    )
  } else if (dataType === "product") {
    return (
      <div
        // to={`}
        onClick={() => {
          navigate(`/product/${props.id}`, { state: { fromSearch: true } })
          setSearchModal(false)
        }}
        className="z-[222] cursor-pointer flex"
      >
        <ProductGrid
          {...props}
          hiddenCta
          title={<Highlight attribute="title" hit={props} />}
        />{" "}
      </div>
    )
  } else if (dataType === "retailer") {
    return null
    return (
      <div
        // to={`}
        onClick={() => {
          navigate("/retailers", { state: { fromSearch: true } })
          setSearchModal(false)
        }}
      >
        <RetailerListCard
          {...props}
          search
          name={<Highlight attribute="name" hit={props} />}
        />
      </div>
    )
  } else if (dataType === "retail-store") {
    return (
      <div
        // to={`}
        onClick={() => {
          navigate(`retail-stores`, { state: { fromSearch: true } })
          setSearchModal(false)
        }}
        className="z-[222] cursor-pointer flex"
      >
        <RetailerListCard
          search
          isStore
          {...props}
          name={<Highlight attribute="name" hit={props} />}
        />{" "}
      </div>
    )
  }
  return (
    <div className="border-gray-200 border p-6 text-xl w-full">
      <Link to={`${props.hit.dataType}/${props.hit.id}`}>
        <div className="hit-name">
          <Highlight attribute="title" hit={props.hit} />
        </div>
        <div className="hit-description">
          <Highlight attribute="description" hit={props.hit} />
        </div>
        <div className="hit-price">description:{props.hit.description}</div>
        <div className="hit-price">Type:{props.hit.dataType}</div>
      </Link>
    </div>
  )
}
const HitsGrid = props => {
  if (props?.hits?.length) {
    return (
      <>
        {!typeof window !== "undefined" && props.adArray !== null && (
          <>
            <AdRegion adArray={props.adArray} />
          </>
        )}
        {props.hits.map((hit, index) => (
          <HitGrid {...hit} key={index} />
        ))}
      </>
    )
  }
  return null
}
const CustomHitsGrid = connectHits(props => <HitsGrid {...props} />)
function ShowSide(props) {
  let { dataType } = props
  if (dataType === "brand") {
    return (
      <BrandGrid
        // searchResultGrid={props.gridView}
        {...props}
        title={<Highlight attribute="title" hit={props} />}
      />
    )
  } else if (dataType === "asset") {
    return (
      <ProductGrid
        assets
        hiddenCta
        searchResult={!props.gridView}
        {...props}
        t={<Highlight attribute="title" hit={props} />}
      />
    )
  } else if (dataType === "product") {
    return (
      <ProductGrid
        hiddenCta
        searchResult={!props.gridView}
        {...props}
        title={<Highlight attribute="title" hit={props} />}
      />
    )
  } else if (dataType === "retailer") {
    return null
    return (
      <>
        <RetailerListCard
          {...props}
          search
          name={<Highlight attribute="name" hit={props} />}
        />
      </>
    )
  } else if (dataType === "retail-store") {
    return null
    return (
      <RetailerListCard
        search
        isStore
        {...props}
        name={<Highlight attribute="name" hit={props} />}
      />
    )
  }
  return (
    <div className="border-gray-200 border p-6 text-xl w-full">
      <Link to={`${props.hit.dataType}/${props.hit.id}`}>
        <div className="hit-name">
          <Highlight attribute="title" hit={props.hit} />
        </div>
        <div className="hit-description">
          <Highlight attribute="description" hit={props.hit} />
        </div>
        <div className="hit-price">description:{props.hit.description}</div>
        <div className="hit-price">Type:{props.hit.dataType}</div>
      </Link>
    </div>
  )
}
const Hit = props => {
  const [current, setCurrent] = useState(props.hits[0])
  const { setSearchModal } = useContext(SearchContext)

  return (
    <>
      <ul
        className="relative pl-[5%] flex-none lg:inline-block"
        css={css`
          &&& {
            width: calc(90% / 2 - 30px);
          }
        `}
      >
        {props?.hits?.length > 0
          ? props.hits.map((hit, index) => {
              let { dataType, id } = hit
              // console.log(hit)
              // console.log("hit")
              let isCurrent = JSON.stringify(current) === JSON.stringify(hit)
              // console.log(isCurrent)
              return (
                <button
                  // to={`}
                  onClick={() => {
                    if (dataType === "retailer") {
                      setSearchModal(false)

                      navigate("/retailers", { state: { fromSearch: true } })
                    } else {
                      setSearchModal(false)

                      navigate(`/${dataType}/${id}`)
                    }
                  }}
                  css={css`
                    &&& {
                      cursor: pointer;
                      display: block;
                      /* font-size: 15px; */
                      z-index: 3333333;
                      /* padding: 14px 0; */
                      width: 100%;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                      box-sizing: border-box;
                      /* font-weight: 400; */
                      letter-spacing: normal;
                      /* border-bottom: 1px solid #ebebeb; */
                      border-radius: 4px;
                      color: ${isCurrent ? "#fff" : "#717171"};
                      .ais-Highlight-highlighted,
                      .ais-Snippet-highlighted {
                        color: ${isCurrent ? "#fff" : "#222"};
                      }
                      &:hover,
                        /* &:focus, */
                        &:active {
                        .ais-Highlight-highlighted,
                        .ais-Snippet-highlighted {
                          color: #fff;
                        }
                        color: #fff !important;
                      }
                    }
                  `}
                  className={classNames(
                    "hover:bg-red-700 flex justify-between hover:text-white text-left py-[15px] px-[15px] text-reg-16",
                    isCurrent ? "text-white bg-red-700" : "text-[#717171] "
                  )}
                  onMouseEnter={() => setCurrent(hit)}
                >
                  {/* <Img /> */}
                  {dataType === "retail-store" || dataType === "retailer" ? (
                    <Highlight attribute="name" hit={hit} />
                  ) : (
                    <Highlight attribute="title" hit={hit} />
                  )}
                  <p>{toCapitalCase(dataType)}</p>
                </button>
              )
            })
          : null}
      </ul>

      <div className="preview-panel w-1/2 bg-grey-200-opacity-60 hidden xl:block flex-none  w-full sticky top-[120px] right-[5%] float-right">
        <section
          aria-label="Preview panel"
          aria-busy="false"
          aria-live="polite"
          className="w-full pl-4 h-full"
        >
          {current && <ShowSide {...current} gridView={false} />}
        </section>
      </div>
    </>
  )
}
const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}
const CustomHits = connectHits(props => <Hit {...props} />)
const SearchBoxC = ({ currentRefinement, refine, refVal, setFocus }) => {
  const [inputRef, setInputFocus] = useFocus()
  useEffect(() => {
    setInputFocus(true)
    return () => {
      refine("")
    }
  }, [])
  const { setSearchModal } = useContext(SearchContext)

  return (
    <div className="relative">
      <input
        ref={inputRef}
        // type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        // onKeyPress={event => {
        //   // if (event.key === "Enter") {
        //   //   navigate("/search")
        //   //   setSearchModal(false)
        //   // }
        // }}
        className={`bg-white ring-1 ring-[#EBEBEB] focus:ring-[#EBEBEB] shadow-[0px_6px_20px_rgba(34,34,34,0.05)] block w-full h-[70px] pl-8 pr-20 border-0 rounded-full focus:outline-none text-reg-16`}
        css={css`
          &&& {
            min-width: 230px;
            /* font-size: 17px; */
          }
        `}
        placeholder="Start typing to search..."
        autoFocus={true}
        onFocus={e => {
          setFocus(true)
          e.currentTarget.select()
        }}
        onBlur={e => {
          if (!document.activeElement === refVal.current) setFocus(false)
        }}
        reset={null}
      />
      <button
        className="absolute inset-y-0 right-0 pr-7 flex items-center pointer-events-none"
        onClick={() => navigate("/search")}
      >
        <SearchIcon
          className="h-5 w-5"
          aria-hidden="true"
          css={css`
            path {
            }
          `}
        />
      </button>
    </div>
  )
}

const CustomSearchBox = connectSearchBox(props => (
  <SearchBoxC {...props} reset={null} />
))
function SearchModal({ isOpen, setIsOpen }) {
  const location = useLocation()
  const ref = useRef(null)
  const [focus, setFocus] = useState(false)
  const { query, setSearchModal, adCode } = useContext(SearchContext)
  const [grid, setGrid] = useState(true)
  const { token } = useContext(StateContext)
  useEffect(() => {
    setSearchModal(false)
  }, [location])

  // console.log(adCode)
  const { userType, authentication } = useContext(AuthenticationContext)
  const { state } = useContext(StateContext)
  // console.log(authentication)
  // console.log("authentication")
  const { currentUserData } = authentication
  const { brands, retailers } = currentUserData

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  let defaultRefinement = ["asset", "product", "brand"]

  // if (userType.brandManager) defaultRefinement = ['retailer']

  // retailers?.length
  //                               // ? ["asset", "product", "brand", "retailer"]
  //                               ? ["asset", "product", "brand"]
  //                               : ["retailer"]

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-[1000] overflow-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0 bg-[rgba(0,0,0,.7)] md:bg-white"
                // css={css`
                //   &&& {
                //     background: #fff;
                //     /* background: rgba(0, 0, 0, 0.7); */
                //   }
                // `}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom={
                isMobile ? `opacity-0 translate-y-full` : `opacity-0 scale-95`
              }
              enterTo={
                isMobile ? `opacity-100 translate-y-0` : `opacity-100 scale-100`
              }
              leave="ease-out duration-200"
              leaveFrom={
                isMobile ? `opacity-100 translate-y-0` : `opacity-100 scale-100`
              }
              leaveTo={
                isMobile ? `opacity-0 translate-y-full` : `opacity-0 scale-95`
              }
            >
              <div
                className={`
                  bg-white absolute bottom-0 -left-[1px] -right-[1px] border border-[#EBEBEB] rounded-[15px_15px_0_0]
                  md:relative md:rounded-none md:border-none md:left-auto md:right-auto md:bottom-auto md:bg-transparent w-full mx-auto z-[9999999] text-left transition-all transform
                  flex md:inline-block flex-col h-full overflow-hidden md:overflow-auto
                `}
              >
                <div className="border-b border-[#EBEBEB] py-[10px] sticky top-0 z-[999] bg-white">
                  <div className="w-[90%] mx-auto  flex items-center">
                    {isMobile ? (
                      <h1 className="text-strong-20">Search</h1>
                    ) : (
                      <h1 className="text-strong-25">
                        {query
                          ? `Showing results for ${query}...`
                          : "What are you looking for?"}
                      </h1>
                    )}
                    <div className="ml-auto">
                      <button
                        type="button"
                        className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
                        onClick={() => setIsOpen(false)}
                      >
                        <Icon className="w-3 h-3" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-[90%] mx-auto">
                  <div className="pt-[35px] pb-[35px]">
                    <CustomSearchBox
                      setFocus={setFocus}
                      refVal={ref}
                      reset={null}
                    />
                    <div className="w-full flex mt-[35px] px-2">
                      <div className="w-full flex justify-between overflow-x-scroll md:overflow-hidden pb-3">
                        <div className="flex items-center">
                          <span className="mr-2 text-med-14">Filter</span>
                          <CustomRefinementList
                            attribute="dataType"
                            className="mr-2 flex"
                            defaultRefinement={defaultRefinement}
                            transformItems={items =>
                              items.map(item => ({
                                ...item,
                                label: item.label.endsWith("s")
                                  ? item.label
                                  : item.label + "s",
                              }))
                            }
                          />
                          <ClearRefinements clearsQuery />
                        </div>
                        <div className="flex">
                          <button
                            type="button"
                            className="hidden md:block p-2 -m-2 ml-2 sm:ml-5 text-gray-400 hover:text-gray-500"
                            css={css`
                              rect {
                                transition: 0.2s ease all;
                              }
                              &:hover {
                                rect {
                                  stroke: #717171;
                                }
                              }
                            `}
                            onClick={() => setGrid(true)}
                          >
                            <span className="sr-only">View grid</span>

                            <GridIcon className="w-4 h-4" aria-hidden="true" />
                          </button>
                          <button
                            type="button"
                            className="hidden md:block p-2 -m-2 ml-2 sm:ml-5 text-gray-400 hover:text-gray-500"
                            onClick={() => setGrid(false)}
                            css={css`
                              rect {
                                transition: 0.2s ease all;
                              }
                              &:hover {
                                rect {
                                  fill: #717171;
                                }
                              }
                            `}
                          >
                            <span className="sr-only">View List</span>
                            <ListIcon className="w-4 h-4" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {query !== "" && !grid && <CustomHits ref={ref} />}
                {query !== "" && grid && (
                  <div className="w-[90%] mx-auto overflow-y-scroll pb-4">
                    <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-4">
                      <CustomHitsGrid ref={ref} adArray={adCode} />
                    </div>
                  </div>
                )}

                {query !== "" && <CustomPagination />}
                {/*</div>*/}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default SearchModal
