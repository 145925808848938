/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useState, useEffect, useContext, useRef } from "react"
import NotificationContext from "./NotificationContext"
import { useStaticQuery, graphql } from "gatsby"
import {
  getCart,
  // getUserStoresListing,
  processCheckOut,
  setCart,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import AuthenticationContext from "../authentication/AuthenticationContext"

import { Transition } from "@headlessui/react"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline"
import IconClose from "../../components/Elements/IconClose"
export const NotificationProvider = props => {
  const [show, setShow] = useState(false)
  const [content, setContent] = useState(false)
  const [error, setError] = useState(false)
  const [wait, setWait] = useState(false)
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)

  useEffect(() => {
    if (show === true) setWait(true)
    setTimeout(() => {
      setShow(false)
    }, 6000)
  }, [show])

  useEffect(() => {
    if (show === false) {
      setTimeout(() => {
        setError(false)
      }, 1000)
      setTimeout(() => {
        setWait(false)
      }, 500)
    }
  }, [show])

  const toggleNotification = data => {
    if (wait === true) {
      return null
    } else {
      if (data.error) setError(data.error)
      setShow(true)
      setContent(data.content)
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        toggleNotification,
        notificationIsVisible: show,
      }}
    >
      {props.children}
      <>
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-4 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="translate-y-4 opacity-0"
        >
          <div className="md:max-w-sm z-[222222222222] bottom-0 right-0 left-0 rounded-[15px_15px_0_0] w-screen md:bottom-[40px]  mx-auto fixed md:w-full bg-white shadow-[0px_6px_20px_rgba(34,34,34,0.05)] md:rounded-[8px] pointer-events-auto ring-1 ring-[#EBEBEB] overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 mt-[2px]">
                  <div
                    className={`w-[40px] h-[40px] rounded-full ${
                      error
                        ? `bg-[#fce3e1] text-[#E43D30]`
                        : `bg-[rgba(0,157,79,.15)] text-[#009D4F]`
                    } flex items-center justify-center `}
                  >
                    {error ? (
                      <ExclamationCircleIcon
                        className="h-5 w-5"
                        css={css`
                          stroke-width: 1.25px;
                        `}
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-5 w-5"
                        css={css`
                          stroke-width: 1.25px;
                        `}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
                <div className="ml-4 w-0 flex-1">
                  <p
                    className="mb-0 pb-0 text-reg-15"
                    css={css`
                      line-height: 1.1;
                    `}
                  >
                    {content}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <IconClose />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </>
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
