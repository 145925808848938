/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import FilterBarSort from "./FilterBarSort"
import FilterBarMeta from "./FilterBarMeta"

function FilterBar(props) {
  const {
    children,
    meta,
    filterCallback,
    dataLength,
    showSideBar,
    total,
    filterBody,
    callReset,
    metaHideReset,
    forceTray,
  } = props
  return (
    <div className="relative flex flex-col items-baseline justify-between grid-cols-none pb-2 md:flex-row md:pb-6">
      <FilterBarMeta
        filterCallback={filterCallback}
        dataLength={dataLength}
        showSideBar={showSideBar}
        total={total}
        callReset={callReset}
        forceTray={forceTray}
        metaHideReset={metaHideReset}
        loading={props.loading}
      >
        {meta}
      </FilterBarMeta>
      <FilterBarSort {...props} />
    </div>
  )
}

export default FilterBar
