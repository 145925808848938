import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Select from "react-select"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import FloatingInput from "../Elements/Form/FloatingInput"
// import { provinceCA, provi } from "../../utils/locations"
// import {getRegionsListing } from '../../helpers/DrupalHelper'
import DataContext from "../../context/Data/DataContext"
import StateContext from "../../context/state/StateContext"
import { getRegionsListing } from "../../helpers/DrupalHelper"
import { useLocation } from "@reach/router"
import regionsUSData from './regionsUS.json'
import regionsCAData from './regionsCA.json'

function CustomLocationForm({ data, token, edit, id, onSave, list }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    resetField,
    getValues,
    unregister,
    control,
  } = useForm()
  // console.log(errors)
  const { state, dispatch } = useContext(StateContext)
  const { data: dataFromContext } = useContext(DataContext)
  const regionsAsOptionsUS = dataFromContext?.regionsAsOptionsUS
  const regionsAsOptionsCA = dataFromContext?.regionsAsOptionsCA
  const onSubmit = () => {
    let data = getValues()

    onSave(data)
    // console.log(errors)
  }
  const [country, setCountry] = useState({ label: "Canada", value: "CA" })
  const [province, setProvince] = useState(null)
  const [regionsCA, setRegionsCA] = useState([])
  const [regionsUS, setRegionsUS] = useState([])
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === "/register") {
      setRegionsCA(regionsCAData)
      setRegionsUS(regionsUSData)
      // getRegionsListing(token).then(res => {
      //   let regionsAsOptions = []
      //   let regionsAsOptionsCA = []
      //   let regionsAsOptionsUS = []
      //   res["CA"].map((i, k) => {
      //     let pushCa = {
      //       value: i.id,
      //       label: i.name + ` (${i.code}, CA)`,
      //       region_code: i.code,
      //     }
      //     regionsAsOptions.push(pushCa)
      //     regionsAsOptionsCA.push(pushCa)
      //   })
      //   res["US"].map((i, k) => {
      //     let pushUs = {
      //       value: i.id,
      //       label: i.name + ` (${i.code}, US)`,
      //       region_code: i.code,
      //     }
      //     regionsAsOptions.push(pushUs)
      //     regionsAsOptionsUS.push(pushUs)
      //   })
      //   setRegionsUS(regionsAsOptionsUS)
      //   setRegionsCA(regionsAsOptionsCA)
      // })
    } else {
      setRegionsCA(regionsAsOptionsCA)
      setRegionsUS(regionsAsOptionsUS)
    }

    register("state", {
      required: `Province can't be empty`,
      // pattern: {
      //   value: /^([a-zA-Z0-9 _-]+)$/,
      //   message: "Alphanumeric characters only",
      // },
    })
    register("country_code", {
      required: `Country can't be empty`,
      pattern: {
        value: /^([a-zA-Z0-9 _-]+)$/,
        message: "Alphanumeric characters only",
      },
    })
    setValue("country_code", "CA")

    // register({ name: name });
  }, [])
  console.log(state)
  useEffect(() => {
    dispatch({ type: "SET_LOC_FORM_SUBMIT", payload: handleSubmit })
    return () => dispatch({ type: "SET_LOC_FORM_SUBMIT", payload: null })
  }, [])
  useEffect(() => {
    if (location.pathname !== "/register") {
      setRegionsCA(regionsAsOptionsCA)
      setRegionsUS(regionsAsOptionsUS)
    }

  }, [regionsAsOptionsCA, regionsAsOptionsUS])
  // console.log(regionsCA, regionsUS, "regions")
  // alert('hi')
  // console.log(state)
  return (
    // <SectionCard
    // title={edit ? "Edit Store" : "Create New Store"}
    // id="create">
    <form onChange={onSubmit}>
      <FloatingInput
        label="Address 1"
        name="address_line1"
        hookForm
        register={register}
        error={errors["address_line1"]}
        required
        {...register("address_line1", {
          minLength: {
            value: 5,
            message: "Address 1 must have at least 5 characters",
          },
          required: `Address 1 can't be empty`,
          pattern: {
            value: /^([a-zA-Z0-9 _-]+)$/,
            message: "Alphanumeric characters only",
          },
        })}
      />
      <FloatingInput
        label="Address 2"
        name="address_line2"
        hookForm
        register={register}
        error={errors["address_line2"]}
        // required
        {...register("address_line2", {
          // minLength: {
          //   value: 5,
          //   message: "Address 2 must have at least 5 characters",
          // },
          pattern: {
            value: /^([a-zA-Z0-9 _-]+)$/,
            message: "Alphanumeric characters only",
          },
          // required: `Address 2 can't be empty`,
        })}
      />
      {/* <div className="grid xl:grid-cols-2 xl:gap-6">
          <FloatingInput
            label="First name"
            name="fname2"
            hookForm
            register={register}
          />
          <FloatingInput
            label="Last name"
            name="lname2"
            hookForm
            register={register}
          />
        </div> */}
      {/* <div className="grid xl:grid-cols-2 xl:gap-6"> */}
      <FloatingInput
        label="City"
        name="city"
        hookForm
        register={register}
        error={errors["city"]}
        required
        {...register("city", {
          pattern: {
            value: /^([a-zA-Z0-9 _-]+)$/,
            message: "Alphanumeric characters only",
          },
          required: `City can't be empty`,
        })}
      />
      <div className="flex space-x-2">
        <FloatingSelect
          value={country}
          name={"country_code"}
          required
          label={"Country"}
          // {...register("country_code", {
          //   // pattern:{value:/^([a-zA-Z0-9 _-]+)$/, message:'Alphanumeric characters only'},
          //   required: `Country can't be empty`,
          // })}
          options={[
            { label: "Canada", value: "CA" },
            { label: "USA", value: "US" },
          ]}
          // isSearchable={true}
          onChange={(value, action) => {
            setCountry(value)
            setValue("country_code", value.value)
            setValue("state", null)
            setProvince(null)
            // let data = getValues()
            // onSave({ ...data, country_code: value.value })
          }}
          error={errors["country_code"]}
          // isClearable
          // styles={customStyles}
          // defaultValue={defaultValue[0]}
        />
        <FloatingSelect
          options={country.value === "CA" ? regionsCA : regionsUS}
          // {...register("state", {
          //   // pattern:{value:/^([a-zA-Z0-9 _-]+)$/, message:'Alphanumeric characters only'},
          //   required: `State can't be empty`,
          // })}
          label="Province"
          name="state"
          // value={getValues()['state']}
          // hookForm
          value={province}
          locationSelect
          onChange={(value, action) => {
            setProvince(value)
            setValue("state", value.region_code)
            // let data = getValues()
            // onSave({ ...data, state: value.label })
          }}
          // required
          // // register={register}
          // {...register('province',{
          //              // pattern:{value:/^([a-zA-Z0-9 _-]+)$/, message:'Alphanumeric characters only'},
          //              required: `Province can't be empty`,
          //            })}
          error={errors["state"]}
          required
        />
      </div>
      <FloatingInput
        label="Postal Code"
        name="postal_code"
        hookForm
        register={register}
        error={errors["postal_code"]}
        required
        {...register("postal_code", {
          pattern: {
            value: /^([a-zA-Z0-9 _-]+)$/,
            message: "Alphanumeric characters only",
          },
          required: `City can't be empty`,
          validate: value => {
            if (country?.value && country?.value === "CA") {
              if (value.length === 6 || value.length === 7) return true
              else return "Postal Code must be 6 or 7  charecters"
            } else {
              if (value.length === 5 || value.length === 10) return true
              else return "Postal Code must be 5 or 9  charecters"
            }
          },
          onChange: e => {
            console.log()
            let str = e.target.value
            if (country?.value && country?.value !== "CA") {
              if (str.length > 5) {
                if (!str.includes("-")) {
                  let first5 = str.substring(0, 5)
                  setValue("postal_code", first5 + "-" + str.substring(5))
                }
              } else {
                setValue("postal_code", str.replace("-", ""))
              }
            }
          },
        })}
      />

      <></>
      {/* </div> */}
    </form>
    // </SectionCard>
  )
}

export default CustomLocationForm
