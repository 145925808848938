let HOST = "https://api.useo2o.com"

if (
  typeof window !== "undefined" &&
  (window.location.hostname === "test.app.useo2o.com" ||
    window.location.hostname.includes("app-o2otest.netlify.app"))
) {
  HOST = "https://test.api.useo2o.com"
} else if (
  typeof window !== "undefined" &&
  (window.location.hostname === "dev.app.useo2o.com" ||
    window.location.hostname.includes("app-o2odev.netlify.app") ||
    window.location.hostname.includes("localhost"))
) {
  HOST = "https://dev.api.useo2o.com"
}
// HOST = "https://dev.api.useo2o.com"
// HOST = "https://cocoon-useo2o.pantheonsite.io"
if (process.env.GATSBY_API_BASE) HOST = process.env.GATSBY_API_BASE

export const verifiedHost = HOST

export const isDev = HOST !== "https://api.useo2o.com"
