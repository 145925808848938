/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useContext, useRef, useState } from "react"
import {
  connectHits,
  connectStateResults,
  Index,
  Highlight,
  connectSearchBox,
  SearchBox,
} from "react-instantsearch-dom"
import SearchIcon from "../../../assets/icons/search.svg"
import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import SearchContext from "../../../context/Search/searchContext"
import { Transition } from "@headlessui/react"
import LazyImage from "../../LazyImage"
import SearchModal from "../../Search/SearchModal"
import { useMediaQuery } from "react-responsive"
/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const Hit = props => {
  return (
    <div
      className="origin-top-right z-[444444] absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-black"
      css={css`
        top: 100%;
        background-color: hsl(0, 0%, 100%);
        border-radius: 8px;
        box-shadow: 0px 6px 20px rgb(34 34 34 / 5%);
        margin-bottom: 8px;
        margin-top: 8px;
        position: absolute;
        width: 100%;
        z-index: 22221;
        box-sizing: border-box;
        min-width: 200px;
        max-width: 300px;
        text-align: left;
        border: 1px solid #222;
        left: 0;
      `}
    >
      <div
        className="py-1 "
        css={css`
          max-height: 300px;
          overflow-y: auto;
          padding-bottom: 4px;
          padding-top: 4px;
          position: relative;
          -webkit-overflow-scrolling: touch;
          box-sizing: border-box;
          padding: 5px 20px;
          cursor: pointer;
        `}
      >
        {props?.hits?.length > 0
          ? props.hits.map((hit, index) => {
              let { dataType, id } = hit
              console.log(hit)
              const Img = () => {
                if (dataType === "brand") {
                  return <LazyImage src={hit.logo} />
                } else if (dataType === "asset") {
                  return <LazyImage src={hit.cover && hit.cover[0]} />
                } else if (dataType === "product") {
                  return <LazyImage src={hit.img && hit.img[0]} />
                } else if (dataType === "retailer") {
                  return null //<LazyImage src={hit.cover[0]} />
                } else {
                  return null //<LazyImage src={hit.cover[0]} />
                }
              }
              return (
                <Link
                  to={`/${dataType}/${id}`}
                  css={css`
                    background-color: transparent;
                    color: #717171;
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    z-index: 3333333;
                    padding: 14px 0;
                    width: 100%;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    box-sizing: border-box;
                    font-weight: 400;
                    letter-spacing: normal;
                    border-bottom: 1px solid #ebebeb;
                    border-radius: 4px;
                  `}
                >
                  <Img />
                  {dataType === "retail-store" || dataType === "retailer" ? (
                    <Highlight attribute="name" hit={hit} />
                  ) : (
                    <Highlight attribute="title" hit={hit} />
                  )}
                </Link>
              )
            })
          : null}
      </div>
    </div>
  )
}

const CustomHits = connectHits(props => <Hit {...props} />)
const SearchBoxC = ({
  currentRefinement,
  refine,
  refVal,
  floating,
  setFocus,
}) => (
  <div className="relative lg:-mr-1">
    <input
      type="search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
      onKeyPress={event => {
        if (event.key === "Enter") {
          navigate("/search")
        }
      }}
      className={`${
        floating
          ? `bg-transparent !text-white !placeholder-white hover:ring-[rgba(255,255,255,.1)] focus:ring-[rgba(255,255,255,.1)]`
          : `bg-white hover:ring-[#EBEBEB] focus:ring-[#EBEBEB] hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)]`
      } block w-full h-[50px] pl-6 pr-14 border-0 rounded-full hover:ring-1 focus:outline-none text-reg-15`}
      css={css`
        &&& {
          min-width: 230px;
        }
      `}
      placeholder="Search For Anything"
      onFocus={() => setFocus(true)}
      onBlur={() => {
        if (!document.activeElement === refVal.current) setFocus(false)
      }}
    />
    <button
      className="absolute inset-y-0 z-[222] right-0 pr-5 flex items-center pointer-events-none"
      onClick={() => navigate("/search")}
    >
      <SearchIcon
        className="h-5 w-5"
        aria-hidden="true"
        css={css`
          path {
            fill: ${floating ? `#fff` : ``};
          }
        `}
      />
    </button>
  </div>
)
const CustomSearchBox = connectSearchBox(props => <SearchBoxC {...props} />)
export default function Search({ floating, isTop }) {
  const location = useLocation()
  const ref = useRef(null)
  const [focus, setFocus] = useState(false)
  const { query, searchModalOpen, setSearchModal } = useContext(SearchContext)
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <div className="relative lg:-mr-1">
      <div className="flex items-stretch w-full flex-col relative">
        <div className="relative lg:-mr-1">
          {!isMobile && (
            <button
              onClick={() => setSearchModal(true)}
              className={`${
                floating
                  ? `bg-transparent !text-white !placeholder-white hover:ring-[rgba(255,255,255,.1)] focus:ring-[rgba(255,255,255,.1)]`
                  : `bg-white hover:ring-[#EBEBEB] focus:ring-[#EBEBEB] hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)]`
              } block w-full h-[50px] pl-6 pr-14 border-0 rounded-full ring-1 ring-[#EBEBEB] hover:ring-1 focus:outline-none text-reg-15`}
              css={css`
                &&& {
                  min-width: 230px;
                  z-index: 33;
                }
              `}
            >
              Search For Anything
            </button>
          )}
          <button
            className={`${
              isMobile
                ? `h-[50px] w-[50px] justify-center`
                : `absolute pointer-events-none inset-y-0 z-1 right-0 pr-5`
            } flex items-center`}
            onClick={() => setSearchModal(true)}
          >
            <SearchIcon
              className="h-5 w-5"
              aria-hidden="true"
              css={css`
                path {
                  fill: ${floating ? `#fff` : ``};
                }
              `}
            />
          </button>
        </div>

        <SearchModal isOpen={searchModalOpen} setIsOpen={setSearchModal} />
        {location.pathname !== "/search" && focus && query && (
          <CustomHits ref={ref} />
        )}
      </div>
    </div>
  )
}
