/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { classNames } from "../../../utils"
import { validatePhone } from "../../../utils/messageValidation"
import { Controller } from "react-hook-form"
// let prefixes = [
//   { i: "facebook", link: "https://www.facebook.com/" },
//   { i: "twitter", link: "https://twitter.com/" },
//   { i: "linkedin", link: "https://www.linkedin.com/in/" },
//   { i: "instagram", link: "https://www.instagram.com/" },
// ]

import FloatingInput from "./FloatingInput"
const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
// const PrefixedInput = ({ onChange, name, defaultValue, register, data }) => {
//   let prefix = prefixes.filter(v => name.includes(v.i))[0].link
//   const [inputVal, setInputValue] = useState(prefix)
//   useEffect(() => {
//     if (data.current_value)
//       setInputValue(
//         data?.current_value.includes(prefix)
//           ? data.current_value
//           : prefix + data.current_value
//       )
//
//     register(name)
//   }, [])
//
//   return (
//     <input
//       type={"text"}
//       // name={inputName}
//       className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
//       css={css`
//         /* border: 1px solid #ebebeb; */
//         border-radius: 8px;
//         ${fontStyles}
//       `}
//       placeholder=" "
//       required=""
//       defaultValue={defaultValue}
//       value={inputVal}
//       onChange={e => {
//         const input = e.target.value
//         let v = prefix + input.substr(prefix.length)
//         onChange(v)
//
//         setInputValue(v)
//       }}
//       // {...register(inputName)}
//     />
//   )
// }

export default function FloatingTelephone(props) {
  const {
    // onChange, use props.onChange
    type,
    label,
    name,
    icon,
    hookForm,
    register,
    ref,
    required,
    error,
    className,
    defaultValue,
    data,
    setValue,
    watch,
    passwordR,
    description,
    flush,
    disabled,
    noValidation,
  } = props
  const inputName = name
    ? name
    : label.toLowerCase().replaceAll(" ", "-") + "-" + Math.random(5000, 9000)
  // let isUrlField =
  //   name.includes("facebook") ||
  //   name.includes("twitter") ||
  //   name.includes("linkedin") ||
  //   name.includes("instagram")
  // console.log(props)
  const [liveError, setLiveError] = useState(false)
  const updateliveError = v => setLiveError(v ? { message: v } : false)
  const [number, setNumber] = useState("")
  useEffect(() => {
    if (number) {
      let rawNumber = number.replaceAll("-", "")
      // console.log(rawNumber, 'rawnumber')
      if (rawNumber.length !== 10)
        updateliveError("A valid number should be 10 digits long.")
      else {
        if (!validatePhone(number)) {
          // console.log(validatePhone(number), 'valid')
          if (rawNumber.length !== 10)
            updateliveError("A valid number should be 10 digits long.")
          else updateliveError("This is not a valid phone number.")
        } else updateliveError(false)
      }
    }
  }, [number])
  // console.log(liveError)

  return (
    <>
      <Controller
        control={props.control}
        render={({ field: { onChange, value, name, ref } }) => {
          const threeSpilter = str => {
            if (!str) return ""
            else if (str.length > 3 && str.length <= 6) {
              let first3 = str.substring(0, 3)
              return first3 + "-" + str.substring(3)
            } else if (str.length > 6) {
              let first3 = str.substring(0, 3)
              let second3 = str.substring(3, 6)
              return first3 + "-" + second3 + "-" + str.substring(6)
            } else if (str.length <= 3) return str
            else if (str.length === 6) return str
          }

          return (
            <>
              <FloatingInput
                name={name}
                type="tel"
                inputRef={ref}
                // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key) && event.key !== "+") {
                    event.preventDefault()
                  }
                }}
                value={value}
                flush={flush}
                onChange={e => {
                  let val = e.target.value
                  console.log(val, value)
                  console.log(threeSpilter(val.replaceAll("-", "")))
                  setNumber(threeSpilter(val.replaceAll("-", "")))
                  onChange(threeSpilter(val.replaceAll("-", "")))
                }}
                error={liveError || error}
                label={label}
                description={description}
                disabled={disabled ? disabled : false}
              />
            </>
          )
        }}
        rules={
          !noValidation && {
            required:
              data?.required || props.required
                ? "Please Enter Your Number"
                : false,
            validate: value => {
              if (data?.required || props.required)
                return (
                  validatePhone(value) || "This is not a valid phone number."
                )
              else if (!data?.required && !props.required && !value) return true
              else
                return (
                  validatePhone(value) || "This is not a valid phone number."
                )
            },
            maxLength: {
              value: 12 /* account for 2 hyphens, 11+2 */,
              message: "A valid phone number should be 10 digits long.",
            },
            minLength: {
              value: 12 /* account for 2 hyphens, 9+2 */,
              message: "A valid phone number should be 10 digits long.",
            },
          }
        }
        name={name}
      />
    </>
  )

  // return (
  //   <div
  //     className={`relative z-0 ${!flush && `mb-6`} w-full group ${
  //       className && className
  //     }`}
  //   >
  //     {hookForm ? (
  //       <>
  //         {isUrlField ? (
  //           <PrefixedInput
  //             name={name}
  //             register={register}
  //             setValue={setValue}
  //             defaultValue={defaultValue}
  //             onChange={v => setValue(name, v)}
  //             data={data}
  //           />
  //         ) : required ? (
  //           <input
  //             type={type ? type : "text"}
  //             className={classNames(
  //               "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer",
  //               error
  //                 ? "border-red-600 focus:border-red-600 focus:text-red-600 "
  //                 : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
  //             )}
  //             css={css`
  //               /* border: 1px solid #ebebeb; */
  //               border-radius: 8px;
  //               ${fontStyles}
  //             `}
  //             {...register(
  //               inputName,
  //
  //               inputName === "password"
  //                 ? {
  //                     minLength: {
  //                       value: 8,
  //                       message: "Password must have at least 8 characters",
  //                       required: `Password can't be empty`,
  //                     },
  //                     required: `Password can't be empty`,
  //                   }
  //                 : inputName === "password_repeat"
  //                 ? {
  //                     validate: passwordR
  //                       ? value => {
  //                           // console.log(value, passwordR)
  //                           return (
  //                             (passwordR && value && value === passwordR) ||
  //                             (passwordR !== "" &&
  //                               value &&
  //                               "The passwords do not matched")
  //                           )
  //                         }
  //                       : () => {},
  //                     required: passwordR && `This field is required`,
  //                   }
  //                 : inputName === "tel"
  //                 ? {
  //                     minLength: {
  //                       value: 10,
  //                       message: "Phone number must have at least 10 numbers",
  //                     },
  //                     required: `Phone number can't be empty`,
  //                   }
  //                 : {
  //                     required: `This field is required`,
  //                   }
  //             )}
  //             placeholder=" "
  //             defaultValue={defaultValue}
  //             disabled={disabled}
  //             {...props}
  //           />
  //         ) : (
  //           <input
  //             type={type ? type : "text"}
  //             className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
  //             css={css`
  //               /* border: 1px solid #ebebeb; */
  //               border-radius: 8px;
  //               ${fontStyles}
  //             `}
  //             // placeholder={placeholder ? placeholder : "Type here"}
  //             {...register(
  //               inputName,
  //               inputName === "password"
  //                 ? required === false
  //                   ? {
  //                       validate: value => {
  //                         // console.log(value, passwordR)
  //                         if (value && value.length < 8) {
  //                           return "Password must have at least 8 characters"
  //                         } else return true
  //                       },
  //                     }
  //                   : {
  //                       minLength: {
  //                         value: 8,
  //                         message: "Password must have at least 8 characters",
  //                         required: `Password can't be empty`,
  //                       },
  //                       required: `Password can't be empty`,
  //                     }
  //                 : inputName === "password_repeat"
  //                 ? {
  //                     validate: passwordR
  //                       ? value => {
  //                           // console.log(value, passwordR)
  //                           return (
  //                             (passwordR && value && value === passwordR) ||
  //                             (passwordR !== "" &&
  //                               value &&
  //                               "The passwords do not matched")
  //                           )
  //                         }
  //                       : () => {},
  //                     required: passwordR && `This field is required`,
  //                   }
  //                 : inputName === "tel"
  //                 ? {
  //                     minLength: {
  //                       value: 10,
  //                       message: "Phone number must have at least 10 numbers",
  //                     },
  //                   }
  //                 : undefined
  //             )}
  //             defaultValue={defaultValue}
  //             placeholder=" "
  //             disabled={disabled}
  //             {...props}
  //           />
  //         )}
  //       </>
  //     ) : (
  //       <input
  //         type={type ? type : "text"}
  //         name={inputName}
  //         className={classNames(
  //           "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer",
  //           error
  //             ? "border-red-600 focus:border-red-600 focus:text-red-600 "
  //             : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
  //         )}
  //         css={css`
  //           /* border: 1px solid #ebebeb; */
  //           border-radius: 8px;
  //           ${fontStyles}
  //         `}
  //         placeholder=" "
  //         required=""
  //         defaultValue={defaultValue}
  //         onChange={onChange}
  //         disabled={disabled}
  //         {...props}
  //       />
  //     )}
  //     {icon && (
  //       <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
  //         {icon}
  //       </div>
  //     )}
  //
  //     <label
  //       for={inputName}
  //       className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
  //       css={css`
  //         ${fontStyles}
  //       `}
  //     >
  //       {label}
  //     </label>
  //     {error && <span className="text-red-600">{error.message}</span>}
  //     {description && inputName !== "title" && (
  //       <span
  //         // className="text-sm"
  //         css={css`
  //           font-family: "Jost";
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 12px;
  //           line-height: 16px;
  //           display: inline-block;
  //           padding: 0 10px;
  //           margin: 7px 0 10px;
  //           letter-spacing: 0.02em;
  //           color: #717171;
  //         `}
  //       >
  //         {description}
  //       </span>
  //     )}
  //   </div>
  // )
}
