export function getRemainingTimeToMidnightEST() {
  // Set Eastern Standard Time (EST) timezone for USA and Canada parts
  const estTz = "America/Toronto"

  // Get the current time in EST
  const now = new Date().toLocaleString("en-US", { timeZone: estTz })
  const currentTime = new Date(now)

  // Calculate the time remaining until midnight
  const midnight = new Date(now)
  midnight.setHours(24, 0, 0, 0) // Set time to midnight

  const remainingTimeMs = midnight - currentTime
  const remainingTimeSec = Math.floor(remainingTimeMs / 1000)

  const hours = Math.floor(remainingTimeSec / 3600)
  const minutes = Math.floor((remainingTimeSec % 3600) / 60)

  return { hours, minutes }
}
