import { useState, useEffect, useRef } from "react"
export const useElementOnScreen = options => {
  const loadRef = useRef(null)
  const [loadMore, setIsVisible] = useState(false)
  const callbackFunction = entries => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (loadRef.current) observer.observe(loadRef.current)
    return () => {
      if (loadRef.current) observer.unobserve(loadRef.current)
    }
  }, [loadRef, options])
  return [loadRef, loadMore]
}
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}
export const handleScrolltoRef = ref => {
  window.scrollTo({
    top: ref.offsetTop - 100,
    left: 0,
    behavior: "smooth",
  })
  // ref.scrollIntoView({ behavior: "smooth" })
}
export const paginatationFromArray = (array, page_size, page_number) => {
  return array.slice(
    page_number * page_size,
    page_number * page_size + page_size
  )
}
export const toCapitalCase = string => {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const usePagination = (array, page_size, page_number) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    const newArray = paginatationFromArray(array, page_size, page_number)
    setItems(newArray)
  }, [array, page_size, page_number])

  if (array.length < page_size) return array
  return items
}
export const formatDate = d =>
  typeof d === "string"
    ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
    : d &&
    String(d.getDate()).padStart(2, "0") +
    "/" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "/" +
    d.getFullYear()
// export const getPageData = (currentItems, totalPage, page_number) => {
//   const [items, setItems] = useState([])
//   useEffect(() => {
//     const newArray = paginatationFromArray(array, page_size, page_number)
//     setItems(newArray)
//   }, [array, page_size, page_number])

//   if (array.length < page_size) return array
//   return items
// }
