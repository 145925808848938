/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { useForm } from "react-hook-form"
import React, { useState, useEffect } from "react"

import DatePicker from "react-datepicker"
import FloatingSelect from "./FloatingSelect"
import "react-datepicker/dist/react-datepicker.css"
import { classNames } from "../../../utils"
const formatDate = d =>
  typeof d === "string"
    ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
    : String(d.getDate()).padStart(2, "0") +
      "/" +
      String(d.getMonth() + 1).padStart(2, "0") +
      "/" +
      d.getFullYear()
const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
function FloatingDatePicker({
  setValue,
  defaultValue,
  error,
  label,
  description,
  plugin,
  forceDefaultEmpty,
  onChange,
  flush,
}) {
  const [birthDate, setBirthDate] = useState(null)
  useEffect(() => {
    console.log(defaultValue, "defaultValue")
    if (defaultValue) {
      console.log(defaultValue, "defaultValue2")
      let dob = new Date(formatDate(defaultValue))
      setBirthDate(dob)
    }
  }, [])
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm({
    defaultValues: defaultValue
      ? {
          day: defaultValue.split("/")[0],
          month: defaultValue.split("/")[1],
          year: defaultValue.split("/")[2],
        }
      : {},
  })
  const dayAsOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    label: i + 1 < 10 ? `0${i + 1}` : i + 1,
  }))
  const monthAsOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((v, k) => ({
    value: k + 1 < 10 ? `0${k + 1}` : `${k + 1}`,
    label: v,
  }))
  const yearAsOptions = Array.from(
    { length: new Date().getFullYear() - 18 - 1920 },
    (_, i) => ({
      value: `${i + 1920}`,
      label: i + 1920,
    })
  ).reverse()
  useEffect(() => {
    if (!defaultValue)
      setValue(
        "dob",
        yearAsOptions[0].value +
          "-" +
          monthAsOptions[0].value +
          "-" +
          dayAsOptions[0].value
      )
  }, [])
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let { day, month, year } = value
      console.log("u")

      if (day && month && year) {
        console.log("u")
        console.log("dob", year + "-" + month + "-" + day)
        setValue("dob", year + "-" + month + "-" + day)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])
  // const dateChange = () => {
  //   let { day, month, year } = getValues()
  //   console.log("customChange")

  //   if (day && month && year) {
  //     console.log("customChange")
  //     console.log("dob", year + "-" + month + "-" + day)
  //     setValue("dob", year + "-" + month + "-" + day)
  //   }
  // }

  if (plugin === true)
    return (
      <div className="mb-6 relative z-[3] w-full group flex flex-col">
        <DatePicker
          selected={birthDate}
          calendarAriaLabel={"Your birthday"}
          className={classNames(
            "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}",
            error
              ? "border-[#e43d30] focus:border-[#e43d30] focus:text-[#e43d30]"
              : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
          )}
          css={css`
            /* border: 1px solid #ebebeb; */
            border-radius: 8px;
            ${fontStyles}
          `}
          dateFormat="dd/MM/yyyy"
          onChange={date => {
            setBirthDate(date)
            let today = date
            var dd = String(today.getDate()).padStart(2, "0")
            var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
            var yyyy = today.getFullYear()

            // today = dd + "/" + mm + "/" + yyyy
            /*  202207050144: For Drupal's weird format */ today =
              yyyy + "-" + mm + "-" + dd
            console.log(today)

            if (onChange) onChange(today)
            if (setValue) setValue("dob", today)
          }}
        />
        <label
          for={"dob"}
          className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
          css={css`
            ${fontStyles}
          `}
        >
          {label}
        </label>

        {error && <span className="text-[#e43d30]">{error.message}</span>}
        {description && (
          <span
            // className="text-sm"
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding: 0 10px;
              margin: 7px 0 10px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
          >
            {description}
          </span>
        )}
      </div>
    )
  return (
    <div className={`${!flush ? "mb-6" : "mb-3"} relative z-[3] w-full group`}>
      <div
        className="flex space-x-[15px]"
        css={css`
          > * {
            margin-bottom: 0 !important;
          }
        `}
      >
        <FloatingSelect
          // label={"Day"}
          name="day"
          placeholder="Day"
          options={dayAsOptions}
          hookForm
          register={register}
          error={errors["day"]}
          rqMsg="Please select day"
          control={control}
          required
          widthAuto
          // customChange={dateChange}
        />
        <FloatingSelect
          placeholder="Month"
          // label={"Month"}
          name="month"
          hookForm
          register={register}
          error={errors["day"]}
          rqMsg="Please select day"
          control={control}
          // customChange={dateChange}
          required
          options={monthAsOptions}
          widthAuto
        />
        <FloatingSelect
          placeholder="Year"
          // label={"Year"}
          hookForm
          register={register}
          error={errors["day"]}
          rqMsg="Please select day"
          // customChange={dateChange}
          control={control}
          required
          name="year"
          options={yearAsOptions}
          // defaultValue={{
          //   value: 1980,
          //   label: 1980,
          // }}
          widthAuto
        />
      </div>
      <label
        for={"dob"}
        className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
        css={css`
          ${fontStyles}
        `}
      >
        {label}
      </label>
      {error && <span className="text-[#e43d30]">{error.message}</span>}
      {description && (
        <span
          // className="text-sm"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            padding: 0 10px;
            margin: 7px 0 10px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          {description}
        </span>
      )}
    </div>
  )
}

export default FloatingDatePicker
