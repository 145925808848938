import React from "react"

export const defaultState = {
  roles: [],
  regions: [],
  regionsAsOptions: [],
  retailStores: [],
  retailStoresAsOptions: [],
  myProducts: [], // will be redundant b/c of MyEntityListing, but save it anyway
  myAssets: [], // will be redundant b/c of MyEntityListing, but save it anyway
  myBrands: [],
  myBrandsAsOptions: [],
  myProductsAsOptions: [],
  myAssetsAsOptions: [],
  myUsers: [],
  myUsersAsOptions: [],
  userGroups: [],
  myUserGroups: [],
  universalUserGroups: [],
  tagsAsOptions: [],
  categoriesAsOptions: [],
  entityItemsNumber: {},
  myBookmarksID: {},
  myBookmarks: [],
  myBookmarksItems: {},
  myNotifications: [],
  onSiteMarketing: [],
  regionsAsOptionsCA: [],
  regionsAsOptionsUS: [],
  regionsAsOptions: [],
  productTagsAsOptions: [],
  productCategoriesAsOptions: [],
  storeCompaniesAsOptions: [],
  storeRegionsAsOptions: [],
  storeCitiesAsOptions: [],
  messages: null,
}

const DataContext = React.createContext(defaultState)
export default DataContext
