import React, { useState, useEffect } from "react"
import Select, { components } from "react-select"
import DropIcon from "../assets/icons/arrow-down.svg"
import XIcon from "../assets/icons/close-alt.svg"

export const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <XIcon className="h2 w-2" />
    </components.ClearIndicator>
  )
}

export const DropdownIndicator = ({ children, ...props }) => {
  if (props.hasValue === true && props.selectProps.isClearable === true)
    return null
  return (
    <components.DropdownIndicator {...props}>
      <DropIcon className="h3 w-3" />
    </components.DropdownIndicator>
  )
}

const defaultControl = {
  display: `flex`,
  alignItems: `center`,
}

const defaultPlaceholder = {
  // color: `rgba(0,0,0,.5)`,
  position: `absolute`,
  color: `#717171`,
  fontSize: `15px`,
  fontWeight: 400,
  letterSpacing: `normal`,
}
const defaultIndicatorsContainer = {
  // marginTop: 10,
  display: `flex`,
  alignItems: `center`,
}

const defaultValueContainer = {
  overflow: "visible",
  padding: 0,
  color: `#717171`,
  fontSize: `15px`,
  fontWeight: 400,
  letterSpacing: `normal`,
  // Dummy input:
  // "input[readonly]": {
  //   position: `absolute`,
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  // },
}

export const selectStyles = {
  container: (provided, state) => ({
    // fontSize: `50px`,
    border: `1px solid #EBEBEB`,
    borderColor: state.isFocused ? `#222` : `#EBEBEB`,
    backgroundColor:
      state.selectProps.isDisabled === true ? "#EEE" : "transparent",
    borderRadius: 8,
    padding: `10px 18px`,
    minHeight: `62px`,
    maxHeight: state.selectProps.maxHeight ? `62px` : `none`,
    // '@media(787px)': {
    // padding: `0 18px`,
    // height: `58px`,
    // },
    display: `flex`,
    alignItems: `stretch`,
    // zIndex: state.isFocused ? `9999999` : `0`,
    position: `relative`,
    // lineHeight: `1.2`,
    // "&:focus": {
    //   border: `1px solid #222`,
    // },
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...defaultControl,
    width: "100%",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...defaultValueContainer,
    // flexWrap: state.selectProps.wrapMultiItems ? "wrap" : "nowrap",
    flexWrap: "wrap",
  }),
  input: (provided, state) => ({
    flexGrow: 1,
    // boxShadow: `none!important`,
    // display: `none!important`
    position: state.selectProps.maxHeight ? "absolute" : "unset",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...defaultIndicatorsContainer,
    position: "absolute",
    right: "20px",
  }),
  loadingIndicator: (provided, state) => ({
    ...provided,
    span: {
      fontSize: "5px!important",
    },
  }),
  placeholder: (provided, state) => ({
    ...defaultPlaceholder,
  }),
  singleValue: (provided, state) => ({
    // ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: state.isFocused ? 9999999 : `0`,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99999999,
    minWidth: `200px`,
    maxWidth: `300px`,
    textAlign: `left`,
    // zIndex: state.isFocused ? 9999999 : `0`,
    // marginTop: 20,
    // fontSize: 20,
    boxShadow: `0px 6px 20px rgba(34, 34, 34, 0.05)`,
    border: `1px solid #222`,
    borderRadius: 8,
    // zIndex: state.isFocused ? `99999999999` : `0`,
    // position: 'fixed',
    // overflow: `hidden`,
    left: `0`,
    "&:after, &:before": {
      bottom: `100%`,
      right: `15px`,
      border: `solid transparent`,
      content: `""`,
      height: 0,
      width: 0,
      position: `absolute`,
      pointerEvents: `none`,
    },
    "&:after": {
      borderColor: `rgba(136, 183, 213, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `4px`,
      marginLeft: `-4px`,
    },
    "&:before": {
      borderColor: `rgba(235, 235, 235, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `5px`,
      marginLeft: `-5px`,
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: `5px 20px`,
    cursor: `pointer`,
    zIndex: 900000,
  }),
  option: (provided, state) => ({
    ...provided,
    color: `#717171`,
    fontSize: `15px`,
    fontWeight: 400,
    letterSpacing: `normal`,
    cursor: `pointer`,
    backgroundColor: `transparent`,
    // fontSize: 20,
    padding: "14px 0",
    borderBottom: `1px solid #EBEBEB`,
    borderRadius: 0,
    "&:hover, &:active, &:focus": {
      backgroundColor: `transparent`,
      color: "#222",
      cursor: `pointer`,
    },
    "&:last-child": {
      borderBottom: 0,
    },
  }),
}
export const selectFilterStyles = {
  container: (provided, state) => ({
    // fontSize: `50px`,
    border: `1px solid #EBEBEB`,
    borderColor: state.isFocused ? `#222` : `#EBEBEB`,
    backgroundColor:
      state.selectProps.isDisabled === true ? "#EEE" : "transparent",
    borderRadius: 8,
    padding: `10px 18px`,
    minHeight: `62px`,
    maxHeight: state.selectProps.maxHeight ? `62px` : `none`,
    // '@media(787px)': {
    // padding: `0 18px`,
    // height: `58px`,
    // },
    display: `flex`,
    alignItems: `stretch`,
    // zIndex: state.isFocused ? `9999999` : `0`,
    position: `relative`,
    // lineHeight: `1.2`,
    // "&:focus": {
    //   border: `1px solid #222`,
    // },
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...defaultControl,
    width: "100%",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...defaultValueContainer,
    // flexWrap: state.selectProps.wrapMultiItems ? "wrap" : "nowrap",
    flexWrap: "wrap",
  }),
  input: (provided, state) => ({
    flexGrow: 1,
    // boxShadow: `none!important`,
    // display: `none!important`
    position: state.selectProps.maxHeight ? "absolute" : "unset",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...defaultIndicatorsContainer,
    position: "absolute",
    right: "20px",
  }),
  loadingIndicator: (provided, state) => ({
    ...provided,
    span: {
      fontSize: "5px!important",
    },
  }),
  placeholder: (provided, state) => ({
    ...defaultPlaceholder,
  }),
  singleValue: (provided, state) => ({
    // ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: state.isFocused ? 9999999 : `0`,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99999999,
    minWidth: `200px`,
    maxWidth: `300px`,
    textAlign: `left`,
    // zIndex: state.isFocused ? 9999999 : `0`,
    // marginTop: 20,
    // fontSize: 20,
    boxShadow: `0px 6px 20px rgba(34, 34, 34, 0.05)`,
    border: `1px solid #222`,
    borderRadius: 8,
    // zIndex: state.isFocused ? `99999999999` : `0`,
    // position: 'fixed',
    // overflow: `hidden`,
    left: `50%`,
    transform: `translateX(-50%)`,
    "&:after, &:before": {
      bottom: `100%`,
      right: `15px`,
      border: `solid transparent`,
      content: `""`,
      height: 0,
      width: 0,
      position: `absolute`,
      pointerEvents: `none`,
    },
    "&:after": {
      borderColor: `rgba(136, 183, 213, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `4px`,
      marginLeft: `-4px`,
    },
    "&:before": {
      borderColor: `rgba(235, 235, 235, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `5px`,
      marginLeft: `-5px`,
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: `5px 20px`,
    cursor: `pointer`,
    zIndex: 900000,
  }),
  option: (provided, state) => ({
    ...provided,
    color: `#717171`,
    fontSize: `15px`,
    fontWeight: 400,
    letterSpacing: `normal`,
    cursor: `pointer`,
    backgroundColor: `transparent`,
    // fontSize: 20,
    padding: "14px 0",
    borderBottom: `1px solid #EBEBEB`,
    borderRadius: 0,
    "&:hover, &:active, &:focus": {
      backgroundColor: `transparent`,
      color: "#222",
      cursor: `pointer`,
    },
    "&:last-child": {
      borderBottom: 0,
    },
  }),
}
export const selectFilterStylesFullWidth = label => ({
  container: (provided, state) => ({
    // fontSize: `50px`,
    border: `1px solid #EBEBEB`,
    borderColor: state.isFocused ? `#222` : `#EBEBEB`,
    backgroundColor:
      state.selectProps.isDisabled === true ? "#EEE" : "transparent",
    borderRadius: 8,
    padding: `10px 18px`,
    minHeight: `62px`,
    maxHeight: state.selectProps.maxHeight ? `62px` : `none`,
    // '@media(787px)': {
    // padding: `0 18px`,
    // height: `58px`,
    // },
    display: `flex`,
    alignItems: `stretch`,
    // zIndex: state.isFocused ? `9999999` : `0`,
    position: `relative`,
    // lineHeight: `1.2`,
    // "&:focus": {
    //   border: `1px solid #222`,
    // },
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...defaultControl,
    width: "100%",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...defaultValueContainer,
    // flexWrap: state.selectProps.wrapMultiItems ? "wrap" : "nowrap",
    flexWrap: "wrap",
  }),
  input: (provided, state) => ({
    flexGrow: 1,
    // boxShadow: `none!important`,
    // display: `none!important`
    position: state.selectProps.maxHeight ? "absolute" : "unset",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...defaultIndicatorsContainer,
    position: "absolute",
    right: "20px",
  }),
  loadingIndicator: (provided, state) => ({
    ...provided,
    span: {
      fontSize: "5px!important",
    },
  }),
  placeholder: (provided, state) => ({
    ...defaultPlaceholder,
  }),
  singleValue: (provided, state) => ({
    // ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: state.isFocused ? 9999999 : `0`,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99999999,
    minWidth: `200px`,
    width: "560%",
    backgroundColor: "#f8f8f8",
    // maxWidth: `1300px`,
    textAlign: `left`,
    // zIndex: state.isFocused ? 9999999 : `0`,
    marginTop: "12px",
    // fontSize: 20,
    boxShadow: `0px 6px 20px rgba(34, 34, 34, 0.05)`,
    border: `1px solid #EBEBEB`,
    borderRadius: 8,
    // zIndex: state.isFocused ? `99999999999` : `0`,
    // position: 'fixed',
    // overflow: `hidden`,
    left: label === "Brands" ? `100%` : label === "Tags" ? `-100%` : `0`,
    transform: `translateX(-50%)`,
    "@media screen and (max-width: 992px)": {
      width: "330% !important",
      transform: `translateX(-32%) !important`,
    },
    "&:after, &:before": {
      bottom: `100%`,
      right: `15px`,
      border: `solid transparent`,
      // content: `""`,
      height: 0,
      width: 0,
      position: `absolute`,
      pointerEvents: `none`,
    },
    "&:after": {
      borderColor: `rgba(136, 183, 213, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `4px`,
      marginLeft: `-4px`,
    },
    "&:before": {
      borderColor: `rgba(235, 235, 235, 0)`,
      borderBottomColor: `#222`,
      borderWidth: `5px`,
      marginLeft: `-5px`,
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: `5px 20px`,
    // cursor: `pointer`,
    zIndex: 900000,
    display: "flex",
    columnGap: "20px",
    rowGap: "0px",
    flexWrap: "wrap",
    maxHeight: "200px",
    overflowY: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    color: `#717171`,
    width: "auto",
    fontSize: `15px`,
    fontWeight: 400,
    letterSpacing: `normal`,
    cursor: `pointer`,
    backgroundColor: `transparent`,
    // fontSize: 20,
    padding: "10px 0",
    // borderBottom: `1px solid #EBEBEB`,
    borderRadius: 0,
    "&:hover, &:active, &:focus": {
      backgroundColor: `transparent`,
      color: "#222",
      cursor: `pointer`,
    },
    "&:last-child": {
      borderBottom: 0,
    },
  }),
})

export const selectStylesSmall = {
  ...selectStyles,
  container: (provided, state) => ({
    // fontSize: `50px`,
    ...selectStyles.container(provided, state),
    padding: `0 18px`,
    height: `48px`,

    // lineHeight: `1.2`,
    // "&:focus": {
    //   border: `1px solid #222`,
    // },
  }),
}
export const selectStylesVisualLabel = {
  ...selectStyles,
  multiValue: (provided, state) => ({
    ...provided,
    background: "transparent",
    alignItems: "center",
    margin: "2px 2px 2px 0",
  }),
  valueContainer: (provided, state) => ({
    ...selectStyles.valueContainer(provided, state),
    display: "flex",
    //if more than 7 words, show ...
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // flexWrap: state.selectProps.wrapMultiItems ? "wrap" : "nowrap",
    flexWrap: "wrap",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    height: "20px",
    width: "20px",
    marginRight: "4px",
    marginLeft: "2px",
    borderRadius: "6px",
    background: "#ebebeb",
    border: "1px solid #fff",
    transition: ".15s ease all",
    color: "#222",
    boxShadow: "inset 0 0 0 1px #fff",
    svg: {
      fill: "rgba(0,0,0,.4)",
    },
    "&:hover": {
      borderColor: "#222",
      background: "#ebebeb",
      color: "#222",
      svg: {
        fill: "#222",
      },
    },
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    padding: "2px",
    paddingLeft: 0,
  }),
}
