/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { Link } from "gatsby"
import {
  red,
  green,
  blue,
  blueLight,
  redLight,
  greenLight,
  yellow,
  yellowLight,
} from "../Styles"

function ButtonLite(props) {
  const {
    color,
    children,
    height,
    inline,
    callback,
    sm,
    bold,
    hoverMeta,
    company,
    onClick,
    type,
    customContent,
    colorHover,
  } = props

  let colorFont = red,
    colorBg = colorHover || redLight,
    colorBgHover = red,
    colorFontHover = colorHover || redLight
  if (color === "green") {
    colorFont = green
    colorBg = greenLight
    colorBgHover = green
    colorFontHover = "#fff"
  }
  if (color === "blue") {
    colorFont = blue
    colorBg = blueLight
    colorBgHover = blue
    colorFontHover = blueLight
  }
  if (color === "grey") {
    colorFont = "#22233"
    colorBg = "#ededed"
    colorBgHover = "rgba(0,0,0,.3)"
    colorFontHover = "#fff"
  }
  if (color === "yellow") {
    colorFont = yellow
    colorBg = yellowLight
    colorBgHover = yellow
    colorFontHover = yellowLight
  }

  let hoverMetaCss = ``

  if (hoverMeta)
    hoverMetaCss = `&:after {
    content: "${hoverMeta}";
    position: absolute;
    left: calc(100% + 15px);
    // width: 100%;
    white-space:nowrap;
    text-align: left;
    opacity: 0;
    color: ${colorFont};
    transition: .2s ease all;
    transform: translateX(-10px);
    font-size: ${sm ? "10px" : "12px"};
  }
  &:hover {
    &:after {
      opacity: 1;
      transform: translateX(0);
    }
  }`

  const buttonContent = customContent ? customContent : children
  const hoverBg = customContent ? "" : colorBgHover
  // colorBgHover = red,

  if (typeof callback !== "undefined") {
    return (
      <button
        type={type && type}
        onClick={callback}
        className={`${props.className && props.className} transition-all`}
        css={css`
          &&& {
            position: relative;
            min-width: ${inline ? `0` : `112px`};
            padding: ${inline ? `0 15px` : `0`};
            border-radius: 8px;
            height: ${height ? height : "35px"};
            font-family: "Jost";
            font-style: normal;
            font-weight: ${bold ? 500 : 400};
            font-size: ${sm ? "12px" : "14px"};
            line-height: ${height ? height : "35px"};
            letter-spacing: ${sm ? 0 : "0.02em"};
            background-color: ${customContent ? "white" : colorBg};
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${colorFont};
            border: ${color === "grey" ? `1px solid rgba(0,0,0,.1)` : 0};
            path {
              transition: 0.2s ease all;
            }
            &:hover {
              background-color: ${colorBgHover ? hoverBg : colorFont};
              color: ${colorFontHover ? colorFontHover : colorBg};
              path {
                fill: ${color === "green" ? "#fff" : colorBg};
              }
              border: ${color === "grey" ? `1px solid rgba(0,0,0,0)` : 0};
            }
            ${hoverMetaCss}
          }
        `}
      >
        {buttonContent}
      </button>
    )
  }
  if (company) {
    return (
      <button
        // type={"submit"}
        onClick={onClick}
        className={`${props.className && props.className} transition-all`}
        css={css`
          &&& {
            position: relative;
            min-width: ${inline ? `0` : `112px`};
            padding: ${inline ? `0 15px` : `0`};
            border-radius: 8px;
            height: ${height ? height : "35px"};
            font-family: "Jost";
            font-style: normal;
            font-weight: ${bold ? 500 : 400};
            font-size: ${sm ? "12px" : "14px"};
            line-height: ${height ? height : "35px"};
            letter-spacing: ${sm ? 0 : "0.02em"};
            background-color: ${colorBg};
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${colorFont};
            border: ${color === "grey" ? `1px solid rgba(0,0,0,.1)` : 0};
            path {
              transition: 0.2s ease all;
            }
            &:hover {
              background-color: ${colorBgHover ? colorBgHover : colorFont};
              color: ${colorFontHover ? colorFontHover : colorBg};
              path {
                fill: ${color === "green" ? "#fff" : colorBg};
              }
              border: ${color === "grey" ? `1px solid rgba(0,0,0,0)` : 0};
            }
            ${hoverMetaCss}
          }
        `}
      >
        {children}
      </button>
    )
  }
  return (
    <Link
      to={props.to ? props.to : "#"}
      // onClick={callback}
      className={`${props.className && props.className} transition-all`}
      css={css`
        &&& {
          position: relative;
          min-width: ${inline ? `0` : `112px`};
          padding: ${inline ? `0 15px` : `0`};
          border-radius: 8px;
          height: ${height ? height : "35px"};
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: ${height ? height : "35px"};
          letter-spacing: 0.02em;
          background-color: ${colorBg};
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${colorFont};
          border: ${color === "grey" ? `1px solid rgba(0,0,0,.1)` : 0};
          path {
            transition: 0.2s ease all;
          }
          &:hover {
            background-color: ${colorBgHover ? colorBgHover : colorFont};
            color: ${colorFontHover ? colorFontHover : colorBg};
            path {
              fill: ${color === "green" ? "#fff" : colorBg};
            }
            border: ${color === "grey" ? `1px solid rgba(0,0,0,0)` : 0};
          }
          ${hoverMetaCss}
        }
      `}
    >
      {children}
    </Link>
  )
}

export default ButtonLite
