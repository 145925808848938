const parse = require("html-react-parser")

export const parseHTML = html => {
  if (typeof html !== "string") return ""
  if (html === "<p><br></p>") return ""
  return parse(html)
}

export const isLatestUpdated = (date_created, diffDays) => {
    const date_now = new Date().getTime() / 1000;
    const delta = Math.abs( date_now  - date_created );
    const days = Math.floor(delta / 86400);

    return days < diffDays
}