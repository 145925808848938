/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function SlideoutBody(props) {
  const { children } = props

  return <div className="relative flex-1 px-[30px]">{children}</div>
}

export default SlideoutBody
