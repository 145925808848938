/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React,{Fragment} from "react"
import { Dialog, Transition } from "@headlessui/react"

function SlideoutBackdrop(props) {
  // const { children } = props

  return (
    <Transition.Child
      as={Fragment}
      enter="ease-in-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
  )
}

export default SlideoutBackdrop
