/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import DataContext from "../../../../context/Data/DataContext"
import { classNames } from "../../../../utils"
import FloatingSelect from "../../../Elements/Form/FloatingSelect"

function AssetBrand({
  name,
  data,
  label,
  setValue,
  errors,
  register,
  description,
  control,
  watch,
}) {
  const { field_asset_brand } = watch()
  const { myBrandsAsOptions } = useContext(DataContext).data
  useEffect(() => {
    if (myBrandsAsOptions.length) {
      // console.log(myBrandsAsOptions)
      // console.log(myBrandsAsOptions, data.current_value)
      if (!data.current_value)
        setValue(name, field_asset_brand || myBrandsAsOptions[0].value)
      if (
        data.current_value &&
        data?.current_value?.length > 0 &&
        name == "field_asset_brand"
      )
        setValue(name, `${data?.current_value[0]?.target_id}`)
    }
  }, [myBrandsAsOptions])
  if (myBrandsAsOptions.length > 1)
    return (
      <FloatingSelect
        label={label}
        // placeholder="Start typing..."
        // type={"myBrandsAsOptions"}
        myBrands={true}
        // isSearchable
        required
        // defaultValue={myBrandsAsOptions[0]}
        hookForm
        name={name}
        register={register}
        error={errors[name]}
        options={myBrandsAsOptions}
        control={control}
        // customChange={v => {
        //   console.log(v)
        //   setTitle(v.label)
        // }}
      />
    )
  else return null
}

export default AssetBrand
