/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

export default function MenuHead(props) {
  const { title } = props
  return (
    <div className={`${!props.reducedPaddingX && `px-4`} ${!props.reducedPaddingY && `pb-2`} w-full`}>
      <span
        className=""
        css={css`
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 0.02rem;
        `}
      >
        {title}
      </span>
    </div>
  )
}
