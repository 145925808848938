/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useState } from "react"
import ReactDOM from "react-dom"
import { Dialog, Transition } from "@headlessui/react"
import Icon from "../../assets/icons/close-alt.svg"
import { useMediaQuery } from "react-responsive"
// class ModalContainer extends React.Component {
//   constructor(props) {
//     super(props)
//     this.el = document.createElement("div")
//   }

//   componentDidMount() {
//     // The portal element is inserted in the DOM tree after
//     // the Modal's children are mounted, meaning that children
//     // will be mounted on a detached DOM node. If a child
//     // component requires to be attached to the DOM tree
//     // immediately when mounted, for example to measure a
//     // DOM node, or uses 'autoFocus' in a descendant, add
//     // state to Modal and only render the children when Modal
//     // is inserted in the DOM tree.
//     const modalRoot = document.getElementById("modal-root")

//     modalRoot.appendChild(this.el)
//   }

//   componentWillUnmount() {
//     const modalRoot = document.getElementById("modal-root")

//     modalRoot.removeChild(this.el)
//   }

//   render() {
//     return ReactDOM.createPortal(this.props.children, this.el)
//   }
// }

export default function Modal({
  isOpen,
  closeModal,
  children,
  title,
  subtitle,
  md,
  full,
  fullMax,
  screenHeight,
  isMap,
  p0,
  titleColor,
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  if (isMobile) {
    return (
      <>
        <Transition
          as={Fragment}
          show={isOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0"
            // onClick={closeModal}
            css={css`
              &&& {
                background: rgba(0, 0, 0, 0.7);
              }
            `}
          />
        </Transition>
        <Transition
          appear
          show={isOpen}
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom={isMobile ? `opacity-0 translate-y-full` : `opacity-0`}
          enterTo={isMobile ? `opacity-100 translate-y-0` : `opacity-100`}
          leave="ease-out duration-500"
          leaveFrom={isMobile ? `opacity-100 translate-y-0` : `opacity-100`}
          leaveTo={isMobile ? `opacity-0 translate-y-full` : `opacity-100`}
        >
          <Dialog
            as="div"
            className="fixed z-[10] overflow-y-auto bottom-0 left-0 right-0"
            onClose={closeModal}
          >
            <div
              className={`inline-block w-full ${!md && `max-w-xl`} ${
                md && `max-w-[960px]`
              }
              ${
                full && `max-w-[100vw] w-[100%] `
              } md:my-8 overflow-visible text-left align-bottom md:align-middle transition-all transform bg-white shadow-xl rounded-[8px]`}
            >
              <div className="border-b border-[#EBEBEB] flex items-center py-[10px] px-[15px]">
                {title && (
                  <h1 className={`text-strong-20 ${titleColor}`}>{title}</h1>
                )}
                <div className="ml-auto">
                  <button
                    type="button"
                    className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
                    onClick={closeModal}
                  >
                    <Icon className="w-3 h-3" />
                  </button>
                </div>
              </div>
              <div
                className={
                  !isMap
                    ? "p-[15px] min-h-[70vh] max-h-[70vh] md:min-h-none md:max-h-none overflow-x-hidden overflow-y-scroll"
                    : "h-[70vh] md:h-[600px] "
                }
              >
                {children}
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          as="div"
          className={`fixed inset-0 z-[99] ${
            screenHeight ? `overflow-hidden` : `overflow-y-auto`
          }`}
          onClose={closeModal}
        >
          <div
            className={`${screenHeight ? `h-screen` : `min-h-screen`} ${
              full ? `px-[50px] py-[40px]` : fullMax ? `!p-0` : `px-4`
            } text-center`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                css={css`
                  &&& {
                    background: rgba(0, 0, 0, 0.7);
                  }
                `}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-out duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block w-full ${!md && `max-w-xl`} ${
                  md && `max-w-[960px]`
                }
                ${
                  full
                    ? `!my-[10px] max-w-[100vw] w-[100%]`
                    : fullMax
                    ? `!max-w-[100vw] !w-[100%] !min-h-[100vh] !my-0`
                    : ``
                }
                ${
                  screenHeight && `h-[calc(100vh-20px)]`
                } my-8 overflow-visible text-left align-middle transition-all transform bg-white shadow-xl ${
                  !fullMax && `rounded-[8px]`
                }`}
              >
                <div
                  className={`border-b border-[#EBEBEB]  py-[10px] px-[30px] ${
                    fullMax && `sticky bg-[#fff] top-[0]`
                  }`}
                >
                  <div className="flex items-center w-full">
                    {title && (
                      <h1 className={`text-strong-25 ${titleColor}`}>
                        {title}
                      </h1>
                    )}
                    <div className="ml-auto">
                      <button
                        type="button"
                        className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
                        onClick={closeModal}
                      >
                        <Icon className="w-3 h-3" />
                      </button>
                    </div>
                  </div>
                  {subtitle && (
                    <p className="text-reg-14 !leading-[19px] max-w-[400px] mb-3">
                      {subtitle}
                    </p>
                  )}
                </div>

                <div
                  className={!isMap ? (p0 ? "p-0" : "p-[30px]") : "h-[600px] "}
                >
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
