/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import ButtonLite from "./ButtonLite"
import FilterIcon from "../../assets/icons/filter.svg"
import { RefreshIcon } from "@heroicons/react/outline"

function ButtonFilter(props) {
  const { title, callback, color, type, iconColor, noMargin } = props

  return (
    <ButtonLite
      inline
      callback={callback}
      className={noMargin ? "space-x-2" : "space-x-2 mr-4"}
      color={color}
      type={type}
    >
      {props.reset ? (
        <RefreshIcon className="w-3 h-3" />
      ) : (
        <FilterIcon
          className={`w-3 h-3 fill-${iconColor ? iconColor : "red"}`}
        />
      )}
      <span className="uppercase text-semibold-11">{title}</span>
    </ButtonLite>
  )
}

export default ButtonFilter
