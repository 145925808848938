/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { useContext } from "react"
// import EntityReferenceRevisions from "./DynamicForm/FormTypes/EntityReferenceRevisions"
import NotificationContext from "../../context/Notification/NotificationContext"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import DataContext from "../../context/Data/DataContext"
import ButtonLite from "../Elements/ButtonLite"
import { Tr, Td1, Td2 } from "../Table/TableElements"
import FloatingQuantitySelect from "../Elements/Form/FloatingQuantitySelect"
import IconClose from "../Elements/IconClose"
import { PlusSmIcon } from "@heroicons/react/solid"
import AssetAllocationsTable from "../Table/AssetAllocationsTable"

const Allocations = props => {
  const { toggleNotification } = useContext(NotificationContext)
  const { allocationState, setAllocationState, errors, total } = props
  // const [create, setCreate] = useState(false)
  // console.log(props, "props")
  const { myUserGroups, myUserGroupsAsOptions, universalUserGroups } =
    useContext(DataContext).data

  // const increment = k => {
  //   return
  // }
  // const decrement = k => {
  //   return
  // }
  console.log(allocationState, "internal")

  const addAnother = () => {
    if (
      parseInt(total) === 0 &&
      props.watch()?.field_asset_type?.value === "physical"
    )
      return

    // set the default allocation to 10 unless the total stock remaining is less than 10, then set it to the total stock remaining in the allocationState is less than 10, then set it to the total stock remaining
    let defaultAllocation = 0
    if (parseInt(total) < 10) {
      defaultAllocation = parseInt(total)
    } else {
      let totalAllocated = 0
      allocationState.forEach(item => {
        totalAllocated += parseInt(item.aloc)
      })
      if (totalAllocated + 10 > parseInt(total)) {
        defaultAllocation = parseInt(total) - totalAllocated
      }
    }

    setAllocationState(prevState => [
      ...prevState,
      {
        roles: [],
        aloc: parseInt(defaultAllocation),
        type: "can_order",
        restriction_number: "5",
        restriction_frequency: "daily",
        // price: "0",
      },
    ])
  }

  const onRemove = k => {
    if (
      parseInt(total) === 0 &&
      props.watch()?.field_asset_type?.value === "physical"
    )
      return

    let tmpArr = JSON.parse(JSON.stringify([...allocationState]))
    tmpArr.splice(k, 1)
    setAllocationState(tmpArr)
  }

  const updateFieldChanged = (stateIndex, fieldName, fieldValue) => {
    if (
      parseInt(total) === 0 &&
      props.watch()?.field_asset_type?.value === "physical"
    )
      return

    if (fieldName === "aloc") {
      // check that total allocated does not exceed total in stock
      let totalAllocated = 0
      allocationState.forEach((item, k) => {
        if (k !== stateIndex) {
          totalAllocated += parseInt(item.aloc)
        }
      })
      totalAllocated += parseInt(fieldValue)
      if (totalAllocated > parseInt(total)) {
        toggleNotification({
          error: true,
          content: "You've reached the maximum stock available.",
        })

        return
      }
    }

    let newArr = [...allocationState]
    newArr[stateIndex][fieldName] = fieldValue
    setAllocationState(newArr)
  }

  const typesOptions = [
    { value: "can_order", label: "Can Order" },
    { value: "must_request", label: "Must Request" },
  ]

  const frequencyOptions = [
    { value: "daily", label: "Per day" },
    { value: "weekly", label: "Per week" },
    // { value: "biweekly", label: "Biweekly" },
    { value: "monthly", label: "Per month" },
    { value: "yearly", label: "Per year" },
  ]

  return (
    <div className="relative">
      {parseInt(total) === 0 &&
        props.watch()?.field_asset_type?.value === "physical" && (
          <div
            className="absolute mb-2 top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.4)] z-[1] rounded-[8px]"
            css={css`
              backdrop-filter: blur(4px);
            `}
          >
            <div className="bg-white rounded-[8px] px-8 py-10 absolute top-[100px] mx-auto left-0 right-0 w-[50%] shadow-[0_10px_30px_rgba(0,0,0,.2)]">
              <h2 className="text-strong-25 !text-left">Sorry!</h2>
              <p className="text-reg-16 mb-4">
                This item must be in stock before allocation inventory.
              </p>
            </div>
          </div>
        )}
      <div
        className={
          parseInt(total) === 0 &&
          props.watch()?.field_asset_type?.value === "physical"
            ? `p-6 h-[400px] overflow-hidden`
            : `p-0`
        }
      >
        {/* <p className="text-reg-16 mb-8">
            Select 1 or more User Segments and assign their Per Segment
            Availability and Per Location Order Limits. Allocations are
            prioritized from most specific User Segment to least; Users, Stores,
            Companies and then Regions.
          </p> */}
        <AssetAllocationsTable
          columns={[
            "",
            "Per Segment Availability",
            "Per Location Order Limits",
            // "Payments",
          ]}
          sm
          overflowVisible
        >
          {allocationState.map((item, k) => {
            const option = [
              ...universalUserGroups.map(item => {
                let groupType = ""
                if (item.companies.length) groupType = "Retail companies"
                if (item.stores.length) groupType = "Retail stores"
                if (item.regions.length) groupType = "Regional retailers"
                if (item.users.length) groupType = "Company users"
                return Object.assign({
                  value: item.id,
                  label: item.title,
                  type: groupType,
                })
              }),
              ...myUserGroups.map(item => {
                let groupType = ""
                if (item.field_group_companies.length)
                  groupType = "Retail companies"
                if (item.field_group_stores.length) groupType = "Retail stores"
                if (item.field_group_regions.length)
                  groupType = "Regional retailers"
                if (item.field_group_users.length) groupType = "Company users"
                return Object.assign({
                  value: item.id,
                  label: item.title,
                  type: groupType,
                })
              }),
            ]
            // const { title } = myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] ? myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] : ''

            // console.log(myUserGroupsAsOptions, 'sss')
            // console.log(myUserGroupsAsOptions.filter(f => parseInt(f.value) === parseInt(item.roles[0]))[0])

            return (
              <Tr key={k}>
                <Td1 className="min-w-[220px] !pr-0">
                  <div className="flex flex-col">
                    <FloatingSelect
                      label="User Segment"
                      name="User Segment"
                      extraValueLabel={`Type : ${
                        option.find(
                          i =>
                            parseInt(i.value) ===
                            parseInt(
                              item.roles.length
                                ? [
                                    ...universalUserGroups.map(i =>
                                      Object.assign({
                                        value: i.id,
                                        label: i.title,
                                      })
                                    ),
                                    ...myUserGroupsAsOptions,
                                  ].filter(
                                    f =>
                                      parseInt(f.value) ===
                                      parseInt(item.roles[0])
                                  )[0].value
                                : null
                            )
                        )?.type
                      }`}
                      options={option}
                      subtitleSelector="type"
                      value={
                        item.roles.length
                          ? [
                              ...universalUserGroups.map(i =>
                                Object.assign({
                                  value: i.id,
                                  label: i.title,
                                })
                              ),
                              ...myUserGroupsAsOptions,
                            ].filter(
                              f => parseInt(f.value) === parseInt(item.roles[0])
                            )[0]
                          : null
                      }
                      className={`${
                        item.roles.length === 0 || errors.has(k)
                          ? "mt-6 !mb-0"
                          : "!mb-0"
                      } `}
                      onChange={v => {
                        console.log([
                          ...universalUserGroups.map(item => {
                            let groupType = ""
                            if (item.companies.length)
                              groupType = "Retail companies"
                            if (item.stores.length) groupType = "Retail stores"
                            if (item.regions.length)
                              groupType = "Regional retailers"
                            if (item.users.length) groupType = "Company users"
                            return Object.assign({
                              value: item.id,
                              label: item.title,
                              type: groupType,
                            })
                          }),
                          ...myUserGroups.map(item => {
                            let groupType = ""
                            if (item.field_group_companies.length)
                              groupType = "Retail companies"
                            if (item.field_group_stores.length)
                              groupType = "Retail stores"
                            if (item.field_group_regions.length)
                              groupType = "Regional retailers"
                            if (item.field_group_users.length)
                              groupType = "Company users"
                            return Object.assign({
                              value: item.id,
                              label: item.title,
                              type: groupType,
                            })
                          }),
                        ])
                        updateFieldChanged(
                          k,
                          "roles",
                          v?.value ? [v.value] : []
                        )
                      }}
                      error={
                        item.roles.length === 0 || errors.has(k)
                          ? { message: "This field is required." }
                          : false
                      }
                      required={true}
                    />
                  </div>
                </Td1>

                <Td1>
                  <div className="flex space-x-0.5">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.aloc) > 0)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) - 1)
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) + 1)
                      }}
                      count={item.aloc}
                      label={"Qty"}
                      onChange={v => {
                        if (isNaN(v)) updateFieldChanged(k, "aloc", parseInt(0))
                        else updateFieldChanged(k, "aloc", parseInt(v))
                      }}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      label="Type"
                      name="type"
                      options={typesOptions}
                      onChange={v => {
                        updateFieldChanged(k, "type", v.value || null)
                      }}
                      required={true}
                      value={
                        typesOptions
                          .map(i => i.value)
                          .includes(allocationState[k].type)
                          ? typesOptions.filter(
                              f => allocationState[k].type === f.value
                            )[0]
                          : typesOptions[0]
                      }
                    />
                  </div>
                </Td1>
                <Td1>
                  <div className="flex space-x-0.5">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.restriction_number) > 0)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) - 1
                          )
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) + 1
                          )
                      }}
                      onChange={v => {
                        if (isNaN(v))
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(0)
                          )
                        else
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(v)
                          )
                      }}
                      count={parseInt(item.restriction_number)}
                      label={"Qty"}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      options={frequencyOptions}
                      onChange={v => {
                        updateFieldChanged(k, "restriction_frequency", v.value)
                      }}
                      value={
                        frequencyOptions
                          .map(i => i.value)
                          .includes(allocationState[k].restriction_frequency)
                          ? frequencyOptions.filter(
                              f =>
                                allocationState[k].restriction_frequency ===
                                f.value
                            )[0]
                          : frequencyOptions[0]
                      }
                      label="Frequency"
                      required={true}
                    />
                  </div>
                </Td1>

                {
                  //  <Td2>
                  //   <div className="flex space-x-0.5">
                  //     <FloatingQuantitySelect
                  //       flush
                  //       decrement={() => {
                  //         if (parseInt(item.price) > 0)
                  //           updateFieldChanged(
                  //             k,
                  //             "price",
                  //             parseInt(item.price) - 1
                  //           )
                  //       }}
                  //       increment={() => {
                  //         if (true)
                  //           updateFieldChanged(
                  //             k,
                  //             "price",
                  //             parseInt(item.price) + 1
                  //           )
                  //       }}
                  //       onChange={v => {
                  //         if (isNaN(v))
                  //           updateFieldChanged(k, "price", parseInt(0))
                  //         else updateFieldChanged(k, "price", parseInt(v))
                  //       }}
                  //       count={parseInt(item.price)}
                  //       label={"price*"}
                  //     />
                  //   </div>
                  // </Td2>
                }

                <Td1 className={"p-1"}>
                  <IconClose
                    callback={() => onRemove(k)}
                    small
                    height={2}
                    width={2}
                  />
                </Td1>
              </Tr>
            )
          })}
        </AssetAllocationsTable>

        <div className="mt-3 mb-8 max-w-[200px]">
          {/* <ButtonLite red inline callback={() => addAnother()}>
              Add another Allocation
            </ButtonLite> */}

          <ButtonLite
            type="button"
            inline
            callback={() => addAnother()}
            customContent={
              <div className="pt-5 flex cursor-pointer">
                <div className="w-[26px] h-[26px] text-[#2e669b] !hover:text-[#2e669b] rounded-full flex items-center justify-center border-4 border-[#2e669b]">
                  <PlusSmIcon />
                </div>
                <div className="text-[#2e669b] font-semibold text-lg pl-2">
                  Add Allocation
                </div>
              </div>
            }
            colorHover="#2e669b"
          ></ButtonLite>

          {/* <div className="pt-5 flex cursor-pointer">
              <div className="w-[26px] h-[26px] text-[#2e669b] rounded-full flex items-center justify-center border-4 border-[#2e669b]">
                <PlusSmIcon />
              </div>
              <div className="text-[#2e669b] font-semibold text-lg pl-2">
                Add Allocation
              </div>
            </div> */}
        </div>
      </div>
    </div>
  )
}
export default Allocations
