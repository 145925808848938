/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import { FixedSizeList } from "react-window"
import { ClearIndicator, DropdownIndicator } from "../../styled/Select"
import DataContext from "../../context/Data/DataContext"

const fontStyles = `font-family: "Jost";
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 28px;
letter-spacing: 0.02em;
color: #717171;`

const MenuList = props => {
  const { options, children, maxHeight, getValue } = props
  const height = 35

  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  )
}

const CreateAssetRestrict = ({
  watch,
  register,
  errors,
  control,
  data,
  name,
}) => {
  const { myUserGroups, universalUserGroups } = useContext(DataContext).data

  const [allUserGroupsAsOptions, setAllUserGroupsAsOptions] = useState([])

  useEffect(() => {
    setAllUserGroupsAsOptions([
      ...universalUserGroups.map(item => {
        let groupType = ""
        if (item.companies.length) groupType = "Retail companies"
        if (item.stores.length) groupType = "Retail stores"
        if (item.regions.length) groupType = "Regional retailers"
        if (item.users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
      ...myUserGroups.map(item => {

        // console.log(item.title, 'item.title')
        let groupType = ""
        if (item.field_group_companies.length) groupType = "Retail companies"
        if (item.field_group_stores.length) groupType = "Retail stores"
        if (item.field_group_regions.length) groupType = "Regional retailers"
        if (item.field_group_users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
    ])
  }, [myUserGroups, universalUserGroups])

  const { restrict_expanded } = watch()
  const [defaultRestrictChecked, setDefaultRestrictChecked] = useState(
    restrict_expanded === undefined ? false : restrict_expanded
  )
  const [entitySuggestion, setEntitySuggestion] = useState([
    { value: "demo", label: "demo" },
  ])

  useEffect(() => {
    setEntitySuggestion([...allUserGroupsAsOptions])
  }, [allUserGroupsAsOptions])

  const changedRestrictChecked = set => {
    setDefaultRestrictChecked(set)
  }
  return (
    <>
      <div
        className={
          "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
        }
      >
        <div className="flex">
          <div>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                defaultChecked={defaultRestrictChecked}
                onClick={() => changedRestrictChecked(!defaultRestrictChecked)}
                {...register(`restrict_expanded`)}
              />
              <label className="ml-3 text-[#222] flex flex-col">
                Restrict availability
              </label>
            </div>

            <div
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: inline-block;
                padding: 0 10px;
                margin: 0 0 0 18px;
                letter-spacing: 0.02em;
                color: #717171;
              `}
            >
              This asset is only available to certain User Groups or Regions
            </div>
          </div>
        </div>
      </div>
      {restrict_expanded === true && (
        <div className={`relative mb-6 w-full group `}>
          <FloatingSelect
            name={name}
            options={entitySuggestion}
            isSearchable={true}
            onChange={(value, action) => {
              // handleChange(value, action, setSelect1, name)
            }}
            isClearable
            // value={select1 !== null ? select1 : defaultValue[0]}
            components={{
              MenuList,
              DropdownIndicator: DropdownIndicator,
              ClearIndicator: ClearIndicator,
            }}
            hookForm
            error={errors}
            control={control}
            // defaultValue={defaultValue[0]}
            isMulti={data["value_limit"] === 1 ? false : true}
            description={data.description}
            // styles={selectStyles}
          />
          <label
            for={name}
            className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
            css={css`
              ${fontStyles}
            `}
          >
            {data.label}
          </label>
        </div>
      )}
    </>
  )
}

export default CreateAssetRestrict
