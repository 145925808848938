/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { useMediaQuery } from "react-responsive"

function BannerSimple(props) {
  const { heading } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <div className="container pt-14 pb-8 md:py-24">
      <div className="mx-auto text-center">
        <h1 className={isMobile ? `text-strong-25` : `text-strong-35`}>{heading || "O2O"}</h1>
      </div>
    </div>
  )
}

export default BannerSimple
