/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"
import postscribe from "postscribe"
import { isSSR } from "../../../helpers/RendererHelper"

function AdRegion(props) {
  const { ad } = props

  const [rand, setRand] = useState(Math.random(100000, 999999))
  useEffect(() => {
    if (props.ad && !isSSR) {
      if (ad.code_snippet !== null && ad.code_snippet !== "") {
        // const script = document.createElement("script")
        postscribe(`#-o2o__adspco-footer-P-${rand}`, `${ad.code_snippet}`)
      }
    }
  }, [ad])

  if (ad) {
    return (
      <>
        <div className=" bg-[rgba(255,255,255,.1)] overflow-hidden rounded-[8px] min-h-[250px] min-w-[250px]">
          <div id={`-o2o__adspco-footer-P-${rand}`}></div>
        </div>
      </>
    )
  }
  return null
}

export default AdRegion
