/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { classNames } from "../../../../utils"
import ErrorMessage from "../ErrorMessage"
import React, { useEffect, useState } from "react"

const fontStyles = `font-family: "Jost";
     font-style: normal;
     font-weight: 400;
     font-size: 15px;
     line-height: 28px;
     letter-spacing: 0.02em;
     color: #717171;`
const PostFixInput = ({
  onChange,
  name,
  defaultValue,
  register,
  data,
  isMg,
  ...props
}) => {
  let postfix = "mg"
  // if (!isMg) prefix = prefixes.filter(v => name.includes(v.i))[0].link

  const [inputVal, setInputValue] = useState("")
  useEffect(() => {
    if (data.current_value) setInputValue(data?.current_value)

    register(name)
  }, [])

  return (
    <input
      type={"numeric"}
      onKeyPress={event => {
        // console.log(event.key)
        if (!/[0-9]/.test(event.key) && event.key !== ".") {
          event.preventDefault()
        }
      }}
      // name={inputName}
      className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
      css={css`
        /* border: 1px solid #ebebeb; */
        border-radius: 8px;
        ${fontStyles}
      `}
      placeholder=" "
      required=""
      defaultValue={defaultValue}
      value={`${inputVal}mg`}
      onChange={e => {
        const input = e.target.value
        const { value } = e.target
        if (value.endsWith("mg")) {
          onChange(input.replace("mg", ""))
          setInputValue(input.replace("mg", ""))
        }
        console.log(input)
      }}
      {...props}
      // {...register(inputName)}
    />
  )
}
const Float = ({ data, error, control, name, register, className, setValue }) => {
  let { label, default_value, description, required } = data
  const fontStyles = `font-family: "Jost";
     font-style: normal;
     font-weight: 400;
     font-size: 15px;
     line-height: 28px;
     letter-spacing: 0.02em;
     color: #717171;`

  return (
    <div className={`relative z-0 mb-6 w-full group ${className && className}`}>
      <>
        {name.includes("_mg_") ? (
          <PostFixInput
            name={name}
            register={register}
            setValue={setValue}
            defaultValue={default_value}
            onChange={v => setValue(name, v)}
            data={data}
          />
        ) : required ? (
          <input
            type={"number"}
            className={classNames(
              "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}",
              error
                ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
            )}
            css={css`
              /* border: 1px solid #ebebeb; */
              border-radius: 8px;
              ${fontStyles}
            `}
            {...register(name, {
              required: `This field is required`,
            })}
            placeholder=" "
            defaultValue={default_value}
            step="any"
          />
        ) : (
          <input
            type={"number"}
            className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
            css={css`
              /* border: 1px solid #ebebeb; */
              border-radius: 8px;
              ${fontStyles}
            `}
            // placeholder={placeholder ? placeholder : "Type here"}
            {...register(name)}
            defaultValue={default_value}
            placeholder=" "
            step="any"
          />
        )}
      </>

      <label
        for={name}
        className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
        css={css`
          ${fontStyles}
        `}
      >
        {label === "Price" ? `$ ${label}` : label}
      </label>

      <span
        css={css`
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: inline-block;
          padding: 0 10px;
          margin: 7px 0 10px;
          letter-spacing: 0.02em;
          color: #717171;
        `}
        dangerouslySetInnerHTML={{ __html: description }}
      ></span>
    </div>
  )
}

export default Float
