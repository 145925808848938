/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { navigate } from "gatsby"
import { classNames } from "../../utils"
import ProductIcon from "../../assets/icons/coupon-alt.svg"
import AssetIcon from "../../assets/icons/coupon-alt.svg"
import Cart from "../../assets/icons/cart.svg"
// import StatusIcon from "../../assets/icons/coupon-alt.svg"
import { UserGroupIcon as RegularStatusIcon } from "@heroicons/react/outline"
import BrandIcon from "../../assets/icons/shop1.svg"
import ButtonLite from "../Elements/ButtonLite"
import CircleAvatar from "../Elements/CircleAvatar"
import ProductListCard from "./ProductListCard"
import AssestCard from "./AssestCard"
import {
  red,
  redLight,
  blue,
  blueLight,
  green,
  greenLight,
  yellow,
  yellowLight,
} from "../Styles"
import { Link } from "gatsby"
import Skeleton from "../Skeleton"
function ActivityFeedCard(props) {
  const {
    title,
    body,
    date,
    products,
    assets,
    list,
    brand,
    isPage,
    isSnippet,
    type,
    brandLogo,
    loading,
  } = props
  // console.log(props)
  // console.log("props!!!1")
  // if (type === "brand") console.log(props, "props")
  let colorIcon = red,
    colorIconBg = redLight
  if (type === "product") {
    colorIcon = green
    colorIconBg = greenLight
  }
  if (type === "asset") {
    colorIcon = blue
    colorIconBg = blueLight
  }
  if (type === "brand") {
    colorIcon = yellow
    colorIconBg = yellowLight
  }

  let id, name, logo
  if (brand && brand.length) ({ id, name, logo } = brand[0])
  if (!logo) logo = brandLogo

  const StatusIcon = ({ type }) => {
    if (type === "product")
      return <ProductIcon className="justify-self-center h-4 w-4" />
    if (type === "asset")
      return <AssetIcon className="justify-self-center h-4 w-4" />
    if (type === "brand")
      return <BrandIcon className="justify-self-center h-4 w-4" />
    return (
      <RegularStatusIcon
        className="justify-self-center h-[1.1rem] w-[1.1rem]"
        css={css`
          &&& {
            path {
              fill: none;
              stroke-width: 1.25px;
              stroke: ${colorIcon};
            }
          }
        `}
      />
    )
  }

  const ActionButton = () => {
    if (type === "brand")
      return (
        <ButtonLite color={"yellow"} to={`/brand/${id}`}>
          {"View Profile"}
        </ButtonLite>
      )

    if (type === "product" && Array.isArray(products) && products.length > 0)
      return (
        <ButtonLite color={"green"} to={`/product/${products[0].id}`}>
          {"View Product"}
        </ButtonLite>
      )

    if (type === "asset" && Array.isArray(assets) && assets.length > 0)
      return (
        <ButtonLite color={"blue"} to={`/asset/${assets[0].id}`}>
          {"View Asset"}
        </ButtonLite>
      )

    return null
    // (
    //
    //   <ButtonLite to={`/brand/${id}`}>
    //     // {type === "brand" ? "View Profile" : "View Product"}
    //   </ButtonLite>
    // )
  }
  if (loading)
    return (
      <li
        className={`${
          isPage === true &&
          !isSnippet &&
          `md:border-gray-400 md:border-dashed relative md:border-x md:border-r-0 pb-7`
        } ${!isPage && !isSnippet && `pt-8 border-b border-[#EBEBEB]`} ${
          isSnippet === true && `pb-7`
        }`}
      >
        {isPage && !isSnippet && (
          <div
            className={`w-[35px] h-[35px] left-[56px] top-[56px] z-[2] md:w-[50px] md:h-[50px] rounded-full p-1.5 border border-white border-[2px] md:border-[5px] flex md:top-0 md:left-[-25px] absolute text-center justify-center items-center md:z-[1]`}
            css={css`
              background-color: ${colorIconBg};
              svg {
                path,
                rect {
                  fill: ${colorIcon};
                }
              }
            `}
          >
            <Skeleton dark width={"100%"} height={"100%"} />
          </div>
        )}
        <div
          className={`bg-white transition-all ${
            isPage &&
            `shadow-[0px_6px_50px_rgba(34,34,34,0.05)] border border-[#EBEBEB] md:border-0 rounded-[8px] md:ml-14 p-[15px] md:p-[30px]`
          } ${isPage && !isSnippet && `border-[#EBEBEB] border`}${
            !isPage && `relative pb-8`
          }

        ${
          isSnippet &&
          `hover:shadow-[0px_10px_55px_rgba(34,34,34,0.05)] hover:scale-[1.02] cursor-pointer `
        }`}
        >
          <div className="relative flex space-x-3">
            <div class="relative w-14 h-14 mr-6 w-full">
              <CircleAvatar
                alt={""}
                image={""}
                border
                md
                forceSizeMobile={"70px"}
              />
            </div>
            <div className="w-full">
              <div className="flex w-full lg:mt-[4px] lg:mb-[20px] items-center">
                <p className="flex md:flex-col items-center md:items-start space-x-2">
                  <Skeleton dark width={"200px"} height={"20px"} />
                </p>
                <p
                  className="italic lowercase text-[#7a7a7a] text-reg-14 ml-auto"
                  css={css`
                    &&& {
                      line-height: 1.1;
                    }
                  `}
                >
                  <Skeleton dark width={"300px"} height={"10px"} />
                </p>
              </div>
              <div className="mt-[10px] md:mt-0 md:flex-1 flex flex-col items-start md:flex-row md:justify-between md:space-x-4 md:items-center">
                <div className="w-full space-y-2 md:space-y-0">
                  <h5
                    className="text-med-10 uppercase hidden md:block"
                    css={css`
                      &&& {
                        color: #717171;
                      }
                    `}
                  >
                    <Skeleton dark width={"200px"} height={"30px"} />
                  </h5>
                  <p className="text-reg-16 text-dark">
                    <Skeleton dark width={"200px"} height={"10px"} />
                  </p>
                  <div className="w-full mt-[30px]">
                    {Array(2)
                      .fill(1)
                      .map((v, k) => {
                        return (
                          <>
                            <AssestCard loading={true} />
                          </>
                        )
                      })}
                  </div>
                </div>
                <div className="mt-4 mb-2 md:mb-0 md:mt-0">
                  <Skeleton dark />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  return (
    <li
      className={`${
        isPage === true &&
        !isSnippet &&
        `md:border-gray-400 md:border-dashed relative md:border-x md:border-r-0 pb-7`
      } ${!isPage && !isSnippet && `pt-8 border-b border-[#EBEBEB]`} ${
        isSnippet === true && `pb-7`
      }`}
    >
      {isPage && !isSnippet && (
        <div
          className={`w-[35px] h-[35px] left-[56px] top-[56px] z-[2] md:w-[50px] md:h-[50px] rounded-full p-1.5 border border-white border-[2px] md:border-[5px] flex md:top-0 md:left-[-25px] absolute text-center justify-center items-center md:z-[1]`}
          css={css`
            background-color: ${colorIconBg};
            svg {
              path,
              rect {
                fill: ${colorIcon};
              }
            }
          `}
        >
          <StatusIcon type={type} />
        </div>
      )}
      <div
        className={`bg-white transition-all ${
          isPage &&
          `shadow-[0px_6px_50px_rgba(34,34,34,0.05)] border border-[#EBEBEB] md:border-0 rounded-[8px] md:ml-14 p-[15px] md:p-[30px]`
        } ${isPage && !isSnippet && `border-[#EBEBEB] border`}${
          !isPage && `relative pb-8`
        }

        ${
          isSnippet &&
          `hover:shadow-[0px_10px_55px_rgba(34,34,34,0.05)] hover:scale-[1.02] cursor-pointer `
        }`}
        onClick={() => (isSnippet ? navigate("/activity") : null)}
      >
        <div className="relative flex space-x-3">
          <div class="relative w-14 h-14 mr-6 w-full">
            <Link to={`/brand/${id}`}>
              <CircleAvatar
                alt={name}
                image={logo}
                border
                md
                forceSizeMobile={"70px"}
              />
            </Link>
          </div>
          <div className="w-full">
            <div className="flex w-full lg:mt-[4px] lg:mb-[20px] items-center">
              <p className="flex md:flex-col items-center md:items-start space-x-2">
                <Link to={`/brand/${id}`}>
                  {" "}
                  {title && <span className="text-med-16">{title}</span>}{" "}
                </Link>
              </p>
              <p
                className="italic lowercase text-[#7a7a7a] text-reg-14 ml-auto"
                css={css`
                  &&& {
                    line-height: 1.1;
                  }
                `}
              >
                {date}
              </p>
            </div>
            <div className="mt-[10px] md:mt-0 md:flex-1 flex flex-col items-start md:flex-row md:justify-between md:space-x-4 md:items-center">
              <div className="w-full space-y-2 md:space-y-0">
                {name && type === "brand" && (
                  <Link to={`/brand/${id}`}>
                    <h5
                      className="text-med-10 uppercase hidden md:block"
                      css={css`
                        &&& {
                          color: #717171;
                        }
                      `}
                    >
                      {name}
                    </h5>
                  </Link>
                )}
                {name && type !== "brand" && (
                  <h5
                    className="text-med-10 uppercase hidden md:block"
                    css={css`
                      &&& {
                        color: #717171;
                      }
                    `}
                  >
                    {name}
                  </h5>
                )}
                <p className="text-reg-16 text-dark">{body}</p>
                {products?.length > 0 && (
                  <div className="w-full mt-[30px]">
                    {products.map((v, k) => {
                      return (
                        <>
                          <ProductListCard {...v} />
                        </>
                      )
                    })}
                  </div>
                )}
                {assets?.length > 0 && (
                  <div className="w-full mt-[30px]">
                    {assets.map((v, k) => {
                      return (
                        <>
                          <AssestCard {...v} />
                        </>
                      )
                    })}
                  </div>
                )}
              </div>
              <div className="mt-4 mb-2 md:mb-0 md:mt-0">
                <ActionButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default ActivityFeedCard
