/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { PlusCircleIcon, } from "@heroicons/react/outline";

function ButtonAdd(props) {
    const { text, onClick, disable } = props

    if (props.disable) {
        return (
            <div
                className={`${props.className ? props.className : ""} flex items-center justify-center`}
                css={css`
                    border-radius: 8px;
                    letter-spacing: 0.03em;
                    color: #9c9c9c;
                    transition: 0.2s ease all;
                    cursor: not-allowed;
                    pointer-events: all !important;
                    &:hover {
                        color: #9c9c9c;
                    }
                    svg {
                        path {
                            fill: #9c9c9c;
                        }
                        stroke-width: 1.6px;
                    }
                `}
            >
                <div className="bg-[#ebebeb] rounded-[8px] h-[30px] w-[30px] flex items-center justify-center mr-2">
                    <PlusCircleIcon className="text-white h-4 w-4" />
                </div>
                <span className="">{text || "Add"}</span>
            </div>
        )
    }
    return (
        <button
            onClick={onClick}
            className="text-reg-15 flex items-center hover-group"
            css={css`
                &&& {
                    color: #009D4F;
                    svg {
                        stroke-width: 1.6px;
                    }
                }
            `}
            type={"button"}
        >
            <div className="bg-[#e0f0e5] rounded-[8px] h-[30px] w-[30px] flex items-center justify-center mr-2">
                <PlusCircleIcon className="h-4 w-4" />
            </div>
            <span className="hover hover-green">{text || "Add"}</span>
        </button>
    )
}

export default ButtonAdd
