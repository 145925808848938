var emailExp =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
export function validateEmail(email) {
  if (!email) return false

  if (email.length > 254) return false
  var valid = emailExp.test(email)
  if (!valid) return false
  // Further checking of some things regex can't handle
  var parts = email.split("@")
  if (parts[0].length > 64) return false
  var domainParts = parts[1].split(".")
  if (
    domainParts.some(function (part) {
      return part.length > 63
    })
  )
    return false
  return true
}


export function stringIncludesCanadianTelephone(p) {
  var phoneExp = /\d{11,}/
  var valid = phoneExp.test(p)
  console.log(valid, 'v')
  if (!valid) return false
  // Further checking of some things regex can't handle
  // var parts = phone.toString().split("0")
  // if (parts[0].length > 10) return false

  return true

}

export function validatePhone(p) {

  let phone = p.replaceAll("-", "")
  // phone = parseInt(phone)
  // console.log(phone.length, 'phone')
  if (!phone) return false
  if (phone.length !== 10) return false

  return true;

  // var valid = phoneExp.test(p)
  // // alert(valid)
  // if (!valid) return false
  // // Further checking of some things regex can't handle
  // // var parts = phone.toString().split("0")
  // // if (parts[0].length > 10) return false
  //
  // return true
}

export function validateUrl(url) {
  if (
    new RegExp(
      "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
    ).test(url)
  ) {
    return true
  } else {
    return false
  }
}
