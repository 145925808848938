import React, { useState, useEffect } from "react"
import {
  getSubUserListing,
  myEntitySuggestions,
} from "../../../../helpers/DrupalHelper"

import FloatingSelect from "../../../Elements/Form/FloatingSelect"
import Skeleton from "../../../Skeleton"

function CompanyField({
  data,
  name,
  register,
  label,
  setValue,
  token,
  watch,
  control,
  errors,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState(null)

  useEffect(async () => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      })
    } else {
      register(name)
    }
    const entity = data.entity_type.machine_name
    const bundle = data.entities
      ? data.entities.map(v => v.machine_name)
      : data.entity_type.machine_name
    if (data.current_value) {
      setValue(
        `${name}`,
        data.current_value.map(item => item.target_id.toString())
      )
      let a = data.current_value.map(item => {
        return { value: item.target_id, label: item.title }
      })
    }

    let datas = await myEntitySuggestions(token, entity, bundle)

    if (datas.length === 1) {
      setValue(name, datas[0].target_id ? [datas[0].target_id] : [datas[0].id])
    }
    setEntitySuggestion(
      datas.map(v => ({
        ...v,
        value: v.target_id ? v.target_id : v.id,
        label: v.title,
      }))
    )
  }, [])

  if (entitySuggestion !== null) {
    let defaultVal = null

    if (
      data.current_value &&
      (data.value_limit === false || data.value_limit > 1)
    )
      defaultVal = data.current_value.map(item => ({
        value: item.target_id,
        label: item.title,
      }))
    else if (data.current_value && data.value_limit === 1)
      defaultVal = {
        value: data.current_value[0].target_id,
        label: data.current_value[0].title,
      }

    return (
      <>
        {entitySuggestion.length > 1 ? (
          <FloatingSelect
            label={label}
            name={name}
            hookForm
            register={register}
            error={errors[name]}
            options={entitySuggestion}
            control={control}
            required
            noSelection
            defaultValue={defaultVal}
            // customChange={async v => {}}
          />
        ) : null}
      </>
    )
  } else {
    return <Skeleton />
  }
}

export default CompanyField
