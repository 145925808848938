/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext"
import {
  getUserFileListing,
  getUserFileListingPost,
} from "../../../../helpers/DrupalHelper"

import Modal from "../../../Modal/Modal"
import Button from "../../../Elements/Button"
import ErrorMessage from "../ErrorMessage"
import LazyImage from "../../../LazyImage"
import StateContext from "../../../../context/state/StateContext"
import ImageUpload from "../../../Elements/ImageUpload"
import { InformationCircleIcon } from "@heroicons/react/outline"
import Icon from "../../../../assets/icons/close-alt.svg"
import IconClose from "../../../Elements/IconClose"
import RecentlyUploadedCard from "../../../Cards/Dashboard/RecentlyUploadedCard"
const style = {
  position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
}

function MediaLibaryModal(props) {
  let { getData } = props
  const [open, setOpen] = useState(props.open)

  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const loadRef = useRef()
  // console.log(allProject);
  // const getData = async (number) => {
  //   return fetch(`${activeHost()}/api/bockdash/media/image?page=${number}`, {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "X-CSRF-Token": localStorage.getItem("token"),
  //     },
  //   }).then((response) => response.json());
  // };
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleObserver = entities => {
    const target = entities[0]
    // console.log(target);
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
    // setHasMore(true);
  }, [loadRef.current])
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const pageNo = Math.floor(currentLength / 20) + 1
      // console.log(pageNo);
      getData(pageNo).then(data => {
        // console.log(data);
        setList([...list, ...data])
        setLoadMore(false)
      })
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    if (list !== null) {
      //   const val = Math.floor(list.length / 20);
      let a = list.length % 20
      // console.log(a);
      setHasMore(a === 0 ? true : false)
    }
  }, [list]) //eslint-disable-line

  const handleClose = () => {
    setOpen(false)
    props.closeModal(false)
  }

  useEffect(async () => {
    getData(1).then(data => {
      // console.log(data);
      setList(data)
    })
  }, [])

  const insertData = v => {
    if (props.data.value_limit === 1) {
      props.setValue(`${props.name}`, { target_id: parseInt(v.id) })
      props.makeOutput(v)
    } else {
      if (props.output !== null) {
        const prevOutput = props.output.map(v => {
          return { target_id: parseInt(v.id) }
        })
        props.setValue(`${props.name}`, [
          ...prevOutput,
          { target_id: parseInt(v.id) },
        ])
      } else {
        // const prevOutput = props.output.map((v) => v.id);
        props.setValue(`${props.name}`, [{ target_id: parseInt(v.id) }])
      }

      props.makeOutput(v)
    }
    setOpen(false)
    props.closeModal(false)
  }

  return (
    <div>
      <Modal
        isOpen={open}
        closeModal={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ overflow: "scroll", outline: "none" }}
        title={"Select from Library"}
        md
      >
        <div className="container ">
          <div className="grid grid-cols grid-cols-4 mt-4">
            {list !== null &&
              list.length > 0 &&
              list.map((v, k) => {
                return (
                  <RecentlyUploadedCard
                    {...v}
                    key={k}
                    callback={() => insertData(v)}
                    widthAuto
                  />
                )
              })}
          </div>
          <div className="load-more-area text-center my-6" ref={loadRef}>
            {list.length > 0 && hasMore ? (
              <div className="w-full overflow-hidden flex flex-col items-center justify-center">
                <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
              </div>
            ) : (
              <>
                <p className="text-reg-14">
                  {list.length > 0
                    ? "No more results to display"
                    : "Nothing to display"}
                </p>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
function Media({
  // token,
  data,
  name,
  label,
  errors,
  register,
  setValue,
  watch,
  unregister,
  control,
  custom,
  location,
  file_size,
  description,
  noIcon,
  fileLimit,
  preview,
  multiFiles,
  setThumbImages,
}) {
  console.log(errors)
  const { token } = useContext(StateContext).state
  const { authentication } = useContext(AuthenticationContext)
  const userId = authentication.currentUserData.i
  const companyId = authentication.currentUserData.companies[0].id
  const getData = async number => {
    let data = await getUserFileListingPost(token, companyId, {
      type: ["jpg", "png", "jpeg", "webp"],
    })
    console.log(data)
    return data
  }
  const { value_limit } = data
  const [showModal, setShowModal] = useState(false)
  const [output, setOutput] = useState(null)
  useEffect(() => {
    if (data.current_value) {
      // console.log(data.current_value)
      if (data.value_limit === 1 && data.value_limit !== false) {
        let cImg = data.current_value[0]
        if (cImg)
          setOutput({
            thumb: cImg.file_url,
            id: cImg.target_id,
            filetype: "png/image",
            title: cImg.title,
          })
      } else {
        if (data.current_value.length) {
          let outputData = data.current_value.map(cImg => ({
            thumb: cImg.file_url,
            id: cImg.target_id,
            filetype: cImg.title.includes("png" || "jpg" || "jpeg" || "webp")
              ? "png/image"
              : "File",
            title: cImg.title,
          }))
          setOutput(outputData)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (data.required) {
      register(name, { required: "This field is required" })
    } else {
      register(name)
    }
  }, [])
  const removeSingle = () => {
    setOutput(null)
    setValue(`${name}`, null)
  }

  useEffect(() => {
    if (setThumbImages) {
      setThumbImages(output)
    }
  }, [output])
  const removeItem = i => {
    const tempArr = [...output]

    // let val = watch(`${name}`)
    // let temparrVal = val.filter(v => v.target_id !== output[i].id)
    // // console.log(temparrVal);
    // if(setValue)setValue(`${name}`, temparrVal)
    tempArr.splice(i, 1)
    setOutput([...tempArr])
  }
  const makeOutput = v => {
    // console.log(v)
    if (data.value_limit === 1 && data.value_limit !== false) {
      setOutput(v)
    } else {
      let val = output
      if (val !== null) {
        setOutput([...val, v])
      } else {
        setOutput([v])
      }
    }
  }
  // console.log(data.value_limit === 1 && output !== null)
  let valueMeta = `No file limit`
  if (value_limit !== false) {
    valueMeta = `Maximum <span style="font-weight:500;">${value_limit}</span> ${
      parseInt(value_limit) === 1 ? `file` : `files`
    }`
  }

  if (data.description) valueMeta = valueMeta + ". " + data.description
  if (description) valueMeta = description
  console.log(data)

  return (
    <>
      <div className="border border-[#EBEBEB] rounded-[8px] py-[16px] px-[18px] block w-full relative mb-6">
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              htmlFor={name}
              className="absolute transform -translate-y-7 scale-75 top-[15px] left-[12px] origin-[0] px-[8px] bg-white z-[10] text-[#717171]"
            >
              {label}
              {data.required ? "*" : ""}
            </label>
          </div>
        </div>
        <div
          className="flex items-center space-x-1 text-reg-15"
          id="PolarisTextField2HelpText"
          style={{ marginBottom: 10 }}
        >
          {!noIcon && (
            <InformationCircleIcon
              className="w-4 h-4"
              css={css`
                &&& {
                  path {
                    stroke-width: 1.5px;
                  }
                }
              `}
            />
          )}
          <span dangerouslySetInnerHTML={{ __html: valueMeta }}></span>
        </div>
        <ImageUpload
          label=""
          sm
          name={`fromUpload_${name}`}
          setValue={setValue}
          register={register}
          watch={watch}
          file_size={file_size}
          fileLimit={fileLimit}
          multiFiles={multiFiles}
          setThumbImages={setThumbImages}
        />
        <div>
          <div className="mt-[15px]">
            {data.value_limit === 1 && output !== null ? (
              <Button
                onClick={() => {
                  // console.log("Remove")
                  removeSingle(true)
                }}
                // type="button"
                pink
                widthAuto
              >
                {custom ? "Or select existing media" : "Remove Media"}
              </Button>
            ) : (
              <Button
                props={{ role: "none" }}
                onClick={() => {
                  setShowModal(true)
                  // console.log("Open,Modal", showModal)
                }}
                pink
                widthAuto
              >
                {custom ? "Or select existing media" : "Add Media"}
              </Button>
            )}
            {output !== null && data.value_limit === 1 && (
              <>
                <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                  <article className="overflow-hidden rounded-[8px] border-[#EBEBEB] border relative">
                    <div className="absolute top-3 right-3">
                      <IconClose
                        height={2}
                        width={2}
                        small
                        callback={() => removeSingle(true)}
                      />
                    </div>
                    {output.filetype.includes("image") && (
                      <>
                        <LazyImage
                          alt="Placeholder"
                          className="block h-auto w-full"
                          src={output.thumb}
                        />
                      </>
                    )}

                    {/*    <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                      <h1 className="text-lg">
                        <span className="no-underline hover:underline text-black">
                          {output.id}
                        </span>
                      </h1>
                    </header>*/}

                    <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                      <p>{output.title ? output.title : output.filetype}</p>
                    </footer>
                  </article>
                </div>
              </>
            )}

            {output !== null && data.value_limit !== 1 && (
              <div className="flex wrap mt-[15px]">
                {output.map((file, i) => {
                  console.log(file)
                  return (
                    <div className="w-full md:w-1/2 lg:w-1/3">
                      <article className="overflow-hidden rounded-[8px] border-[#EBEBEB] border relative">
                        <div className="absolute top-3 right-3">
                          <IconClose
                            height={2}
                            width={2}
                            small
                            callback={() => removeItem(i)}
                          />
                        </div>

                        {file.filetype.includes("image") && (
                          <>
                            <LazyImage
                              alt="Placeholder"
                              className="block h-auto w-full"
                              src={file.thumb}
                            />
                          </>
                        )}

                        {file.filetype.includes("File") && preview && (
                          <>
                            <LazyImage
                              alt="Placeholder"
                              className="block h-auto w-full"
                              src={file.thumb}
                            />
                          </>
                        )}

                        {/*                        <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                          <h1 className="text-lg">
                            <span className="no-underline hover:underline text-black">
                              {file.id}
                            </span>
                          </h1>
                        </header>*/}

                        <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                          <p>{file.title ? file.title : file.filetype}</p>
                        </footer>
                      </article>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </div>
      </div>
      {showModal && (
        <MediaLibaryModal
          open={showModal}
          closeModal={setShowModal}
          name={name}
          setValue={setValue}
          output={output}
          register={register}
          data={data}
          makeOutput={makeOutput}
          getData={getData}
          // limit={data.value_limit}
        />
      )}
    </>
  )
}

export default Media
