/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/react/solid"
import { PlusSmIcon as PlusSmIconOutline } from "@heroicons/react/outline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function SocialButton(props) {
  const { lg, transparent, link } = props

  if (link !== null) {
    return (
      <a
        href={link}
        target="_blank"
        className={`inline-flex items-center justify-center ${
          lg ? `h-[50px] w-[50px]` : `h-[35px] w-[35px]`
        } rounded-full ${
          transparent
            ? `bg-transparent text-white border-[rgba(255,255,255,.1)] hover:bg-white hover:text-[#222]`
            : `bg-white text-[#222] border-[#EBEBEB] hover:bg-[#222] hover:text-white hover:border-[#222]`
        } border transition-all focus:outline-none  ${props.className}`}
      >
        <FontAwesomeIcon
          className={`${lg ? `h-4 w-4` : `h-3 w-3`}`}
          icon={props.icon}
        />
      </a>
    )
  }
  return (
    <span
      className={`inline-flex items-center justify-center cursor-pointer ${
        lg ? `h-[50px] w-[50px]` : `h-[35px] w-[35px]`
      } rounded-full ${
        transparent
          ? `bg-transparent text-white border-[rgba(255,255,255,.1)] hover:bg-white hover:text-[#222]`
          : `bg-white text-[#222] border-[#EBEBEB] hover:bg-[#222] hover:text-white hover:border-[#222]`
      } border transition-all focus:outline-none  ${props.className}`}
    >
      <FontAwesomeIcon
        className={`${lg ? `h-4 w-4` : `h-3 w-3`}`}
        icon={props.icon}
      />
    </span>
  )
}

export default SocialButton
