/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import React, { Component, createRef } from "react"
import PropTypes from "prop-types"
import styles from "./style.css"

class Zoom extends Component {
  constructor(props) {
    super(props)
    const { height, img, transitionTime, width, widthS, heightS } = this.props

    this.state = {
      zoom: false,
      mouseX: null,
      mouseY: null,
      outerDivStyle: {
        height: `${widthS}`,
        width: `${heightS}`,
        overflow: "hidden",
      },
      innerDivStyle: {
        height: `${heightS}`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "auto 100%",
        transition: `transform ${transitionTime}s ease-out`,
        backgroundImage: `url('${img}')`,
      },
    }

    // this.outerDivStyle = {
    //   height: `${height}px`,
    //   width: `${width}px`,
    //   overflow: "hidden",
    // }

    // this.innerDivStyle = {
    //   height: `${height}px`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "center",
    //   backgroundSize: "auto 100%",
    //   transition: `transform ${transitionTime}s ease-out`,
    //   backgroundImage: `url('${img}')`,
    // }

    this.imageRef = createRef()

    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
    this.handleMouseMovement = this.handleMouseMovement.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  handleMouseOver() {
    this.setState({
      zoom: true,
    })
  }

  handleMouseOut() {
    this.setState({
      zoom: false,
    })
  }

  handleMouseMovement(e) {
    const { height, width } = this.props

    var imageWidth = width,
      imageHeight = height,
      ratio = imageHeight / imageWidth

    // Adjust the box to fit the image and to adapt responsively
    var percentage = ratio * 100 + "%"
    const { offsetLeft, offsetTop } = this.imageRef.current
    var boxWidth = this.imageRef.current.clientWidth,
      // Get the cursor position, minus element offset
      x = e.pageX - offsetLeft,
      y = e.pageY - offsetTop,
      // Convert coordinates to % of elem. width & height
      xPercent = x / (boxWidth / 100) + "%",
      yPercent = y / ((boxWidth * ratio) / 100) + "%"

    let a = {
      backgroundPosition: xPercent + " " + yPercent,
      backgroundSize: imageWidth + "px",
      // paddingBottom: percentage,
    }
    console.log(a, offsetLeft, offsetTop, boxWidth)

    this.setState(prevState => ({
      innerDivStyle: { ...prevState.innerDivStyle, ...a },
    }))
    // this.setState({
    //   mouseX: x,
    //   mouseY: y,
    // })
  }
  onMouseLeave = () => {
    let a = {
      backgroundPosition: "center",
      backgroundSize: "cover",
      paddingBottom: 0,
    }
    this.setState(prevState => ({
      innerDivStyle: { ...prevState.innerDivStyle, ...a },
    }))
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { height, img, transitionTime, width, widthS, heightS } = this.props

      let outerDivStyle = {
        height: `${heightS}`,
        width: `${widthS}`,
        overflow: "hidden",
      }

      let innerDivStyle = {
        height: `${heightS}`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "auto 100%",
        transition: `transform ${transitionTime}s ease-out`,
        backgroundImage: `url('${img}')`,
      }

      this.setState(prevState => ({
        ...prevState,
        outerDivStyle,
        innerDivStyle,
      }))
    }
  }
  render() {
    const { mouseX, mouseY, zoom, outerDivStyle, innerDivStyle } = this.state

    const { zoomScale } = this.props

    // const transform = {
    //   transformOrigin: `${mouseX - 100}% ${mouseY - 100}%`,
    // }

    return (
      <div
        style={{ ...outerDivStyle, height: "100%", width: "100%" }}
        // onMouseOver={this.handleMouseOver}
        // onMouseOut={this.handleMouseOut}
        // onMouseMove={this.handleMouseMovement}
        // onMouseLeave={this.onMouseLeave}
        ref={this.imageRef}
      >
        <div
          style={{
            // ...transform,
            ...innerDivStyle,
            // transform: zoom ? `scale(${zoomScale})` : "scale(1.0)",
            maxHeight: "100%",
          }}
          className={"zoomImg"}
        />
      </div>
    )
  }
}

Zoom.propTypes = {
  /** The path to the image. It can be a url. */
  img: PropTypes.string.isRequired,
  /** The zoom scale. */
  zoomScale: PropTypes.number.isRequired,
  /** The height of the image in pixels */
  height: PropTypes.number.isRequired,
  /** The width of the image in pixels */
  width: PropTypes.number.isRequired,
  /** The time (in seconds) that will take to scale your image. */
  transitionTime: PropTypes.number,
}

Zoom.defaultProps = {
  transitionTime: 0.1,
}

export default Zoom
