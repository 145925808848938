/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { useMediaQuery } from "react-responsive"

const MenuBackdrop = props => {
  const { open } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  if (!isMobile) return null
  return (
    <Transition
      as={Fragment}
      show={open}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-0"
        // onClick={closeModal}
        css={css`
          &&& {
            background: rgba(0, 0, 0, 0.7);
          }
        `}
      />
    </Transition>
  )
}

export default MenuBackdrop
