/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import NavigateIcon from "../../../../assets/icons/navigate.svg"
import Cart from "../../../../assets/icons/cart.svg"
import BellIcon from "../../../../assets/icons/bell.svg"
import Button from "../../../Elements/Button"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { ExclamationCircleIcon } from "@heroicons/react/outline"
import { red } from "../../../Styles"
import { navigation } from "../../../Layout/Dashboard"
import { styles } from "./utils"
import HeaderFeed from "../HeaderFeed"
import Separator from "../../../Elements/Separator"
import { getMyNotifications } from "../../../../helpers/DrupalHelper"
import StateContext from "../../../../context/state/StateContext"
import { classNames } from "../../../../utils"
import MenuSection from "../MenuSection"
import { useMediaQuery } from "react-responsive"
export default function IconButton(props) {
  const { floating, icon, isCart, isMessage, badge } = props
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const url = window.location.pathname

  return (
    <Menu.Button
      className={classNames(
        styles,
        floating
          ? "hover:bg-white hover:ring-[#fff]"
          : "hover:ring-[#EBEBEB] hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)]",
        isMobile && `hover:bg-[#fce3e1] hover:ring-[#fce3e1]`,
        isCart && "cartDropdownButton002"
      )}
      css={css`
        min-width: 50px;
        min-height: 50px;
        &:hover {
          path {
            fill: ${isMobile ? `#e43d30` : floating ? `#484848` : ``};
          }
        }
      `}
      id={isCart ? "cartDropdownButton" : ""}
    >
      <props.icon
        className="h-5 w-5"
        css={css`
          path {
            fill: ${floating ? `#fff` : `#484848`};
            ${isCart && url === "/cart"
              ? `stroke : #e43d30`
              : isMessage &&
                url === "/dashboard/messages" &&
                `stroke : #e43d30`};
          }
        `}
      />
      {badge && (
        <span className="h-[15px] w-[15px] border border-[#EBEBEB] rounded-full font-[600] text-[8px] leading-[14px] bg-white absolute top-[0] right-[0] text-[rgba(0,0,0,.8)] shadow-[0px_3px_10px_rgba(34,34,34,0.05)]">
          {badge}
        </span>
      )}
    </Menu.Button>
  )
}
