import React, { useContext, useState, useEffect } from "react"
import algoliasearch from "algoliasearch/lite"
import SearchContext from "./searchContext"
import { InstantSearch } from "react-instantsearch-dom"
import AuthenticationContext from "../authentication/AuthenticationContext"
import { getOnSiteMarketingCustomLocation } from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import { isDev } from "../../helpers/HostHelper"

const SearchContextProvider = ({ children }) => {
  const { searchOptions } = useContext(AuthenticationContext)
  const { searchKey, indexName } = searchOptions
  let [isOpen, setIsOpen] = useState(false)
  const [adCode, setAdCode] = useState(null)
  const { token } = useContext(StateContext)

  const searchClient = algoliasearch(
    isDev
      ? process.env.GATSBY_ALGOLIA_APP_ID_DEV
      : process.env.GATSBY_ALGOLIA_APP_ID,
    searchKey
  )

  let initialSearchState = {
    query: null,
  }
  let initialQuery = ""
  const [query, setQuery] = useState(initialQuery)
  const [searchState, setSearchState] = useState(initialSearchState)
  // const [filterTypes, setFilterTypes] = useState(initialSearchState)
  // console.log(query, searchState)

  // const updateHits = v => {
  //   console.log(v)
  //   setTtemsLength(v.hits.length)
  //   if (v.hits.length) {
  //     let foundTypes = []
  //     v.hits.map(c => {
  //       if (!foundTypes.includes(c.type)) foundTypes.push(c.type)
  //     })
  //     setFilterTypes(foundTypes)
  //   }
  // }
  useEffect(() => {
    // console.log(adCode)

    if (adCode === null)
      getOnSiteMarketingCustomLocation(token, "19").then(res => {
        setAdCode(res.items)
      })
  }, [])
  const onSearchStateChange = updatedSearchState => {
    // console.log(updatedSearchState);
    setSearchState(updatedSearchState)
    if (updatedSearchState.query !== undefined)
      setQuery(updatedSearchState.query)
  }
  return (
    <SearchContext.Provider
      value={{
        query,
        searchState,
        searchModalOpen: isOpen,
        setSearchModal: setIsOpen,
        adCode,
        setAdCode,
      }}
    >
      <InstantSearch
        indexName={isDev ? "dev" : "main"}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        // refresh={true}
        searchable={true}
      >
        {children}
      </InstantSearch>
    </SearchContext.Provider>
  )
}
export default SearchContextProvider
