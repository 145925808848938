export function deliveryDate() {
  const currentDate = new Date()

  let daysToAdd = 0
  if (currentDate.getDay() === 5) {
    // Friday
    daysToAdd = 7
  } else if (currentDate.getDay() === 6) {
    // Saturday
    daysToAdd = 6
  } else if (currentDate.getDay() === 0) {
    // Sunday
    daysToAdd = 5
  } else if (currentDate.getDay() === 1) {
    // Monday
    daysToAdd = 7
  } else if (currentDate.getDay() === 2) {
    // Tueday
    daysToAdd = 7
  } else if (currentDate.getDay() === 3) {
    // Wednesday
    daysToAdd = 7
  } else if (currentDate.getDay() === 4) {
    // Thursday
    daysToAdd = 7
  }
  const estimatedDeliveryDate = new Date(
    currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  )

  return estimatedDeliveryDate
}
