/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Tag from "../Elements/Tag"
import Tags from "../Elements/Tags"
import MetaUpdated from "../Elements/MetaUpdated"
import MetaDelivery from "../Elements/MetaDelivery"
import MetaDownload from "../Elements/MetaDownload"
import ImageCardSmall from "../Elements/ImageCardSmall"
import { classNames } from "../../utils"
import AddToCartBox from "../Elements/AddToCartBox"
import { formatTextInline } from "../../helpers/RendererHelper"
import { Link } from "gatsby"
import PlaceholderImage from "../../assets/images/image_coming_soon.png"
import Skeleton from "../Skeleton"
export default function AssetsCard({
  title,
  description,
  last_update,
  last_change,
  cover,
  total_files,
  files,
  tags,
  className,
  type,
  id,
  by_request,
  listing,
  mini,
  loading,
  price,
  assetData,
  fromAssetGroupPage,
}) {
  console.log({ assetData, fromAssetGroupPage })
  if (loading)
    return (
      <li
        className={`py-[15px] ${
          !mini ? `md:py-8` : `md:py-4`
        } min-w-full ${className} ${listing ? "lg:h-full " : ""}`}
      >
        <div className={`flex ${!mini ? `md:items-start` : `md:items-center`}`}>
          <div className="mr-4">
            <ImageCardSmall />
          </div>

          <div className="grow">
            <div className="flex flex-col md:flex-row align-center">
              <h3 className="text-med-16 pr-2 flex items-center">
                <Skeleton dark width={"300px"} height={"30px"} />
              </h3>
            </div>
            <div className="flex mt-10">
              <MetaUpdated
                text={<Skeleton dark width={"100px"} height={"20px"} />}
                className="!flex !items-center"
              />

              <div className="md:ml-auto text-reg-14">
                <Skeleton dark width={"200px"} height={"40px"} />
              </div>
            </div>
            <Tags wrap>
              <Skeleton dark width={"100px"} height={"25px"} />
            </Tags>
            <div className="flex mt-3 mb-3">
              <Skeleton dark width={"100px"} height={"25px"} />
            </div>
            {!mini && (
              <>
                <p className="text-reg-15">
                  {" "}
                  <Skeleton dark width="100px" height="10px" />
                </p>
              </>
            )}
          </div>
        </div>
      </li>
    )

  return (
    <li
      className={`py-[15px] ${
        !mini ? `md:py-8` : `md:py-4`
      } min-w-full ${className} ${listing ? "lg:h-full " : ""}`}
    >
      <div className={`flex ${!mini ? `md:items-start` : `md:items-center`}`}>
        <div className="mr-4">
          <Link to={`/asset/${id}`}>
            <ImageCardSmall
              image={
                Array.isArray(cover)
                  ? cover[0] || PlaceholderImage
                  : cover || PlaceholderImage
              }
              alt={title}
            />
          </Link>
        </div>

        <div
          className={`${
            fromAssetGroupPage ? "flex justify-between w-full" : "flex-grow"
          }`}
        >
          <div className="flex flex-col items-start --rm--justify-between">
            <h3 className="text-med-16 pr-2 flex items-center">
              <Link to={`/asset/${id}`}>{title}</Link>
            </h3>
            {fromAssetGroupPage && assetData.type === "digital" ? (
              <p className="pr-2 flex items-center text-reg-16">{`File Type(s): ${assetData.file_types?.map(
                (item, index) =>
                  `${item.split("/")[1]}${
                    index !== assetData.file_types.length - 1 ? "," : ""
                  }`
              )}`}</p>
            ) : (
              fromAssetGroupPage && (
                <p className="pr-2 flex items-center text-reg-16">
                  Maximum Order Quantity:{" "}
                  {(() => {
                    if (assetData.status.max_qty_frequency === "daily")
                      return `${assetData.status.max_qty} per location, per day`
                    else if (assetData.status.max_qty_frequency === "weekly")
                      return `${assetData.status.max_qty} per location, per week`
                    else if (assetData.status.max_qty_frequency === "biweekly")
                      return `${assetData.status.max_qty} per location, every two weeks`
                    else if (assetData.status.max_qty_frequency === "monthly")
                      return `${assetData.status.max_qty} per location, per month`
                    else if (assetData.status.max_qty_frequency === "yearly")
                      return `${assetData.status.max_qty} per location, per year`
                    else if (assetData.status.max_qty_frequency === "forever")
                      return `${assetData.status.max_qty} per location. After ordering this asset, you won't be able to order it again.`
                  })()}
                </p>
              )
            )}
            {typeof price !== "undefined" &&
              price !== null &&
              price !== "0.00" && (
                <p className="ml-auto price-label-lg !text-[14px]">${price}</p>
              )}
          </div>
          {!fromAssetGroupPage && (
            <div className="flex">
              <Link to={`/asset/${id}`}>
                <MetaUpdated
                  text={`Updated ${last_update || last_change}`}
                  className="!flex !items-center"
                />
              </Link>

              <div className="md:ml-auto text-reg-14">
                <Link to={`/asset/${id}`}>
                  {type === "digital" && <MetaDownload />}
                  {type === "physical" && <MetaDelivery />}
                </Link>
                {/* {total_files || files} Asset{(total_files || files) !== 1 && "s"}
              /File{(total_files || files) !== 1 && "s"} */}
              </div>
            </div>
          )}
          {!fromAssetGroupPage && (
            <Tags wrap>
              {tags &&
                tags.map((v, k) => {
                  let color = "green"
                  var i = k + 1
                  if (i % 1 == 0) {
                    color = "red"
                  } else if (i % 2 == 0) {
                    color = "blue"
                  }

                  return (
                    <Tag color={color} key={k}>
                      {v}
                    </Tag>
                  )
                })}
            </Tags>
          )}
          <div className="flex flex-col">
            <AddToCartBox
              type={type}
              id={id}
              title={title}
              // by_request={by_request}
              assetData={assetData}
              fromAssetGroupPage={fromAssetGroupPage}
              sm
            />
          </div>
          {!mini && !fromAssetGroupPage && (
            <>
              {typeof description === "string" && description !== "" && (
                <p className="text-reg-15">{formatTextInline(description)}</p>
              )}
            </>
          )}
        </div>
      </div>
    </li>
  )
}
