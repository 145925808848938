import { Link } from "gatsby"
import React, { useContext } from "react"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import DataContext from "../../../context/Data/DataContext"

export function SubHeaderManager() {
  return (
    <nav className="bg-gray-200 py-2">
      <ul className="flex justify-start space-x-3 md:px-[100px]">
        <li className="cursor-pointer px-4">
          <Link
            to={"/assets"}
            className="text-gray-800  text-[15px] hover hover-red hover:text-[#e43d30] transition-all"
          >
            All Asset Listings
          </Link>
        </li>
        <li className="cursor-pointer px-4">
          <Link
            to={"/products"}
            className="text-gray-800  text-[15px] hover hover-red hover:text-[#e43d30] transition-all"
          >
            All Product Listings
          </Link>
        </li>
        <li className="cursor-pointer px-4">
          <Link
            to={"/brands"}
            className="text-gray-800  text-[15px] hover hover-red hover:text-[#e43d30] transition-all"
          >
            All Brand Listings
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export function SubHeaderEditor() {
  const { authentication } = useContext(AuthenticationContext)
  const { setData } = useContext(DataContext).data
  const { currentUserData } = authentication
  console.log(currentUserData.submenu)
  return (
    <nav className="bg-gray-200 py-2">
      <ul className="flex justify-start space-x-3 md:px-[100px]">
        {currentUserData?.submenu?.map(item => (
          <li className="cursor-pointer px-4">
            <Link
              to={`/assets?tag=${item.tag_id}`}
              className="text-gray-800  text-[15px] hover hover-red hover:text-[#e43d30] transition-all"
            >
              {item.tag_title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
