import React, { useReducer, useEffect, useState } from "react"
import StateContext, { StateConsumer } from "./StateContext"
const initialState = {
  token: null,
  formSubmitted: false,
  locformsubmit: null,
}
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      }
    case "SET_FORM_SUBMIT":
      return {
        ...state,
        formSubmitted: action.payload,
      }
    case "SET_LOC_FORM_SUBMIT":
      return {
        ...state,
        locformsubmit: action.payload,
      }
    // case "SET_LOGOUT_TOKEN":
    //   localStorage.setItem("logOutToken", action.payload)

    //   console.log(action.payload , 'action.payload logoiut')
    //   return {
    //     ...state,
    //     logOutToken: action.payload,
    //   }
    case "SET_INITIAL":
      return initialState
    default:
      return state
  }
}

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  // useEffect(async () => {
  //   let user = JSON.parse(sessionStorage.getItem("user") || null);
  //   const token = await sessToken();
  //   const logOutToken = localStorage.getItem("logOutToken");
  //   const TOKENS = {
  //     csrf_token: token,
  //     logout_token: logOutToken,
  //   };

  //   await CallMyBock(token).then(async (data) => {
  //     if (data.isAuthenticated === true) {
  //       sessionStorage.setItem("user", JSON.stringify(data));
  //       console.log(data);
  //       user = data;
  //       dispatch({
  //         type: "LOGIN",
  //         payload: data,
  //       });
  //     }
  //   });
  //   console.log("checking", user);

  //   if (token) {
  //     dispatch({
  //       type: "TOKEN",
  //       payload: TOKENS,
  //     });
  //   }

  //   dispatch({ type: "LOADING", payload: false });
  // }, []);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}
export default StateProvider
