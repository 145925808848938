/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, {
  useContext,
  useRef,
  //  useEffect, useState
} from "react"
import {
  //  Link,
  navigate,
} from "gatsby"
// import NavigateIcon from "../../../../assets/icons/navigate.svg"
// import Cart from "../../../../assets/icons/cart.svg"
import BellIcon from "../../../../assets/icons/bell.svg"
// import Button from "../../../Elements/Button"
// import { Fragment } from "react"
import {
  Menu,
  //  Transition
} from "@headlessui/react"
// import { ChevronDownIcon } from "@heroicons/react/solid"
import { ExclamationCircleIcon } from "@heroicons/react/outline"
// import { red } from "../../../Styles"
import { styles, menuItemsClasses } from "./utils"
// import HeaderFeed from "../HeaderFeed"
import Separator from "../../../Elements/Separator"
// import { clearMyNotification, clearMyNotifications } from "../../../../helpers/DrupalHelper"
import StateContext from "../../../../context/state/StateContext"
import { classNames } from "../../../../utils"
import MenuSection from "../MenuSection"
import MobileMenuHead from "../MobileMenuHead"
import IconButton from "./IconButton"
// import { useMediaQuery } from "react-responsive"
import MenuTransition from "./MenuTransition"
import MenuBackdrop from "./MenuBackdrop"
// import MenuItems from "./MenuItems"
import DataContext from "../../../../context/Data/DataContext"
import {
  //  classNames,
  formatDate,
} from "../../../../utils"
import {
  MailIcon,
  InboxInIcon,
  // ChatAlt2Icon,
  // UsersIcon,
} from "@heroicons/react/outline"
import UserIcon from "../../../../assets/icons/user.svg"
import ShopIcon from "../../../../assets/icons/shop.svg"
import DeliveryIcon from "../../../../assets/icons/delivery.svg"
import ButtonLite from "../../../Elements/ButtonLite"
import IconClose from "../../../Elements/IconClose"
import ReactTimeAgo from "react-time-ago"
// import en from "javascript-time-ago/locale/en.json"
export default function Notifications({ floating }) {
  // const [myNotifications, setMyNotifications] = useState([])
  const { state } = useContext(StateContext)
  const { token } = state
  const { myNotifications } = useContext(DataContext).data
  const { myNotificationsClearAll, myNotificationsClearSingle } =
    useContext(DataContext)

  // const MenuSection = props => {
  //   return (
  //     <div className="border-b border-[#EBEBEB]">
  //       <Link
  //         to={props.link}
  //         className={` pb-2 mb-4 ${
  //           props.className && props.className
  //         }`}
  //       >
  //         <MenuHead title={props.title} />
  //       </Link>
  //       {props.children}
  //     </div>
  //   )
  // }

  // const isMobile = useMediaQuery({
  //   query: "(max-width: 768px)",
  // })

  const ref = useRef(null)

  function calcTime(time) {
    var today = new Date(formatDate(time))
    console.log(today, time)
    today.setHours(today.getHours() - 1 + -new Date().getTimezoneOffset() / 60)
    return today
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <MenuBackdrop open={open} />
            <Menu.Button className={styles} ref={ref}>
              {Array.isArray(myNotifications) && myNotifications.length > 0 && (
                <div className="rounded-[50px] bg-[#fce3e1] text-[#E43D30] text-[9px] font-[600] w-[15px] top-[0] left-[0] h-[15px] absolute leading-[15px]">
                  {myNotifications.length < 9 ? myNotifications.length : "9+"}
                </div>
              )}
              <IconButton icon={BellIcon} floating={floating} />
            </Menu.Button>
            <MenuTransition>
              <Menu.Items
                // className={menuItemsClasses}
                className={`${menuItemsClasses} lg:!w-[410px]`}
                // className={`origin-top-right absolute right-0 mt-4 w-[410px] rounded-[8px] shadow-[0px_6px_20px_rgba(34,34,34,0.05)] bg-white z-[9] ring-1 ring-[#EBEBEB] focus:outline-none`}
                // css={css`
                //   &:after,
                //   &:before {
                //     bottom: 100%;
                //     left: calc(410px - 25px);
                //     border: solid transparent;
                //     content: "";
                //     height: 0;
                //     width: 0;
                //     position: absolute;
                //     pointer-events: none;
                //   }
                //
                //   &:after {
                //     border-color: rgba(136, 183, 213, 0);
                //     border-bottom-color: #fff;
                //     border-width: 8px;
                //     margin-left: -8px;
                //   }
                //   &:before {
                //     border-color: rgba(235, 235, 235, 0);
                //     border-bottom-color: #ebebeb;
                //     border-width: 9px;
                //     margin-left: -9px;
                //   }
                // `}
                // css={menuItemsStyles}
              >
                <div
                  className="p-[20px_15px_20px_15px] lg:p-[20px] overflow-y-scroll overflow-x-hidden"
                  css={css`
                    max-height: calc(80vh - 90px);
                  `}
                >
                  <Menu.Button className="w-full md:hidden ">
                    <MobileMenuHead title="Notifications" />
                  </Menu.Button>
                  <MenuSection
                    title={
                      <div className="flex items-center">
                        {myNotifications.length === 0
                          ? "No"
                          : myNotifications.length}{" "}
                        new notifications{" "}
                        {myNotifications.length > 0 && (
                          <div className="ml-auto">
                            <div
                              onClick={() => {
                                myNotificationsClearAll()
                              }}
                              css={css`
                                &&& {
                                  cursor: pointer;
                                  transition: 0.2s ease all;
                                  position: relative;
                                  min-width: 0;
                                  padding: 0 15px;
                                  border-radius: 8px;
                                  height: 30px;
                                  font-family: "Jost";
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 12px;
                                  line-height: 30px;
                                  letter-spacing: 0;
                                  background-color: #fce3e1;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  color: #e43d30;
                                  border: 0;
                                  path {
                                    transition: 0.2s ease all;
                                  }
                                  &:hover {
                                    background-color: #e43d30;
                                    color: #fce3e1;
                                    path {
                                      fill: #fce3e1;
                                    }
                                    border: 0;
                                  }
                                }
                              `}
                            >
                              Clear all
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    // link={"#"}
                    reducedPaddingX
                    reducedPaddingY
                    noBorder
                  >
                    {Array.isArray(myNotifications) &&
                    myNotifications.length > 0 ? (
                      <div className="pb-[0]">
                        {myNotifications.map((item, k) => {
                          const Icon = () => {
                            if (item.type === "inventory_change")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[#e0f0e5] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#009D4F] mr-[15px] border border-[#e0f0e5] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <DeliveryIcon
                                      className="h-4 w-4"
                                      css={css`
                                        path {
                                          fill: #009d4f;
                                        }
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "new_message")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[#e6eaf6] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#0033A1] mr-[15px] border border-[#e6eaf6] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <MailIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "new_message_reply")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[#e6eaf6] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#0033A1] mr-[15px] border border-[#e6eaf6] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <InboxInIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "order_status")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <ExclamationCircleIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "request_asset")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <ExclamationCircleIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "request_visit")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[#fce3e1] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#E43D30] mr-[15px] border border-[#fce3e1] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <ShopIcon
                                      className="h-4 w-4"
                                      css={css`
                                        // stroke-width: 0.5px;
                                        path {
                                          fill: red;
                                        }
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "user_updates")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <UserIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "brand_profile_changes")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <UserIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "store_profile_changes")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <UserIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            if (item.type === "inventory_submissions")
                              return (
                                <>
                                  <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-[rgb(255,247,227)] group-hover:bg-[#f7ebe9] group-hover:border-[#f7ebe9] group-hover:text-[#e43d30] flex items-center justify-center text-[#CE9D15] mr-[15px] border border-[rgb(255,247,227)] ring-1 ring-transparent group-hover:ring-1 group-hover:ring-[#e43d30] group-hover:ring-offset-2 transition-all">
                                    <UserIcon
                                      className="h-5 w-5"
                                      css={css`
                                        stroke-width: 1.25px;
                                      `}
                                    />
                                  </div>
                                </>
                              )
                            return null
                          }

                          let itemType = ""
                          if (item.type === "inventory_change")
                            itemType = "Inventory Change"
                          if (item.type === "new_message")
                            itemType = "New Message"
                          if (item.type === "new_message_reply")
                            itemType = "New Reply"
                          if (item.type === "request_asset")
                            itemType = "Asset Requested"
                          if (item.type === "request_visit")
                            itemType = "Visit Requested"
                          if (item.type === "order_status")
                            itemType = "New Order Placed"
                          if (item.type === "user_updates")
                            itemType = "User Update"
                          if (item.type === "brand_profile_changes")
                            itemType = "Brand Profile Changes"
                          if (item.type === "store_profile_changes")
                            itemType = "Store Profile Changes"
                          if (item.type === "inventory_submissions")
                            itemType = "Inventory Submission"

                          let notifUrl = "#"
                          let notifState = {}

                          if (
                            [
                              "new_message",
                              "new_message_reply",
                              "request_visit",
                              "request_asset",
                            ].includes(item.type)
                          ) {
                            notifUrl = "/dashboard/messages"
                            notifState = { id: item.submission }
                          }
                          if (item.type === "order_status") {
                            notifUrl = "/dashboard/my-orders"
                            notifState = { type: "physical", id: item.order_id }
                          }
                          if (item.type === "user_updates") {
                            notifUrl = "/dashboard/manage-users"
                            notifState = { company: null, user_id: null }
                          }
                          if (item.type === "inventory_change") {
                            notifUrl = "#"
                            notifState = {}
                          }

                          const navigateNotif = async () => {
                            navigate(notifUrl, { state: notifState })
                            console.log("test")
                            await myNotificationsClearSingle(item.id)
                          }

                          const clearNotif = async () => {
                            setTimeout(() => {
                              ref.current?.click()
                            }, 0)
                            await myNotificationsClearSingle(item.id)
                          }

                          return (
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  className={classNames(
                                    k < myNotifications.length - 1
                                      ? `mb-[20px]`
                                      : "mb-[0]",
                                    "mt-[20px] mb-[20px]"
                                  )}
                                >
                                  <div className=" flex items-start w-full">
                                    <div
                                      onClick={() => navigateNotif()}
                                      className={
                                        "cursor-pointer flex !text-[#222] transition-all group  transition-all"
                                      }
                                    >
                                      <Icon />
                                      <div className="">
                                        <span className="text-med-15 group-hover:text-[#e43d30] transition-all">
                                          {itemType}
                                        </span>
                                        <span className="block text-reg-12 text-light group-hover:text-[#e43d30] transition-all">
                                          {item.title}
                                        </span>
                                        <span className="block text-reg-12 text-light group-hover:text-[#e43d30] transition-all">
                                          <ReactTimeAgo
                                            date={calcTime(item.created)}
                                            locale="en-CA"
                                          />
                                        </span>
                                      </div>
                                    </div>

                                    <div className="ml-auto">
                                      <IconClose
                                        small
                                        height={2}
                                        width={2}
                                        callback={() => clearNotif()}
                                      />
                                    </div>
                                  </div>
                                  {k < myNotifications.length - 1 && (
                                    <Separator className="mt-[20px]" />
                                  )}
                                </div>
                              )}
                            </Menu.Item>
                          )
                        })}
                      </div>
                    ) : (
                      <p className="text-reg-14">
                        No new notifications to display.
                      </p>
                    )}
                  </MenuSection>
                  {/*<MenuSection
                    title="Recent Activity"
                    link={"/activity"}
                    reducedPaddingX
                    reducedPaddingY
                  >
                    <HeaderFeed />
                    <Button red full>
                      More Activity
                    </Button>
                  </MenuSection>*/}
                </div>
              </Menu.Items>
            </MenuTransition>
          </>
        )}
      </Menu>
    </>
  )
}
