/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import CartIcon from "../../assets/icons/cart.svg"
import React, { useEffect, useState, useContext } from "react"
const MaxQtyMeta = props => {
  const { address, status, itemInCartQty } = props
  const { ordered_last, max_qty, max_qty_ordered, max_qty_frequency } = status

  const formatDate = d =>
    typeof d === "string"
      ? d.split("/")[1] + "/" + d.split("/")[0] + "/" + d.split("/")[2]
      : String(d.getDate()).padStart(2, "0") +
        "/" +
        String(d.getMonth() + 1).padStart(2, "0") +
        "/" +
        d.getFullYear()
  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
  const addMonths = (numOfMonths, date = new Date()) =>
    date.setMonth(date.getMonth() + numOfMonths)
  const addDays = (numOfDays, date = new Date()) =>
    date.setDate(date.getDate() + numOfDays)

  const [date, setDate] = useState(false)

  useEffect(async () => {
    if (ordered_last && ordered_last !== "") {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, "0")
      var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
      var yyyy = today.getFullYear()
      today = mm + "/" + dd + "/" + yyyy

      let orderLastDate = new Date(formatDate(ordered_last))

      let doMath = false
      switch (max_qty_frequency) {
        case "daily":
          doMath = addDays(1, orderLastDate)
          break
        case "weekly":
          doMath = addDays(7, orderLastDate)
          break
        case "monthly":
          doMath = addMonths(1, orderLastDate)
          break
        case "yearly":
          doMath = addMonths(12, orderLastDate)
          break
        default:
          break
      }

      if (doMath !== false) setDate(diffDays(doMath, new Date(today)))
    }
  }, [])

  return (
    <label
      htmlFor={`filter-stores-${address.address_id}`}
      className="flex flex-col cursor-pointer w-full"
    >
      {
        // <>
        //   <span className="text-reg-16 text-dark">{address.address_name}</span>
        //   <span
        //     htmlFor={`filter-stores-${address.address_id}`}
        //     className={`text-reg-14 max-w-[70%] !leading-6`}
        //   >
        //     {address.address}
        //   </span>
        // </>
      }
      {max_qty !== null && (
        <>
          <div className="bg-[#f7f7f7] border border-[#EBEBEB] rounded-[8px] px-2 py-2 text-reg-12 !leading-[1.2] d-flex mr-auto mt-2">
            {max_qty_frequency === "forever" && (
              <span>
                {max_qty - max_qty_ordered <= 0
                  ? `You've reached your order limit for this asset. You can't order more units.`
                  : `You can still order ${
                      max_qty - max_qty_ordered
                    } units of this asset.`}
              </span>
            )}
            {/*max_qty_frequency === "yearly" && (
                    <span>
                      {max_qty - max_qty_ordered === 0
                        ? `You've reached your order limit for this year.
                  / ${date} days until you can order again`
                        : `You can still order ${
                            max_qty - max_qty_ordered
                          } this year
                  / ${date} days until you can order again`}
                    </span>
                  )*/}
            {max_qty_frequency === "yearly" && (
              <span>
                {max_qty - max_qty_ordered <= 0
                  ? `You've reached your order limit for this year.
                    ${
                      date !== false &&
                      `/ ${date} days until you can order again`
                    }`
                  : `You can still order ${
                      max_qty - max_qty_ordered
                    } this year`}
              </span>
            )}
            {max_qty_frequency === "monthly" && (
              <span>
                {max_qty - max_qty_ordered <= 0
                  ? `You've reached your order limit for this month.
                    ${
                      date !== false &&
                      `/ ${date} days until you can order again`
                    }`
                  : `You can still order ${
                      max_qty - max_qty_ordered
                    } this month`}
              </span>
            )}
            {max_qty_frequency === "biweekly" && (
              <span>
                {max_qty - max_qty_ordered <= 0
                  ? `You've reached your order limit for this fortnight.`
                  : `You can still order ${
                      max_qty - max_qty_ordered
                    } during this two-week period.`}
              </span>
            )}
            {max_qty_frequency === "weekly" && (
              <span>
                {max_qty - max_qty_ordered <= 0
                  ? `You've reached your order limit for this week.`
                  : `You can still order ${
                      max_qty - max_qty_ordered
                    } this week.`}
              </span>
            )}
            {max_qty_frequency === "daily" && (
              <>
                <span>
                  {max_qty - max_qty_ordered <= 0
                    ? `You've reached your order limit for today.`
                    : `You can still order ${max_qty - max_qty_ordered} today.`}
                </span>
              </>
            )}
          </div>
          {itemInCartQty !== null && itemInCartQty !== false && (
            <span className="flex space-x-2 items-center mt-2">
              <CartIcon className="w-4 h-4" />
              <span className="text-reg-11 text-dark">
                {itemInCartQty} already in your cart. You can add{" "}
                {max_qty - itemInCartQty} more.
              </span>
            </span>
          )}
        </>
      )}
    </label>
  )
}
export default MaxQtyMeta
