/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import LikedIcon from "../../../../assets/icons/heart.svg"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { XIcon } from "@heroicons/react/outline"
import { red } from "../../../Styles"
import ImageCardSmall from "../../../Elements/ImageCardSmall"
import CartContext from "../../../../context/Cart/CartContext"
import Icon from "../../../../assets/icons/close-alt.svg"
import CartCard from "../../../Cards/CartCard"
import Button from "../../../Elements/Button"
import IconButton from "./IconButton"
import BellIcon from "../../../../assets/icons/bell.svg"
import { ChartBarIcon } from "@heroicons/react/outline"
import { styles, menuItemsClasses } from "./utils"
import HeaderFeed from "../HeaderFeed"
import Separator from "../../../Elements/Separator"
import {
  getMyBookmarks,
  getMyNotifications,
} from "../../../../helpers/DrupalHelper"
import StateContext from "../../../../context/state/StateContext"
import { classNames } from "../../../../utils"
import MenuSection from "../MenuSection"
import MobileMenuHead from "../MobileMenuHead"
import { useMediaQuery } from "react-responsive"
import MenuTransition from "./MenuTransition"
import MenuBackdrop from "./MenuBackdrop"
import MenuItems from "./MenuItems"
import LikeHits from "../../../Elements/LikeHits"
import DataContext from "../../../../context/Data/DataContext"

export default function Reporting({ floating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  const url = window.location.pathname
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <MenuBackdrop open={open} />
            <Menu.Button className={styles}>
              <button
                className="relative inline-block text-left z-10"
                onClick={() => navigate("/dashboard/reports")}
              >
                <div
                  className={`${styles} ${
                    floating
                      ? "hover:bg-white hover:ring-[#fff]"
                      : "hover:ring-[#EBEBEB] hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)]"
                  }`}
                  css={css`
                    min-width: 50px;
                    min-height: 50px;
                    &:hover {
                      path {
                        fill: ${floating ? `#484848` : ``};
                      }
                    }
                  `}
                >
                  <ChartBarIcon
                    className="h-5 w-5 "
                    css={css`
                      path {
                        stroke-width: 1.4px;
                        ${url === "/dashboard/reports" && `stroke : #e43d30`};
                      }
                    `}
                  />
                </div>
              </button>
            </Menu.Button>
          </>
        )}
      </Menu>
    </>
  )
}
