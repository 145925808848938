/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import LazyImage from "../LazyImage"
import Skeleton from "../Skeleton"
import PlaceholderImage from "../../assets/images/image_coming_soon.png"
import { useMediaQuery } from "react-responsive"

function CircleAvatar(props) {
  // const size = props.sm || props.md ? "50px" : "120px"

  const { profile, noImage } = props
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const { padding } = props

  let size = "120px"
  if (props.sm || props.md) size = "50px"
  if (props.xs) size = "40px"

  const border = props.border && props.image ? "1px solid #EBEBEB" : "0"
  let fontSize = "14px"
  let letterSpacing = "0.03em"
  if (props.md) {
    fontSize = "16px"
    letterSpacing = "0.02em"
  }
  let shadow = "0px 6px 20px rgba(34, 34, 34, 0.05)"
  if (props.shadowNone) shadow = "none"

  if (props.forceSizeMobile && isMobile) size = props.forceSizeMobile

  let cssHeight = "70%"
  if (props.maxImg) {
    cssHeight = "70%"
  }

  if (props.full) cssHeight = "100%"

  if (props.text) {
    return (
      <div className="flex items-center relative z-[1]">
        {!noImage ? (
          <div
            className="flex items-center justify-center"
            css={css`
              width: ${size};
              min-width: ${size};
              height: ${size};
              min-height: ${size};
              background: #fff;
              box-shadow: ${props.image ? shadow : ``};
              border-radius: 50%;
              border: ${border};
              margin-right: 13px;
              overflow: hidden;
            `}
          >
            {props.image ? (
              <LazyImage
                src={props.image}
                alt={props.alt || ""}
                css={css`
                  height: ${cssHeight};
                  min-height: ${cssHeight};
                  width: auto;
                  padding: ${padding ? padding : 0};
                `}
              />
            ) : props.image === null ? (
              <LazyImage
                src={PlaceholderImage}
                alt={props.alt || ""}
                css={css`
                  height: auto;
                  width: auto;
                  padding: ${padding ? padding : 0};
                `}
              />
            ) : (
              <Skeleton dark />
            )}
          </div>
        ) : (
          <div
            css={css`
              height: ${size};
              min-height: ${size};
              margin-right: 13px;
              border: ${border};
              overflow: hidden;
            `}
          ></div>
        )}
        <span
          className="text-meta-sm !text-left"
          css={css`
            &&& {
              font-size: ${fontSize};
              letter-spacing: ${letterSpacing};
            }
          `}
        >
          {props.text}
        </span>
      </div>
    )
  }
  return (
    <div
      className="flex items-center justify-center relative z-[1]"
      css={css`
        width: ${size};
        min-width: ${size};
        height: ${size};
        min-height: ${size};
        background: ${profile && !props.image ? `#222` : `#fff`};
        color: ${profile && !props.image ? `#fff` : `#222`};
        box-shadow: ${props.image ? shadow : ``};
        border-radius: 50%;
        border: ${border};
        overflow: hidden;
      `}
    >
      {props.image ? (
        <>
          {props.profile ? (
            <LazyImage
              src={props.image}
              alt={props.alt || ""}
              css={css`
                height: 100%;
                // min-height: ${cssHeight};
                width: 100%;
                padding: ${padding ? padding : 0};
                object-fit: cover;
              `}
            />
          ) : (
            <LazyImage
              src={props.image}
              alt={props.alt || ""}
              css={css`
                height: ${cssHeight};
                min-height: ${cssHeight};
                width: auto;
                padding: ${padding ? padding : 0};
              `}
            />
          )}
        </>
      ) : props.image === null ? (
        <LazyImage
          src={PlaceholderImage}
          alt={props.alt || ""}
          css={css`
            height: auto;
            width: auto;
            padding: ${padding ? padding : 0};
          `}
        />
      ) : (
        <>
          {profile ? props.alt?.match(/\b(\w)/g).join("") : <Skeleton dark />}
        </>
      )}
    </div>
  )
}

export default CircleAvatar
