/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useEffect, useState } from "react"

import { useFieldArray, useForm } from "react-hook-form"
import { classNames } from "../../../utils"
import FloatingInput from "./FloatingInput"
import AsyncFloatingSelect from "./AsyncFloatingSelect"

const FloatingRadiosInput = ({
  menuClassNames,
  name,
  title,
  options,
  onChange,
  controlForm,
  hookForm,
  disabled,
  registerForm,
  unregisterForm,
  description,
  error,
  label,
  required,
  setValue,
  flush,
  // getValues,
  customOnChange,
  textFieldName,
  errors,
  setReferralBrand,
}) => {
  const [items, setItems] = useState(options)
  const [isSelected, setIsSelected] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { register, control, getValues } = useForm()
  console.log(textFieldName)
  useEffect(() => {
    registerForm(textFieldName)
  }, [])
  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  //   {
  //     control, // control props comes from useForm (optional: if you are using FormContext)
  //     name: name, // unique name for your Field Array,
  //     // shouldUnregister: true,
  //     // defaultValues: {
  //     //   array: [],
  //     // },
  //   }
  // )
  // console.log(fields)
  const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`

  useEffect(() => {
    setValue(name, "")
  }, [])

  console.log(selectedItem)

  let inputLabel = label
  if (required && !inputLabel.includes("*")) {
    inputLabel = inputLabel + "*"
  }
  return (
    <div className={`mt-4 ${!flush ? `mb-6` : `mb-2`}`}>
      <div
        className={classNames(
          `border rounded-[8px] px-[20px] py-[20px] relative`,
          error ? `border-red-600` : `border-[#EBEBEB]`
        )}
      >
        <label
          // for={name}
          className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
          css={css`
            ${fontStyles}
          `}
        >
          {title || inputLabel}
        </label>

        {hookForm ? (
          <div>
            <div className="grid xl:grid-cols-3 xl:gap-x-6 xl:gap-y-1">
              {items.map((option, optionIdx) => (
                <div key={option.value} className="flex items-center">
                  <input
                    id={`filter-${name}-${optionIdx}`}
                    name={name}
                    defaultValue={null}
                    type="radio"
                    defaultChecked={option.checked}
                    value={option.value}
                    disabled={disabled ? true : false}
                    className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                    {...registerForm(name)}
                    onChange={e => {
                      setIsSelected(true)
                      if (
                        [
                          "tradeshow",
                          "another_retailer",
                          "brand_rep",
                          "other",
                        ].includes(e.target.value)
                      ) {
                        const selectedItem = items.find(
                          item => item.value === e.target.value
                        )
                        setSelectedItem(selectedItem)
                      } else {
                        setSelectedItem(null)
                        unregisterForm(textFieldName)
                      }
                    }}
                  />
                  <label
                    htmlFor={`filter-${name}-${optionIdx}`}
                    className="ml-3 text-reg-14 whitespace-nowrap"
                    css={css`
                      &&& {
                        /* color: #222; */
                      }
                    `}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {selectedItem !== null && (
              <div className="mt-4">
                {selectedItem.value !== "brand_rep" ? (
                  <FloatingInput
                    label={selectedItem.textFieldLabel}
                    hookForm
                    flush
                    name={textFieldName}
                    register={registerForm}
                    required
                    description={selectedItem.textFieldDescription}
                    error={errors[textFieldName]}
                  />
                ) : (
                  <AsyncFloatingSelect
                    label={selectedItem.textFieldLabel}
                    name={textFieldName}
                    hookForm
                    register={registerForm}
                    error={errors[textFieldName]}
                    control={controlForm}
                    required
                    body={{
                      entity: "brand",
                      bundle: "default",
                    }}
                    setReferralBrand={setReferralBrand}
                    customOnChange={data => {
                      if (setReferralBrand) {
                        setReferralBrand(data)
                      }
                    }}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <form
            className="grid xl:grid-cols-3 xl:gap-x-6 xl:gap-y-1"
            onChange={() => {
              let data = getValues(name)
              console.log(data)

              setValue(
                name,
                data
                // data.filter(v => Boolean(v.value))
              )

              if (typeof customOnChange !== "undefined") {
                customOnChange(data)
              }
            }}
          >
            {items.map((option, optionIdx) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={`filter-${name}-${optionIdx}`}
                  name={name}
                  defaultValue={null}
                  type="radio"
                  defaultChecked={option.checked}
                  value={option.value}
                  className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                  {...register(name)}
                />
                <label
                  htmlFor={`filter-${name}-${optionIdx}`}
                  className="ml-3 text-reg-14"
                  css={css`
                    &&& {
                      /* color: #222; */
                    }
                  `}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </form>
        )}
      </div>
      {error && !isSelected && (
        <span
          // className="border-red-600 text-red-600 w-full"
          style={{
            color: "#E43D30",
          }}
        >
          {error.message} <br />
        </span>
      )}

      {description && (
        <span
          // className="text-sm"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            padding: 0 10px;
            margin: 7px 0 10px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          {description}
        </span>
      )}
    </div>
  )
}

export default FloatingRadiosInput
