/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useState, useEffect, useContext } from "react";
import AuthenticationContext from "../../../../context/authentication/AuthenticationContext";
import {
  entitySuggestPublic,
  getSubUserListing,
  myEntitySuggestions,
} from "../../../../helpers/DrupalHelper";
import AsyncFloatingSelect from "../../../Elements/Form/AsyncFloatingSelect";

import FloatingSelect from "../../../Elements/Form/FloatingSelect";
import Skeleton from "../../../Skeleton";
import EntityReferenceRevisions from "./EntityReferenceRevisions";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../../utils";

const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`;

function ManageUserCompany({
  data,
  name,
  register,
  label,
  setValue,
  token,
  watch,
  control,
  errors,
  dataUser,
  dataStore,
  dataRegion,
  dataCompany,
  unregister,
  editing,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [storesOptions, setStoresOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [showStores, setShowStores] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showUsers, setShowUsers] = useState(editing ? false : true);
  const { authentication } = useContext(AuthenticationContext);
  const getType = (v) => {
    return authentication.currentUser?.companies?.filter(
      (f) => parseInt(f.id) === parseInt(v)
    )[0].type;
  };
  let [radioOptions, setRadioOptions] = useState([
    { value: "users", label: "User" },
    { value: "regions", label: "Region" },
    { value: "stores", label: "Store" },
    { value: "companies", label: "Company" },
  ]);

  useEffect(async () => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      });
    } else {
      register(name);
    }
    const entity = data.entity_type.machine_name;

    const bundle = data.entities

      ? data.entities.map((v) => v.machine_name)
      : data.entity_type.machine_name;

    let datas = await myEntitySuggestions(token, entity, bundle);

    if (data.current_value && data.current_value?.length) {
      console.log(data.current_value);
      setValue(`${name}`, `${data?.current_value[0]?.target_id}`);
      let a = data.current_value.map((item) => {
        return { value: item.target_id, label: item.title };
      });
      /* 20221115211759 Not sure what this is doing*/
      /*let type = getType(a[0].value)
      if (!editing && type !== "retailer") {
        setShowRegions(true)
      }*/
      let userOpData = await getSubUserListing(token, a[0].value);
      setUserOptions([
        ...userOpData.map((v) => ({ ...v, value: v.id, label: v.name })),
      ]);
    } else {
      setValue(name, datas[0].target_id ? [datas[0].target_id] : [datas[0].id]);
      let userOpData = await getSubUserListing(token, datas[0].target_id);

      setUserOptions([
        ...userOpData.map((v) => ({ ...v, value: v.id, label: v.name })),
      ]);
    }
    setEntitySuggestion(
      datas.map((v) => ({
        ...v,
        value: v.target_id ? v.target_id : v.id,

        label: v.title,
      }))
    );

    if (editing) {
      if (dataStore?.current_value?.length > 0) {
        setShowStores(true);
        setPlan("stores");
      } else if (dataRegion?.current_value?.length > 0) {
        setShowRegions(true);
        setPlan("regions");
      } else if (dataCompany?.current_value?.length > 0) {
        setShowCompanies(true);
        setPlan("companies");
      } else {
        // register("field_group_users")
        // if (dataUser?.current_value?.length > 0) {
        //   console.log(dataUser.current_value, "cc")
        //   setValue("field_group_users", dataUser.current_value)
        // }
        setShowUsers(true);
        setPlan("users");
        setValue("field_group_stores", null);
        setValue("field_group_regions", null);
        setValue("field_group_companies", null);
      }
    }
  }, []);
  let [plan, setPlan] = useState("users");

  // console.log(dataUser, "dataUser")
  if (entitySuggestion !== null) {
    return (
      <>
        {entitySuggestion.length > 1 ? (
          <FloatingSelect
            label={label}
            name={name}
            hookForm
            register={register}
            error={errors[name]}
            options={entitySuggestion}
            control={control}
            required
            defaultValue={
              data.current_value
                ? data.current_value.map((item) => {
                    return { value: item.target_id, label: item.title };
                  })
                : false
            }
            customChange={async (v) => {
              let userOpData = await getSubUserListing(token, v.value);
              setUserOptions([
                ...userOpData.map((v) => ({
                  ...v,
                  value: v.id,
                  label: v.name,
                })),
              ]);
              // /* This is for if we're doing something different with field values if it's a brand or retail company... but commenting for now because we dont have this requirement */
              // let type = getType(v.value)
              // if (type === "retailer") {
              //   // setStoresOptions
              //   if (!storesOptions.length) {
              //     let data = await entitySuggestPublic(token, {
              //       bundle: "retail_store",
              //       entity: "store",
              //     })
              //     setStoresOptions([
              //       ...data.map(v => ({
              //         ...v,
              //         value: v.target_id ? v.target_id : v.id,
              //         label: v.title,
              //       })),
              //     ])
              //   }
              //   unregister("field_group_regions")
              //   setShowRegions(false)
              // } else {
              //   setShowRegions(true)
              //   unregister("field_group_stores")
              // }
            }}
          />
        ) : null}
        <div className={`relative z-0 mb-6 w-full group`}>
          <RadioGroup
            className="border border-[#EBEBEB] rounded-[8px] px-[20px] py-[15px] text-reg-15"
            value={plan}
            onChange={(v) => {
              console.log(v);
              setPlan(v);
              if (v === "stores") {
                setShowStores(true);
                setShowRegions(false);
                setShowCompanies(false);
                setValue("field_group_regions", null);
                setValue("field_group_companies", null);
                setValue("field_group_users", null);
                // unregister("field_group_regions")
                // unregister("field_group_companies")
                // unregister("field_group_users")
                setShowUsers(false);
              } else if (v === "companies") {
                setShowStores(false);
                setShowRegions(false);
                setShowUsers(false);
                setValue("field_group_stores", null);
                setValue("field_group_regions", null);
                setValue("field_group_users", null);
                // unregister("field_group_stores")
                // unregister("field_group_regions")
                // unregister("field_group_users")
                setShowCompanies(true);
              } else if (v === "users") {
                setShowStores(false);
                setShowRegions(false);
                setShowCompanies(false);
                setValue("field_group_stores", null);
                setValue("field_group_regions", null);
                setValue("field_group_companies", null);
                // unregister("field_group_stores")
                // unregister("field_group_regions")
                // unregister("field_group_companies")
                setShowUsers(true);
              } else if (v === "regions") {
                setShowStores(false);
                setShowCompanies(false);
                setShowUsers(false);
                setShowRegions(true);
                setValue("field_group_stores", null);
                setValue("field_group_users", null);
                setValue("field_group_companies", null);
                // unregister("field_group_stores")
                // unregister("field_group_users")
                // unregister("field_group_companies")
                if (dataRegion?.current_value?.length > 0) {
                  setValue(
                    "field_group_regions",
                    dataRegion?.current_value.map(v => v.target_id.toString())
                  )
                }
              }
            }}
          >
            <label
              className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
              css={css`
                ${fontStyles}
              `}
            >
              Type *
            </label>
            {/*<RadioGroup.Label>Type</RadioGroup.Label>*/}
            {/*<RadioGroup.Label>Restrict access by:</RadioGroup.Label>*/}
            <div className="bg-white flex space-x-2">
              {radioOptions.map((v) => (
                <RadioGroup.Option
                  value={v.value}
                  className={({ checked }) => `
                ${
                  checked
                    ? "bg-[#fce3e1] border-[#e43d30]"
                    : "hover:border-[#EBEBEB]"
                }
                relative flex p-4 rounded-[8px] cursor-pointer transition-all border border-[#fff] grow justify-center
          `}
                >
                  {({ checked }) => (
                    <div className="flex flex-col ">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-[#e43d30]" : "text-gray-900",
                          "block text-sm font-medium"
                        )}
                      >
                        {v.label}
                      </RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        {showRegions && (
          <>
            <FloatingSelect
              label={dataRegion.label}
              name={"field_group_regions"}
              hookForm
              register={register}
              error={errors["field_group_regions"]}
              control={control}
              required
              body={{
                bundle: "default",
                entity: "region",
              }}
              isMulti={true}
              defaultValue={
                dataRegion.current_value
                  ? dataRegion.current_value.map((item) => {
                      return { value: item.target_id, label: item.title };
                    })
                  : null
              }
            />
            {/*<EntityReferenceRevisions
            data={dataRegion}
            errors={errors}
            key={"field_group_regions"}
            name={"field_group_regions"}
            register={register}
            label={dataRegion.label  + " *"}
            setValue={setValue}
            watch={watch}
            token={token}
          />*/}
          </>
        )}
        {showStores && (
          <AsyncFloatingSelect
            label={dataStore.label}
            name={"field_group_stores"}
            hookForm
            register={register}
            error={errors["field_group_stores"]}
            control={control}
            required
            body={{
              bundle: "retail_store",
              entity: "store",
            }}
            isMulti={true}
            defaultValue={
              dataStore.current_value
                ? dataStore.current_value.map((item) => {
                    return { value: item.target_id, label: item.title };
                  })
                : null
            }
          />
        )}
        {showCompanies && (
          <AsyncFloatingSelect
            label={dataCompany.label}
            name={"field_group_companies"}
            hookForm
            register={register}
            error={errors["field_group_companies"]}
            control={control}
            required
            body={{
              bundle: "default",
              entity: "company",
            }}
            isMulti={true}
            defaultValue={
              dataCompany.current_value
                ? dataCompany.current_value.map((item) => {
                    return { value: item.target_id, label: item.title };
                  })
                : null
            }
          />
        )}
        {showUsers && (
          <FloatingSelect
            label={dataUser.label + " *"}
            name={"field_group_users"}
            hookForm
            required
            noDefaultValue
            register={register}
            error={errors["field_group_users"]}
            options={userOptions}
            control={control}
            defaultValue={
              dataUser.current_value
                ? dataUser.current_value.map((item) => {
                    return { value: item.target_id, label: item.title };
                  })
                : false
            }
            isMulti={true}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <Skeleton height={58} rounded={"6px"} dark />
        <div className="mb-[24px]" />
        <Skeleton height={86} rounded={"6px"} />
        <div className="mb-[24px]" />
        <Skeleton height={58} rounded={"6px"} dark />
        <div className="mb-[24px]" />
      </>
    );
  }
}

export default ManageUserCompany;
