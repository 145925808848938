/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import Navigate from "../../../assets/icons/navigate.svg"
import Cart from "../../../assets/icons/cart.svg"
import Bell from "../../../assets/icons/bell.svg"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { red } from "../../Styles"
import MenuTransition from "./Icons/MenuTransition"
import MenuBackdrop from "./Icons/MenuBackdrop"
import MobileMenuHead from "./MobileMenuHead"
import { menuItemsClasses } from "./Icons/utils"

// import { navigation } from "../../Layout/Dashboard"
// export default function User({floating}) {

import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
import StateContext from "../../../context/state/StateContext"
import { makeLogOut } from "../../../helpers/DrupalHelper"
import NotificationContext from "../../../context/Notification/NotificationContext"
import { LogoutIcon } from "@heroicons/react/outline"

export default function User({ floating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  const { navigation, dispatchAuthentication, authentication } = useContext(
    AuthenticationContext
  )
  const { profile_picture } = authentication.currentUserData
  const { state, dispatch } = useContext(StateContext)
  const { token } = state
  const { toggleNotification } = useContext(NotificationContext)
  const preLogOut = async () => {
    // let logoutoken = localStorage.getItem("logOutToken")
    let res = await makeLogOut(token)
    // console.log(res, res.ok)
    if (res.ok) {
      dispatchAuthentication({
        type: "LOGOUT",
        //  payload: data,
      })
      // dispatchAuthentication({ type: "LOGOUT" })
      dispatch({ type: "SET_INITIAL" })
      // toggleNotification({
      //   content: `Succesfully Log out.`,
      // })
      return true
    } else {
      toggleNotification({
        content: "Fail to Log Out",
      })
      return false
    }
  }
  const doLogOut = async () => {
    preLogOut().then(d => {
      // console.log(authentication)

      if (d) navigate("/login", { state: { logOut: true } })
    })
  }

  let fName = authentication.currentUserData.firstname
  let lName = authentication.currentUserData.lastname
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <MenuBackdrop open={open} />
            <Menu.Button
              className={`inline-flex items-center justify-center bg-[#222] w-[50px] h-[50px] border rounded-full hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)] text-white ring-1 ring-transparent hover:ring-1 hover:ring-[#E43D30] hover:ring-offset-2 focus:outline-none transition-all ${
                floating ? `ring-offset-[#222]` : `ring-offset-white`
              }`}
            >
              {profile_picture ? (
                <div className="rounded-full overflow-hidden h-[48px] w-[48px]">
                  <img
                    src={profile_picture}
                    alt="Profile Picture"
                    className="w-full h-full object-cover"
                    // className="w-[50px] h-[50px]"
                  />
                </div>
              ) : (
                fName.charAt(0) + lName.charAt(0)
              )}
            </Menu.Button>
            <MenuTransition>
              {/*<Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >*/}
              <Menu.Items
                className={`${menuItemsClasses} p-[20px] lg:p-0`}
                // className="origin-top-right absolute right-0 mt-4 w-[310px] rounded-[8px] shadow-[0px_6px_20px_rgba(34,34,34,0.05)] bg-white z-[9] ring-1 ring-[#EBEBEB] focus:outline-none"
                // css={css`
                //   &:after,
                //   &:before {
                //     bottom: 100%;
                //     left: 285px;
                //     border: solid transparent;
                //     content: "";
                //     height: 0;
                //     width: 0;
                //     position: absolute;
                //     pointer-events: none;
                //   }
                //
                //   &:after {
                //     border-color: rgba(136, 183, 213, 0);
                //     border-bottom-color: #fff;
                //     border-width: 8px;
                //     margin-left: -8px;
                //   }
                //   &:before {
                //     border-color: rgba(235, 235, 235, 0);
                //     border-bottom-color: #ebebeb;
                //     border-width: 9px;
                //     margin-left: -9px;
                //   }
                // `}
              >
                <Menu.Button className="w-full md:hidden">
                  <MobileMenuHead title="My Account" />
                </Menu.Button>
                <div
                  className="overflow-y-scroll overflow-x-hidden"
                  css={css`
                    max-height: calc(100vh - 90px);
                  `}
                >
                  <div className="lg:p-[20px]">
                    {navigation.map((item, k) => (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={item?.href}
                            className={classNames(
                              active
                                ? `bg-[#f7ebe9] !text-[#e43d30]`
                                : "!text-[#222] !hover:text-[#e43d30]",
                              "block px-4 text-sm rounded-[8px] h-[50px] flex items-center text-reg-16  transition-all"
                            )}
                          >
                            <item.icon
                              className={`mr-4 flex-shrink-0 ${
                                !item.stroke
                                  ? `h-4 w-4`
                                  : `h-[1.1rem] w-[1.1rem]`
                              } `}
                              // className={"mr-4 flex-shrink-0 h-5 w-5 transition-all"}
                              css={css`
                                path {
                                  fill: ${active && !item.stroke
                                    ? red
                                    : item.stroke
                                    ? `none`
                                    : `#222`};
                                  /* transition: all 0.15s ease; */
                                  stroke-width: ${item.stroke
                                    ? `1.25px`
                                    : `/**/`};
                                }
                              `}
                              // css={css`
                              //   &&& {
                              //     path {
                              //       fill: ${active ? red : `#222`};
                              //     }
                              //   }
                              // `}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={doLogOut}
                          className={classNames(
                            active
                              ? `bg-[#f7ebe9] !text-[#e43d30]`
                              : "!text-[#222] !hover:text-[#e43d30]",
                            "w-full block px-4 text-sm rounded-[8px] h-[50px] flex items-center text-reg-16  transition-all"
                          )}
                        >
                          <LogoutIcon
                            className={`mr-4 flex-shrink-0 ${
                              !true ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
                            } `}
                            // className={"mr-4 flex-shrink-0 h-5 w-5 transition-all"}
                            css={css`
                              path {
                                fill: ${active && !true
                                  ? red
                                  : true
                                  ? `none`
                                  : `#222`};
                                /* transition: all 0.15s ease; */
                                stroke-width: ${true ? `1.25px` : `/**/`};
                              }
                            `}
                            // css={css`
                            //   &&& {
                            //     path {
                            //       fill: ${active ? red : `#222`};
                            //     }
                            //   }
                            // `}
                            aria-hidden="true"
                          />
                          Log out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </div>
              </Menu.Items>
            </MenuTransition>
          </>
        )}
      </Menu>
    </>
  )
}
