import React, { useState, useEffect, useContext } from "react"
import OnSiteMarketingContext from "./OnSiteMarketingContext"
// import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  // getCart,
  // getUserStoresListing,
  // processCheckOut,
  // setCart,
  getOnSiteMarketingCustomLocation,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import { list } from "postcss"

export const OnSiteMarketingProvider = props => {
  const [assetGrid, setAssetGrid] = useState(null)
  const [productGrid, setProductGrid] = useState(null)
  const [brandGrid, setBrandGrid] = useState(null)
  const [pageFooter, setPageFooter] = useState(null)
  const [searchGrid, setSearchGrid] = useState(null)
  const [activityFeed, setActivityFeed] = useState(null)

  const { state } = useContext(StateContext)
  const { token } = state
  const fetchPageFooter = async () => {
    if (pageFooter !== null) return pageFooter
    let a = await getOnSiteMarketingCustomLocation(token, "5")
    let b = a.items[0]
    setPageFooter(b)
    return b
  }

  const fetchActivityFeed = async () => {
    if (activityFeed !== null) return activityFeed
    let a = await getOnSiteMarketingCustomLocation(token, "6")
    let b = a.items[0]
    setActivityFeed(b)
    return b
  }

  return (
    <OnSiteMarketingContext.Provider
      value={{
        pageFooterAd: fetchPageFooter,
        activityFeedAd: fetchActivityFeed,
      }}
    >
      {props.children}
    </OnSiteMarketingContext.Provider>
  )
}

export default OnSiteMarketingProvider
