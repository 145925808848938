/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { Link } from "gatsby"
import MenuHead from "./MenuHead"
export default function MenuSection(props) {
  return (
    <div className={!props.noBorder && `border-b border-[#EBEBEB]`}>
      {props.link ? (
        <Link
          to={props.link}
          className={`${!props.reducedPaddingY && `mb-4 pb-2 `} ${
            props.className && props.className
          }`}
        >
          <MenuHead {...props} />
        </Link>
      ) : (
        <div
          // to={props.link}
          className={`${!props.reducedPaddingY && `mb-4 pb-2 `} ${
            props.className && props.className
          }`}
        >
          <MenuHead {...props} />
        </div>
      )}

      {props.children}
    </div>
  )
}
