/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState, useEffect, useRef } from "react"
import StateContext from "../../../../context/state/StateContext"
import { getMySingleForm } from "../../../../helpers/DrupalHelper"
import { selectStyles } from "../../../../styled/Select"
import DynamicForm from "../DynamicForm"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete"
import AsyncFloatingSelect from "../../../Elements/Form/AsyncFloatingSelect"
import Button from "../../../Elements/Button"
import ButtonLite from "../../../Elements/ButtonLite"
import ButtonEdit from "../../../Elements/ButtonEdit"
import { components } from "react-select"
import CustomLocationForm from "../../CustomLocationForm"
import { getLocationFromId } from "../../../../helpers/DrupalHelper"
function GeoLocations({
  onChange,
  label,
  errors,
  name,
  hookForm,
  register,
  defaultValue,
  data,
  token,
  setValue,
  required,
  unregister,
  fromAddressPage,
  control,
}) {
  const [formData, setFormData] = useState(null)
  const [customActive, setCustomActive] = useState(false)
  const [selectInput, setSelectInput] = useState(false)
  const pRef = useRef(null)
  const { state } = useContext(StateContext)
  const IndicatorsContainer = ({ children, ...props }) => {
    useEffect(() => {
      if (props.selectProps.inputValue !== "") {
        setSelectInput(true)
      }
    }, [props.selectProps.inputValue])
    useEffect(() => {
      if (
        props.selectProps.value === null &&
        props.selectProps.isLoading === false
      ) {
        if (props.selectProps.inputValue) setSelectInput(true)
      } else {
        if (props.selectProps.inputValue) setSelectInput(true)
        else setSelectInput(false)
      }
    }, [props.selectProps.value, props.selectProps.isLoading])
    return (
      <components.IndicatorsContainer {...props}>
        <div className="flex justify-between items-center">
          {selectInput && (
            <ButtonLite
              inline
              pink
              sm
              bold
              callback={() => setCustomActive(true)}
            >
              Can't find your location?
            </ButtonLite>
          )}{" "}
          {children}
        </div>
      </components.IndicatorsContainer>
    )
  }
  useEffect(async () => {
    let formData = await getMySingleForm(state.token, "location")
    // console.log(formData)
    setFormData(formData)
  }, [])
  const [placeValue, setPlaceValue] = useState(null)
  const [customError, setCustomError] = useState(false)
  const [disable, setDisable] = useState(data?.current_value ? true : false)

  useEffect(() => {
    if (placeValue !== null && customError === true) {
      setCustomError(false)
    }
  }, [placeValue])
  const onSave = data => {
    let values = Object.values(data).filter(Boolean)
    let strAdd = values.toLocaleString().replaceAll(",", ", ")
    // console.log(values, strAdd)
    setValue("customBodyLocation", {
      name: name,
      body: {
        entity_title: strAdd,
        json: {
          field_location_address: { ...data },
          field_location_shortname: strAdd,
        },
      },
    })
  }
  useEffect(() => {
    register(name, { required: "This field is required" })

    register("customBodyLocation")
    return () => {
      unregister(name)
      unregister("customBodyLocation")
    }
  }, [])
  useEffect(async () => {
    // console.log(placeValue)
    // console.log(pRef)
    let token = ""
    if (pRef?.current !== null) {
      let { getSessionToken } = pRef.current
      token = getSessionToken()
      // console.log(token)
    }
    if (placeValue !== null) {
      // geocodeByPlaceId(placeValue.value.place_id)
      // let data = await fetch(
      //   `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeValue.value.place_id}&key=AIzaSyBYcydNhbqt3F75Bj01568YJjWYTT9Zz9g`,
      //   {
      //     method: "GET",
      //     headers: {},
      //   }
      // )
      // console.log(data)
      // console.log(await data.json())
    }
  }, [placeValue])
  return (
    <>
      <div
        className={`relative z-[222] ${
          !fromAddressPage && "mb-6"
        } w-full group`}
      >
        <div id="2" style={{ display: "none" }}></div>
        {!customActive && (
          <>
            {customError === true && (
              <p className="text-reg-17 !text-[11px] -bottom-[15px] absolute red">
                We couldn't confirm this as a valid store address. You must
                specify a full address.
              </p>
            )}
            <AsyncFloatingSelect
              label={"..."}
              name={"..."}
              hookForm
              // register={register}
              error={errors["field_group_stores"]}
              control={control}
              required={required}
              defaultValue={
                data.current_value && data.current_value.length
                  ? Object.assign({
                      value: data.current_value[0].target_id,
                      label: data.current_value[0].title,
                    })
                  : null
              }
              isDisabled={disable}
              body={{
                bundle: "default",
                entity: "location",
              }}
              isMulti={false}
              placeholder={"Start typing to find your address..."}
              minChars={5}
              notFoundButton={true}
              notFoundButtonTitle="Can't find your location?"
              notFoundButtonF={() => {
                setCustomActive(true)
                unregister(name)
              }}
              // loadingMessage={() => 'searching...'}
              customOnChange={async v => {
                if (v?.value) setValue(name, v.value)
                else setValue(name, null)
                // setShowLoc(true)
                // let data = await getRetailStoresLocation(state.token, v.value)
                // console.log(data)
                // setStoreLocation(data)
              }}
            />
            {/*  <GooglePlacesAutocomplete
              apiKey={process.env.GATSBY_GOOGLE_MAP_API}
              apiOptions={{ language: "en", region: "us" }}
              ref={pRef}
              autocompletionRequest={{
                // bounds: [
                //   { lat: 50, lng: 50 },
                //   { lat: 100, lng: 100 },
                // ],
                componentRestrictions: {
                  country: ["us", "ca"],
                  // fields: ["address_components", "geometry"],
                  // types: ["address"],
                },
                types: ["address"],
              }}
              selectProps={{
                // defaultInputValue: location,
                value: placeValue,
                // value: 'San',
                // isLoading: true,
                onChange: async v => {
                  // console.log(v.value.types)
                  // console.log(v.value)
                  if (
                    (!v.value.types.includes("street_address") &&
                      !v.value.types.includes("premise")) ||
                    v.value.terms.length < 4
                  ) {
                    setCustomError(true)
                  } else {
                    setPlaceValue(v)

                    let chekExtist = await getLocationFromId(
                      token,
                      v.value.place_id
                    )
                    if (Array.isArray(chekExtist) && !chekExtist.length) {
                      let service = new window.google.maps.places.PlacesService(
                        new window.google.maps.Map(
                          document.getElementById("2"),
                          {
                            zoom: 15,
                          }
                        )
                      )

                      service.getDetails(
                        {
                          placeId: v.value.place_id,
                          // sessionToken: token,
                        },
                        (a, b) => {
                          console.log(b)
                          if (b === "OK") {
                            let field_location_address = {
                              city: "",
                              country_code: "",
                              postal_code: "",
                              address_line1: "",
                              address_line2: "",

                              state: "",
                            }
                            a.address_components.map(v => {
                              if (
                                v.types.includes("administrative_area_level_1")
                              ) {
                                field_location_address.state = v.short_name
                              } else if (
                                v.types.includes("administrative_area_level_2")
                              ) {
                                field_location_address.city = v.long_name
                              } else if (v.types.includes("country")) {
                                field_location_address.country_code =
                                  v.short_name
                              } else if (v.types.includes("postal_code")) {
                                field_location_address.postal_code = v.long_name
                              } else if (v.types.includes("street_number")) {
                                field_location_address.address_line1 =
                                  field_location_address.address_line1
                                    ? field_location_address.address_line1 +
                                      v.long_name
                                    : v.long_name
                                // field_location_address.address_line2 =
                                //   field_location_address.address_line2 + v.long_name
                              } else if (v.types.includes("route")) {
                                field_location_address.address_line1 =
                                  field_location_address.address_line1
                                    ? field_location_address.address_line1 +
                                      ", " +
                                      v.long_name
                                    : v.long_name
                                // field_location_address.address_line2 =
                                //   field_location_address.address_line2 + v.long_name
                              } else if (v.types.includes("locality")) {
                                field_location_address.address_line1 =
                                  field_location_address.address_line1
                                    ? field_location_address.address_line1 +
                                      ", " +
                                      v.long_name
                                    : v.long_name
                                // field_location_address.address_line2 =
                                //   field_location_address.address_line2 + v.long_name
                              }
                              return v
                            })
                            console.log(a, field_location_address)
                            setValue("customBodyLocation", {
                              name: name,
                              body: {
                                entity_title: v.label,
                                json: {
                                  title: v.label,
                                  field_location_shortname: v.label,
                                  field_active: "1",
                                  field_location_address:
                                    field_location_address,
                                  field_location_id: v.value.place_id,
                                  field_location_geocode: JSON.stringify(v),

                                  field_location_lat: a.geometry.location.lat(),
                                  field_location_lng: a.geometry.location.lng(),
                                },
                              },
                            })
                          }
                        }
                      )
                      // setValue("customBodyLocation", {
                      //   name: name,
                      //   body: {
                      //     entity_title: placeValue.label,
                      //     json: {
                      //       title: placeValue.label,
                      //       field_location_shortname: placeValue.label,
                      //       field_active: "1",
                      //       field_location_address: {
                      //         city: "HaNoi",
                      //         country_code: "US",
                      //         postal_code: "55555",
                      //         address_line1: "ACBDDD",
                      //         address_line2: "SDSDFSDF",
                      //         // company: "ACB",
                      //         state: "NY",
                      //       },
                      //       field_location_id: placeValue.value.place_id,
                      //       field_location_geocode: JSON.stringify(placeValue),
                      //     },
                      //   },
                      // })
                      setValue(name, [])
                    } else {
                      console.log(name, chekExtist, Object.keys(chekExtist)[0])
                      setValue(name, Object.keys(chekExtist)[0])
                    }
                  }
                },
                // menuIsOpen: true,
                isDisabled: disable,
                placeholderFontSize: "40px",
                placeholderPosition: "relative",
                placeholderPadding: "0 0 0 0!important",
                placeholder:
                  data?.current_value && data?.current_value[0]?.title
                    ? data?.current_value[0]?.title
                    : disable
                    ? "No location specified"
                    : "Start typing to find your address...",
                valueContainerStyles: {
                  maxWidth: "360px",
                  marginRight: "40px",
                  overflow: "hidden",
                  padding: "0 0",
                  marginBottom: disable ? "4px" : "0",
                },
                styles: selectStyles,
                components: { IndicatorsContainer },
                noOptionsMessage: e =>
                  e.inputValue === "" ? "Start typing..." : "Nothing found",
              }}
            /> */}
            <label
              for={"address"}
              className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-1 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100  peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
              css={css`
                font-family: "Jost";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 28px;
                letter-spacing: 0.02em;
                color: #717171;
                background: #fff;
                background: ${disable
                  ? "linear-gradient(180deg, #fff 0%, #fff 12.6px, #ebebeb 12.6px, #eee 13.6px)"
                  : "#fff"};
              `}
            >
              {label ? label : "Address"}
            </label>
            {errors[name] && <span>{errors[name]["message"]}</span>}
            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row md:items-center mt-2">
              {data?.description && (
                <span
                  className="md:pr-4"
                  css={css`
                    font-family: "Jost";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    display: inline-block;
                    padding: 0 10px;
                    letter-spacing: 0.02em;
                    color: #717171;
                  `}
                >
                  {data.description}
                </span>
              )}
              {data?.current_value && disable && (
                <div className="flex justify-end md:ml-auto mt-[-11px] mb-[10px]">
                  <ButtonEdit
                    onClick={() => setDisable(false)}
                    text={"Change address"}
                  />
                </div>
              )}
            </div>
          </>
        )}{" "}
      </div>

      {formData && customActive && <CustomLocationForm onSave={onSave} />}
    </>
  )
}

export default GeoLocations
