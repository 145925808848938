/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
function FloatingCheckbox({
  name,
  label,
  value,
  hookForm,
  description,
  register,
  require,
  error,
  onChange,
  disabled,
  flush,
  gray,
  className,
}) {
  return (
    <div className="block">
      <div className={!flush ? `my-3` : `flex`}>
        <label className="inline-flex items-center">
          {hookForm === false ? (
            <input
              type="checkbox"
              className={
                !gray
                  ? `w-6 h-6 rounded rounded-[4px] ${className} ${
                      disabled &&
                      `bg-gray-100 border-gray-300 checked:bg-gray-400`
                    }`
                  : `mr-2 h-4 w-4 ${className} border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]`
              }
              onChange={onChange}
              checked={value}
              disabled={disabled || false}
            />
          ) : (
            <input
              type="checkbox"
              className={`w-6 h-6 rounded rounded-[4px] ${className}`}
              {...register(
                `${name}`,
                require
                  ? {
                      required: `This field is required`,
                    }
                  : undefined
              )}
            />
          )}
          {label && <span className="ml-2">{label}</span>}
        </label>
        {error && <span className="text-red-600">{error.message}</span>}
        {description && (
          <span
            // className="text-sm"
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding: 0 10px;
              margin: 7px 0 10px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
          >
            {description}
          </span>
        )}
      </div>
    </div>
  )
}

export default FloatingCheckbox
