import React from "react"

const defaultState = {
  options: {},
  items: [],
  toggleNotification: () => {},
}

const NotificationContext = React.createContext(defaultState)
export default NotificationContext
