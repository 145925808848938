/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import IconAddCircle from "../../../Elements/IconAddCircle"
import Button from "../../../Elements/Button"
import { useMediaQuery } from "react-responsive"

function BoxCardCreate(props) {
  const { title, callback } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  if (isMobile) {
    return (
      <div className="mr-auto">
        <Button
          red
          widthAuto
          onClick={callback}
          // className="lg:px-4 rounded-[8px] flex items-center justify-center flex-col"
          // css={css`
          //   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
          // `}
        >
          {title}
        </Button>
      </div>
    )
  }
  return (
    <a
      // href="#create"
      onClick={callback}
      className="lg:px-4 rounded-[8px] flex items-center justify-center flex-col cursor-pointer group hover-group py-[40px]"
      css={css`
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
        background-size: calc(100% - 1px);
        background-repeat: no-repeat;
        rect {
          transition: .2s ease all;
        }
        &:hover {
          rect {
            fill: #e43d30;
          }
        }
      `}
    >
      <IconAddCircle
        className="w-[70px] h-[70px] mb-5 ring-1 ring-transparent group-hover:ring-[#e43d30] group-hover:ring-offset-2 group-hover:bg-[rgba(228,61,48,.1)] transition-all"

      />
      <span className="text-semibold-20 hover group-hover:text-[#e43d30] transition-all">{title}</span>
    </a>
  )
}

export default BoxCardCreate
