/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { useMediaQuery } from "react-responsive"

const MenuTransition = props => {
  const { children } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom={
        isMobile
          ? `transform opacity-0 translate-y-full`
          : `transform opacity-0 scale-95`
      }
      enterTo={
        isMobile
          ? `transform opacity-100 translate-y-0`
          : `transform opacity-100 scale-100`
      }
      leave="transition ease-in duration-75"
      leaveFrom={
        isMobile
          ? `transform opacity-100 translate-y-0`
          : `transform opacity-100 scale-100`
      }
      leaveTo={
        isMobile
          ? `transform opacity-0 translate-y-full`
          : `transform opacity-0 scale-95`
      }
    >
      {children}
    </Transition>
  )
}

export default MenuTransition
