/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState, useMemo, useContext } from "react"
import { useDropzone } from "react-dropzone"
import Button from "../../components/Elements/Button"
import UploadIcon from "../../assets/icons/upload.svg"
import ImageCardSmall from "./ImageCardSmall"
import { DocumentTextIcon } from "@heroicons/react/solid"
import ErrorMessage from "../Forms/DynamicForm/ErrorMessage"
import NotificationContext from "../../context/Notification/NotificationContext"
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
}

const focusedStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

function StyledDropzone(props) {
  const [files, setFiles] = useState([])
  const { toggleNotification } = useContext(NotificationContext)
  const onDrop = React.useCallback(acceptedFiles => {
    props.setValue(props.name, [
      ...files,
      ...acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ])
    console.log(acceptedFiles)
    // if (props.type === "image") handleImageUpload(acceptedFiles);
    setFiles(prev => [
      ...prev,
      ...acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ])
    // setFiles((prev) => [...prev, ...acceptedFiles]);
    // props.setRootFiles((prev) => [...prev, ...acceptedFiles]);
  }, [])

  useEffect(() => {
    if (props.setThumbImages) props.setThumbImages(files)
  }, [files])

  useEffect(() => {
    // props.setValue(props.name, files)
    //get image width and height of the first image in the array of each File type
    if (props.name === "fromUpload_field_asset_cover_image") {
      if (files.length > 0) {
        const image = new Image()
        image.src = files[0].preview
        // image.onload = function () {
        //   if (image.width !== 500 || image.height !== 500) {
        //     toggleNotification({
        //       content: "First image must be 500x500 pixels",
        //       error: true,
        //     })
        //     setFiles([])
        //   }
        //   //also if first image is not ppng then error
        //   if (files[0].type !== "image/png") {
        //     toggleNotification({
        //       content: "First image must be PNG",
        //       error: true,
        //     })
        //     setFiles([])
        //   }
        // }
      }
    }

    console.log(files)
  }, [files])
  console.log(props.watch())
  useEffect(() => {
    if (props.watch) {
      let data = props.watch(props.name)
      console.log(data)
      if (data) setFiles(data)
    }
    props.register(props.name)
  }, [])

  const validateFileSize = file => {
    console.log(props.file_size)
    if (file.size > props.file_size) {
      toggleNotification({
        content: `File size exceeds the maximum limit of ${
          props.file_size / 1048576
        }MB.`,
        error: true,
      })
      return `File size exceeds the maximum limit of ${
        props.file_size / 1048576
      }MB.`
    }
    return null // File size is within the limit
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      eventsBubblingEnabled: true,
      maxSize: props.file_size ? props.file_size : null,
      validator: props.file_size ? validateFileSize : null,
    })

  const { sm } = props

  const style = useMemo(
    () => ({
      ...baseStyle,
      border: 0,
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E43D30' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e")`,
      backgroundColor: `transparent`,
      padding: sm ? `40px 20px` : `60px 0 50px`,
      flexDirection: sm ? `row` : `column`,
      ...(isFocused
        ? {
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23222' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e")`,
          }
        : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  // const InnerSmall = props => {
  //   return (

  //   )
  // }

  // const InnerLarge = props => {
  //   return (
  //     <div className="container z-[222]" {...getRootProps({ style })}>
  //       <input {...getInputProps()} />
  //       <UploadIcon className="mb-4 w-10" />
  //       <h3 className="text-med-18 mb-4">Drag and Drop Files Here</h3>
  //       <p className="text-reg-14 mb-2">
  //         File Supported: PDF, XSLS, IMAGE, SCANNER
  //       </p>
  //       <Button red outline>
  //         Choose File(s)
  //       </Button>
  //       <p className="text-reg-14 mt-3">Max size: 5MB</p>
  //     </div>
  //   )
  // }
  const handleRemoveClick = i => {
    if (files.length == 1) {
      setFiles([])
    } else {
      const tempArr = [...files]
      tempArr.splice(i, 1)
      // setValue(`${name}`, [...tempArr]);

      setFiles([...tempArr])
      props.setValue(props.name, [...tempArr])
    }
  }
  return (
    <>
      <div className="container " {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {sm ? (
          <div className="flex items-center justify-start w-full absolute">
            <UploadIcon className="w-10 mr-5" />
            <div>
              <h3
                className="text-med-16 mb-0"
                css={css`
                  &&& {
                    line-height: 26px;
                  }
                `}
              >
                {sm
                  ? "Drag & Drop Here or Click to Select Files"
                  : "Drag & Drop Files Here"}
              </h3>
              <p
                className="text-reg-11 mb-0"
                css={css`
                  &&& {
                    line-height: 20px;
                  }
                `}
              >
                {`Max size: ${
                  !props.fileLimit ? "25MB" : props.fileLimit + "MB"
                }`}
                {!sm && <>. (PDF, IMAGE, VIDEO, DOCX, PPTX, XSLS)</>}
              </p>
            </div>
            {!sm && (
              <div className="ml-auto">
                <Button red outline>
                  Choose File(s)
                </Button>
              </div>
            )}
          </div>
        ) : (
          <>
            <UploadIcon className="mb-4 w-10" />
            <h3 className="text-med-18 mb-4">Drag and Drop Files Here</h3>
            <p className="text-reg-14 mb-2">
              File Supported: PDF, XSLS, IMAGE, SCANNER
            </p>
            <Button red outline>
              Choose File(s)
            </Button>
            <p className="text-reg-14 mt-3">{`Max size: ${
              !props.fileLimit ? "25MB" : props.fileLimit + "MB"
            }`}</p>
          </>
        )}
      </div>
      <div className="flex">
        {files.map((file, i) => {
          console.log(file, "file!")
          const imageTypes = [
            "jpg",
            "jpeg",
            "png",
            "webp",
            "gif",
            "tiff",
            "tif",
            "bmp",
          ]
          let size = file.size / 1024 / 1024
          return (
            <div
              style={{
                flexGrow: 1,

                paddingTop: true || files.length > 3 ? 10 : 0,
                paddingBottom: true || files.length > 3 ? 10 : 0,
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
              }}
              key={file.path}
            >
              {imageTypes.includes(file.type) ? (
                <ImageCardSmall sm image={file.preview} alt={file.name} />
              ) : (
                <DocumentTextIcon className={"h-6 w-6"} />
              )}
              <div
                style={{
                  color: "rgba(0,0,0,.8)",
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                <strong>{file.name}</strong>
                <p>{size.toFixed(2)} MB</p>
                <Button
                  sm
                  plain
                  destructive
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default function ImageUpload(props) {
  const {
    onChange,
    icon,
    label,
    placeholder,
    name,
    sm,
    file_size,
    fileLimit,
    multiFiles,
  } = props
  return (
    <div className="z-[33333333]">
      {label && (
        <label
          htmlFor={props.name ? name : "input-" + Math.random(90000, 10000)}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div
      // className="relative"
      >
        <StyledDropzone {...props} />

        {/*icon && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )*/}
      </div>
    </div>
  )
}
