/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Icon from "../../assets/icons/delivery.svg"
import { red } from "../Styles"
function MetaMini(props) {
  const { title, grey, noHeight } = props

  return (
    <div
      className={`${
        props.grey
          ? `bg-[#f7f7f7] text-[#717171]`
          : `bg-[#fce3e1] text-[#e43d30]`
      } rounded-[6px] inline-flex text-[12px] p-[1px_10px] ${
        !noHeight && "h-[22px]"
      } items-center`}
    >
      {title}
    </div>
  )
}

export default MetaMini
