export const isSSR = typeof window === "undefined"

export const formatTextThin = str => {
  let string = str
  string = string.replaceall("<div>&nbsp;</div>", "")
  string = string.replaceall("<div>&nbsp;</div>", "")
  return string
}

export const formatTextInline = str => {
  return str.replaceAll(/(<([^>]+)>)/gi, "")
}
