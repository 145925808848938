/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import SlideoutHeader from "./SlideoutHeader"
import SlideoutBody from "./SlideoutBody"
import SlideoutBackdrop from "./SlideoutBackdrop"
import { classNames } from "../../../utils"
function Slideouts(props) {
  const { title, children, open, setOpen, onClose, fromPage, toggleSidebar } =
    props
  return (
    <Transition.Root
      show={open}
      as={"div"}
      // className={classNames(open ? "" : "hidden")}
    >
      <Dialog
        as="div"
        className={classNames("fixed inset-0 overflow-hidden z-[99]")}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className={classNames("absolute inset-0 overflow-hidden")}>
          {open && <SlideoutBackdrop />} <SlideoutBackdrop />
          <div
            className={classNames(
              "pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md lg:max-w-[330px]">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-[10px] shadow-xl">
                  <SlideoutHeader
                    title={title}
                    onClose={() => setOpen(false)}
                    fromPage={fromPage}
                    toggleSidebar={toggleSidebar}
                  />
                  <SlideoutBody>{children}</SlideoutBody>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function Slideout(props) {
  const { title, children, open, setOpen, onClose, fromPage, toggleSidebar } =
    props
  const [showStart, setShow] = useState(false)
  useEffect(() => {
    if (open === true) {
      setShow(true)
    }
  }, [open])

  return (
    <Transition.Root
      unmount={showStart ? false : true}
      show={open}
      as={"div"}
      // className={classNames(open ? "" : "hidden")}
    >
      <Dialog
        as="div"
        className={classNames("fixed inset-0 overflow-hidden z-[99]")}
        onClose={() => setOpen(false)}
        open={open}
        unmount={showStart ? false : true}
      >
        <div className={classNames("absolute inset-0 overflow-hidden")}>
          {open && <SlideoutBackdrop />} <SlideoutBackdrop />
          <div
            className={classNames(
              "pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0 "
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              unmount={showStart ? false : true}
            >
              <div className="pointer-events-auto w-screen max-w-md lg:max-w-[330px]">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-[10px] shadow-xl">
                  <SlideoutHeader
                    title={title}
                    onClose={() => setOpen(false)}
                    fromPage={fromPage}
                    toggleSidebar={toggleSidebar}
                  />
                  <SlideoutBody>{children}</SlideoutBody>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
