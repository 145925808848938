import React, { useState, useEffect, useContext } from "react"
import CartContext from "./CartContext"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  getCart,
  getUserStoresListing,
  getUserAddressBookListing,
  processCheckOut,
  setCart,
  addToCart,
  adjustQuantityInCart,
  removeItemFromCart,
  submitWebform,
  getProductListing,
  orderDigitalAsset as orderDigitalAssetCallback,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import { list } from "postcss"
import { verifiedHost } from "../../helpers/HostHelper"
import ModalCart from "../../components/Cart/ModalCart"
import Modal from "../../components/Modal/Modal"
import AuthenticationContext from "../authentication/AuthenticationContext"
import NotificationContext from "../Notification/NotificationContext"
import Button from "../../components/Elements/Button"
import ImageCardSmall from "../../components/Elements/ImageCardSmall"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import FloatingTextarea from "../../components/Elements/Form/FloatingTextarea"
import { set } from "react-hook-form"
export const CartProvider = props => {
  const [cartItems, setCartItems] = useState([])
  const [prevCart, setPrevCart] = useState([])
  const [orderConfirmation, setOrderConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [userStores, setUserStores] = useState([])
  const [userAddresses, setUserAddresses] = useState([])
  const [emptyAddressBookModalOpen, setEmptyAddressBookModalOpen] =
    useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openCartDropdown, setOpenCartDropdown] = useState(false)
  const [assetId, setAssetId] = useState(null)
  const [products, setProducts] = useState([])
  const { state } = useContext(StateContext)
  const { authentication } = useContext(AuthenticationContext)
  const { toggleNotification } = useContext(NotificationContext)

  useEffect(() => {
    if (cartItems.length > 0) {
      const x = checkCartItemQuantitiesAndRemoveInvalidExtras(cartItems)
      // if(JSON.stringify(cartItems) !== JSON.stringify(x)) setCartItems([...x])
    }
  }, [cartItems])

  const checkCartItemQuantitiesAndRemoveInvalidExtras = (items) => {

    const newItems = JSON.parse(JSON.stringify([...items]));

    items.map((item, k) => {
      const { addresses, status } = item

      addresses.map((address, s) => {

        const { address_id, quantity } = address
        if (parseInt(quantity) > parseInt(status.max_qty)) {
          // newItems[k].addresses[s].quantity = status.max_qty.toString()

          quantityUpdate(item.asset_id, address_id, status.max_qty)
        }
      })
    })

    // console.log(newItems, "newItems")
    return newItems

  }

  useEffect(() => {
    // console.log(cartItems, "cartItems")

    if (cartItems.length > 0) {
      setPrevCart(cartItems)
    }
  }, [cartItems])
  const handleProcessCart = async (
    giftOrder = false,
    orderNote = null,
    notify = null
  ) => {
    let addressIds = new Set()
    // let addressIdsWithLessThan2Assets = new Set()

    cartItems.filter((f, k) => {
      const { addresses } = f
      addresses.map((address, s) => {
        const { address_id } = address
        addressIds.add(address_id)
      })
    })

    addressIds = Array.from(addressIds)

    // let errorArr = []
    // addressIds.map((item, k) => {
    //   let assetIdsForAddress = new Set()
    //   cartItems.filter(f => {
    //     f.addresses.filter(e => {
    //       if (e.address_id === item) {
    //         assetIdsForAddress.add(f.asset_id)
    //       }
    //     })
    //   })
    //   if (assetIdsForAddress.size < 2) errorArr.push(item)
    // })
    // console.log(
    //   errorArr
    // ) /* addressIds with less than 2 unique assets in the cart*/

    // if (errorArr.length > 0) {
    //   toggleNotification({
    //     content:
    //       "You must order at least two unique assets to each address to checkout.",
    //     error: true,
    //   })
    // } else {
    // setLoading(true)
    setPrevCart(cartItems)
    setCartItems([])
    setOrderConfirmation(null)
    processCheckOut(token, giftOrder, orderNote, notify).then(async res => {
      if ("message" in res) {
        toggleNotification({ content: res.message, error: true })
        setLoading(false)
        navigate("/cart")
      } else {
        // let items = await getCart()
        // setCartItems([...items])
        // navigate(`/order-confirmation`, {
        //   state: { orderConfirmation: res, prevCart: prevCart },
        // })
        setOrderConfirmation(res)
        setLoading(false)
        toggleNotification({
          content: `Success! Your order has been placed.`,
        })
      }
    })
    console.log({ cartItems })
    const temp_cart = cartItems.map(asset => {
      const stores = asset.status.stores.map(store => {
        const matchingAddress = asset.addresses.find(
          address => address.address_id === store.id
        )
        const maxQtyOrdered = matchingAddress
          ? parseInt(matchingAddress.quantity) + store.max_qty_ordered
          : store.max_qty_ordered
        return { ...store, max_qty_ordered: maxQtyOrdered }
      })
      return {
        ...asset,
        status: { ...asset.status, stores },
        timeStamp: Date.now(),
      }
    })
    localStorage.setItem("temp_cart", JSON.stringify(temp_cart))
    navigate(`/order-confirmation`, {
      // state: { orderConfirmation: null, prevCart: prevCart },
    })
    // }
  }
  const { token } = state
  useEffect(async () => {
    if (authentication.currentUserData.isAuthenticated === true) {
      let data = await getCart(token)
      setCartItems(data)
      let id = authentication.currentUserData.i
      // let userStoresList = await getUserStoresListing(token, id)
      // setUserStores([...userStoresList])

      let userAddressesList = await getUserAddressBookListing(token)

      const makeAddressBook = setUserAddressBook(userAddressesList)

      // console.log(makeAddressBook.length, "s")
      // console.log(authentication.currentUserData.onboarding)

      // console.log(authentication.currentUserData.status) === "approved"

      if (
        // authentication.currentUserData.retailers.length > 0 &&
        makeAddressBook.length === 0 &&
        // authentication.currentUserData.onboarding !== 0 &&
        authentication.currentUserData.status === "approved"
      ) {
        setEmptyAddressBookModalOpen(true)
      }
    }
  }, [])

  const setUserAddressBook = userAddressesList => {
    let userAddressesListSorted = userAddressesList.length
      ? userAddressesList.sort(
        (a, b) =>
          a.default === b.default
            ? 0
            : a.default
              ? -1
              : 1 /* Default item first */
      )
      : []
    setUserAddresses(userAddressesListSorted)
    return userAddressesListSorted
  }

  const updateCartQuantities = async ({
    assetId: assestId,
    addresses: addressIds,
    title: title = null,
    userGroup: userGroup,
    // status: status,
  }) => /* include the assetId and Cart address array with qties to change*/ {
    // const updateCartQuantities = async (
    //   assestId,
    //   addressIds,
    //   title
    //   /* include the assetId and Cart address array with qties to change*/
    // ) => {

    console.log(assestId, addressIds, title, "res")
    /* we retain unchanged items */
    let body = await getCart(token)
    body.map((item, k) => {
      item.addresses.map((x, y) => {
        body[k].addresses[y].quantity = "0"
      })
    })

    let locateAsset = body.filter(
      f => parseInt(f.asset_id) === parseInt(assestId)
    )
    if (locateAsset.length > 0) {
      // alert('found')
      locateAsset = locateAsset[0]
      /* just reset it, user is changing the order for assetID completely, so wipe */
      await removeItemFromCart(token, [
        {
          asset_id: assestId,
          addresses: [],
        },
      ])
      /* reset the body, fetch it straight from Drupal so we reduce chance of human error*/
      body = await getCart(token)
      /* we retain unchanged items */
      body.map((item, k) => {
        item.addresses.map((x, y) => {
          body[k].addresses[y].quantity = "0"
        })
      })
    }

    body = [
      ...body,
      {
        asset_id: assestId,
        addresses: addressIds,
        user_group: userGroup,
      },
    ]

    let message = `Asset ${title || assestId} added to cart successfully!`
    if (cartItems.filter(f => f.asset_id === assestId).length > 0) {
      message = `Changes applied to your cart for ${title || "asset " + assestId
        }.`
      if (true) {
        let existingItem = {}
        existingItem.asset_id = cartItems.filter(
          f => f.asset_id === assestId
        )[0].asset_id
        existingItem.addresses = cartItems.filter(
          f => f.asset_id === assestId
        )[0].addresses

        let proposedNewItem = { asset_id: assestId, addresses: addressIds }

        if (JSON.stringify(existingItem) === JSON.stringify(proposedNewItem)) {
          message = `No changes were applied to your cart for ${title || "asset " + assestId
            }.`
        }
      }
    }

    let data = await addToCart(token, body)

    let items = await getCart()
    setCartItems([...items])
    closeModal()
    toggleNotification({
      content: message,
    })
  }

  const openCartDropdownFunc = async (id, count, t, userGroup) => {
    let el = document.getElementsByClassName("cartDropdownButton002")[0]
    setOpenCartDropdown(!openCartDropdown)
    await updateCartQuantities({
      assetId: id,
      addresses: [{ address_id: userAddresses[0]?.id, quantity: count }],
      title: t || null,
      userGroup: userGroup,
    })
    //   id,
    //   [
    //     { address_id: userAddresses[0]?.id, quantity: count }
    //   ],
    //   t
    // )

    // assetId: assestId,
    // addresses: addressIds,
    // title: title = null,
    // userGroup: userGroup,

    el.click()
  }

  const handleRemoveClick = async (assetId, addressId) => {
    const tempArray = [...cartItems]
    let locateAsset = tempArray.filter(
      f => parseInt(f.asset_id) === parseInt(assetId)
    )
    // alert(locateAsset)
    if (!Array.isArray(locateAsset)) {
      return false
    }
    locateAsset = locateAsset[0]

    if (
      locateAsset.addresses.length === 1 &&
      parseInt(locateAsset.addresses[0].address_id) === parseInt(addressId)
    ) {
      let body = [
        {
          asset_id: assetId,
          addresses: [],
        },
      ]
      await removeItemFromCart(token, body).then(async v => {
        let items = await getCart()
        setCartItems([...items])
        toggleNotification({
          content: `You removed an item from your cart.`,
        })
      })
    } else if (
      locateAsset.addresses.length > 1 &&
      locateAsset.addresses.filter(
        f => parseInt(f.address_id) === parseInt(addressId)
      ).length > 0
    ) {
      let body = [
        {
          asset_id: assetId,
          addresses: [
            {
              address_id: addressId,
            },
          ],
        },
      ]
      await removeItemFromCart(token, body).then(async v => {
        let items = await getCart()
        setCartItems([...items])
        toggleNotification({
          content: `You removed an item from your cart.`,
        })
      })
    } else {
      toggleNotification({
        content: `An error occured while attempting to remove this item from your cart. Please contact support.`,
        error: true,
      })
    }
  }

  const quantityUpdate = async (assetId, addressId, totalQuantity) => {
    if (totalQuantity === 0) {
      handleRemoveClick(assetId, addressId)
    } else {
      adjustQuantityInCart(token, assetId, addressId, totalQuantity).then(
        async v => {
          let items = await getCart()
          setCartItems([...items])
        }
      )
    }
  }

  const [cartData, setCartData] = useState({})
  const [requestedAssetData, setRequestedAssetData] = useState(null)

  const orderDigitalAsset = async ({
    assetId: asset_id,
    addresses: addresses,
    title: title,
  }) => {
    console.log(asset_id, addresses, title, "orderDigitalAsset")
    // alert('downloading')

    let res = await orderDigitalAssetCallback(
      token,
      asset_id,
      addresses.map(a => a.address_id)
    )
    if (res.ok) {
      const result = await res.text()

      if (result.includes("OK")) {
        toggleNotification({
          content: `Thanks for ordering ${title}! Your download is starting now.`,
        })
        onDownload(asset_id)
      } else {
        toggleNotification({
          content: `Downloading the latest version of ${title}`,
        })
        onDownload(asset_id)
      }
    } else {
      toggleNotification({
        content: `There was an issue processing your digital order.`,
        error: true,
      })
    }
  }

  const onDownload = async id => {
    // alert(id)
    const rand = () => Math.floor(100000000000 + Math.random() * 900000000000)
    let query = `${rand()}${id}${rand()}`
    query = btoa(query)
    const url = `${verifiedHost}/api/download?q=${query}`
    window.open(url, "_blank").focus()
  }

  const showCartModal = (assetId, data) => {
    setAssetId(assetId)
    setOpenModal(true)
    setCartData(data)

    console.log(data, "data")
    setRequestedAssetData(data.assetData)
  }
  const closeModal = () => {
    setOpenModal(false)
    setAssetId(null)
    setCartData({})
  }
  const navigateCreateAddressBookItem = () => {
    setEmptyAddressBookModalOpen(false)
    navigate("/dashboard/address-book", {
      state: { create: true },
    })
  }

  const [assetRequest, setAssetRequest] = useState(null)
  const [openRequestModal, setOpenRequestModal] = useState(false)

  const updateRequest = (asset_id, selected_stores) => {
    setAssetRequest({
      id: asset_id,
      stores: selected_stores,
      message: "",
      products: [],
    })
    setOpenModal(false)
    setOpenRequestModal(true)
  }

  useEffect(async () => {
    if (assetRequest !== null && requestedAssetData !== null) {
      const { brand_id } = requestedAssetData

      const body = {
        brand: [brand_id],
        item_per_page: 100,
      }
      const x = await getProductListing(token, body)
      setProducts(
        x.results.map(i => Object.assign({ value: i.id, label: i.title }))
      )
    }
  }, [assetRequest])

  const closeRequestModal = () => {
    setOpenRequestModal(false)
    setAssetRequest(null)
  }

  useEffect(() => {
    if (openRequestModal === false)
      document.getElementsByTagName("html")[0].style.overflow = "visible"
  }, [openRequestModal])

  const submitAssetRequest = async () => {
    let body = {
      webform_id: "request_asset",
      delivery_locations: assetRequest.stores.map(item => item.address_id),
      quantities: assetRequest.stores.map(item => item.quantity),
      asset: assetRequest.id,
      message: assetRequest.message,
      related_products: assetRequest.products,
      brand: requestedAssetData.brand_id,
    }

    // console.log(body, 'bod')
    await submitWebform(token, body)
      .then(res => {
        setAssetRequest(null)
        setOpenRequestModal(false)
      })
      .then(res =>
        toggleNotification({
          content:
            "Your request has been successfully submitted. Within 7 days you will receive a notification when your request is approved or denied.",
        })
      )
      .catch(e =>
        toggleNotification({ error: true, content: "Sorry, an error occured." })
      )
  }
  setInterval(() => {
    const temp_cart = localStorage.getItem("temp_cart")
    if (!temp_cart) {
      return
    }
    const currentTime = new Date().getTime()
    const item = JSON.parse(temp_cart)
    if (currentTime - item[0].timeStamp >= 5 * 60 * 1000) {
      localStorage.removeItem("temp_cart")
    }
  }, 5 * 60 * 1000) // check every 5 minutes
  // console.log("prevCart", prevCart)
  // console.log("orderConfirmation", orderConfirmation)
  // console.log("cartItems", cartItems)
  return (
    <CartContext.Provider
      value={{
        cart: cartItems,
        setCartItems: setCartItems,
        updateCartQuantities: updateCartQuantities,
        // updateCart,
        // userStores,
        showCartModal,
        handleRemoveClick,
        quantityUpdate,
        userAddresses: userAddresses,
        openCartDropdown,
        setOpenCartDropdown: openCartDropdownFunc,
        handleProcessCart: handleProcessCart,
        prevCart,
        orderConfirmation,
        setOrderConfirmation,
        setLoading,
        updateRequest,
        setUserAddressBook,
        orderDigitalAsset,
      }}
    >
      {loading ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
          <div className="flex items-center justify-center text-xl">
            <div>
              Processing your order.
              <p className="text-reg-14">Please do not refresh this page.</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          {emptyAddressBookModalOpen === true && (
            <>
              <Modal
                title="Add a Delivery Address"
                isOpen={emptyAddressBookModalOpen}
                closeModal={() =>
                  setEmptyAddressBookModalOpen(!emptyAddressBookModalOpen)
                }
              >
                <p className="text-reg-17">
                  Please add a delivery address to your Address Book. Until you
                  do, your access on O2O will remain limited.
                </p>
                <br />
                <Button
                  red
                  widthAuto
                  onClick={() => navigateCreateAddressBookItem()}
                >
                  Add an Address
                </Button>
              </Modal>
            </>
          )}
          {props.children}

          <ModalCart
            open={openModal}
            id={assetId}
            closeModal={closeModal}
            {...cartData}
          // byRequest={byRequest}
          />
          {openRequestModal === true && (
            <Modal
              isOpen={openRequestModal}
              closeModal={closeRequestModal}
              title={"Request Asset"}
            >
              {assetRequest !== null && (
                <>
                  <div className="flex flex-col">
                    <div className="flex items-center pb-[20px] border-b border-[#EBEBEB] mb-[20px]">
                      <ImageCardSmall image={requestedAssetData.cover[0]} />
                      <div className="ml-6 flex flex-col space-y-2">
                        <span className="text-med-10 !leading-[1]">
                          {requestedAssetData.brand_name}
                        </span>
                        <span className="!leading-[1]">
                          {requestedAssetData.title}
                        </span>
                        <span className=" !leading-[1] text-reg-14">
                          {requestedAssetData.sku}
                        </span>
                      </div>
                    </div>
                    <div className="mt-[20px]">
                      <FloatingSelect
                        isMulti
                        label="Related Products We Carry In-store"
                        name="related_products"
                        options={products}
                        description="Select any products you carry that are related to the asset you are requesting"
                        onChange={v => {
                          setAssetRequest(prevState => ({
                            ...prevState,
                            products: [...v.map(i => i.value)],
                          }))
                        }}
                      />
                      <FloatingTextarea
                        label={"Personal Message to Brand"}
                        name="message"
                        description="Type a personal message you want the brand to see."
                        onChange={v => {
                          setAssetRequest(prevState => ({
                            ...prevState,
                            message: v.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>
                  <Button onClick={() => submitAssetRequest()}>Submit</Button>
                </>
              )}
              {/* {JSON.stringify(requestedAssetData)} */}
            </Modal>
          )}
        </>
      )}
    </CartContext.Provider>
  )
}

export default CartProvider
