import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import StateContext from "../../../context/state/StateContext"
import FloatingSelect from "../../Elements/Form/FloatingSelect"
import Button from "../../Elements/Button"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
// import DataContext from "../../../context/Data/DataContext"
import {
  getTagsListing,
  myEntitySuggestions,
  submitWebform,
} from "../../../helpers/DrupalHelper"
import { getBrandsListing } from "../../../helpers/DrupalHelper"
import CircleAvatar from "../../Elements/CircleAvatar"
import FloatingInput from "../../Elements/Form/FloatingInput"
import FloatingTextarea from "../../Elements/Form/FloatingTextarea"
import AsyncFloatingSelect from "../../Elements/Form/AsyncFloatingSelect"
import {
  stringIncludesCanadianTelephone,
  validateEmail,
  validateUrl,
} from "../../../utils/messageValidation"
import NotificationContext from "../../../context/Notification/NotificationContext"
import FloatingCheckboxs from "../../Elements/Form/FloatingCheckboxs"
import Skeleton from "../../Skeleton"

function CreateMessage({ setModalOpen, setArchiveTrigger, share, title }) {
  const { state } = useContext(StateContext)
  const { userType } = useContext(AuthenticationContext)
  // const { currentUserCompaniesAsOp } = useContext(DataContext).data
  console.log({ userType })
  const { token } = state
  const [typeOptions, setTypeOptions] = useState()
  const [tags, setTags] = useState([])
  // const [list, setList] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [myStores, setMyStores] = useState([])
  const [brandsLoading, setBrandsLoading] = useState(true)
  // const [company, setCompany] = useState(
  //   currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
  // )
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()
  // useEffect(async () => {
  //   if (currentUserCompaniesAsOp) {
  //     setCompany(currentUserCompaniesAsOp[0])
  //   }
  // }, [currentUserCompaniesAsOp])
  useEffect(async () => {
    let data = await myEntitySuggestions(token, "store", ["retail_store"])
    setMyStores(data)
  }, [])

  useEffect(() => {
    if (share) {
      setValue("webform_id", "general_message")
      setValue("title", `Check out ${title}`)
      setValue("body", `Check out ${title} - ${window.location.href} on O2O!`)
    }
  }, [])
  const { toggleNotification } = useContext(NotificationContext)

  // useEffect(async () => {
  //   let users = []
  //   if (company && typeof company.value !== "undefined") {
  //     const data = await getSubUserListing(token, company.value)
  //     data?.map(item => {
  //       users.push({
  //         value: item.id,
  //         label: `${item.first_name} ${item.last_name} ${item.email}`,
  //         renderLabel: (
  //           <div className="flex flex-col">
  //             <span>{`${item.first_name} ${item.last_name}`}</span>
  //             <span>{item.email}</span>
  //           </div>
  //         ),
  //       })
  //     })
  //     setList(users)
  //   }
  // }, [company])

  useEffect(async () => {
    let options = []
    let body = {
      featured: null,
      regions: [],
      sort: [{ title: "desc" }],
    }
    let brandData = await getBrandsListing(token, body)
    setBrandsLoading(false)
    // let assetData = await getAssetListing(token)
    // console.log({ assetData })
    brandData?.items.map(item => {
      options.push({
        value: "brand" + item.id,
        label: item.title,
        renderLabel: (
          <div className="flex items-center">
            <CircleAvatar border xs image={item.logo} />
            <span className="ml-2">Brand - {item.title}</span>
          </div>
        ),
      })
    })
    setAttachment(options)
  }, [])
  console.log({ attachment })
  useEffect(() => {
    let t_options = [
      {
        value: "general_message",
        label: "General Message",
      },
    ]
    if (userType.retailerManager === true || userType.retailerEditor === true) {
      t_options.push(
        {
          value: "request_visit",
          label: "Visit Request",
        },
        {
          value: "contact_us",
          label: "Contact Us",
        }
      )
    }
    setTypeOptions(t_options)
  }, [userType])
  console.log({ errors })

  const onSubmit = async data => {
    console.log({ data })
    const runGeneralF = async () => {
      console.log({ data })
      let body
      if (
        userType.retailerManager === true ||
        userType.retailerEditor === true
      ) {
        body = { ...data, store: data.from }
      } else if (
        userType.brandManager === true ||
        userType.brandEditor === true
      ) {
        body = { ...data, brand: data.from }
      }
      delete body.from

      let assets = []
      let products = []
      let brands = []
      body.attachments?.forEach(item => {
        const [type, value] = item.split("$")

        if (type === "asset") {
          assets.push(parseInt(value))
        } else if (type === "product") {
          products.push(parseInt(value))
        } else if (type === "brand") {
          brands.push(parseInt(value))
        }
      })
      body = {
        ...body,
        assets: assets,
        products: products,
        brands: brands,
      }
      delete body.attachments
      console.log({ body })

      await submitWebform(token, body)
        .then(res => {
          toggleNotification({
            content: "Your message has been sent successfully!!",
          })
          console.log({ res })
          if (res.status === 200) {
            setModalOpen(false)
            setArchiveTrigger && setArchiveTrigger(true)
          }
        })
        .catch(e =>
          toggleNotification({
            error: true,
            content: "Failed to submit message",
          })
        )
    }
    const runVisitF = async () => {
      const storeName = myStores.filter(f => f.target_id === data.store)[0]
        ?.title

      const title = `Request Store Visit - ${storeName}`

      let days = data.days.map(v => v.value)
      let time_of_day = data.time_of_day.map(v => v.value)
      let body = {
        ...data,
        time_of_day: time_of_day?.toLocaleString(),
        days: days?.toLocaleString(),
        title: title,
        brand: `${data.brand.match(/\d+/)[0]}`,
        webform_id: "request_visit",
      }
      console.log(body)
      console.log("body")
      let res = await submitWebform(token, body)
      if (res.status === 200) {
        toggleNotification({
          content: `Your message has been sent successfully!!`,
        })
        setModalOpen(false)
        setArchiveTrigger(true)
      } else {
        toggleNotification({
          content: `Failed to submit visit request`,
          error: true,
        })
      }
    }
    const runContactF = async () => {
      let body = {
        ...data,
        title: data.title,
        brand: `${data.brand.match(/\d+/)[0]}`,
        webform_id: "contact_us",
      }
      let res = await submitWebform(token, body)
      if (res.status === 200) {
        toggleNotification({
          content: `Your message has been sent successfully!!`,
        })
        setModalOpen(false)
        setArchiveTrigger(true)
      } else {
        toggleNotification({
          content: `Failed to submit visit request`,
          error: true,
        })
      }
    }
    const runF = async () => {
      if (data.webform_id === "general_message") {
        runGeneralF()
      } else if (data.webform_id === "request_visit") {
        runVisitF()
      } else if (data.webform_id === "contact_us") {
        runContactF()
      }
    }

    if (data.body) {
      let myString = data.body
      let strArray = myString.split(" ")
      let found = strArray.some(
        str =>
          /*validatePhone(str) ||*/ stringIncludesCanadianTelephone(str) ||
          validateEmail(str) ||
          validateUrl(str)
        // str => validatePhone(str) || validateEmail(str) || validateUrl(str)
      )
      console.log(found)
      if (/\/(asset|product|brand)\/(\d+)/g.test(myString)) {
        await runF()
      } else if (found) {
        toggleNotification({
          content: `Oops, please don't share email addresses, weblinks, or phone numbers.`,
          error: true,
        })
      } else {
        await runF()
      }
    } else {
      await runF()
    }
  }
  useEffect(() => {
    register("webform_id", { required: "Please select this field" })
    console.log(watch("webform_id"))
    unregister("store")
    unregister("brand")
    if (watch("webform_id") === "general_message") {
      unregister("days")
      unregister("time_of_day")
      register("from", { required: "Please select this field" })
      register("cc", {
        validate: value => {
          if (value?.length > 5) {
            return "Please select upto 5 users"
          } else {
            return true
          }
        },
      })
      register("attachments", {
        validate: value => {
          if (value?.length > 5) {
            return "Please select upto 5 attachments"
          } else {
            return true
          }
        },
      })
      register("to", { required: "Please select this field" })
      register("title", { required: "Please select this field" })
      register("body", {
        required: "Please select this field",
      })
    }
    if (watch("webform_id") === "request_visit") {
      getTagsListing(token, "purpose_of_visit").then(res => {
        let array = []
        res.map((item, k) => {
          array.push({ value: item.title, label: item.title })
        })
        setTags(array)
      })
      register("brand", { required: "Please select this field" })
      register("time_of_day", { required: "Please select this field" })
      register("days", { required: "Please select this field" })
      register("purpose", { required: "Please select this field" })
      register("store", { required: "Please select this field" })
      register("body", { required: "Please select this field" })
    }
    if (watch("webform_id") === "contact_us") {
      unregister("days")
      unregister("time_of_day")
      register("store", { required: "Please select this field" })
      register("brand", { required: "Please select this field" })
      register("title", { required: "Please select this field" })
      register("body", {
        required: "Please select this field",
      })
    }
  }, [watch("webform_id")])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FloatingSelect
          label={"Type*"}
          name="webform_id"
          error={errors["webform_id"]}
          control={control}
          options={typeOptions}
          hookForm
          placeholder="Select one..."
        />
        {watch("webform_id") === "general_message" && (
          <>
            <FloatingSelect
              label={"From*"}
              name="from"
              error={errors["from"]}
              control={control}
              type={
                userType.brandManager === true || userType.brandEditor === true
                  ? "myBrandsAsOptions"
                  : (userType.retailerManager === true ||
                      userType.retailerEditor === true) &&
                    "myStoresAsOptions"
              }
              hookForm
              placeholder="Select one..."
            />
            <AsyncFloatingSelect
              label={"To"}
              name="to"
              hookForm
              register={register}
              error={errors["to"]}
              rqMsg="Please select this field"
              userOptions
              control={control}
              minChars={3}
              required
            />
            <AsyncFloatingSelect
              label={"CC(optional)"}
              name="cc"
              hookForm
              register={register}
              error={errors["cc"]}
              userOptions
              control={control}
              minChars={3}
              isMulti
            />
            <AsyncFloatingSelect
              label={"Attachments"}
              name="attachments"
              hookForm
              register={register}
              error={errors["attachments"]}
              attachmentOptions
              control={control}
              minChars={3}
              isMulti
            />
            <FloatingInput
              label="Subject *"
              hookForm
              name="title"
              register={register}
              error={errors["title"]}
            />
            <FloatingTextarea
              label="Message *"
              error={errors["body"]}
              name="body"
              hookForm
              register={register}
            />
          </>
        )}
        {watch("webform_id") === "request_visit" && (
          <>
            {brandsLoading ? (
              <h3 className="text-med-16 pr-2 flex items-center">
                <Skeleton dark width={"300px"} height={"30px"} />
              </h3>
            ) : (
              <FloatingSelect
                label={"Select Brand"}
                name="brand"
                hookForm
                error={errors["brand"]}
                options={attachment}
                control={control}
              />
            )}
            <FloatingCheckboxs
              options={[
                { value: "Morning", label: "Morning" },
                { value: "Afternoon", label: "Afternoon" },
                { value: "Evening", label: "Evening" },
                { value: "Night", label: "Night" },
              ]}
              title={"Time Of Day"}
              name="time_of_day"
              error={errors["time_of_day"]}
              hookForm
              control={control}
              description="What time of day is best to visit?"
              getValues={getValues}
              setValue={setValue}
            />
            <FloatingCheckboxs
              options={[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map(v => ({ value: v, label: v }))}
              setValue={setValue}
              title={"Days"}
              name="days"
              control={control}
              description="Which days are best to visit?"
              getValues={getValues}
              error={errors["days"]}
            />
            <FloatingSelect
              label={"Purpose of visit"}
              name="purpose"
              hookForm
              error={errors["purpose"]}
              options={tags}
              control={control}
              description={"The main reason for visit."}
            />
            <FloatingSelect
              label={"Store"}
              type={"myStoresAsOptions"}
              name="store"
              hookForm
              error={errors["store"]}
              control={control}
            />
            <FloatingTextarea
              label="Comments"
              error={errors["body"]}
              name="body"
              hookForm
              register={register}
            />
          </>
        )}
        {watch("webform_id") === "contact_us" && (
          <>
            <FloatingInput
              label="Subject *"
              hookForm
              name="title"
              register={register}
              error={errors["title"]}
            />
            {brandsLoading ? (
              <h3 className="text-med-16 pr-2 flex items-center mb-5">
                <Skeleton dark width={"300px"} height={"30px"} />
              </h3>
            ) : (
              <FloatingSelect
                label={"Select Brand"}
                name="brand"
                hookForm
                error={errors["brand"]}
                options={attachment}
                control={control}
              />
            )}
            <FloatingSelect
              label={"Store*"}
              name="store"
              error={errors["store"]}
              control={control}
              type={"myStoresAsOptions"}
              hookForm
              placeholder="Select one..."
            />
            <FloatingTextarea
              label="Message *"
              error={errors["body"]}
              name="body"
              hookForm
              register={register}
            />
          </>
        )}
        <Button red type="submit">
          Send
        </Button>
      </form>
    </div>
  )
}

export default CreateMessage