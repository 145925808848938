/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import LikedIcon from "../../../../assets/icons/heart.svg"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { XIcon } from "@heroicons/react/outline"
import { red } from "../../../Styles"
import ImageCardSmall from "../../../Elements/ImageCardSmall"
import CartContext from "../../../../context/Cart/CartContext"
import Icon from "../../../../assets/icons/close-alt.svg"
import CartCard from "../../../Cards/CartCard"
import Button from "../../../Elements/Button"
import IconButton from "./IconButton"
import BellIcon from "../../../../assets/icons/bell.svg"
import { ExclamationCircleIcon } from "@heroicons/react/outline"
import { styles, menuItemsClasses } from "./utils"
import HeaderFeed from "../HeaderFeed"
import Separator from "../../../Elements/Separator"
import {
  getMyBookmarks,
  getMyNotifications,
} from "../../../../helpers/DrupalHelper"
import StateContext from "../../../../context/state/StateContext"
import { classNames } from "../../../../utils"
import MenuSection from "../MenuSection"
import MobileMenuHead from "../MobileMenuHead"
import { useMediaQuery } from "react-responsive"
import MenuTransition from "./MenuTransition"
import MenuBackdrop from "./MenuBackdrop"
import MenuItems from "./MenuItems"
import LikeHits from "../../../Elements/LikeHits"
import DataContext from "../../../../context/Data/DataContext"

export default function Liked({ floating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const [list, setList] = useState(null)
  const { token } = useContext(StateContext).state
  const { myBookmarks } = useContext(DataContext).data
  const url = window.location.pathname

  useEffect(async () => {
    // let data = await getMyBookmarks(token)
    setList(myBookmarks)
  }, [myBookmarks])
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <MenuBackdrop open={open} />
            <Menu.Button className={styles}>
              <button
                className="relative inline-block text-left z-10"
                onClick={() => navigate("/liked")}
              >
                <div
                  className={`${styles} ${
                    floating
                      ? "hover:bg-white hover:ring-[#fff]"
                      : "hover:ring-[#EBEBEB] hover:shadow-[0px_6px_20px_rgba(34,34,34,0.05)]"
                  }`}
                  css={css`
                    min-width: 50px;
                    min-height: 50px;
                    &:hover {
                      path {
                        fill: ${floating ? `#484848` : ``};
                      }
                    }
                  `}
                >
                  <LikedIcon
                    className="h-5 w-5"
                    css={css`
                      path {
                        fill: ${floating ? `#fff` : `#484848`};

                        ${url === "/liked" && `stroke : #e43d30`};
                      }
                    `}
                  />
                  {/* <IconButton icon={LikedIcon} floating={floating} /> */}
                </div>
              </button>
            </Menu.Button>
            {/*    <MenuTransition>
              <Menu.Items
                // className={menuItemsClasses}
                className={`${menuItemsClasses} lg:!w-[410px]`}
                // className={`origin-top-right absolute right-0 mt-4 w-[410px] rounded-[8px] shadow-[0px_6px_20px_rgba(34,34,34,0.05)] bg-white z-[9] ring-1 ring-[#EBEBEB] focus:outline-none`}
                // css={css`
                //   &:after,
                //   &:before {
                //     bottom: 100%;
                //     left: calc(410px - 25px);
                //     border: solid transparent;
                //     content: "";
                //     height: 0;
                //     width: 0;
                //     position: absolute;
                //     pointer-events: none;
                //   }
                //
                //   &:after {
                //     border-color: rgba(136, 183, 213, 0);
                //     border-bottom-color: #fff;
                //     border-width: 8px;
                //     margin-left: -8px;
                //   }
                //   &:before {
                //     border-color: rgba(235, 235, 235, 0);
                //     border-bottom-color: #ebebeb;
                //     border-width: 9px;
                //     margin-left: -9px;
                //   }
                // `}
                // css={menuItemsStyles}
              >
                <div
                  className="p-[20px_15px_20px_15px] lg:p-[20px] overflow-y-scroll overflow-x-hidden"
                  css={css`
                    max-height: calc(80vh - 90px);
                  `}
                >
                  <Menu.Button className="w-full md:hidden ">
                    <MobileMenuHead title="Liked" />
                  </Menu.Button>
                  <MenuSection
                    title="My Liked"
                    // link={"#"}
                    reducedPaddingX
                    reducedPaddingY
                    noBorder
                  >
                    <div className="pb-[30px]">
                      {list !== null ? (
                        list &&
                        list.length &&
                        list.map((item, k) => {
                          let data = Object.entries(item)[0]
                          let propData = Array.isArray(data[1])
                            ? data[1][0]
                            : data[1]
                          return (
                            <Menu.Item key={k}>
                              {({ active }) => (
                                <div className="w-[80%] m-auto ">
                                  <LikeHits dataType={data[0]} {...propData} />

                                  <Separator className="mt-[20px]" />
                                </div>
                              )}
                            </Menu.Item>
                          )
                        })
                      ) : (
                        <div className="w-full overflow-hidden flex flex-col items-center justify-center">
                          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
                        </div>
                      )}
                    </div>
                    <Link to="/liked">
                      <Button red full>
                        Visit Liked
                      </Button>
                    </Link>
                  </MenuSection>
                </div>
              </Menu.Items>
            </MenuTransition> */}
          </>
        )}
      </Menu>
    </>
  )
}
