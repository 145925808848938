/** @jsx jsx */
import { css, jsx } from "@emotion/react"

import React, {
  //  Children, 
   useContext } from "react"
// import { Link } from "gatsby"
// import BreadCrumb from "../../Elements/BreadCrumb"
// import Logo from "../../../assets/logos/o2o-logo-r.png"
import { 
  // Fragment,
   useState } from "react"
// import { Dialog, Menu, Transition } from "@headlessui/react"
import Footer from "../../Commons/Footer/Footer"
import {
  // BellIcon,
  // CalendarIcon,
  ChartBarIcon,
  // FolderIcon,
  // HomeIcon,
  // InboxIcon,
  // MenuAlt2Icon,
  // PhotographIcon,
  // PuzzleIcon,
  // UsersIcon,
  // XIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline"
// import { red, redLight } from "../../Styles"
// import { LoginIcon, SearchIcon } from "@heroicons/react/solid"
// import { classNames } from "../../../utils"

import Header from "../../Commons/Header/Header"
import Navigate from "../../../assets/icons/navigate.svg"
import Download from "../../../assets/icons/download-alt.svg"
import Warehouse from "../../../assets/icons/warehouse-alt.svg"
import UserIcon from "../../../assets/icons/user.svg"
import Settings from "../../../assets/icons/settings.svg"
import Sent from "../../../assets/icons/sent.svg"
import Shop from "../../../assets/icons/shop.svg"
import Rss from "../../../assets/icons/rss.svg"
import Folder from "../../../assets/icons/folder.svg"
// import Coupon from "../../../assets/icons/coupon-alt.svg"
// import Pin from "../../../assets/icons/pin.svg"
// import Grid from "../../../assets/icons/grid.svg"
import Cart from "../../../assets/icons/cart.svg"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
// import Search from "../../Commons/Header/Search"
// import Icons from "../../Commons/Header/Icons"
// import User from "../../Commons/Header/User"
// import { faProductHunt } from "@fortawesome/free-brands-svg-icons"
import { useLocation } from "@reach/router"
// import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
import AuthenticationContext from "../../../context/authentication/AuthenticationContext"
// import AccessDenied from "../../AccessDenied"
import Helmet from "react-helmet"
// import LazyImage from "../../LazyImage"
import { useMediaQuery } from "react-responsive"
// let r = [
//   {
//     name: "My Downloads",
//     href: "/dashboard/my-downloads",
//     icon: Download,
//     current: false,
//   },
//   {
//     name: "Manage Files",
//     href: "/dashboard/manage-files",
//     icon: Folder,
//     current: false,
//   },
//   {
//     name: "My Orders",
//     href: "/dashboard/my-orders",
//     icon: Sent,
//     current: false,
//   },
//   {
//     name: "Stock List",
//     href: "/dashboard/stock-list",
//     icon: Warehouse,
//     current: false,
//   },
//   {
//     name: "My Stores",
//     href: "/dashboard/my-stores",
//     icon: Shop,
//     current: false,
//   },
// ]
export const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: Navigate, current: true },

  // { name: "Manage Roles", href: "/dashboard/manage-roles", icon: Settings, current: false },
  {
    name: "Manage User Groups",
    href: "/dashboard/manage-user-groups",
    icon: Settings,
    current: false,
  },
  {
    name: "Manage Users",
    href: "/dashboard/manage-users",
    icon: UserIcon,
    current: false,
  },
  {
    name: "Manage Subscriptions",
    href: "/dashboard/manage-subscriptions",
    icon: Rss,
    current: false,
  },

  {
    name: "My Brands",
    href: "/dashboard/my-brands",
    icon: UserGroupIcon,
    stroke: true,
    current: false,
  },
  {
    name: "My Assets",
    href: "/dashboard/my-assets",
    icon: Cart,
    current: false,
  },

  {
    name: "My Companies",
    href: "/dashboard/my-companies",
    icon: OfficeBuildingIcon,
    stroke: true,
    current: false,
  },
  {
    name: "My Retailers",
    href: "/dashboard/my-retailers",
    icon: ShoppingBagIcon,
    stroke: true,
    current: false,
  },
  {
    name: "My Sales",
    href: "/dashboard/my-sales",
    icon: ChartBarIcon,
    stroke: true,
    current: false,
  },
]
export const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
]

export default function LayoutDashBoard({
  // trail,
  heading,
  subheading,
  metaTitle,
  metaDesc,
  meta,
  children,
  report,
  headingMargin,
}) {
  let pageTitle = "O2O"
  let pageDescription = ""
  // console.log(headingMargin)
  // Check for title and description next...
  if (typeof metaTitle !== "undefined" && metaTitle !== null) {
    pageTitle = metaTitle + " - O2O"
  }
  if (typeof metaDesc !== "undefined" && metaDesc !== null) {
    pageDescription = metaDesc
  }

  // Finally, if a whole object was passed, use that instead...
  if (typeof meta !== "undefined") {
    if (typeof meta.title !== "undefined" && meta.title !== null) {
      pageTitle = meta.title
    }
    if (typeof meta.description !== "undefined" && meta.description !== null) {
      pageDescription = meta.description
    }
  }
  // const [sidebarOpen, setSidebarOpen] = useState(false)
  const {
    //  navigation, 
    userType } = useContext(AuthenticationContext)
  const location = useLocation()
  // let currentTrail = navigation
  //   .filter(v => location.pathname.includes(v.href))
  //   .map(v => {
  //     return { text: v.name, href: "#" }
  //   })
  // let trails = [dashboardTrail[0], currentTrail[0]]
  // if (currentTrail[0] && currentTrail[0].text == "Dashboard")
  //   trails = [dashboardTrail[0]]

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const [isTop, setIsTop] = useState(true)
  const [moveNavbarOnScroll, setMoveNavbarOnScroll] = useState(true)
  const [open, setOpen] = useState(
    location.pathname.includes(`reports`) ||
      location.pathname.includes(`inventory`)
      ? false
      : true
  )

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -50
      if (isShow !== moveNavbarOnScroll) setMoveNavbarOnScroll(isShow)
      if (currPos.y < 50) {
        setIsTop(true)
      } else {
        setIsTop(false)
      }
    },
    [moveNavbarOnScroll],
    null,
    false,
    100
  )

  return (
    <>
      <Helmet
        title={metaTitle ? pageTitle : heading + " | " + pageTitle}
        titleTemplate={"%s"}
      >
        <meta name="description" content={"O2O Dashboard"} />
      </Helmet>

      <div>
        <Header />
        {/* {!isMobile && (
          <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 flex z-40 md:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <LazyImage
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 space-y-1">
                        {navigation.map(item => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                </div>
              </Dialog>
            </Transition.Root>

            {report ? (
              <div
                className={`hidden md:flex md:flex-col md:fixed md:inset-y-0 transition-all duration-250 group delay-300 hover:delay-[0ms] ${
                  open ? `md:w-[300px]` : `md:w-[80px] hover:md:w-[300px] z-[9]`
                } `}
              >
                <div
                  className={`flex flex-col flex-grow border-r border-[#EBEBEB] overflow-y-auto bg-[#F7F7F7] !overflow-visible ${moveNavbarOnScroll ? "pt-[120px]" : "pt-[30px]"
                    } transition-[all] duration-250`}
                >
                  <div className="mt-5 flex-grow flex flex-col relative overflow-visible">
                    <button
                      type="button"
                      className={`flex mb-4 self-end d bg-[#ffff] items-center justify-center rotate-180 rounded-[4px] h-[22px] w-[22px] ring-1 ring-offset-2 ${open ? `ring-transparent top-[-38px] right-[12px]` : `ring-[#222] top-[-38px] right-[-12px]`}  hover:ring-[#222] transition-all absolute `}
                      onClick={() => {
                        setOpen(!open)
                      }}
                    >
                      <span className="sr-only">Toggle Sidebar</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-3 w-3 ${open ? `rotate-180` : `rotate-0`
                          } transition-all`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <nav className="flex-1 pb-4 space-y-1 pr-[30px]">
                      {navigation.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={
                            "group flex items-center pr-2 text-reg-15 transition-all"
                          }
                          css={css`
                            &&& {
                              padding-right: ${open ? `0.5rem` : `65px`};
                              color: ${location.pathname === item.href
                              ? red
                              : `#222`};
                              background-color: ${location.pathname ===
                              item.href
                              ? redLight
                              : `transparent`};
                              border-radius: 0 8px 8px 0;
                              &:before {
                                content: "";
                                width: 2px;
                                margin-right: 30px;
                                height: 50px;
                                background-color: ${location.pathname ===
                              item.href
                              ? red
                              : `transparent`};
                                transition: 0.15s ease all;
                              }
                              &:hover {
                                color: ${red};
                                background-color: ${redLight};
                                &:before {
                                  background-color: ${red};
                                }
                                path {
                                  fill: ${item.stroke ? `none` : red};
                                }
                              }
                            }
                          `}
                        >
                          <item.icon
                            className={`mr-4 flex-shrink-0 ${!item.stroke ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
                              }`}
                            css={css`
                              path {
                                fill: ${location.pathname === item.href &&
                                !item.stroke
                                ? red
                                : item.stroke
                                  ? `none`
                                  : `#222`};
                                stroke-width: ${item.stroke
                                  ? `1.25px`
                                  : ``};
                              }
                            `}
                            aria-hidden="true"
                          />
                          <span className={`group-hover:!opacity-[1] group-hover:!translate-x-[0] delay-200 group-hover:delay-[0ms]`}
                            css={css`
                              transition: 0.25s ease all;
                              transform: ${open
                                ? `translateX(0)`
                                : `translateX(-10px)`};
                              opacity: ${open ? `1` : `0`};
                              white-space: nowrap;
                            `}
                          >
                            {item.name}
                          </span>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hidden md:flex md:w-[300px] md:flex-col md:fixed md:inset-y-0">
                <div
                  className={`flex flex-col flex-grow border-r border-[#EBEBEB] overflow-y-auto bg-[#F7F7F7] ${moveNavbarOnScroll ? "pt-[120px]" : "pt-[30px]"
                    } transition-[all] duration-250`}
                >
                  <div className="mt-5 flex-grow flex flex-col">
                    <nav className="flex-1 pb-4 space-y-1 pr-[30px]">
                      {navigation.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={
                            "group flex items-center pr-2 text-reg-15 transition-all"
                          }
                          css={css`
                            &&& {
                              color: ${location.pathname === item.href
                              ? red
                              : `#222`};
                              background-color: ${location.pathname ===
                              item.href
                              ? redLight
                              : `transparent`};
                              border-radius: 0 8px 8px 0;
                              &:before {
                                content: "";
                                width: 2px;
                                margin-right: 30px;
                                height: 50px;
                                background-color: ${location.pathname ===
                              item.href
                              ? red
                              : `transparent`};
                                transition: 0.15s ease all;
                              }
                              &:hover {
                                color: ${red};
                                background-color: ${redLight};
                                &:before {
                                  background-color: ${red};
                                }
                                path {
                                  fill: ${item.stroke ? `none` : red};
                                }
                              }
                            }
                          `}
                        >
                          <item.icon
                            className={`mr-4 flex-shrink-0 ${!item.stroke ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
                              }`}
                            css={css`
                              path {
                                fill: ${location.pathname === item.href &&
                                !item.stroke
                                ? red
                                : item.stroke
                                  ? `none`
                                  : `#222`};
                                stroke-width: ${item.stroke
                                  ? `1.25px`
                                  : ``};
                              }
                            `}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </>
        )} */}

        {/* <div
          className={`pb-16 md:pb-0 flex flex-col flex-1
            ${!open ? "md:pl-[80px]" : "md:pl-[300px]"}
          `}
        > */}
        <div className={`pb-16 md:pb-0 flex flex-col flex-1 container`}>
          {/*!isMobile && (
            <div className="sticky top-0 z-10 flex-shrink-0 flex h-[90px] bg-white border-b border-[#EBEBEB] pr-[30px]">
              <button
                type="button"
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div className="flex space-x-6 ml-auto items-center">
                <Search />
                <Icons />
                <User />
              </div>
            </div>
          )*/}

          <main
            className={`flex-1 ${
              userType.brandManager === true ? "pt-[150px]" : "pt-[30px]"
            } px-[15px] lg:px-0`}
          >
            {location.pathname === "/dashboard/message" && children}
            <div className="">
              {/* <div
                className={
                  !open
                    ? " mx-auto px-[15px] md:px-8 w-full"
                    : "max-w-7xl mx-auto px-[15px] md:px-8"
                }
              > */}
              <div className={`mx-auto w-full ${headingMargin}`}>
                <div className="py-4 ">
                  {false && report && !open && (
                    <>
                      <button
                        type="button"
                        className="flex mb-4 justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
                        onClick={() => {
                          setOpen(true)
                        }}
                      >
                        <span className="sr-only">Sidebar Off</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                  {/* <BreadCrumb items={trails} /> */}

                  {(heading || subheading) && (
                    <div
                      className={` ${
                        isMobile
                          ? `flex flex-col items-start justify-start ${
                              !subheading ? `mb-[30px]` : ``
                            } !leading-[40px]`
                          : `flex items-center justify-between ${
                              !subheading ? `mb-10` : ``
                            }`
                      }`}
                    >
                      <h1 className={`text-strong-${isMobile ? "20" : "35"}`}>
                        {heading}
                      </h1>
                      {subheading && (
                        <p className={`text-reg-16 ${!report && `mb-10`}`}>
                          {subheading}
                        </p>
                      )}
                    </div>
                  )}
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}
