import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FloatingInput from "../Elements/Form/FloatingInput"
import Button from "../Elements/Button"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import SectionCard from "../Cards/Dashboard/SectionCard"
import {
  addUserStore,
  getMySingleEditForm,
  getStoreSingle,
  updateMySingleEditForm,
  updateStoreSingle,
} from "../../helpers/DrupalHelper"
import { selectStyles } from "../../styled/Select"
import DynamicForm from "./DynamicForm/DynamicForm"
import Skeleton from "../Skeleton"

function EditFormTemplate({
  token,
  name,
  bundle,
  id,
  onSave,
  list,
  nested,
  dynamicFields,
  suppData,
  savedStoreId,
  onExtraClick,
}) {
  const [formData, setFormData] = useState(null)
  useEffect(async () => {
    let data = await getMySingleEditForm(token, name, bundle, id)
    setFormData(data)
  }, [])
  useEffect(async () => {
    let data = await getMySingleEditForm(token, name, bundle, id)
    setFormData(data)
  }, [id])
  const onSubmit = async (data, defaultValues) => {
    console.log(data)
    let ModifiedObj = {}
    Object.keys(data).filter(key => {
      if (JSON.stringify(data[key]) !== JSON.stringify(defaultValues[key])) {
        Object.assign(ModifiedObj, { [key]: data[key] })
        return { key: key, value: data[key] }
      }
    })
    let body = { json: ModifiedObj }

    let res = await updateMySingleEditForm(token, name, bundle, id, body)
    if (res.status === 200) {
      let objIndex = list.findIndex(obj => obj.id == id)
      let newList = list
      newList[objIndex] = { ...newList[objIndex], ...ModifiedObj }
      let nList = [...newList]

      onSave(nList, "edited")
    } else {
      onSave("Editing " + name, "failed")
    }
  }
  console.log(list)
  return (
    <>
      {formData ? (
        <DynamicForm
          data={formData}
          token={token}
          name={name}
          bundle={bundle}
          edit
          editSubmit={onSubmit}
          nested={nested ? true : false}
          dynamicFields={dynamicFields}
          list
          suppData={suppData}
          onExtraClick={onExtraClick}
          savedStoreId={savedStoreId}
        />
      ) : (
        <Skeleton table />
      )}
    </>
  )
}

export default EditFormTemplate
