import React, { useState, useEffect } from "react"
import {
  getMyListing,
  getSubUserListing,
  myEntitySuggestions,
} from "../../../../helpers/DrupalHelper"

import FloatingSelect from "../../../Elements/Form/FloatingSelect"
import Skeleton from "../../../Skeleton"

function RetailerField({
  data,
  name,
  register,
  label,
  setValue,
  token,
  watch,
  control,
  dataCompany,
  errors,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState(null)
  console.log(entitySuggestion)
  useEffect(async () => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      })
      register("field_store_company")
    } else {
      register(name)
      register("field_store_company")
    }
    const entity = data.entity_type.machine_name
    // console.log(name, entity)
    const bundle = data.entities
      ? data.entities.map(v => v.machine_name)
      : data.entity_type.machine_name
    if (data.current_value && data.current_value?.length) {
      setValue(`${name}`, data?.current_value[0]?.target_id?.toString())
      setValue(`field_store_company`, [dataCompany.current_value[0]?.target_id])
    }

    let datas = await getMyListing(token, entity, bundle)
    // console.log(datas, "from my entities")
    if (datas.length === 1) {
      setValue(name, datas[0].target_id ? [datas[0].target_id] : [datas[0].id])
      setValue("field_store_company", [
        datas[0].field_retailer_company[0].target_id,
      ])
    }
    setEntitySuggestion(
      datas.map(v => ({
        ...v,
        value: v.target_id ? v.target_id : v.id,

        label: v.title,
      }))
    )
  }, [])
  if (entitySuggestion !== null) {
    return (
      <>
        {entitySuggestion.length > 1 ? (
          <FloatingSelect
            label={label}
            name={name}
            hookForm
            register={register}
            error={errors[name]}
            options={entitySuggestion}
            control={control}
            required
            noSelection
            defaultValue={
              data.current_value &&
              Array.isArray(data.current_value) &&
              data.current_value[0]
                ? {
                    value: data.current_value[0].target_id,
                    label: data.current_value[0].title,
                  }
                : false
            }
            customChange={async v => {
              setValue("field_store_company", [
                v.field_retailer_company[0].target_id,
              ])
            }}
          />
        ) : null}
      </>
    )
  } else {
    return <Skeleton />
  }
}

export default RetailerField
