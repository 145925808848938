/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { classNames } from "../../../../utils"
import CurrencyInput from "react-currency-input-field"
import { Controller } from "react-hook-form"
const fontStyles = `font-family: "Jost";
     font-style: normal;
     font-weight: 400;
     font-size: 15px;
     line-height: 28px;
     letter-spacing: 0.02em;
     color: #717171;`

function PriceBooleanType({
  name,
  data,
  label,
  setValue,
  error,
  register,
  description,
  control,
  watch,
  tabsTouched,
}) {
  const { field_asset_price_expanded } = watch()
  const [defaultChecked, setDefaultChecked] = useState(
    field_asset_price_expanded === undefined ? true : field_asset_price_expanded
  )
  // useEffect(() => {
  //   if (field_asset_price_expanded)
  //     setDefaultChecked(field_asset_price_expanded)
  // }, [field_asset_price_expanded])

  const changedChecked = set => {
    if (defaultChecked === false && set === true) {
      setValue(name, "")
    }
    setDefaultChecked(set)
  }

  return (
    <>
      <div
        className={classNames(
          "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}",
          error
            ? "border-red-600 focus:border-red-600 focus:text-red-600 "
            : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
        )}
      >
        <div className="flex">
          <div>
            <div className="flex items-center">
              <input
                id={`${name}_expanded`}
                type="checkbox"
                defaultChecked={defaultChecked}
                className="h-4 w-4 border-[#EBEBEB] rounded rounded-[4px] text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                onClick={() => changedChecked(!defaultChecked)}
                {...register(`${name + "_expanded"}`)}
              />
              <label
                htmlFor={`${name}_expanded`}
                className="ml-3 text-[#222] flex flex-col"
              >
                This asset is free
              </label>
            </div>

            {(data?.description || description) && (
              <div
                css={css`
                  font-family: "Jost";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  display: inline-block;
                  padding: 0 10px;
                  margin: 0 0 0 18px;
                  letter-spacing: 0.02em;
                  color: #717171;
                `}
                dangerouslySetInnerHTML={{
                  __html: data?.description || description,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {field_asset_price_expanded === false && (
        <div className={`relative z-0 mb-6 w-full group `}>
          <label
            for={name}
            className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
            css={css`
              ${fontStyles}
            `}
          >
            {label}
          </label>
          <Controller
            control={control}
            name={name}
            {...register(name)}
            render={({ field: { onChange, name, value } }) => (
              <CurrencyInput
                className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
                css={css`
                  /* border: 1px solid #ebebeb; */
                  border-radius: 8px;
                  ${fontStyles}
                `}
                placeholder="0.00"
                value={value ? value.toString().replace(/,/g, "") : ""}
                defaultValue={value}
                step="any"
                decimalsLimit={2}
                allowNegativeValue={false}
                fixedDecimalLength={2}
                // prefix="$"
                onChange={onChange}
              />
            )}
          />
          <div
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding: 0 10px;
              margin: 0 0 0 18px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
            dangerouslySetInnerHTML={{
              __html: "All prices in Canadian dollar.",
            }}
          />
        </div>
      )}
    </>
  )
}

export default PriceBooleanType
