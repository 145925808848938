/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { classNames } from "../../../utils"
import ButtonLite from "../ButtonLite"
let prefixes = [
  { i: "facebook", link: "https://www.facebook.com/" },
  { i: "twitter", link: "https://twitter.com/" },
  { i: "linkedin", link: "https://www.linkedin.com/in/" },
  { i: "instagram", link: "https://www.instagram.com/" },
]

const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
const PrefixedInput = ({ onChange, name, defaultValue, register, data }) => {
  let prefix = prefixes.filter(v => name.includes(v.i))[0].link
  const [inputVal, setInputValue] = useState(prefix)
  useEffect(() => {
    if (data.current_value)
      setInputValue(
        data?.current_value.includes(prefix)
          ? data.current_value
          : prefix + data.current_value
      )

    register(name)
  }, [])

  return (
    <input
      type={"text"}
      // name={inputName}
      className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
      css={css`
        /* border: 1px solid #ebebeb; */
        border-radius: 8px;
        ${fontStyles}
      `}
      placeholder=" "
      required=""
      defaultValue={defaultValue}
      value={inputVal}
      onChange={e => {
        const input = e.target.value
        let v = prefix + input.substr(prefix.length)
        onChange(v)

        setInputValue(v)
      }}
      // {...register(inputName)}
    />
  )
}

export default function FloatingInput({
  onChange,
  type,
  label,
  name,
  icon,
  hookForm,
  register,
  ref,
  required,
  error,
  className,
  defaultValue,
  data,
  setValue,
  watch,
  passwordR,
  description,
  flush,
  disabled,
  subdued,
  login,
  company_exist,
  company_error,
  setCustomCompanyActive,
  ...props
}) {
  const inputName = name
    ? name
    : label.toLowerCase().replaceAll(" ", "-") + "-" + Math.random(5000, 9000)

  let isUrlField =
    name.includes("facebook") ||
    name.includes("twitter") ||
    name.includes("linkedin") ||
    name.includes("instagram")

  let inputLabel = label
  if (required && !inputLabel.includes("*")) inputLabel = inputLabel + "*"
  return (
    <div
      className={`relative z-0 ${!flush ? `mb-6` : `mb-2`} w-full group ${
        className && className
      }`}
    >
      {hookForm ? (
        <>
          {isUrlField ? (
            <PrefixedInput
              name={name}
              register={register}
              setValue={setValue}
              defaultValue={defaultValue}
              onChange={v => setValue(name, v)}
              data={data}
            />
          ) : required ? (
            <>
              <input
                type={type ? type : "text"}
                className={classNames(
                  "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer",
                  error
                    ? "border-[#e43d30] focus:border-[#e43d30] focus:text-[#e43d30]"
                    : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                )}
                css={css`
                  /* border: 1px solid #ebebeb; */
                  border-radius: 8px;
                  ${disabled &&
                  `opacity: 0.5 !important;
                  background-color: #d9d9d9 !important;`}
                  ${fontStyles}
                `}
                {...register(
                  inputName,
                  inputName === "password"
                    ? {
                        minLength: {
                          value: 12,
                          message: "Password must have at least 12 characters",
                          required: `Password can't be empty`,
                        },
                        validate: value => {
                          const passwordRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/
                          if (!passwordRegex.test(value)) {
                            return "Password must contain: At least 1 uppercase letter, At least 1 lowercase letter, At least 1 number, At least 1 symbol"
                          } else return true
                        },
                        required: `Password can't be empty`,
                      }
                    : inputName === "password_repeat"
                    ? {
                        validate: passwordR
                          ? value => {
                              // console.log(value, passwordR)
                              return (
                                (passwordR && value && value === passwordR) ||
                                (passwordR !== "" &&
                                  value &&
                                  "The passwords did not match")
                              )
                            }
                          : () => {},
                        required: passwordR && `This field is required`,
                      }
                    : inputName === "tel"
                    ? {
                        minLength: {
                          value: 10,
                          message: "Phone number must have at least 10 numbers",
                        },
                        required: `Phone number can't be empty`,
                      }
                    : inputName === "company_manual"
                    ? {
                        required: `Company name can't be empty`,
                        validate: value => {
                          // console.log(value, passwordR)
                          if (company_exist && company_exist > 0) {
                            return "Sorry, a company with that name already exists. Instead of creating a new company, please search for and select this company from the list."
                          } else {
                            return true
                          }
                        },
                      }
                    : {
                        required: `This field is required`,
                      }
                )}
                placeholder=" "
                defaultValue={defaultValue}
                disabled={disabled}
                {...props}
              />
              {company_error ? (
                <ButtonLite
                  className="z-[22] mt-2"
                  inline
                  pink
                  sm
                  bold
                  company
                  onClick={() => setCustomCompanyActive(false)}
                >
                  Select from company list
                </ButtonLite>
              ) : null}
            </>
          ) : (
            <input
              type={type ? type : "text"}
              className="block py-[16px] px-[18px] w-full bg-transparent border border-[#EBEBEB] rounded-[8px] appearance-none focus:text-[#222] focus:outline-none focus:ring-0 focus:border-[#222] peer"
              css={css`
                /* border: 1px solid #ebebeb; */
                border-radius: 8px;
                ${disabled &&
                `opacity: 0.5 !important;
                  background-color: #d9d9d9 !important;`}
                ${fontStyles}
              `}
              // placeholder={placeholder ? placeholder : "Type here"}
              {...register(
                inputName,
                inputName === "password"
                  ? required === false
                    ? //  {
                      //     validate: value => {
                      //       // console.log(value, passwordR)
                      //       if (value && value.length < 12) {
                      //         return "Password must have at least 12 characters"
                      //       } else return true
                      //     },
                      //   }
                      {
                        minLength: {
                          value: 12,
                          message: "Password must have at least 12 characters",
                        },
                        validate: value => {
                          const passwordRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/
                          if (!passwordRegex.test(value) && value) {
                            return "Password must contain: At least 1 uppercase letter, At least 1 lowercase letter, At least 1 number, At least 1 symbol"
                          } else return true
                        },
                      }
                    : !login && {
                        minLength: {
                          value: 12,
                          message: "Password must have at least 12 characters",
                          required: `Password can't be empty`,
                        },
                        validate: value => {
                          const passwordRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/
                          if (!passwordRegex.test(value)) {
                            return "Password must contain: At least 1 uppercase letter, At least 1 lowercase letter, At least 1 number, At least 1 symbol"
                          } else return true
                        },
                        required: `Password can't be empty`,
                      }
                  : inputName === "password_repeat"
                  ? {
                      validate: passwordR
                        ? value => {
                            // console.log(value, passwordR)
                            return (
                              (passwordR && value && value === passwordR) ||
                              (passwordR !== "" &&
                                value &&
                                "The passwords did not match")
                            )
                          }
                        : () => {},
                      required: passwordR && `This field is required`,
                    }
                  : inputName === "tel"
                  ? {
                      minLength: {
                        value: 10,
                        message: "Phone number must have at least 10 numbers",
                      },
                    }
                  : undefined
              )}
              defaultValue={defaultValue}
              placeholder=" "
              disabled={disabled}
              {...props}
            />
          )}
        </>
      ) : (
        <input
          type={type ? type : "text"}
          name={inputName}
          className={classNames(
            "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer",
            error
              ? "border-[#e43d30] focus:border-[#e43d30] focus:text-[#e43d30] "
              : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]",
            subdued && "bg-[#ebebeb]"
          )}
          css={css`
            /* border: 1px solid #ebebeb; */
            border-radius: 8px;
            ${disabled &&
            `opacity: 0.5 !important;
              background-color: #d9d9d9 !important;`}
            ${fontStyles}
          `}
          placeholder=" "
          required=""
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
      )}
      {icon && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {icon}
        </div>
      )}

      <label
        for={inputName}
        className={classNames(
          `absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10`,
          subdued && `bg-transparent`
        )}
        css={css`
          ${fontStyles}
        `}
      >
        {inputLabel}
      </label>
      {error && <span className="text-[#e43d30]">{error.message}</span>}
      {description && inputName !== "title" && (
        <span
          // className="text-sm"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            padding: 0 10px;
            margin: 7px 0 10px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          {description}
        </span>
      )}
    </div>
  )
}
