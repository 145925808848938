import { verifiedHost } from "./HostHelper"
import fetch from "node-fetch"
const baseUrl = verifiedHost

export const sessToken = async () => {
  let response = await fetch(`${baseUrl}/session/token`, {
    method: "GET",
    credentials: "include",
  })
  let data = await response.text()
  return data
}
export const makeLogin = async (csrfToken, username, password) => {
  let response = await fetch(`${baseUrl}/user/login?_format=json`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      name: username,
      pass: password,
    }),
  })
  // let data = await .json()

  return response
}
export const cancelOrder = async (csrfToken, orderId) => {
  let response = await fetch(`${baseUrl}/api/order/cancel`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      order_id: orderId,
    }),
  })

  return response
}

export const checkEmailExist = async (csrfToken, email) => {
  let response = await fetch(`${baseUrl}/api/verify-email-address`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      email: email,
    }),
  })
  // let data = await .json()

  // alert(response.status)
  if (response.status === 200) return false
  return true
}
export const makeLogOut = async csrfToken => {
  const token_res = await fetch(`${baseUrl}/api/logout/token`, {
    method: "GET",
    credentials: "include",
  })
  const token = await token_res.text()
  console.log(token)
  let response = await fetch(
    `${baseUrl}/user/logout?_format=json&token=${token.replace(/"/g, "")}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      // body:,
    }
  )
  // let data = await .json()

  return response
}

export const forgotPassword = async (csrfToken, email) => {
  // let response = await fetch(`${baseUrl}/user/password?_format=json`, {
  let response = await fetch(`${baseUrl}/user/lost-password?_format=json`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      mail: email,
    }),
  })

  let data = await response

  return data
}

export const resetPassword = async (csrfToken, email, newPass, key) => {
  let response = await fetch(
    `${baseUrl}/user/lost-password-reset?_format=json`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        name: email,
        temp_pass: key,
        new_pass: newPass,
      }),
    }
  )

  let data = await response

  return data
}

export const getRetailStores = async (csrfToken, pageNumber) => {
  let url = `${baseUrl}/api/retail-stores`
  if (pageNumber) url = `${baseUrl}/api/retail-stores?page=${pageNumber}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getRetailStoresLocation = async (csrfToken, retailerId) => {
  let url = `${baseUrl}/api/retail-stores/${retailerId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getRetailStoresByCompany = async (csrfToken, companyId) => {
  let url = `${baseUrl}/api/company/${companyId}/retail-stores`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getAllCompanies = async csrfToken => {
  let url = `${baseUrl}/api/all-companies`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const registerUserDetails = async (csrfToken, body) => {
  let url = `${baseUrl}/api/user/register`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const registerCompanyDetails = async (csrfToken, body) => {
  let url = `${baseUrl}/api/user/register/2`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const registerRetailer = async (csrfToken, body) => {
  let url = `${baseUrl}/api/user/register/retailer`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response
  return data
}
export const getRetailersListing = async (csrfToken, pageNumber) => {
  let url = `${baseUrl}/api/retailers?page=1`
  if (pageNumber) url = `${baseUrl}/api/retailers?page=${pageNumber}`

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()

  return data
}
export const getRetailerById = async (csrfToken, retailerId) => {
  let response = await fetch(`${baseUrl}/api/useo2o/retailer/${retailerId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()

  return data
}
export const getRetailersListingPOST = async (csrfToken, body, pageNumber) => {
  let url = `${baseUrl}/api/retailers?page=1`
  if (pageNumber) url = `${baseUrl}/api/retailers?page=${pageNumber}`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()

  return data
}
export const getBrandsListing = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/brands`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getBrandSingle = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/brand/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })

  return response
  let data = await response.json()

  return data
}
export const getRegionsListing = async (csrfToken, region = null) => {
  let url = `${baseUrl}/api/regions`
  if (region) url = `${baseUrl}/api/regions?country=${region}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTagsListing = async (csrfToken, bundle) => {
  let url = `${baseUrl}/api/tags/default`
  if (bundle) url = `${baseUrl}/api/tags/${bundle}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyBookmarks = async csrfToken => {
  let url = `${baseUrl}/api/my-bookmarks`

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyBookmarkSingle = async (csrfToken, path) => {
  let url = `${baseUrl}/api/my-bookmarks/${path}`

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyBookmarkSingleWithID = async (csrfToken, path, id) => {
  let url = `${baseUrl}/api/my-bookmark/${path}/${id}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getStatusListing = async (csrfToken, pageNumber) => {
  let url = `${baseUrl}/api/status?page=1`
  if (pageNumber) url = `${baseUrl}/api/status?page=${pageNumber}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()

  return data
}
export const addNewStatus = async (csrfToken, body) => {
  let url = `${baseUrl}/api/single-status`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const getStatusListingByBrand = async (
  csrfToken,
  brandId,
  pageNumber
) => {
  let url = `${baseUrl}/api/status/${brandId}?page=${pageNumber}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getSingleProduct = async (csrfToken, productId) => {
  let url = `${baseUrl}/api/products/${productId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  return response
  let data = await response.json()
  return data
}
export const getSingleAssetGroup = async (csrfToken, Id) => {
  let url = `${baseUrl}/api/asset-group/${Id}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getMyOrdersReport = async (csrfToken, companyId) => {
  let url = `${baseUrl}/api/my-report-orders/${companyId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getReportMyHighlights = async (
  csrfToken,
  companyId,
  startDate = "07172022",
  endDate = "08172022"
) => {
  let url = `${baseUrl}/api/reports/highlights/${companyId}/${startDate}-${endDate}`

  // /* */ url = `${baseUrl}/api/reports/highlights/11/07172022-08172022`

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getReportTopPerformers = async (
  csrfToken,
  startDate = "07172022",
  endDate = "08172022"
) => {
  let url = `${baseUrl}/api/reports/top/${startDate}-${endDate}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getReportMyTopPerformers = async (
  csrfToken,
  startDate = "07172022",
  endDate = "08172022",
  companyId,
  brandId = null
) => {
  let url = `${baseUrl}/api/reports/my-top`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      date_range: `${startDate}-${endDate}`,
      company_id: companyId,
      brand_id: brandId,
    }),
  })
  let data = await response.json()
  return data
}

export const getReportRecentOrders = async (
  csrfToken,
  companyId,
  startDate = "07172022",
  endDate = "08172022",
  brandId = null
) => {
  let url = `${baseUrl}/api/reports/recent-orders/${companyId}/${startDate}-${endDate}`
  // url = `${baseUrl}/api/reports/recent-orders/2284/07172022-08172022`

  if (brandId) {
    url = url + `/${brandId}`
  } else {
    url = url + `/all`
  }

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getReportTotalVisits = async (
  csrfToken,
  companyId,
  startDate = "09012022",
  endDate = "09172022",
  brandId = null
) => {
  let url = `${baseUrl}/api/reports/total-visits/${companyId}/${startDate}-${endDate}`
  // url = `${baseUrl}/api/reports/total-visits/15/09012022-09172022`

  if (brandId) {
    url = url + `/${brandId}`
  } else {
    url = url + `/all`
  }

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getReportRecentVisits = async (
  csrfToken,
  companyId,
  startDate = "09012022",
  endDate = "09172022",
  brandId = null
) => {
  let url = `${baseUrl}/api/reports/recent-visits/${companyId}/${startDate}-${endDate}`

  if (brandId) {
    url = url + `/${brandId}`
  } else {
    url = url + `/all`
  }
  // url = `${baseUrl}/api/reports/recent-visits/15/09012022-09172022`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getReportTotalOrders = async (
  csrfToken,
  companyId,
  startDate = "07172022",
  endDate = "08172022"
) => {
  let url = `${baseUrl}/api/reports/total-orders/${companyId}/${startDate}-${endDate}`
  // url = `${baseUrl}/api/reports/total-orders/15/07172022-08172022`

  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getReportOrderSummary = async ({
  token,
  companyId,
  brandId,
  startDate = "07/17/2022",
  endDate = "08/17/2022",
  args = {},
  offset,
  download = false,
}) => {
  console.log(download, "download")
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: `${startDate}-${endDate}`,
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/order-summary`
  if (download === true) url = url + "?dl=true"

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getTrendsAnalysis = async ({
  token,
  companyId,
  brandId,
  date_range = "01/05/2022-01/09/2022",
  time_range = "week",
  args = {},
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range,
    time_range: time_range,
    ...args,
  }
  // body = {
  //   company_id: 15,
  //   date_range: "12/05/2022-30/09/2022",
  //   time_range: "week",
  //   brands: [114],
  //   categories: [6],
  //   tags: [3],
  // }

  let url = `${baseUrl}/api/reports/trends-analysis`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getReportAssetSummary = async ({
  token,
  companyId,
  date_range,
  args = {},
  offset,
  brandId,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/asset-summary`
  if (download === true) url = url + "?dl=true"
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getReportProductSummary = async ({
  token,
  companyId,
  brandId,
  date_range,
  args = {},
  offset,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/product-summary`
  if (download === true) url = url + "?dl=true"
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getReportStoreSummary = async ({
  token,
  companyId,
  brandId,
  date_range,
  args = {},
  offset,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/store-summary`
  if (download === true) url = url + "?dl=true"
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getReportDownloadSummary = async ({
  token,
  companyId,
  brandId,
  date_range,
  args = {},
  offset,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/download-summary`
  if (download === true) url = url + "?dl=true"
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getReportInventorySummary = async ({
  token,
  companyId,
  brandId,
  date_range,
  args = {},
  offset,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }

  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/inventory-summary`
  if (download === true) url = url + "?dl=true"
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getReportCustomerSummary = async ({
  token,
  companyId,
  brandId,
  date_range,
  args = {},
  offset,
  download = false,
}) => {
  let body = {
    company_id: companyId,
    brand_id: brandId,
    date_range: date_range ? date_range : "07/17/2022-08/17/2022",
    ...args,
    // offset: offset,
  }
  if (download === false) body.offset = offset

  let url = `${baseUrl}/api/reports/customer-summary`
  if (download === true) url = url + "?dl=true"

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getProductListing = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/products`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()

  return data
}
export const getAssetListingGroups = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/asset-groups`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })

  let data = await response.json()
  return data
}
export const getAssetListing = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/assets`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()

  return data
}
export const getAssetSubcriptionInventory = async (csrfToken, assetId) => {
  let url = `${baseUrl}/api/subscription/asset-inventory/${assetId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const subcribeAssetInventory = async (csrfToken, body) => {
  let url = `${baseUrl}/api/subscription/asset-inventory`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const getSingleAsset = async (csrfToken, assetId) => {
  let url = `${baseUrl}/api/asset/${assetId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  // let data = await response.json()
  return response
}
export const getSingleAssetRelated = async (csrfToken, assetId) => {
  let url = `${baseUrl}/api/asset/${assetId}/related`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getSingleAssetQty = async (csrfToken, assetId) => {
  let url = `${baseUrl}/api/asset/${assetId}/qty`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const requestAsset = async (csrfToken, body) => {
  let url = `${baseUrl}/api/useo2o/request-asset?_format=json`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const getUser = async csrfToken => {
  let url = `${baseUrl}/api/my`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getUserSubscriptions = async csrfToken => {
  let url = `${baseUrl}/api/my-subscriptions`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const updateUserStock = async (csrfToken, STOCK_ITEM_ID, body) => {
  let url = `${baseUrl}/api/stock/${STOCK_ITEM_ID}
`
  let response = await fetch(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const updateUserSubscriptions = async (csrfToken, body) => {
  let url = `${baseUrl}/api/my-subscriptions`
  let response = await fetch(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const getStoreForm = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-single/store/retail_store`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getStoreFormWithId = async (csrfToken, id) => {
  let url = `${baseUrl}/api/my-single/store/retail_store/${id}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getUserStoresListing = async (csrfToken, id) => {
  // let url = `${baseUrl}/api/stores/${id}`
  let response = await fetch(`${baseUrl}/api/stores/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data

  // const a = await getMyListing(csrfToken, "store", "retail_store")
  // return a
}
export const getUserAddressBookListing = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/address-book`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getUserAddressListing = async (csrfToken, body = {}) => {
  let response = await fetch(`${baseUrl}/api/address-book`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const hideUnhideAddressBook = async (csrfToken, addressBookItemID) => {
  let response = await fetch(
    `${baseUrl}/api/address-book/hide-from-cart/${addressBookItemID}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return { data: data, status: response.status }
}
export const deleteSingleAddressBookItem = async (csrfToken, id) => {
  let url = `${baseUrl}/api/address-book/${id}/delete`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({}),
  })
  return response
  let data = await response.json()
  return data
}
export const getUserFileListingPost = async (csrfToken, companyId, body) => {
  let url = `${baseUrl}/api/files/${companyId}`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getUserFileListing = async (csrfToken, companyId) => {
  let url = `${baseUrl}/api/files/${companyId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyAccount = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/my-account`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const updateMyAccount = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/my-account`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  return response
}
export const filterFileListing = async (csrfToken, USER_ID, body) => {
  let url = `${baseUrl}/api/files/${USER_ID}`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getStoreSingle = async (csrfToken, id) => {
  let url = `${baseUrl}/api/store/${id}`
  let response = await fetch(`${baseUrl}/api/store/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const addUserStore = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/store/new`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  return response
}
export const updateStoreSingle = async (csrfToken, id, body) => {
  let url = `${baseUrl}/api/store/${id}`
  let response = await fetch(`${baseUrl}/api/store/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  return response
}
export const getSubUserListing = async (csrfToken, companyId) => {
  let url = `${baseUrl}/api/subusers/${companyId}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getSubUserSingle = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/subuser/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const addSubUser = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/subuser/new`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ ...body }),
  })
  return response
}
export const updateSubUserSingle = async (csrfToken, id, companyId, body) => {
  let response = await fetch(`${baseUrl}/api/subuser/${id}/${companyId}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  return response
}
export const getSingleFile = async (csrfToken, ID) => {
  let url = `${baseUrl}/api/file/${ID}`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getSingleFileAssignments = async (csrfToken, ID) => {
  let url = `${baseUrl}/api/file/${ID}/assignments`
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const updateSingleFile = async (csrfToken, ID, b) => {
  let url = `${baseUrl}/api/file/${ID}`
  let response = await fetch(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(b),
  })
  // let data = await response.json()
  return response
}

export const addUserFile = async (csrfToken, file) => {
  let url = `${baseUrl}/api/file/new`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file;filename="${file.name}"`,
      "X-CSRF-Token": csrfToken,
    },
    body: file,
  })
  let data = await response.json()
  return { response: response, data: data }
}

export const getUserDownloads = async (csrfToken, filters, pageNumber = 1) => {
  let requestUrl = `${baseUrl}/api/downloads`
  // if (pageNumber) requestUrl = requestUrl + `?page=${pageNumber}`
  let response = await fetch(requestUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ ...filters, page: pageNumber }),
  })
  let data = await response.json()
  return data
}
export const getUserOrders = async (csrfToken, filters) => {
  let requestUrl = `${baseUrl}/api/orders`
  // if (pageNumber) requestUrl = requestUrl + `?page=${pageNumber}`
  let response = await fetch(requestUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(filters),
  })
  let data = await response.json()
  return data
}
export const getUserGroups = async (csrfToken, comparny_Id) => {
  let url = `${baseUrl}/api/subusers`
  let response = await fetch(`${baseUrl}/api/user_groups/${comparny_Id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTags = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/tags/default`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTagsProduct = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/tags/default_product`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTagCategory = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/tags/category`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTagCategoryProduct = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/tags/category_product`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getTagProductType = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/tags/product_type`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getMyProducts = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-products`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getStoreCompanies = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/all-store-companies`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getStoreRegions = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/all-store-regions`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getStoreCities = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/all-store-cities`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getAllCities = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/all-cities`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getDownloadUsersByCompany = async (csrfToken, companyId) => {
  let response = await fetch(
    `${baseUrl}/api/download-users-by-company/${companyId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getOrderUsersByCompany = async (csrfToken, companyId) => {
  let response = await fetch(
    `${baseUrl}/api/order-users-by-company/${companyId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getOrderCompaniesByCompany = async (csrfToken, companyId) => {
  let response = await fetch(
    `${baseUrl}/api/order-companies-by-company/${companyId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getDownloadCompaniesByCompany = async (csrfToken, companyId) => {
  let response = await fetch(
    `${baseUrl}/api/download-companies-by-company/${companyId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getMyAssets = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-assets`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyWebhooks = async (csrfToken, companyId) => {
  let response = await fetch(`${baseUrl}/api/my-webhooks/${companyId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const postMyWebhooks = async (csrfToken, companyId, body) => {
  let response = await fetch(`${baseUrl}/api/my-webhooks/${companyId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}

export const getOrderedAssets = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/orders/assets`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getMyListing = async (
  csrfToken,
  listName,
  bundle = "default",
  page = 1
) => {
  let response = await fetch(
    `${baseUrl}/api/my-listing/${listName}/${bundle}?page=${page}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const getMyListingPagination = async (
  csrfToken,
  listName,
  bundle = "default"
) => {
  let response = await fetch(
    `${baseUrl}/api/my-listing/${listName}/${bundle}/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const getMyListingPost = async (
  csrfToken,
  listName,
  body,
  bundle = "default"
) => {
  let response = await fetch(
    `${baseUrl}/api/my-listing/${listName}/${bundle}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(body),
    }
  )
  let data = await response.json()
  return data
}

export const getMySingleForm = async (csrfToken, listName, bundle) => {
  let response = await fetch(
    `${baseUrl}/api/my-single/${listName}/${bundle ? bundle : "default"}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const getMySingleEditForm = async (
  csrfToken,
  listName,
  BUNDLE,
  ENTITY_ID
) => {
  let response = await fetch(
    `${baseUrl}/api/my-single/${listName}/${BUNDLE}/${ENTITY_ID}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const updateMySingleEditForm = async (
  csrfToken,
  listName,
  BUNDLE,
  ENTITY_ID,
  body
) => {
  let response = await fetch(
    `${baseUrl}/api/my-single/${listName}/${BUNDLE}/${ENTITY_ID}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(body),
    }
  )
  // let data = await response.json()
  return response
}
export const deleteMySingleForm = async (
  csrfToken,
  listName,
  BUNDLE,
  ENTITY_ID
) => {
  let response = await fetch(
    `${baseUrl}/api/my-single/${listName}/${BUNDLE}/${ENTITY_ID}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        json: {
          field_deleted: true,
        },
      }),
    }
  )
  // let data = await response.json()
  return response
}
export const getMyMessageThread = async (csrfToken, WEBFORM_SUBMISSION_ID) => {
  let response = await fetch(
    `${baseUrl}/api/my-inbox/thread/${WEBFORM_SUBMISSION_ID}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const PostMyMessageThread = async (
  csrfToken,
  WEBFORM_SUBMISSION_ID,
  body
) => {
  let response = await fetch(
    `${baseUrl}/api/my-inbox/thread/${WEBFORM_SUBMISSION_ID}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(body),
    }
  )
  // let data = await response.json()
  return response
}
export const getMyMessageSent = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-inbox/sent`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const getMyActiveMessages = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/inbox/active`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getMyArchiveMessages = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/inbox/archived`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const archiveMessage = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/archive-message`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getMyMessageReceived = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-inbox/received`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const entitySuggest = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/entity_suggest`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  // if(data)data= data.json()
  return data
}
export const entitySuggestPublic = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/entity_suggest_public`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  // if(data)data= data.json()
  return data
}
export const entitySuggestions = async (csrfToken, entity, bundle) => {
  let response = await fetch(`${baseUrl}/api/entity_suggest`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      entity: entity,
      bundle: bundle,
    }),
  })
  let data = await response.json()
  return data
}
export const myEntitySuggestions = async (csrfToken, entity, bundle) => {
  let response = await fetch(`${baseUrl}/api/my_entity_suggest`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      entity: entity,
      bundle: bundle,
    }),
  })
  let data = await response.json()
  return data
}
export const myEntitySuggestionsByBody = async (csrfToken, body = {}) => {
  let response = await fetch(`${baseUrl}/api/my_entity_suggest`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getStockListing = async (csrfToken, companyId, page = 1) => {
  let response = await fetch(
    `${baseUrl}/api/stock?i=${companyId}&page=${page}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const getSingleStockListing = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/stock/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const postStockListing = async (csrfToken, filters = {}) => {
  const body = {
    ...filters,
  }

  let response = await fetch(`${baseUrl}/api/stock`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const getLocationFromId = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/location/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const addMySingleNewForm = async (csrfToken, listName, BUNDLE, body) => {
  console.log(body)
  let response = await fetch(
    `${baseUrl}/api/my-single/${listName}/${BUNDLE}/new`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(body),
    }
  )

  return response
}
export const getMyNotifications = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-notifications`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const clearMyNotifications = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/my-notifications`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({}),
  })
  let data = await response.json()
  return data
}
export const clearMyNotification = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/my-notifications/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({}),
  })
  let data = await response.json()
  return data
}
export const makeDownload = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/download`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })

  return response
}
export const addmyBookmark = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/my-bookmark`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })

  return response
}
export const getPageNumbers = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/commons`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const submitWebform = async (csrfToken, body) => {
  let url = `${baseUrl}/api/webform/submit`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}
export const getOnSiteMarketing = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/marketing/on-site`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getOnSiteMarketingAssetsHero = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/marketing/on-site?hero=assets`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getOnSiteMarketingProductsHero = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/marketing/on-site?hero=products`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getOnSiteMarketingCustomLocation = async (csrfToken, id = 1) => {
  // const
  let response = await fetch(
    `${baseUrl}/api/marketing/on-site?location=${id}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const fileUploadDrupal = async (csrfToken, file) => {
  // var reader = new FileReader();
  // let data = reader.readAsBinaryString(file);

  let url = `${baseUrl}/file/upload/media/asset_file/field_media_file`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file;filename="${file.name}"`,
      "X-CSRF-Token": csrfToken,
    },
    body: file,
  })

  let data = await response.json()

  return { response: response, data: data }
}

export const profilePictureUpload = async (csrfToken, body) => {
  let url = `${baseUrl}/api/profile-picture`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })

  let data = await response.json()

  return { response: response, data: data }
}

export const fileUploadToMediaEntityDrupal = async (
  csrfToken,
  bundle,
  companyId,
  data
) => {
  let url = `${baseUrl}/entity/media?_format=json`
  let body = {}
  body.bundle = bundle
  body.uid = data.uid
  body.name = data.filename
  body.field_media_file = [{ target_id: data["fid"][0].value }]
  body.field_media_company = [{ target_id: parseInt(companyId) }]

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}

export const orderDigitalAsset = async (csrfToken, assetId, addresses) => {
  const rand = () => Math.floor(100000000000 + Math.random() * 900000000000)
  let query = `${rand()}${assetId}${rand()}`
  query = btoa(query)
  const body = {
    id: query,
    addresses: addresses,
  }
  let url = `${baseUrl}/api/order/digital`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  return response
}

export const retailerOnboarding = async (csrfToken, data) => {
  let response = await fetch(`${baseUrl}/api/onboarding/retailer`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(data),
  })
  return response
}

export const getCart = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/cart`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}
export const setCart = async (csrfToken, body) => {
  /* deprecated, use addToCart() */
  let url = `${baseUrl}/api/cart`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const addToCart = async (csrfToken, body) => {
  let url = `${baseUrl}/api/cart`
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const processCheckOut = async (
  csrfToken,
  giftOrder = false,
  orderNote = null,
  notificationEmailObj = null
) => {
  let body = giftOrder

  // if (giftOrder !== false) body.gift_order = giftOrder
  // if (orderNote !== null) body.order_note = orderNote
  // if (notificationEmailObj !== null) body.notify = notificationEmailObj

  let response = await fetch(`${baseUrl}/api/process-checkout`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const notificationEmail = async (csrfToken, address_id) => {
  let response = await fetch(
    `${baseUrl}/api/notification-email-by-address/${address_id}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}
export const removeItemFromCart = async (csrfToken, body) => {
  if (typeof body !== "object") return false

  let response = await fetch(`${baseUrl}/api/cart/remove`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const removeAllItemsFromCart = async (csrfToken, body) => {
  if (typeof body !== "object") return false

  let response = await fetch(`${baseUrl}/api/cart/clear`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const adjustQuantityInCart = async (
  csrfToken,
  assetId,
  addressId,
  totalQuantity
) => {
  if (!assetId || !addressId || !totalQuantity) return false

  /* following variable can be refactored to adjust qty for multiple stores/assetIds using an array, just send an array to the func instead of single item ids */
  const arrayToPostWithJustOneAsset = [
    {
      asset_id: assetId,
      addresses: [
        {
          address_id: addressId,
          quantity: totalQuantity,
        },
      ],
    },
  ]

  let response = await fetch(`${baseUrl}/api/cart/adjust-quantity`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(arrayToPostWithJustOneAsset),
  })
  let data = await response.json()
  return data
}

export const trackVisits = async (csrfToken, body) => {
  let url = `${baseUrl}/api/add-track-visit`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()

  return data
}
export const processPayment = async (csrfToken, body) => {
  let url = `${baseUrl}/api/process-payment`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}
export const setAddressAsDefault = async (csrfToken, id) => {
  let url = `${baseUrl}/api/address-book/${id}/set-default`

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({}),
  })
  return response
  let data = await response.json()
  return data
}

export const getDeliveryLocationsByCompany = async (csrfToken, companyId) => {
  let response = await fetch(`${baseUrl}/api/delivery_locations/${companyId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getDeliveryLocationsByCompanyInternal = async (
  csrfToken,
  companyId
) => {
  let response = await fetch(
    `${baseUrl}/api/delivery_locations_internal/${companyId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getAllLocation = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/all-locations`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const getCompanyNotificationSettings = async (csrfToken, companyId) => {
  let response = await fetch(
    `${baseUrl}/api/company/${companyId}/notifications`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  )
  let data = await response.json()
  return data
}

export const getMessageCompanies = async csrfToken => {
  let response = await fetch(`${baseUrl}/api/message-companies`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  let data = await response.json()
  return data
}

export const saveCompanyNotificationSettings = async (
  csrfToken,
  companyId,
  body
) => {
  let response = await fetch(
    `${baseUrl}/api/company/${companyId}/notifications`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(body),
    }
  )
  // let data = await response.json()
  return response
}

export const getInventorySubmission = async (csrfToken, id) => {
  let response = await fetch(`${baseUrl}/api/inventory-submission/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
  // let data = await response.json()
  return response
}

export const patchInventorySubmission = async (csrfToken, id, body) => {
  let response = await fetch(`${baseUrl}/api/inventory-submission/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}

export const postInventorySubmission = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/inventory-submission`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}

export const postInventorySubmissions = async (csrfToken, filtersObj) => {
  const obj = { ...filtersObj }
  let response = await fetch(`${baseUrl}/api/inventory-submissions`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(obj),
  })
  // let data = await response.json()
  return response
}

export const actionRequestAssetRequest = async (
  csrfToken,
  webformSubmissionId,
  action
) => {
  const body = {
    submission_id: webformSubmissionId,
    action: action,
  }
  let response = await fetch(`${baseUrl}/api/asset-request/action`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  // let data = await response.json()
  return response
}

export const getMyUserSegmentationRegions = async (csrfToken, filters) => {
  let response = await fetch(`${baseUrl}/api/user-segmentation-regions`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(filters),
  })
  let data = await response.json()
  return data
}

export const getMyUserSegmentationUsers = async (
  csrfToken,
  companyId,
  filters
) => {
  let response = await fetch(`${baseUrl}/api/user-segmentation-users`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ ...filters, company_id: companyId }),
  })
  let data = await response.json()
  return data
}

export const getAllMessageUsers = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/message-users`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getAllMessageAttachements = async (csrfToken, body) => {
  let response = await fetch(`${baseUrl}/api/message-attachments`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  })
  let data = await response.json()
  return data
}

export const getMyUserSegmentationCompanies = async (csrfToken, filters) => {
  let response = await fetch(`${baseUrl}/api/user-segmentation-companies`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(filters),
  })
  let data = await response.json()
  return data
}

export const getMyUserSegmentationStores = async (csrfToken, filters) => {
  let response = await fetch(`${baseUrl}/api/user-segmentation-stores`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(filters),
  })
  let data = await response.json()
  return data
}
