/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

function FloatingQuantitySelect(props) {
  const { decrement, increment, onChange, count, label, flush, className } =
    props

  const onChangeCallback = e => {
    const { value } = e.target
    onChange(parseInt(value))
  }
  return (
    <div
      className={`relative z-0 ${!flush && `mb-6`} w-full group ${
        className && className
      }`}
    >
      <div className="flex ">
        <div
          className={`custom-number-input flex bg-white flex-row items-center overflow-hidden lg:mr-4`}
          css={css`
            background: #ffffff;
            border: 1px solid #ebebeb;
            box-sizing: border-box;
            border-radius: 8px;
            &:hover {
              border-color: #222;
            }
            min-width: none;
          `}
        >
          <button
            type="button"
            data-action="decrement"
            onClick={decrement}
            className={`h-full px-[20px] cursor-pointer outline-none bg-white overflow-hidden`}
            css={css`
              border-radius: 8px 0 0 8px;
            `}
          >
            <span className="">−</span>
          </button>
          <input
            type="text"
            className={`outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden !py-[16px] !px-[0] z-[1] relative`}
            name="custom-input-number"
            value={count}
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
            onChange={onChange ? onChangeCallback : null}
          />
          <button
            type="button"
            data-action="increment"
            onClick={increment}
            className={`h-full px-[20px] cursor-pointer outline-none bg-white overflow-hidden`}
            css={css`
              border-radius: 0 8px 8px 0;
            `}
          >
            <span className="">+</span>
          </button>
        </div>
        {label && (
          <label
            // for={inputName}
            className="absolute duration-300 transform -translate-y-7 scale-75 top-[15px] left-[12px] z-10 origin-[0] px-[8px] --rm-peer-focus:text-blue-600 peer-placeholder-shown:scale-100 bg-white peer-placeholder-shown:-z-10 peer-placeholder-shown:bg-transparent peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 bg-white peer-focus:bg-white peer-focus:z-10"
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 28px;
              letter-spacing: 0.02em;
              color: #717171;
            `}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export default FloatingQuantitySelect
