/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import FloatingInteger from "../Elements/Form/FloatingInteger"
import FloatingInput from "../Elements/Form/FloatingInput"
import ButtonLite from "../Elements/ButtonLite"
import NotificationContext from "../../context/Notification/NotificationContext"
import DataContext from "../../context/Data/DataContext"
import { AllocationsTable } from "../Table/AllocationsTable"
import FloatingQuantitySelect from "../Elements/Form/FloatingQuantitySelect"
import IconClose from "../Elements/IconClose"
import { Td, Tr } from "../Table/TableElements"
import { PlusSmIcon } from "@heroicons/react/solid"

const Allocations = props => {
  const { toggleNotification } = useContext(NotificationContext)
  const { allocationState, setAllocationState, errors, total } = props
  // const [create, setCreate] = useState(false)
  // console.log(props, "props")
  const { myUserGroups, myUserGroupsAsOptions, universalUserGroups } =
    useContext(DataContext).data

  // const increment = k => {
  //   return
  // }
  // const decrement = k => {
  //   return
  // }

  console.log(allocationState, "internal")

  const addAnother = () => {
    if (parseInt(total) === 0) return

    // set the default allocation to 10 unless the total stock remaining is less than 10, then set it to the total stock remaining in the allocationState is less than 10, then set it to the total stock remaining
    let defaultAllocation = 10
    if (parseInt(total) < 10) {
      defaultAllocation = parseInt(total)
    } else {
      let totalAllocated = 0
      allocationState.forEach(item => {
        totalAllocated += parseInt(item.aloc)
      })
      if (totalAllocated + 10 > parseInt(total)) {
        defaultAllocation = parseInt(total) - totalAllocated
      }
    }

    setAllocationState(prevState => [
      ...prevState,
      {
        roles: [],
        aloc: defaultAllocation.toString(),
        type: "can_order",
        restriction_number: "5",
        restriction_frequency: "daily",
      },
    ])
  }

  const onRemove = k => {
    if (parseInt(total) === 0) return

    let tmpArr = JSON.parse(JSON.stringify([...allocationState]))
    tmpArr.splice(k, 1)
    setAllocationState(tmpArr)
  }

  const updateFieldChanged = (stateIndex, fieldName, fieldValue) => {
    if (parseInt(total) === 0) return

    if (fieldName === "aloc") {
      // check that total allocated does not exceed total in stock
      let totalAllocated = 0
      allocationState.forEach((item, k) => {
        if (k !== stateIndex) {
          totalAllocated += parseInt(item.aloc)
        }
      })
      totalAllocated += parseInt(fieldValue)
      if (totalAllocated > parseInt(total)) {
        toggleNotification({
          error: true,
          content: "You've reached the maximum stock available.",
        })

        return
      }
    }

    let newArr = [...allocationState]
    newArr[stateIndex][fieldName] = fieldValue
    setAllocationState(newArr)
  }

  const typesOptions = [
    { value: "can_order", label: "Can Order" },
    { value: "must_request", label: "Must Request" },
  ]

  const frequencyOptions = [
    { value: "daily", label: "Per day" },
    { value: "weekly", label: "Per week" },
    // { value: "biweekly", label: "Biweekly" },
    { value: "monthly", label: "Per month" },
    { value: "yearly", label: "Per year" },
  ]

  return (
    <div className="relative">
      {parseInt(total) === 0 && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.4)] z-[1] rounded-[8px]"
          css={css`
            backdrop-filter: blur(4px);
          `}
        >
          <div className="bg-white rounded-[8px] px-8 py-10 absolute top-[100px] mx-auto left-0 right-0 w-[50%] shadow-[0_10px_30px_rgba(0,0,0,.2)]">
            <h2 className="text-strong-25 !text-left">Sorry!</h2>
            <p className="text-reg-16 mb-4">
              This item must be in stock before allocation inventory.
            </p>
          </div>
        </div>
      )}
      <div
        className={
          parseInt(total) === 0 ? `p-6 h-[400px] overflow-hidden` : `p-0`
        }
      >
        <p
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            display: inline-block;
            padding: 0 10px;
            margin: 0px 0 15px;
            letter-spacing: 0.02em;
            color: #717171;
          `}
        >
          Select 1 or more User Segments and assign their Per Segment
          Availability and Per Location Order Limits. Allocations are
          prioritized from most specific User Segment to least; Users, Stores,
          Companies and then Regions.
        </p>
        <AllocationsTable
          columns={[
            "",
            "Per Segment Availability",
            "Per Location Order Limits",
            "",
          ]}
          sm
          overflowVisible
        >
          {allocationState.map((item, k) => {
            // const { title } = myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] ? myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] : ''
            // console.log(k)

            // console.log(myUserGroupsAsOptions, 'sss')
            // console.log(myUserGroupsAsOptions.filter(f => parseInt(f.value) === parseInt(item.roles[0]))[0])

            return (
              <Tr key={k}>
                <Td className="min-w-[220px] !pr-0">
                  <div className="flex flex-col">
                    <FloatingSelect
                      label="User Segment"
                      name="User Segment"
                      options={[
                        ...universalUserGroups.map(item => {
                          let groupType = ""
                          if (item.companies.length)
                            groupType = "Retail companies"
                          if (item.stores.length) groupType = "Retail stores"
                          if (item.regions.length)
                            groupType = "Regional retailers"
                          if (item.users.length) groupType = "Company users"
                          return Object.assign({
                            value: item.id,
                            label: item.title,
                            type: groupType,
                          })
                        }),
                        ...myUserGroups.map(item => {
                          let groupType = ""
                          if (item.field_group_companies.length)
                            groupType = "Retail companies"
                          if (item.field_group_stores.length)
                            groupType = "Retail stores"
                          if (item.field_group_regions.length)
                            groupType = "Regional retailers"
                          if (item.field_group_users.length)
                            groupType = "Company users"
                          return Object.assign({
                            value: item.id,
                            label: item.title,
                            type: groupType,
                          })
                        }),
                      ]}
                      subtitleSelector="type"
                      value={
                        item.roles.length
                          ? [
                              ...universalUserGroups.map(i =>
                                Object.assign({ value: i.id, label: i.title })
                              ),
                              ...myUserGroupsAsOptions,
                            ].filter(
                              f => parseInt(f.value) === parseInt(item.roles[0])
                            )[0]
                          : null
                      }
                      className="!mb-0"
                      onChange={v => {
                        updateFieldChanged(
                          k,
                          "roles",
                          v?.value ? [v.value] : []
                        )
                      }}
                      error={
                        item.roles.length === 0 || errors.has(k)
                          ? { message: "This field is required." }
                          : false
                      }
                      required={true}
                    />
                  </div>
                </Td>

                <Td>
                  <div className="flex space-x-1">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.aloc) > 0)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) - 1)
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) + 1)
                      }}
                      count={item.aloc}
                      label={"Qty"}
                      onChange={v => {
                        if (isNaN(v)) updateFieldChanged(k, "aloc", parseInt(0))
                        else updateFieldChanged(k, "aloc", parseInt(v))
                      }}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      label="Type"
                      name="type"
                      options={typesOptions}
                      onChange={v => {
                        updateFieldChanged(k, "type", v.value || null)
                      }}
                      required={true}
                      value={
                        typesOptions
                          .map(i => i.value)
                          .includes(allocationState[k].type)
                          ? typesOptions.filter(
                              f => allocationState[k].type === f.value
                            )[0]
                          : typesOptions[0]
                      }
                    />
                  </div>
                </Td>
                <Td>
                  <div className="flex space-x-1">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.restriction_number) > 0)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) - 1
                          )
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) + 1
                          )
                      }}
                      onChange={v => {
                        if (isNaN(v))
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(0)
                          )
                        else
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(v)
                          )
                      }}
                      count={parseInt(item.restriction_number)}
                      label={"Qty"}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      options={frequencyOptions}
                      onChange={v => {
                        updateFieldChanged(k, "restriction_frequency", v.value)
                      }}
                      value={
                        frequencyOptions
                          .map(i => i.value)
                          .includes(allocationState[k].restriction_frequency)
                          ? frequencyOptions.filter(
                              f =>
                                allocationState[k].restriction_frequency ===
                                f.value
                            )[0]
                          : frequencyOptions[0]
                      }
                      label="Frequency"
                      required={true}
                    />
                  </div>
                </Td>
                <Td className={"!p-[0]"}>
                  <IconClose
                    callback={() => onRemove(k)}
                    small
                    height={2}
                    width={2}
                  />
                </Td>
              </Tr>
            )
          })}
        </AllocationsTable>

        <div className="mt-3 mb-8 max-w-[200px]">
          <ButtonLite
            type="button"
            inline
            callback={() => addAnother()}
            customContent={
              <div className="pt-5 flex cursor-pointer">
                <div className="w-[26px] h-[26px] text-[#2e669b] !hover:text-[#2e669b] rounded-full flex items-center justify-center border-4 border-[#2e669b]">
                  <PlusSmIcon />
                </div>
                <div className="text-[#2e669b] font-semibold text-lg pl-2">
                  Add Allocation
                </div>
              </div>
            }
            colorHover="#2e669b"
          ></ButtonLite>
        </div>
      </div>
    </div>
  )
}

const CreateAssetInventory = ({
  current,
  edit,
  body,
  setBody,
  formErrors,
  setFormErrors,
  segmentErrors,
  setSegmentErrors,
  setRetailersAloc,
  retailersAloc,
  setInternalAloc,
  internalAloc,
  watch,
}) => {
  const { myUserGroups, universalUserGroups } = useContext(DataContext).data
  const [allocationState, setAllocationState] = useState([])
  const [prevSegmentsO2O, setPrevSegmentsO2O] = useState([])
  const [prevSegmentsInHouse, setPrevSegmentsInHouse] = useState([])
  const [prevSegmentsThirdParty, setPrevSegmentsThirdParty] = useState([])
  const [stock, setStock] = useState(0)
  const [unAloc, setunAloc] = useState(0)

  console.log(body)
  const warehouseTypes = [
    { label: "O2O Warehouse", value: "o2o" },
    { label: "In-house", value: "in-house" },
    { label: "Third party", value: "third-party" },
  ]
  const warehouseTypeDigital = [
    { label: "Digital Storage", value: "digital-storage" },
  ]

  useEffect(() => {
    if (current && current.segments) {
      setAllocationState(current.segments)
    }
  }, [current])
  useEffect(() => {
    setBody(prevState => ({
      ...prevState,
      segments: allocationState,
    }))
  }, [allocationState])

  useEffect(() => {
    if (body?.fulfillment_type !== "o2o") {
      setBody(prevState => ({
        ...prevState,
        fulfillment: {
          ...prevState.fulfillment,
          name: null,
          stock: null,
        },
      }))
    }
  }, [body?.fulfillment_type])

  useEffect(() => {
    if (!edit) {
      if (watch().field_asset_type === "digital") {
        setBody(prevState => ({
          ...prevState,
          fulfillment_type: "digital-storage",
          stock_o2o: 0,
          stock_in_house: 0,
          stock_third_party: 0,
        }))
      } else {
        setBody(prevState => ({
          ...prevState,
          fulfillment_type: "o2o",
          stock_o2o: 0,
          stock_in_house: 0,
          stock_third_party: 0,
        }))
      }

    }
  }, [watch().field_asset_type])

  useEffect(() => {
    if (!body) {
      return
    }
    const x = []
    let fields = ["notif_total", "notif_rep", "notif_ret", "fulfillment_type"]
    if (body?.fulfillment_type === "in-house")
      fields = [...fields, "fulfillment_name", "stock_in_house"]
    if (body?.fulfillment_type === "third-party")
      fields = [...fields, "fulfillment_name", "stock_third_party"]
    Object.keys(body)
      .filter(f => fields.includes(f))
      .map((item, k) => {
        if (
          item == "stock_in_house" &&
          parseInt(body[item]) <
            body?.segments
              .map(i => i.aloc)
              .reduce((partialSum, a) => partialSum + a, 0)
        ) {
          x.push(item)
        } else if (
          item == "stock_third_party" &&
          parseInt(body[item]) <
            body?.segments
              .map(i => i.aloc)
              .reduce((partialSum, a) => partialSum + a, 0)
        ) {
          x.push(item)
        } else if (body[item] === null || body[item] === "") {
          x.push(item)
        }
      })

    const y = new Set()
    body?.segments.map((f, k) => {
      if (f.roles.length === 0) y.add(k)
    })
    setFormErrors(x)
    setSegmentErrors(y)
  }, [current, body])

  const returnGroupType = id => {
    let all = [
      ...universalUserGroups.map(item => {
        let groupType = ""
        if (item.companies.length) groupType = "Retail companies"
        if (item.stores.length) groupType = "Retail stores"
        if (item.regions.length) groupType = "Regional retailers"
        if (item.users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
      ...myUserGroups.map(item => {
        let groupType = ""
        if (item.field_group_companies.length) groupType = "Retail companies"
        if (item.field_group_stores.length) groupType = "Retail stores"
        if (item.field_group_regions.length) groupType = "Regional retailers"
        if (item.field_group_users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
    ]

    let role = all.filter(f => parseInt(f.value) === parseInt(id))[0]
    return role?.type || null
  }

  useEffect(() => {
    console.log(body?.stock_in_house, "xxx")
    if (body?.fulfillment_type === "o2o") setStock(body?.stock_o2o)
    if (body?.fulfillment_type === "in-house") setStock(body?.stock_in_house)
    if (body?.fulfillment_type === "third-party")
      setStock(body?.stock_third_party)
  }, [body])

  useEffect(() => {
    if (stock > 0) {
      let internalaloc = 0
      let retailersaloc = 0
      allocationState.map(v => {
        if (v.roles.length) {
          let type = returnGroupType(v.roles[0])
          if (type === "Company users") internalaloc += parseInt(v.aloc)
          else retailersaloc += parseInt(v.aloc)
        }
      })
      setRetailersAloc(retailersaloc)
      setInternalAloc(internalaloc)

      setunAloc(stock - (internalaloc + retailersaloc))
    }
  }, [allocationState, stock])
  return (
    <div className="mb-4">
      <span
        // className="text-sm"
        css={css`
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          display: inline-block;
          padding: 0 10px;
          margin: 0px 0 15px;
          letter-spacing: 0.02em;
          color: #717171;
        `}
      >
        Select where this asset will be stored and fulfilled from, and the total
        quantity you have at that location. The “In Stock Quantity” will be
        automatically updated for all assets stored with and fulfilled by the
        O2O Warehouse.
      </span>
      <div className="gap-5 flex justify-center max-h-[100px]">
        {watch().field_asset_type === "physical" ? (
          <FloatingSelect
            value={
              body?.fulfillment_type
                ? warehouseTypes.filter(
                    f => f.value === body?.fulfillment_type
                  )[0]
                : null
            }
            label="Warehouse Type"
            name="type"
            required={true}
            options={warehouseTypes}
            onChange={e => {
              console.log(e, "e")

              if (body?.fulfillment_type === "o2o") {
                setPrevSegmentsO2O(allocationState)
              }
              if (body?.fulfillment_type === "in-house") {
                setPrevSegmentsInHouse(allocationState)
              }
              if (body?.fulfillment_type === "third-party") {
                setPrevSegmentsThirdParty(allocationState)
              }

              const obj = { fulfillment_type: e?.value || null }

              if (e?.value === "o2o" && prevSegmentsO2O.length) {
                setAllocationState(prevSegmentsO2O)
              } else if (
                e?.value === "in-house" &&
                prevSegmentsInHouse.length
              ) {
                setAllocationState(prevSegmentsInHouse)
              } else if (
                e?.value === "third-party" &&
                prevSegmentsThirdParty.length
              ) {
                setAllocationState(prevSegmentsThirdParty)
              } else {
                setAllocationState([])
              }

              setBody(prevState => ({
                ...prevState,
                ...obj,
                // fulfillment_type: e?.value || null,
              }))
            }}
            error={
              formErrors.includes("fulfillment_type")
                ? { message: "This field must have a value." }
                : false
            }
          />
        ) : (
          <FloatingSelect
            value={{ label: "Digital Storage", value: "digital-storage" }}
            label="Warehouse Type"
            name="type"
            required={true}
            options={warehouseTypeDigital}
            error={
              formErrors.includes("fulfillment_type")
                ? { message: "This field must have a value." }
                : false
            }
          />
        )}
        {body?.fulfillment_type === "digital-storage" && (
          <>
            <FloatingInteger
              value={"infinite"}
              subdued
              disabled={true}
              label="In Stock Quantity (Digital Storage)"
              name="stock_digital_storage"
              required={false}
              onChange={e => {}}
              type="text"
            />
          </>
        )}
        {body?.fulfillment_type === "o2o" ? (
          <>
            {" "}
            <FloatingInteger
              value={body?.stock_o2o}
              subdued
              disabled={body?.fulfillment_type === "o2o"}
              label="In Stock Quantity (O2O Warehouse)"
              name="stock_o2o"
              required={false}
              onChange={e => {}}
            />
          </>
        ) : (
          <>
            {body?.fulfillment_type === "in-house" && (
              <FloatingInteger
                value={body?.stock_in_house}
                label="In Stock Quantity (In house)"
                name="stock_in_house"
                required={false}
                onChange={e => {
                  setBody(prevState => ({
                    ...prevState,
                    stock_in_house: parseInt(e.target.value) || null,
                  }))
                }}
                error={
                  formErrors.includes("stock_in_house")
                    ? {
                        message:
                          body?.stock_in_house <
                          body?.segments
                            .map(i => i.aloc)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            ? "Your total allocations equal more than this quantity. Either increase the quantity in-stock, or modify your allocations."
                            : "This field must have a value.",
                      }
                    : false
                }
              />
            )}
            {body?.fulfillment_type === "third-party" && (
              <FloatingInteger
                value={body?.stock_third_party}
                label="In Stock Quantity (Third party)"
                name="stock_third_party"
                required={false}
                onChange={e => {
                  setBody(prevState => ({
                    ...prevState,
                    stock_third_party: parseInt(e.target.value) || null,
                  }))
                }}
                error={
                  formErrors.includes("stock_third_party")
                    ? {
                        message:
                          body?.stock_third_party <
                          body?.segments
                            .map(i => i.aloc)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            ? "Your total allocations equal more than this quantity. Either increase the quantity in-stock, or modify your allocations."
                            : "This field must have a value.",
                      }
                    : false
                }
              />
            )}
          </>
        )}
      </div>
      <div className="flex mt-6 mb-6 items-end border border-1 border-[#EBEBEB] rounded-[8px] px-4 py-4">
        <div className="mr-6 mb-2">
          <span className="text-strong-20 !leading-[1] mr-1">{stock}</span>
          <span>in stock</span>
        </div>
        <div className="grow flex items-center">
          <div
            className={`w-[${
              stock === 0 ? 33.3 : (internalAloc / stock) * 100
            }%] mr-1`}
            style={{
              color: "#0033A1",
              width: `${stock === 0 ? 33.3 : (internalAloc / stock) * 100}%`,
              minWidth: "11%",
            }}
          >
            <div className="text-[14px]">for Internal</div>
            <div className="w-[100%] rounded-l-lg h-[40px] bg-[#0033A1] text-center text-white p-[7px]">
              {internalAloc}
            </div>
          </div>
          <div
            className={`w-[${
              stock === 0 ? 33.3 : (retailersAloc / stock) * 100
            }%] mr-1`}
            style={{
              color: "#009D4F",
              width: `${stock === 0 ? 33.3 : (retailersAloc / stock) * 100}%`,
              minWidth: "11%",
            }}
          >
            <div className="text-[14px]">for Retailers</div>
            <div className="w-[100%] h-[40px] bg-[#009D4F] text-center text-white p-[7px]">
              {retailersAloc}
            </div>
          </div>
          <div
            className={`w-[${stock === 0 ? 33.3 : (unAloc / stock) * 100}%] `}
            style={{
              color: "rgba(0,0,0,.3)",
              width: `${stock === 0 ? 33.3 : (unAloc / stock) * 100}%`,
            }}
          >
            <div className="text-[14px]">Unallocated</div>
            <div
              className="w-[100%] rounded-r-lg h-[40px] bg-[#EBEBEB] text-center p-[7px]"
              style={{ color: "rgba(0,0,0,.3)" }}
            >
              {unAloc}
            </div>
          </div>
        </div>
      </div>
      <Allocations
        total={stock}
        errors={segmentErrors}
        allocationState={allocationState}
        setAllocationState={setAllocationState}
      />
    </div>
  )
}

export default CreateAssetInventory
