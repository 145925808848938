/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState } from "react"
import { classNames } from "../../../../utils"
import ButtonEdit from "../../../Elements/ButtonEdit"
import ButtonDelete from "../../../Elements/ButtonDelete"

function BoxCard(props) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }
  const {
    children,
    title,
    callbackEdit,
    callbackDelete,
    tr,
    deleteDisable,
    className,
    extra,
    field_hide_from_cart,
  } = props
  return (
    <div
      className={`border border-[#EBEBEB] rounded-[8px] w-full ${
        className ? className : ``
      }`}
      style={
        field_hide_from_cart === "1"
          ? { opacity: isHovered ? 1 : 0.6, transition: "opacity 0.2s ease" }
          : {}
      }
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <header
        className={classNames(
          "flex items-center border-b border-[#EBEBEB] py-5 px-6",
          tr && "justify-between"
        )}
      >
        <h1 className="text-semibold-18">{title}</h1>
        {tr && <p className="href-standard-alt">{tr}</p>}
      </header>
      <footer className="flex flex-col py-4 px-6 pb-6">
        <p className="text-reg-16 mt-4 mb-5">{children}</p>
        <div className="flex items-center space-x-4">
          {callbackEdit && (
            <>
              <ButtonEdit text="Edit" onClick={callbackEdit} />
              {/*<button className="href-standard" onClick={callbackEdit}>
              Edit
            </button>*/}
            </>
          )}

          {callbackDelete && (
            <>
              <ButtonDelete
                disable={deleteDisable ? true : false}
                text="Delete"
                dia
                onClick={callbackDelete}
              />
              {/*<button
              className="href-standard href-standard-alt"
              onClick={callbackDelete}
            >
              Delete
            </button>*/}
            </>
          )}
        </div>
        {extra && extra}
      </footer>
    </div>
  )
}

export default BoxCard
