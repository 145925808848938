/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useContext, useEffect } from "react"
import { classNames } from "../../utils"
import Icon from "../../assets/icons/shop1.svg"
import FloatingInput from "../Elements/Form/FloatingInput"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import CircleAvatar from "../Elements/CircleAvatar"

import Textarea from "../Elements/Textarea"
import Button from "../Elements/Button"
import { red, green, redLight, greenLight } from "../Styles"
import Modal from "../Modal/Modal"
import DataContext from "../../context/Data/DataContext"
import { useForm } from "react-hook-form"
import {
  addNewStatus,
  fileUploadToMediaEntityDrupal,
  fileUploadDrupal,
} from "../../helpers/DrupalHelper"
import NotificationContext from "../../context/Notification/NotificationContext"
import Media from "../Forms/DynamicForm/FormTypes/Media"
import LazyImage from "../LazyImage"
import mapSingle from "../../utils/mapSingle"
export const ActivityFeedCreateModal = ({
  open,
  setOpen,
  onSave,
  token,
  brandId,
  brandTitle,
}) => {
  const { toggleNotification } = useContext(NotificationContext)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()
  const onSubmit = async data => {
    for (let key in data) {
      // console.log(key)
      if (data[key] === undefined) data[key] = null
    }
    // console.log(data)

    let body = { ...data, attachments: [] }
    // console.log(body)
    await mapSingle(
      Object.keys(data),
      async v => {
        if (v.includes("fromUpload_")) {
          let name = v.split("_")[1]
          let files = data[v]
          if (files.length) {
            let ids = []

            await mapSingle(
              files,
              async (file, k) => {
                let res = await fileUploadDrupal(token, file)
                // console.log(res);
                if (res.response.ok) {
                  let res2 = await fileUploadToMediaEntityDrupal(
                    token,
                    "asset_file",
                    res.data
                  )
                  toggleNotification({ content: `${file.name} Uploaded` })
                  let rees2Data = await res2.json()
                  ids.push(rees2Data.mid[0].value)
                  delete body[v]
                } else
                  toggleNotification({
                    content: `Fail to Upload ${file.name} `,
                  })
              },
              1
            )
            body.attachments = ids
          }
        }
      },
      1
    )
    console.log(body)
    let res = await addNewStatus(token, body)
    if (res.status === 200) {
      toggleNotification({ content: `${data.title} post added` })
      let resdata = res.json()
      // console.log(resdata)
      onSave(data, "added")
      setOpen(false)
    } else {
      toggleNotification({ content: `${data.title} fail to add`, error: true })
      onSave(false)
    }
  }
  const { myBrandsAsOptions } = useContext(DataContext).data
  const [title, setTitle] = useState(
    myBrandsAsOptions.length === 1 ? myBrandsAsOptions[0]?.label : ""
  )
  const [brandValue, setBrandValue] = useState(myBrandsAsOptions[0])

  useEffect(() => {
    if (brandId) {
      setValue("brand", [brandId])
      setTitle(brandTitle)
    } else {
      if (myBrandsAsOptions.length) {
        console.log(myBrandsAsOptions)
        setBrandValue(myBrandsAsOptions[0])
        setValue("brand", [myBrandsAsOptions[0].value])
        setTitle(myBrandsAsOptions[0]?.label)
      }
    }
  }, [myBrandsAsOptions])
  useEffect(() => {
    register("brand", { required: "This field is required" })

    // register("assets", { required: "This field is required" })
    // register("products", { required: "This field is required" })
  }, [])
  console.log(brandId)
  return (
    <Modal
      isOpen={open}
      closeModal={() => {
        setOpen(false)
      }}
      title={
        myBrandsAsOptions.length > 1
          ? `Post an Update as ${title}`
          : `Post an Update`
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FloatingInput
          label="Headline"
          hookForm
          name="title"
          error={errors["title"]}
          register={register}
          required
        />
        <FloatingTextarea
          label="Share news, stories, or updates"
          placeholder="Share news, stories, or updates"
          required
          hookForm
          name="body"
          error={errors["body"]}
          register={register}
        />
        {!brandId && myBrandsAsOptions.length && myBrandsAsOptions.length > 1 && (
          <FloatingSelect
            label="Select Brand"
            // placeholder="Start typing..."
            // type={"myBrandsAsOptions"}
            myBrands={true}
            // isSearchable

            required
            defaultValue={myBrandsAsOptions[0]}
            // hookForm
            // name="brand"
            value={brandValue}
            // register={register}
            error={errors["brand"]}
            options={myBrandsAsOptions}
            // control={control}
            onChange={v => {
              console.log(v)
              setTitle(v.label)
              setBrandValue(v)
              setValue("brand", [v.value])
            }}
          />
        )}
        <FloatingSelect
          label="Tag an Asset"
          placeholder="Start typing..."
          type={"myAssetsAsOptions"}
          isClearable
          isSearchable
          name="assets"
          hookForm
          register={register}
          error={errors["assets"]}
          control={control}
          isMulti
          hideIfEmpty
        />
        <FloatingSelect
          label="Tag a Product"
          placeholder="Start typing..."
          type={"myProductsAsOptions"}
          isClearable
          isSearchable
          name="products"
          hookForm
          register={register}
          error={errors["products"]}
          control={control}
          isMulti
          hideIfEmpty
        />
        <Media
          data={{
            default_value: "",
            description: "",
            entities: [{ machine_name: "image", title: "image" }],
            entity_type: { machine_name: "media", title: "media item" },
            value_limit: false,
          }}
          errors={errors}
          name={"attachments"}
          register={register}
          label={"Select existing media"}
          setValue={setValue}
          watch={watch}
          custom
        />
        <div className="mt-[25px]">
          <Button type="submit" red full>
            Post Status
          </Button>
        </div>
      </form>
    </Modal>
  )
}
function ActivityFeedCreateCard(props) {
  const { isPage } = props
  const [open, setOpen] = useState(false)

  return (
    <li className={`pb-[60px] ${isPage === true && `md:pl-[58px]`}`}>
      <div className="mt-[25px]">
        <div className="flex flex-col-reverse md:flex-row items-center">
          <div className="mt-[30px] md:mt-0 md:border-r md:border-[#EBEBEB] md:pr-10 md:mr-10">
            <Button
              onClick={() => setOpen(true)}
              red
              css={css`
                &&& {
                  width: 180px;
                }
              `}
            >
              Post an Update
            </Button>
          </div>
          <div className="text-center md:text-left">
            <h2 className="text-semibold-18">What's New With Your Brand?</h2>
            <p className="mt-[10px] md:mt-0 !leading-[23px] md:!leading-[30px] text-reg-14">
              Share updates on your Brand or Products{" "}
              <span className="text-underline">exclusively</span> with O2O
              retailers.
            </p>
          </div>
        </div>
      </div>

      <ActivityFeedCreateModal
        open={open}
        setOpen={setOpen}
        onSave={props.onSave}
        token={props.token}
        brandId={props.brandId}
        brandTitle={props.brandTitle}
      />
    </li>
  )
}

export default ActivityFeedCreateCard
